import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import "./video-search-result.css";
import Loader from "./loader";

// const openInNewTab = (url) => {
//   const newWindow = window.open(url, "_blank", "noopener,noreferrer");
//   if (newWindow) newWindow.opener = null;
// };

const VideoSearchResult = (props) => {
  const { snippet, id, loader } = props;
  const [title, setTitle] = useState(snippet?.title);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 720) {
        const titleWords = snippet?.title.split(' ');
        setTitle(titleWords.slice(0, 4).join(' ') + '...');
      } else {
        setTitle(snippet?.title);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [snippet?.title]);

  const formattedTitle = title.replace(/&#(\d+);/g, (match, dec) => {
    return String.fromCharCode(dec);
  });

  return (
    <div className="video-search-result-container">
      <div
        onClick={() => window.open(`https://www.youtube.com/watch?v=${id?.videoId}`)}
        className="video-search-result-video-result-div"
      >
        <div className="video-search-result-video-result-image">
          <img
            alt={props.image_alt}
            src={snippet?.thumbnails?.high?.url}
            className="video-search-result-video-result-icon"
          />
        </div>
        <div className="video-search-result-video-result-link-title-description">
          <span className="video-search-result-video-result-link">
            {`https://www.youtube.com/watch?v=${id?.videoId}`}
          </span>
          <span className="video-search-result-video-result-title"
            dangerouslySetInnerHTML={{ __html: formattedTitle }}
          />
          <span className="video-search-result-video-result-description">
            {snippet?.description}
          </span>
        </div>
      </div>
    </div>
  );
};


VideoSearchResult.defaultProps = {
  rootClassName: "",
  image_src: "/playground_assets/share.1%203.svg",
  TitleYoutubeResult: "Juventus",
  linkYoutubeResult: "https://youtube.com › wiki › Serie_A",
  image_alt: "image",
  DescriptionYoutubeResult:
    "10 ore fa — Il Campionato di Serie A, colloquialmente abbreviato in Serie A e ufficialmente denominato Serie A TIM dall'edizione 1998-1999 per ragioni di ...",
};

VideoSearchResult.propTypes = {
  rootClassName: PropTypes.string,
  image_src: PropTypes.string,
  TitleYoutubeResult: PropTypes.string,
  linkYoutubeResult: PropTypes.string,
  image_alt: PropTypes.string,
  DescriptionYoutubeResult: PropTypes.string,
};

export default VideoSearchResult;
