import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import "./tab-power-search.css";
import NewmenuDesktop from "../components/newmenudesktop";
const TabPowerSearch = (props) => {
  const history = useHistory();




  const translations = {
    en: {
     
      'ps1': "News",
      'ps2': 'Corp.',
    },
    it: {

  
      'ps1': 'Notizie',
      'ps2': 'Aziende',
    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser

  const ps1a = translate('ps1', lang);
  const ps2a = translate('ps2', lang);



  return (
    <div className={`tab-power-search-container ${props.rootClassName} `} >
      <div style={{cursor:'pointer'}}
        onClick={() => {
          history.push("/power-search");
        }}
        className="tab-power-search-aziende-tab-div"
      >
        <span className="tab-power-search-aziende-text">{props.Web1}</span>
        {props.type === "web" && (
          <img
            alt={props.image_alt}
            src={props.image_src}
            className="tab-power-search-selection-underline"
          />
        )}
      </div>
      <div style={{cursor:'pointer'}}
        onClick={() => {
          history.push("/news-search");
        }}
        className="tab-power-search-notizie-tab-div"
      >
        <span className="tab-power-search-notizie-tab-text">
        {ps1a}
        </span>
        {props.type === "news" && (
          <img
            alt={props.image_alt}
            src={props.image_src}
            className="tab-power-search-selection-underline"
          />
        )}
      </div>
      <div style={{cursor:'pointer'}} onClick={() => {
          history.push("/aziende-search");
        }} className="tab-power-search-aziende-tab-div">
        <span className="tab-power-search-aziende-text">{ps2a}</span>
        {props.type === "aziende" && (
          <img
            alt={props.image_alt}
            src={props.image_src}
            className="tab-power-search-selection-underline"
          />
        )}
      </div>
      {/* <div style={{cursor:'pointer'}} onClick={() => {
          history.push("/maps");
        }} className="tab-power-search-aziende-tab-div">
        <span className="tab-power-search-aziende-text">Maps</span>
        {props.type === "maps" && (
          <img
            alt={props.image_alt}
            src={props.image_src}
            className="tab-power-search-selection-underline"
          />
        )}
      </div> */}
      <div  style={{cursor:'pointer'}} onClick={() => {
          history.push("video-search");
        }} className="tab-power-search-video-tab-div">
        <span className="tab-power-search-video-tab-text">{props.Video}</span>
        {props.type === "video" && (
          <img
            alt={props.image_alt}
            src={props.image_src}
            className="tab-power-search-selection-underline"
          />
        )}
      </div>
      {/* <div style={{cursor:'pointer'}}  onClick={() => {
          history.push("social-search");
        }} className="tab-power-search-social-tab-div">
        <span className="tab-power-search-social-text">{props.Social}</span>
        {props.type === "social" && (
          <img
            alt={props.image_alt}
            src={props.image_src}
            className="tab-power-search-selection-underline"
          />
        )}
      </div> */}
      {/* <div  onClick={() => {
          history.push("prodotti-search");
        }} style={{cursor:'pointer'}} className="tab-power-search-prodotti-tab-div">
        <span className="tab-power-search-prodotti-text">{props.Prodotti}</span>
        {props.type === "prodotti" && (
          <img
            alt={props.image_alt}
            src={props.image_src}
            className="tab-power-search-selection-underline"
          />
        )}
      </div> */}
    </div>
  );
};

TabPowerSearch.defaultProps = {
  Social: "Social",
  Web1: "Web",
  Aziende: "Aziende",
  image_alt: "image",
  Video: "Video",
  rootClassName: "",
  Prodotti: "Prodotti",
  Notizie: "Notizie",
  image_src: "/playground_assets/line%201.svg",
};

TabPowerSearch.propTypes = {
  Social: PropTypes.string,
  Web1: PropTypes.string,
  Aziende: PropTypes.string,
  image_alt: PropTypes.string,
  Video: PropTypes.string,
  rootClassName: PropTypes.string,
  Prodotti: PropTypes.string,
  Notizie: PropTypes.string,
  image_src: PropTypes.string,
};

export default TabPowerSearch;
