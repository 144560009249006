import { useHistory } from "react-router-dom";
import PopUpFolder1 from "./pop-up-folder1";
import React, { useEffect, useRef, useState } from "react";
const SharedFolder = (props) => {
  
  const history = useHistory();
  const { data } = props;
  const [value, setValue] = useState(props.data.name);
  const [disabled, setDisabled] = useState(true);
  const [show, setShow] = useState(false);
  const inputRef = useRef();
  const handlePopUp = () => {
    setShow((prev) => !prev);
  };
  const reName = () => {};

  useEffect(() => {
    if (data?.addNew === true) {
      setDisabled(false);
    }
  }, [JSON.stringify(data)]);

  return (
    <div className="tutte-le-cartelle-group87">
      <img
        src="/playground_assets/rectangle66762-pcrg.svg"
        alt="Rectangle66762"
        className="archivio-cartelle-condivise-rectangle6"
      />
      <img
        src="/playground_assets/dots16762-udjh.svg"
        alt="dots16762"
        className="archivio-cartelle-condivise-dots1"
        onClick={handlePopUp}
      />
      <img
        src="/playground_assets/folderblank16762-65ul.svg"
        alt="FolderBlank16762"
        className="archivio-cartelle-condivise-folder-blank1"
        onClick={() => {
          if (disabled) {
            history.push({
              pathname: "/archivio-dettaglio",
              state: { data: data },
            });
          }
        }}
      />
      {show ? (
        <PopUpFolder1
          deleteFolder={() => {
            setShow(false);
            props.deleteFolder(props?.data?._id);
          }}
          rename={reName}
          disable={(val) => {
            setDisabled(val);
            if (val) {
              inputRef.current.focus();
            }
          }}
          show={setShow}
          handleSharePopUp={() => props.handleSharePopUp(props?.data?._id)}
        />) : ("")}
      <span className="archivio-cartelle-condivise-text">
        <span className="">
          {" "}
          <input
            ref={inputRef}
            autoFocus={true}
            className={disabled ? "inputDisabled" : "rename"}
            disabled={disabled}
            type="text"
            placeholder={props?.data?.addNew ? "Nome" : "Rename"}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                if (props?.data?.addNew) {
                  props.handleRequest(value, () => {
                    setDisabled(true);
                  });
                } else {
                  props.handleRequest(value, props?.data?._id, () => {
                    setDisabled(true);
                  });
                }
              }
            }}
          />
          {disabled ? (
            ""
          ) : (
            <img
              width={20}
              onClick={() => {
                setDisabled(true);
              }}
              className="close2"
              src="/playground_assets/cancel.png"
            />
          )}
        </span>
      </span>

      <span className="archivio-cartelle-condivise-text02">
        <span className="">{props?.data?.createdAt}</span>
      </span>
      <span className="archivio-cartelle-condivise-text04">
        <span className="">Condivisa</span>
      </span>
      
    </div>
  );
};

export default SharedFolder;
