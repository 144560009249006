import React from "react";
import "./loader.css";

const LoaderPowerSearch = (props) => {
  return (
 
        <div className="loader-containerSearch">
        <div className="loaderSearch"></div>
        <div className="loader-text">Analyzing criticalities...</div>
      </div>
  );
};

export default LoaderPowerSearch;
