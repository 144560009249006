import {takeLatest, put} from 'redux-saga/effects';
import * as types from '../../actions/types';
import {Api} from '../../../utils';
import {endPoints} from '../../../utils';

export function* getSurveyResponseAndCompleteProfileRequest() {
  yield takeLatest(
    types.GET_SURVEY_RESPONSE_AND_COMPLETE_PROFILE,
    getSurveyResponseAndCompleteProfile,
  );
}

function* getSurveyResponseAndCompleteProfile(params) {
  try {
    let options = {
      headers: {
        Authorization: 'Bearer ' + params.token,
      },
    };
    let configuration = Object.assign(options);
    let response = yield Api.getAxios(
      endPoints.getSurveyResponseAndCompleteProfile,
      configuration,
    );
    if (response?.status) {
      params.cbSuccess(response.data);
    } else {
      params.cbFailure(response);
    }
  } catch (err) {
    console.log('error from get survey fields request saga -- > > >  > ', err);
    params.cbFailure(err.message);
  }
}
