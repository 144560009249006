import React, { useContext, useState, useEffect } from "react";
import {
  addFolderRequest,
  getFolderRequest,
  renameFolderRequest,
  deleteFolderRequest,
} from "../redux/actions";
import ComponentHeadArchivio from "./component-head-archivio";
import ArchivioCartelleCondivise from "./archivio-cartelle-condivise";
import TutteLeCartelle from "./tutte-le-cartelle";
import "./archivio-component.css";
import { SearchContext } from "../context/search_context";
import { useSelector, useDispatch } from "react-redux";
import SharePopup from "./SharePopup";
import SearchBar2 from "./search-bar2";
import Loader from "./loader";
import { AuthContext } from "../context/authContext";
import { useHistory } from "react-router-dom";
const ArchivioComponent = (props) => {
  const {
    firstName,
    setFirstName,
    iva,
    setIva,
    email,
    setEmail,
    loading,
    nickName,
    setNickName,
    accountType,
    setAccountType,
    address,
    setAddress,
    common,
    setCommon,
    country,
    setCountry,
    handelProfileEnterprice,
    image,
    setImage,
  } = useContext(AuthContext);
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [showTempFolder, setShowTempFolder] = useState(false);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState();
  const [userFolders, setUserFolders] = useState([]);

  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);

  const { tab } = useContext(SearchContext);
  useEffect(() => {
    setLoader(true);
    handleGetFoldersRequest();
    if (!auth?.data?.token) {
      setLoader(false);
    }
  }, [JSON.stringify(auth?.data?.token)]);

  const handleGetFoldersRequest = () => {
    const cbSuccess = (response) => {
      const tempUserFolders = response?.data?.data?.folders?.filter(
        (item) => !auth?.data?.shared_folders?.includes(item?._id)
      );
      setUserFolders([...(tempUserFolders || [])]);
      setLoader(false);
    };
    const cbFailure = (error) => {
      setLoader(false);
      alert(error.message, [{ text: "OK" }]);
    };
    if (auth?.data?.token) {
      setUserFolders([]);
      setLoader(true);
      dispatch(getFolderRequest(auth?.data?.token, cbSuccess, cbFailure));
    }
  };

  const handleDeleteFolderRequest = (id, shared = false) => {
    const cbSuccess = (response) => {
      handleGetFoldersRequest();
      
    };
    const cbFailure = (error) => {
      setLoader(false);
      alert(error.message);
    };
    const data = new FormData();
    data.append("id", id);
    data.append("shared", shared);
    if (auth?.data?.token) {
      setLoader(true);
      dispatch(
        deleteFolderRequest(data, auth?.data?.token, cbSuccess, cbFailure)
      );
    }
  };

  const handleAddFolderRequest = (folderName, cbs) => {
    if (folderName.length > 0) {
      const cbSuccess = (response) => {
        setShowTempFolder(false);
        cbs();
        setShowTempFolder(false);
        handleGetFoldersRequest();
      };
      const cbFailure = (error) => {
        setShowTempFolder(false);
        cbs();
        console.log("error", error);
        setLoader(false);
        alert(error.message, [{ text: "OK" }]);
      };
      const data = new FormData();
      data.append("name", folderName);
      if (auth?.data?.token) {
        setLoader(true);

        dispatch(
          addFolderRequest(data, auth?.data?.token, cbSuccess, cbFailure)
        );
      }
    } else {
      alert(
        "per favore aggiungi il nome della cartella e seleziona la cartella per rinominare",
        [{ text: "OK" }]
      );
    }
  };

  //handel rename
  const handleRenameFolderRequest = (name, id, cbs) => {
    if (name.length > 0) {
      const cbSuccess = (response) => {
        setLoader(false);
        cbs();
        handleGetFoldersRequest();
      };
      const cbFailure = (error) => {
        setLoader(false);
        alert(error.message);
      };
      const data = new FormData();
      data.append("name", name);
      data.append("folder_id", id);
      if (auth?.data?.token) {
        setLoader(true);
        dispatch(
          renameFolderRequest(data, auth?.data?.token, cbSuccess, cbFailure)
        );
      }
    } else {
      alert("si prega di aggiungere il nome della cartella");
    }
  };

  const handleSharePopUp = (id) => {
    setSelectedFolder(id);
    setShowSharePopup((prev) => !prev);
  };


  const translations = {
    en: {
      'Archiviotutte': 'All your folders',
      'Archiviotuttenf': 'No folder found, try creating one!',
    },
    it: {
      
      'Archiviotutte': 'Tutte le tue cartelle',
      'Archiviotuttenf': 'Nessuna cartella trovata, prova a crearne una!',
    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser
  const Archiviotuttec = translate('Archiviotutte', lang);

  const Archiviotuttef = translate('Archiviotuttenf', lang);


  return (
    <div className="archivio-component-container">
         <div className="searchbararchivio" style={{marginBottom:'90px', marginLeft:'-400px'}}>
          {/* <SearchBar2 />  */}
          </div>
      <ComponentHeadArchivio
        rootClassName="component-head-archivio-root-class-name"
        addfolder={() => setShowTempFolder((prev) => !prev)}
      /> 
      {loader && <Loader />}
      <>
      
        <ArchivioCartelleCondivise
          deleteFolder={handleDeleteFolderRequest}
          handelRenameFolder={handleRenameFolderRequest}
          handelAddFolder={handleAddFolderRequest}
          handelGetFolder={handleGetFoldersRequest}
          handelSharePopUp={handleSharePopUp}
          rootClassName="archivio-cartelle-condivise-root-class-name"
        />

        {showSharePopup && selectedFolder ? (
          <SharePopup
            folderId={selectedFolder}
            setShowSharePopup={setShowSharePopup}
          />
        ) : (
          ""
        )}
             <div className="tuttecartcolumn">
   <span className="tutte-le-cartelle-text">
            {userFolders?.length > 0 || showTempFolder ? (
              <span className="">{Archiviotuttec}</span>
            ) : (
              <span className="">{Archiviotuttef}</span>
            )}
          </span>
          </div>
<div className="cartellegrids">
  
        <div
          className="tutte-le-cartelle-tutte-le-cartelle
      tutte-le-cartelle-root-class-name"
        >
     
       

          {showTempFolder && (
            <TutteLeCartelle
              deleteFolder={() => setShowTempFolder(false)}
              handleSharePopUp={handleSharePopUp}
              handleRequest={handleAddFolderRequest}
              data={{
                name: "",
                addNew: true,
              }}
            />
          )}

          {userFolders?.map((element) => {
            return (
              <TutteLeCartelle
                handleSharePopUp={handleSharePopUp}
                tab={tab}
                deleteFolder={handleDeleteFolderRequest}
                handleRequest={handleRenameFolderRequest}
                data={element}
              />
            );
          })}
        </div>
        </div>
      </>
    </div>
  );
};

export default ArchivioComponent;
