import React, { useState, useContext } from 'react';
import './newAccountPopUp.css'; // Ensure you have the corresponding CSS file
import { AuthContext } from "../context/authContext";

const NewAccountPopUp = ({ closePopup }) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { createAccounts } = useContext(AuthContext);

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Call the createAccounts function from the AuthContext
    createAccounts({ email, password });
    // Close the pop-up
    closePopup();
  };

  return (
    <div className="new-account-modal">
      <div className="new-account-content">
        <span className="close" onClick={closePopup}>&times;</span>
        <div className="new-account-header">
          New Account
        </div>
        <div className="new-account-instructions">
          Create a new Weagle Account by setting Email and Password
        </div>
        <form className="new-account-form" onSubmit={handleSubmit}>
          <div className='new-account-inputs'>
            <label htmlFor="email">Email*</label>
            <input
              type="email"
              id="email"
              placeholder="mail@example.com"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className='new-account-inputs password-input'>
            <label htmlFor="password">Password*</label>
            <input
              type={passwordShown ? "text" : "password"}
              id="password"
              placeholder="Min. 8 characters"
              required
              minLength="8"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span className="password-eye" onClick={togglePasswordVisibility}>
              {passwordShown ? (
                <svg className="eye-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/>
                  <circle cx="12" cy="12" r="3"/>
                </svg>
              ) : (
                <svg className="eye-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M17.5 6.5c-1.5-1.5-4-1.5-5.5 0"/>
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/>
                  <path d="M12 15c-1.66 0-3-1.34-3-3 0-1.66 1.34-3 3-3 1.66 0 3 1.34 3 3 0 1.66-1.34 3-3 3z"/>
                </svg>
              )}
            </span>
            <button type="submit" className="new-account-create-btn">Create</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewAccountPopUp;