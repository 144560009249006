import React, { useContext } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import "./extensionBlockNotification.css";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/authContext";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const ExtensionBlockNotification = () => {
  let history = useHistory();
  const { cisoInfo } = useContext(AuthContext);

  // Get the data for malware detected, phishing blocked, and other threats
  const threatData = cisoInfo.statistics.reduce((acc, group) => {
    const { dailyMalwareFound, dailyPhishingFound, otherThreats } = group;

    Object.entries(dailyMalwareFound || {}).forEach(([date, count]) => {
      acc.malwareDetected.push({ date, count });
    });

    Object.entries(dailyPhishingFound || {}).forEach(([date, count]) => {
      acc.phishingBlocked.push({ date, count });
    });

    Object.entries(otherThreats || {}).forEach(([date, count]) => {
      acc.otherThreats.push({ date, count });
    });

    return acc;
  }, { malwareDetected: [], phishingBlocked: [], otherThreats: [] });

  // Calculate the percentage change compared to the previous month for each threat type
  const calculatePercentageChange = (currentMonthData, previousMonthData) => {
    const currentMonthTotal = currentMonthData.reduce((total, { count }) => total + count, 0);
    const previousMonthTotal = previousMonthData.reduce((total, { count }) => total + count, 0);

    if (previousMonthTotal === 0) {
      return currentMonthTotal > 0 ? 100 : 0;
    }

    const percentageChange = ((currentMonthTotal - previousMonthTotal) / previousMonthTotal) * 100;
    return percentageChange.toFixed(2);
  };

  // Get the data for the current month and the previous month
  const currentDate = new Date();
  const currentMonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const currentMonthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  const previousMonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
  const previousMonthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);

  const currentMonthMalwareData = threatData.malwareDetected.filter(
    ({ date }) => new Date(date) >= currentMonthStart && new Date(date) <= currentMonthEnd
  );
  const previousMonthMalwareData = threatData.malwareDetected.filter(
    ({ date }) => new Date(date) >= previousMonthStart && new Date(date) <= previousMonthEnd
  );

  const currentMonthPhishingData = threatData.phishingBlocked.filter(
    ({ date }) => new Date(date) >= currentMonthStart && new Date(date) <= currentMonthEnd
  );
  const previousMonthPhishingData = threatData.phishingBlocked.filter(
    ({ date }) => new Date(date) >= previousMonthStart && new Date(date) <= previousMonthEnd
  );

  const currentMonthOtherThreatsData = threatData.otherThreats.filter(
    ({ date }) => new Date(date) >= currentMonthStart && new Date(date) <= currentMonthEnd
  );
  const previousMonthOtherThreatsData = threatData.otherThreats.filter(
    ({ date }) => new Date(date) >= previousMonthStart && new Date(date) <= previousMonthEnd
  );

  const malwarePercentageChange = calculatePercentageChange(
    currentMonthMalwareData,
    previousMonthMalwareData
  );
  const phishingPercentageChange = calculatePercentageChange(
    currentMonthPhishingData,
    previousMonthPhishingData
  );
  const otherThreatsPercentageChange = calculatePercentageChange(
    currentMonthOtherThreatsData,
    previousMonthOtherThreatsData
  );

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    scales: {
      x: {
        display: false
      },
      y: {
        display: false
      }
    },
    elements: {
      point:{
        radius: 0 // Hides the points
      }
    },
    maintainAspectRatio: false
  };

  const stats = [
    {
      title: "Malware Detected",
      count: currentMonthMalwareData.reduce((total, { count }) => total + count, 0),
      change: `${malwarePercentageChange >= 0 ? '+' : ''}${malwarePercentageChange}% than last month`,
      chartData: {
        labels: threatData.malwareDetected.map(({ date }) => date),
        datasets: [
          {
            data: threatData.malwareDetected.map(({ count }) => count),
            fill: false,
            borderColor: 'rgb(54, 162, 235)', // Adjusted to match the image color
            tension: 0.1
          }
        ]
      }
    },
    {
      title: "Phishing Blocked",
      count: currentMonthPhishingData.reduce((total, { count }) => total + count, 0),
      change: `${phishingPercentageChange >= 0 ? '+' : ''}${phishingPercentageChange}% than last month`,
      chartData: {
        labels: threatData.phishingBlocked.map(({ date }) => date),
        datasets: [
          {
            data: threatData.phishingBlocked.map(({ count }) => count),
            fill: false,
            borderColor: 'rgb(255, 99, 132)', // Adjusted to match the image color
            tension: 0.1
          }
        ]
      }
    },
    {
      title: "Other Threats",
      count: currentMonthOtherThreatsData.reduce((total, { count }) => total + count, 0),
      change: `${otherThreatsPercentageChange >= 0 ? '+' : ''}${otherThreatsPercentageChange}% than last month`,
      chartData: {
        labels: threatData.otherThreats.map(({ date }) => date),
        datasets: [
          {
            data: threatData.otherThreats.map(({ count }) => count),
            fill: false,
            borderColor: 'rgb(75, 192, 192)', // Adjusted to match the image color
            tension: 0.1
          }
        ]
      }
    },
  ];

  return (
    <div className="extension-block-notification">
      {stats.map((stat, index) => (
        <div key={index} className={`stat-item ${index === stats.length - 1 ? "last-item" : ""}`}>
          <div className="stattitlecountchange">
            <div className="stat-title">{stat.title}</div>
            <div className="stat-count">{stat.count}</div>
            <div className="stat-change">{stat.change}</div>
          </div>
          <div className="stat-chart">
            <Line data={stat.chartData} options={chartOptions} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ExtensionBlockNotification;
