// PrivacyToggleComponentPopUp.js
import React from 'react';
import './privacyToggleComponentPopUp.css';

const PrivacyToggleComponentPopUp = ({
  title,
  description,
  enabledStatus,
  disabledStatus,
  value,
  onChange,
  disabled,
  className
}) => {
  const handleToggleChange = () => {
    onChange(!value);
  };

  return (
    <div className={`toggleComponent-popup ${className}`}>
      <div className="toggleComponent-header">
        <h2>{title}</h2>
      </div>
      <div className="toggleComponent-setting">
        <span className="toggleComponent-text">{description}</span>
        <div className="toggleComponent-toggle">
          <label className="switch">
            <input
              type="checkbox"
              checked={value}
              onChange={handleToggleChange}
              disabled={disabled}
            />
            <span className="slider"></span>
          </label>
          <span className="toggleComponent-status">
            {value ? enabledStatus : disabledStatus}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PrivacyToggleComponentPopUp;
