import { takeLatest, put } from "redux-saga/effects";
import * as types from "../../actions/types";
import { Api } from "../../../utils";
import { endPoints } from "../../../utils";

export function* getUserDataRequest() {
  yield takeLatest(types.GET_USER_DATA, getUserData);
}

function* getUserData(params) {
  try {
    let options = {
      headers: {
        Authorization: "Bearer " + params.token,
      },
    };
    console.log(options);
    let configuration = Object.assign(options);
    let response = yield Api.getAxios(endPoints.getUserData, configuration);
    if (response?.data.status) {
      yield put({type: types.SET_PROFILE_DATA, data: response.data.data});
      params.cbSuccess(response.data);
    } else {
      params.cbFailure(response.data);
    }
  } catch (err) {
    console.log("error from get user data request saga -- > > >  > ", err);
    params.cbFailure(err.message);
  }
}
