// AiTokenManagement.jsx
import React, { useState, useEffect } from 'react';
import './aiTokenManagement.css';

const AiTokenManagement = ({ groups, onTokenAssignmentChange, cisoInfo, selectedGroup, onTokensAssignmentChange }) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (selectedGroup) {
      setInputValue(selectedGroup.features.aiSettings.aiCredits.toFixed(0));
    }
  }, [selectedGroup]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  }; 

  const handleInputBlur = () => {
    const value = parseFloat(inputValue);
    if (!isNaN(value)) {
      onTokenAssignmentChange(selectedGroup.groupName, value);
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleInputBlur();
    }
  };

  const handleTokensAssignmentChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      onTokensAssignmentChange(selectedGroup.groupName, value);
    }
  };

  // Calculate the total tokens assigned to all groups
  const totalAssignedTokens = groups.reduce((total, group) => {
    return total + (group.features.aiSettings.aiCreditsRefill || 0);
  }, 0);

  return (
    <div className="ai-token-management-container">
      {/* AI Token Management Section */}
      <div className="ai-token-management-section">
        <h2>AI Token Management</h2>
        <p>You can set the number of monthly token available for each Role or Group. With the Token refil you con add more tokens to a group from your token balance.</p>
        <div className="ai-token-info">
       
          <div className="ai-token-data">
            <span>Actual Tokens Available</span>
            <span>{cisoInfo.gptCredits.toFixed(0)}</span>
          </div>
          <div className="ai-token-data">
            <span>Tokens Refill</span>
            <input
              type="number"
              step="0.01"
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onKeyDown={handleInputKeyDown}
              disabled={!selectedGroup}
              style={{
                WebkitAppearance: 'none',
                MozAppearance: 'textfield',
                appearance: 'none'
              }}
            />
          </div>
          <div className="ai-token-data">
            <span>Tokens Assignment</span>
            <input
              type="number"
              step="0.01"
              value={selectedGroup?.features?.aiSettings?.aiCreditsRefill?.toFixed(0) || ''}
              onChange={handleTokensAssignmentChange}
              disabled={!selectedGroup}
              style={{
                WebkitAppearance: 'none',
                MozAppearance: 'textfield',
                appearance: 'none'
              }}
            />
          </div>
        </div>
      </div>

      {/* Token Distribution Section */}
      <div className="token-distribution">
        <div className="token-distribution-header">
      
        <div className="total-assigned-tokens">
        <span>Total Tokens:</span>
        <span>&nbsp;</span>
        <span>{cisoInfo.planGptCredits.toFixed(0)}</span>
    </div>
      
          <h2>Token Distribution</h2>
    
        </div>
        <div className="token-table">
          {/* Groups Section */}
          <div className="token-table-section">
           
        
            <h3 className="AitokensTableRows">
              <span>Groups</span>
              <span>Token Count</span>
              <span> Monthly </span>
            </h3>
      

            {groups.map((group) => (
              <div key={group.groupName} className="token-table-row">
                <span>{group.groupName.length > 20 ? `${group.groupName.slice(0, 20)}...` : group.groupName}</span>
                <span className='spanrowai1'>{group.features?.aiSettings?.aiCredits?.toFixed(0) || '0.00'}</span>
                <span className='spanrowai2'>{group.features?.aiSettings?.aiCreditsRefill?.toFixed(0) || '0.00'}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiTokenManagement;
