import React, { useState } from 'react';
import './PrivacyToggleBlackListComponent.css';
import { FaTimes } from 'react-icons/fa';

const PrivacyToggleBlackListComponent = ({ value, onChange, disabled, blacklist, updateBlacklist }) => {
    const handleToggleChange = () => {
        onChange(!value);
    };

    const [isBlacklistManagerOpen, setIsBlacklistManagerOpen] = useState(false);
    const [newURL, setNewURL] = useState('');

    const toggleBlacklistManager = () => {
        setIsBlacklistManagerOpen(!isBlacklistManagerOpen);
    };

    const handleURLChange = (e) => {
        setNewURL(e.target.value);
    };

    const handleURLKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleAddURL();
        }
    };

    const handleAddURL = () => {
        if (newURL.trim() !== '') {
            const newBlacklist = [...blacklist, newURL.trim()];
            updateBlacklist(newBlacklist);
            setNewURL('');
        }
    };

    const handleRemoveURL = (index) => {
        const newBlacklist = blacklist.filter((_, i) => i !== index);
        updateBlacklist(newBlacklist);
    };

    return (
        <div className="toggleComponent-popup">
            <div className="toggleComponent-header">
                <h2>Login Block System</h2>
            </div>
            <div className="toggleComponent-setting">
                <span className="toggleComponent-text">
                    Set up the blocking system to use the <br /> company email as the ID for login on the sites <br /> listed in the blacklist.
                </span>
                <div className="toggleComponent-toggleBlacklist">
                    <label className="switch">
                        <input
                            type="checkbox"
                            checked={value}
                            onChange={handleToggleChange}
                            disabled={disabled}
                        />
                        <span className="slider"></span>
                    </label>
                    <span className="toggleComponent-status">
                        {value ? "Login Blocked" : "Login Unblocked"}
                    </span>
                </div>
                <div className="content-access-buttons loginBlockBlacklistbutton">
                    <button onClick={toggleBlacklistManager}>Manage Blacklist</button>
                </div>
            </div>
            {isBlacklistManagerOpen && (
                <div className="content-access-popup">
                    <div className="content-access-popup-content">
                        <div className="content-access-popup-header">
                            <h3>Manage Blacklist</h3>
                            <button className="content-access-popup-close" onClick={toggleBlacklistManager}>
                               x
                            </button>
                        </div>
                        <div className="content-access-popup-whitelist">
                            <input
                                type="text"
                                value={newURL}
                                onChange={handleURLChange}
                                onKeyDown={handleURLKeyPress}
                                placeholder="Add website URL to blacklist"
                            />
                            <button onClick={handleAddURL}>Add Site</button>
                        </div>
                        <div className="content-access-popup-whitelist-list-container">
                            <ul className="content-access-popup-whitelist-list">
                                {blacklist.map((url, index) => (
                                    <li key={index}>
                                        {url.length > 30 ? `${url.slice(0, 30)}...` : url}
                                        <button className="content-access-popup-remove" onClick={() => handleRemoveURL(index)}>
                                            <FaTimes />
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PrivacyToggleBlackListComponent;
