import React, {useContext, useEffect, useState} from "react";
import "./reportsStatisticsWidgets.css";
import { useHistory } from "react-router-dom";
import UsageStatisticsComponent from "./usageStatisticsComponent";
import VulnerabilityAnalysisComponent from "./vulnerabilityAnalysisComponent";
import RisktreatmentComponent from "./risktreatmentComponent";
import ThreatsComponent from "./threatsComponent";
import AdBlockTrackingreports from "./adBlockTrackingreports";


const ReportsStatisticsWidgets = (props) => {
  
  return (
    <div className="ReportsStatisticsContainer" >
        <div className="AccountListNameSearch">
      <div className="AccountListNameSearchc1">
        
<span className="accountListTitle">REPORTS & STATISTICS</span>
        </div>
   
      </div>
          <div className="ReportsStatisticsContainerc1" >
      
          <UsageStatisticsComponent />
          
          <div className="ReportsStatisticsContainerc1c" >
      
          <ThreatsComponent />
      <AdBlockTrackingreports />
              </div>
                  </div>
                  <div className="ReportsStatisticsContainerc2" >
                  <div className="ReportsStatisticsContainerc2c" >
                  <VulnerabilityAnalysisComponent />
                  
     
              </div>
               
                  <RisktreatmentComponent />
     
              </div>
           

            </div>
  
 
  ); 
};

export default ReportsStatisticsWidgets;
