import * as TYPES from '../../actions/types';

const initialState = {
  search: false,
  keyword: '',
  list: [],
};

const searchInput = (state = initialState, actions) => {
  switch (actions.type) {
    case TYPES.SEARCH_INPUT:
      console.log(actions.params);
      return {
        ...state,
        search: true,
        keyword: actions.params,
      };
    case TYPES.SEARCH_API_SUCCESS:
      return {
        ...state,
        list: actions.data,
      };
    default:
      return state;
  }
};
export default searchInput;
