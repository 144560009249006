import React from 'react'

import PropTypes from 'prop-types'

import './coupon-welfare.css'

const CouponWelfare2 = (props) => {
  return (
    <div className={`coupon-welfare-container ${props.rootClassName} `}>
      <div className="coupon-welfare-coupon-welfare-container">
        <div className="coupon-welfare-frame5024">
          <div className="coupon-welfare-frame5022">
            <div className="coupon-welfare-frame5021">
              <div className="coupon-welfare-frame5020">
                <span className="coupon-welfare-text">
                  <span className="">Booking - B&B</span>
                </span>
                <span className="coupon-welfare-text02">
                  {/* <span className="">-10%</span> */}
                </span>
              </div>
              <span className="coupon-welfare-text04">
                <span className="">
                  <span className="">
                  Bed & Breakfast
Scegli un bed & breakfast! I migliori b&b.


                  </span>
                  <br className=""></br>
                  {/* <span className="">266 caratteri</span> */}
                </span>
              </span>
            </div>
            <div className="coupon-welfare-frame5008">
              <div className="coupon-welfare-frame5030">
                <img
                  src="https://www.hotelvanessa.ch/assets/prizes/booking-5054da0ae2529b21f0621dd13cd05560bbfa599383aaeeb82dff2e94802b5e88.png"
                  alt="image95251"
                  className="coupon-welfare-image9"
                />
                <div className="coupon-welfare-frame5003">
                  <span className="coupon-welfare-text09">
                    <span className="">Viaggi</span>
                  </span>
                </div>
                <div className="coupon-welfare-frame5004">
                  <span className="coupon-welfare-text11">
                    {/* <span className="">Scade il 26/06/22</span> */}
                  </span>
                </div>
              </div> 
              <a target="_blank" href='https://www.booking.com/bed-and-breakfast/index.html?aid=7954906'>
              <div className="coupon-welfare-frame13">
                <span className="coupon-welfare-text13">
                  <span className="">Scopri i dettagli</span>
                </span>
                <img
                  src="/playground_assets/vector24951-98.svg"
                  alt="Vector24951"
                  className="coupon-welfare-vector2"
                />
              </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CouponWelfare2.defaultProps = {
  rootClassName: '',
}

CouponWelfare2.propTypes = {
  rootClassName: PropTypes.string,
}

export default CouponWelfare2
