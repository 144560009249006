import React, { useContext, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import './threatsComponent.css';
import DashboardDatePicker from './dashboardDatePicker';
import { AuthContext } from "../context/authContext";
import DashbooardstatisticsGroupPicker from './dashbooardstatisticsGroupPicker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const ThreatsComponent = () => {
  const { cisoInfo } = useContext(AuthContext);
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: new Date(),
    end: new Date(),
    label: ''
  });
  const [selectedGroup, setSelectedGroup] = useState('All Groups');

  const handleDateRangeSelected = (dateRange) => {
    setSelectedDateRange(dateRange);
  };

  const handleGroupSelected = (group) => {
    setSelectedGroup(group);
  };

  // Get the data for malware detected, phishing blocked, and other threats within the selected date range and group
  const threatData = cisoInfo.statistics.reduce((acc, group) => {
    if (selectedGroup === 'All Groups' || group.groupName === selectedGroup) {
      const { dailyMalwareFound, dailyPhishingFound, otherThreats } = group;

      Object.entries(dailyMalwareFound || {}).forEach(([date, count]) => {
        const threatDate = new Date(date);
        if (threatDate >= new Date(selectedDateRange.start) && threatDate <= new Date(selectedDateRange.end)) {
          acc.malwareDetected.push({ date, count });
        }
      });

      Object.entries(dailyPhishingFound || {}).forEach(([date, count]) => {
        const threatDate = new Date(date);
        if (threatDate >= new Date(selectedDateRange.start) && threatDate <= new Date(selectedDateRange.end)) {
          acc.phishingBlocked.push({ date, count });
        }
      });

      Object.entries(otherThreats || {}).forEach(([date, count]) => {
        const threatDate = new Date(date);
        if (threatDate >= new Date(selectedDateRange.start) && threatDate <= new Date(selectedDateRange.end)) {
          acc.otherThreats.push({ date, count });
        }
      });
    }
    return acc;
  }, { malwareDetected: [], phishingBlocked: [], otherThreats: [] });

  // Calculate the percentage change compared to the previous month for each threat type
  const calculatePercentageChange = (currentMonthData, previousMonthData) => {
    const currentMonthTotal = currentMonthData.reduce((total, { count }) => total + count, 0);
    const previousMonthTotal = previousMonthData.reduce((total, { count }) => total + count, 0);

    if (previousMonthTotal === 0) {
      return currentMonthTotal > 0 ? 100 : 0;
    }

    const percentageChange = ((currentMonthTotal - previousMonthTotal) / previousMonthTotal) * 100;
    return percentageChange.toFixed(2);
  };

  // Get the data for the current month and the previous month
  const currentDate = new Date();
  const currentMonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const currentMonthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  const previousMonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
  const previousMonthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);

  const currentMonthMalwareData = threatData.malwareDetected.filter(
    ({ date }) => new Date(date) >= currentMonthStart && new Date(date) <= currentMonthEnd
  );
  const previousMonthMalwareData = threatData.malwareDetected.filter(
    ({ date }) => new Date(date) >= previousMonthStart && new Date(date) <= previousMonthEnd
  );

  const currentMonthPhishingData = threatData.phishingBlocked.filter(
    ({ date }) => new Date(date) >= currentMonthStart && new Date(date) <= currentMonthEnd
  );
  const previousMonthPhishingData = threatData.phishingBlocked.filter(
    ({ date }) => new Date(date) >= previousMonthStart && new Date(date) <= previousMonthEnd
  );

  const currentMonthOtherThreatsData = threatData.otherThreats.filter(
    ({ date }) => new Date(date) >= currentMonthStart && new Date(date) <= currentMonthEnd
  );
  const previousMonthOtherThreatsData = threatData.otherThreats.filter(
    ({ date }) => new Date(date) >= previousMonthStart && new Date(date) <= previousMonthEnd
  );

  const malwarePercentageChange = calculatePercentageChange(
    currentMonthMalwareData,
    previousMonthMalwareData
  );
  const phishingPercentageChange = calculatePercentageChange(
    currentMonthPhishingData,
    previousMonthPhishingData
  );
  const otherThreatsPercentageChange = calculatePercentageChange(
    currentMonthOtherThreatsData,
    previousMonthOtherThreatsData
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      }
    }
  };

  return (
    <div className="threatscomponentcontainer">
      <div className="threatscomponentTitleDateDiv">
        <span className='threatscomponentTitle'>Threats</span>
        <DashbooardstatisticsGroupPicker
          groups={cisoInfo.groups}
          onGroupSelected={handleGroupSelected}
        />
        <DashboardDatePicker onDateRangeSelected={handleDateRangeSelected} />
   
      </div>

      <div className="doublegraphdivthreats">
        <div className="doublegraphdivc1threats">
          <span className='titlethreatschart'>Malware Detected</span>
          <span className='numberthreatschart'>
            {currentMonthMalwareData.reduce((total, { count }) => total + count, 0)}
          </span>
          <span className='percentagethreatschart'>
            {malwarePercentageChange >= 0 ? '+' : ''}{malwarePercentageChange}% than last month
          </span>
        </div>
        <div className="doublegraphdivc2threats">
          <Line
            data={{
              labels: threatData.malwareDetected.map(({ date }) => date),
              datasets: [
                {
                  fill: true,
                  label: 'Malware Detected',
                  data: threatData.malwareDetected.map(({ count }) => count),
                  backgroundColor: '#D86B5366',
                  borderColor: '#D86B5366',
                  pointBackgroundColor: '#D86B5366',
                  tension: 0.4
                },
              ],
            }}
            options={options}
          />
        </div>
      </div>

      <div className="doublegraphdivthreats">
        <div className="doublegraphdivc1threats">
          <span className='titlethreatschart'>Phishing Blocked</span>
          <span className='numberthreatschart'>
            {currentMonthPhishingData.reduce((total, { count }) => total + count, 0)}
          </span>
          <span className='percentagethreatschart'>
            {phishingPercentageChange >= 0 ? '+' : ''}{phishingPercentageChange}% than last month
          </span>
        </div>
        <div className="doublegraphdivc2threats">
          <Line
            data={{
              labels: threatData.phishingBlocked.map(({ date }) => date),
              datasets: [
                {
                  fill: true,
                  label: 'Phishing Blocked',
                  data: threatData.phishingBlocked.map(({ count }) => count),
                  backgroundColor: '#95B5E5',
                  borderColor: '#95B5E5',
                  pointBackgroundColor: '#95B5E5',
                  tension: 0.4
                },
              ],
            }}
            options={options}
          />
        </div>
      </div>

      <div className="doublegraphdivthreats">
        <div className="doublegraphdivc1threats">
          <span className='titlethreatschart'>Other Threats</span>
          <span className='numberthreatschart'>
            {currentMonthOtherThreatsData.reduce((total, { count }) => total + count, 0)}
          </span>
          <span className='percentagethreatschart'>
            {otherThreatsPercentageChange >= 0 ? '+' : ''}{otherThreatsPercentageChange}% than last month
          </span>
        </div>
        <div className="doublegraphdivc2threats">
          <Line
            data={{
              labels: threatData.otherThreats.map(({ date }) => date),
              datasets: [
                {
                  fill: true,
                  label: 'Other Threats',
                  data: threatData.otherThreats.map(({ count }) => count),
                  backgroundColor: '#EDE4B8',
                  borderColor: '#EDE4B8',
                  pointBackgroundColor: '#EDE4B8',
                  tension: 0.4
                },
              ],
            }}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default ThreatsComponent;
