import React, { useContext } from 'react';
import './vulnerabilityManagementWidget.css';
import { AuthContext } from '../context/authContext';

const VulnerabilityManagementWidget = () => {
  const { cisoInfo } = useContext(AuthContext);

  const calculateFrequency = (data) => {
    const lastTenDays = Object.keys(data).slice(-10);
    const totalRecords = lastTenDays.reduce((sum, date) => sum + data[date], 0);

    if (totalRecords >= 1000) {
      return 'High';
    } else if (totalRecords >= 200) {
      return 'Moderate';
    } else if (totalRecords >= 100) {
      return 'Rare';
    } else {
      return 'Use weagle for more time';
    }
  };

  const calculateQuantity = (data) => {
    return Object.values(data).reduce((sum, count) => sum + count, 0);
  };

  const combinedData = (cisoInfo.statistics || []).reduce((acc, group) => {
    if (group && typeof group === 'object') {
      const { dailyMalwareFound, dailyPhishingFound, otherThreats } = group;
  
      if (dailyPhishingFound && typeof dailyPhishingFound === 'object') {
        Object.entries(dailyPhishingFound).forEach(([date, count]) => {
          acc.phishing[date] = (acc.phishing[date] || 0) + count;
        });
      }
  
      if (dailyMalwareFound && typeof dailyMalwareFound === 'object') {
        Object.entries(dailyMalwareFound).forEach(([date, count]) => {
          acc.malware[date] = (acc.malware[date] || 0) + count;
        });
      }
  
      if (otherThreats && typeof otherThreats === 'object') {
        Object.entries(otherThreats).forEach(([date, count]) => {
          acc.other[date] = (acc.other[date] || 0) + count;
        });
      }
    }
  
    return acc;
  }, { phishing: {}, malware: {}, other: {} });
  

  const threatsData = [
    {
      threat: 'Phishing',
      frequency: calculateFrequency(combinedData.phishing),
      quantity: calculateQuantity(combinedData.phishing),
    },
    {
      threat: 'Malware',
      frequency: calculateFrequency(combinedData.malware),
      quantity: calculateQuantity(combinedData.malware),
    },
    {
      threat: 'Other',
      frequency: calculateFrequency(combinedData.other),
      quantity: calculateQuantity(combinedData.other),
    },
  ];

  return (
    <div className="vulnerability-management-widget">
      <h2>Vulnerability Management</h2>
      <div className="scrollable-table-container">
        <table>
          <thead>
            <tr>
              <th>Threats</th>
              <th>Frequency</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {threatsData.map((item, index) => (
              <tr key={index}>
                <td>{item.threat}</td>
                <td>{item.frequency}</td>
                <td>{item.quantity.toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VulnerabilityManagementWidget;
