import React, { useState, useEffect } from "react";
import DataWalletEnterpriseHeader from "./data-wallet-enterprise-header";
import DataWalletEnterpriseResults from "./data-wallet-enterprise-results";
import "./data-wallet-enterprise.css";
import {
  getArticleFromPortafolioDati,
  deleteArticleFromPortafolioDati,
} from "../redux/actions";

import { useDispatch, useSelector } from "react-redux";
import Loader from "./loader";
import { paginateArray } from "../services";

const LIMIT_PAGE = 10;

const DataWalletEnterprise = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const [links, setLinks] = useState([]);
  const [loader, setLoader] = useState(true);
  const [checkFilter, setCheckFilter] = useState("interessi_personali");
  const [selected, setSelected] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [page, setPage] = useState(1);
  const [paginatedWebSearchResults, setPaginatedWebSearchResults] = useState(
    []
  );
  const handleSelectAllArticles = () => {
    if (selected.length === links.length) {
      // If all articles are currently selected, deselect all
      setSelected([]);
    } else {
      // If not all articles are selected, select all
      setSelected([...links]);
    }
  };
  

  useEffect(() => {
    if (auth?.data?.token) {
      handleGetArticlesFromPortafolioDati(1);
    }
  }, [JSON.stringify(auth?.data)]);

  const paginateWebSearchResults = (data, pg = 1) => {
    const paginated = paginateArray(data, pg, LIMIT_PAGE);
    setPaginatedWebSearchResults(paginated);
  };

  const changePagePagination = (page) => {
    const newPage = paginateArray(links, page, LIMIT_PAGE);
    setPaginatedWebSearchResults(newPage);
    setPage(page);
  };

  // getportfolio
  const handleGetArticlesFromPortafolioDati = async (
    pg,
    type = checkFilter
  ) => {
    const cbSuccess = (response) => {
      setPage(pg);
      if (response?.data[type].length > 0) {
        setLinks(response?.data[type]);
        paginateWebSearchResults(response?.data[type], 1);
        setTotalPages(
          response?.data[type]?.length < LIMIT_PAGE
            ? 1
            : Math.ceil(response?.data[type]?.length / LIMIT_PAGE)
        );
      } else {
        setLinks([]);
        setPaginatedWebSearchResults([]);
      }
      setLoader(false);
    };
    const cbFailure = (error) => {
      setLinks([]);
      setPaginatedWebSearchResults([]);
      setLoader(false);
      // alert(error.message);
    };
    const data = new FormData();
    data.append("type", type);

    if (auth?.data?.token)
      await dispatch(
        getArticleFromPortafolioDati(
          data,
          auth?.data?.token,
          cbSuccess,
          cbFailure
        )
      );
  };

  const handleChanges = (type) => {
    setCheckFilter(type);
    setLoader(true);
    handleGetArticlesFromPortafolioDati(1, type);
  };
  const handleSelect = (link) => {
    if (selected.includes(link)) {
      setSelected(selected.filter((item) => item?.link !== link?.link));
    } else {
      setSelected((selected) => [link, ...selected]);
    }
  };

  const handleDeleteArticlesFromPortafolioDati = (list = selected) => {
    if (list.length > 0) {
      setLoader(true);
      const cbSuccess = (response) => {
        setSelected([]);
        handleGetArticlesFromPortafolioDati(1);
      };
      const cbFailure = (error) => {
        setLoader(false);
        alert(error.message);
      };
      const data = {
        type: checkFilter,
        article_links: list,
      };

      if (auth?.data?.token) {
        setLoader(true);
        dispatch(
          deleteArticleFromPortafolioDati(
            data,
            auth?.data?.token,
            cbSuccess,
            cbFailure
          )
        );
      }
    } else if (list.length > 0) {
      alert("Seleziona link da archiviare", [
        { text: "OK", onPress: () => {} },
      ]);
    } else {
    }
  };


  const translations = {
    en: {
     
      'ppps1segnala': "Select all",
   
    },
    it: {

  
      'ppps1segnala': 'Seleziona tutto',

    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser

  const ppps1segnal = translate('ppps1segnala', lang);
  return (
    <div className="data-wallet-enterprise-container">
      <DataWalletEnterpriseHeader
        handleDelete={handleDeleteArticlesFromPortafolioDati}
        filter={handleChanges}
        check={checkFilter}
        rootClassName="data-wallet-enterprise-header-root-class-name"
      />
      
      {loader && <Loader />}

      {!loader && paginatedWebSearchResults?.length === 0 && (
        <div style={{ fontFamily: "Encode Sans Expanded", marginTop: 100 }}>
          Nessun risultato{" "}
        </div>
      )}
      {!loader && paginatedWebSearchResults?.length > 0 && (
        <div>
             <span className="selezionatuttobutton" onClick={handleSelectAllArticles}>{ppps1segnal}</span>

        <DataWalletEnterpriseResults
        handleSelectAll={handleSelectAllArticles}
          handleSelect={handleSelect}
          handleDeleteLink={handleDeleteArticlesFromPortafolioDati}
          link={paginatedWebSearchResults}
          selectedType={checkFilter}
          selectedCheck={selected}
          rootClassName="data-wallet-enterprise-results-root-class-name"
          totalPages={totalPages}
          changePagePagination={changePagePagination}
          page={page}
        />

        </div>
      )}
    </div>
  );
};

export default DataWalletEnterprise;
