import * as TYPES from '../../actions/types';

const initialState = {
  list: [],
  source: [],
};

const folderReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case TYPES.DELETE_FOLDER_REQUEST:
      return initialState;
      
    case TYPES.SWITCH_USER:
      return initialState;
      
    case TYPES.GET_FOLDER_SUCCESS:
      return {
        ...state,
        list: actions?.data,
      };
    case TYPES.GET_SOURCE_SUCCESS:
      return {
        ...state,
        source: actions.data,
      };
    default:
      return state;
  }
};
export default folderReducer;
