import React from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { AuthContext } from "../context/authContext";

const msalConfig = {
    auth: {
        clientId: '0c94634d-a6f9-492f-8f9a-e5fa631475a9',
        authority: 'https://login.microsoftonline.com/common',
        redirectUri: 'https://weagle.enterprises',
    }
};

const pca = new PublicClientApplication(msalConfig);

const SignInButtonUser = () => {
    const { instance } = useMsal();
    const { handleLoginAzure } = React.useContext(AuthContext);

    const handleLogin = async (loginType) => {
        const loginRequest = {
            scopes: ["0c94634d-a6f9-492f-8f9a-e5fa631475a9/.default"]
        };
    
        try {
            let response;
            if (loginType === "popup") {
                response = await instance.loginPopup(loginRequest);
            } else {
                response = await instance.loginRedirect(loginRequest);
            }
    
            if (response.accessToken) {
                // Call the handleLoginAzure function with the necessary data
                handleLoginAzure(response.accessToken, response.account.username,
                    (response) => {
                        console.log('Login successful:', response);
                    },
                    (error) => {
                        console.error('Login failed:', error);
                    }
                );
            } else {
                throw new Error('Access token not found in the response');
            }
        } catch (error) {
            console.error('Error during login:', error);
        }
    };

    return (
        <div onClick={() => handleLogin("popup")}>
            <img style={{width:'309px', cursor:'pointer'}} src="/playground_assets/loginwithazure.svg" alt="" className="addAccountWidgetIcon" />
        </div>
    );
};

const AzureAuthButtonUser = () => {
    return (
        <MsalProvider instance={pca}>
            <SignInButtonUser />
        </MsalProvider>
    );
};

export default AzureAuthButtonUser;