import {takeLatest, put} from 'redux-saga/effects';
import * as types from '../../actions/types';
import {Api} from '../../../utils';
import {endPoints} from '../../../utils';

export function* addArticlesToPortafolioDatiRequest() {
  yield takeLatest(
    types.ADD_ARTICLES_TO_PORTAFOLIO_DATI_REQUEST,
    addArticlesToPortafolioDati,
  );
}

function* addArticlesToPortafolioDati(params) {
  // console.log('[add article to folder saga]', params);
  try {
    let options = {
      headers: {
        Authorization: 'Bearer ' + params.token,
      },
    };
    let configuration = Object.assign(options);
    yield Api.postAxios(
      endPoints.addArticlesToPortafolioDati,
      params.params,
      configuration,
    );
  } catch (err) {
    console.log(
      'error from add articles to Portafolio Dati request saga -- > > >  > ',
      err,
    );
    params.cbFailure(err.message);
  }
}
