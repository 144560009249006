import React, { useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import './activeUserWidget.css';
import { AuthContext } from "../context/authContext";

// Register the required chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ActiveUserWidget = () => {
  const { cisoInfo } = useContext(AuthContext);

  // Calculate the active users and total accounts
  const calculateActiveUsers = () => {
    const dailyLogins = cisoInfo.statistics.reduce((acc, group) => {
      const { dailyLogins } = group;
      Object.entries(dailyLogins || {}).forEach(([date, userLogins]) => {
        acc[date] = acc[date] || new Set();
        Object.keys(userLogins).forEach((userId) => {
          acc[date].add(userId);
        });
      });
      return acc;
    }, {});

    const uniqueUsers = new Set();
    Object.values(dailyLogins).forEach((userSet) => {
      userSet.forEach((userId) => {
        uniqueUsers.add(userId);
      });
    });

    const activeUsers = uniqueUsers.size;
    const totalAccounts = cisoInfo.seatsBase - cisoInfo.availableSeatsBase;

    return { activeUsers, totalAccounts };
  };

  const { activeUsers, totalAccounts } = calculateActiveUsers();

  // Calculate the percentage change compared to the previous month
  const calculatePercentageChange = () => {
    const currentDate = new Date();
    const currentMonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const currentMonthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const previousMonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    const previousMonthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
  
    const currentMonthActiveUsers = cisoInfo.statistics.reduce((acc, group) => {
      const { dailyLogins } = group;
      Object.entries(dailyLogins || {}).forEach(([date, userLogins]) => {
        const loginDate = new Date(date);
        if (loginDate >= currentMonthStart && loginDate <= currentMonthEnd) {
          Object.keys(userLogins).forEach((userId) => {
            acc.add(userId);
          });
        }
      });
      return acc;
    }, new Set()).size;
  
    const previousMonthActiveUsers = cisoInfo.statistics.reduce((acc, group) => {
      const { dailyLogins } = group;
      Object.entries(dailyLogins || {}).forEach(([date, userLogins]) => {
        const loginDate = new Date(date);
        if (loginDate >= previousMonthStart && loginDate <= previousMonthEnd) {
          Object.keys(userLogins).forEach((userId) => {
            acc.add(userId);
          });
        }
      });
      return acc;
    }, new Set()).size;
  
    if (previousMonthActiveUsers === 0) {
      return currentMonthActiveUsers > 0 ? '100' : '0.00';
    }
  
    const percentageChange = ((currentMonthActiveUsers - previousMonthActiveUsers) / previousMonthActiveUsers) * 100;
    return percentageChange.toFixed(2);
  };
  

  const percentageChange = calculatePercentageChange();

  // Data for the chart
  const data = {
    labels: Array(7).fill(''), // Assuming there are 7 bars like in the image
    datasets: [
      {
        data: Array(7).fill(activeUsers), // Fill the data with active users count
        backgroundColor: '#5BE49B',
        borderColor: '#5BE49B',
        borderWidth: 1,
        borderRadius: 5,
        barThickness: 10, // Adjust bar thickness
      },
    ],
  };

  // Options for the chart
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
        suggestedMax: totalAccounts, // Set the max value for y-axis to total accounts
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false, // Assuming you want no tooltips as in the picture
      },
    },
    events: [], // Disable all events
  };

  return (
    <div className="active-user-widget">
      <div className="widget-header">Total Active Users</div>
      <div className="widget-chart">
        <div className="widget-percentage">{percentageChange >= 0 ? '+' : ''}{percentageChange}%</div>
        <Bar data={data} options={options} />
      </div>
      <div className="widget-users">
        <span className="widget-current-users">{activeUsers}</span> <span className="widget-max-users">di {totalAccounts}</span>
      </div>
    </div>
  );
};

export default ActiveUserWidget;
