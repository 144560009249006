import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/authContext";
import WelfareHeaderAziendale from './welfare-header-aziendale'
import CouponWelfare from './coupon-welfare'
import './welfare-enterprise-component.css'
import { useHistory } from "react-router-dom";
import SearchBar2 from "../components/search-bar2";
import { useDispatch, useSelector } from "react-redux";
import CouponWelfare2 from "./coupon-welfare2";
import CouponWelfare3 from "./coupon-welfare3";
const WelfareEnterpriseComponent = (props) => {
  const dispatch = useDispatch();
  const { auth, folder } = useSelector((state) => state);
  const [news, setNews] = useState({});
  const [loader, setLoader] = useState(true);
  const {
    firstName,
    setFirstName,
    iva,
    setIva,
    email,
    setEmail,
    loading,
    nickName,
    setNickName,
    accountType,
    setAccountType,
    address,
    setAddress,
    common,
    setCommon,
    country,
    setCountry,
    handelProfileEnterprice,
    image,
    setImage,
  } = useContext(AuthContext);



  return (
    <div className="welfare-enterprise-component-container">
       <div className="searchbarwelfare" style={{marginBottom:'20px',marginTop:'50px'}}>
          <SearchBar2 />
          </div>
      <WelfareHeaderAziendale  rootClassName="welfare-header-aziendale-root-class-name"></WelfareHeaderAziendale>
      {auth?.data?.token  ? (
        <div>
          <CouponWelfare rootClassName="coupon-welfare-root-class-name"></CouponWelfare>
          <CouponWelfare2 rootClassName="coupon-welfare-root-class-name"></CouponWelfare2>
          <CouponWelfare3 rootClassName="coupon-welfare-root-class-name"></CouponWelfare3>
          </div>
        
        
) : (
  <span className="msgnonregWelfare" style={{marginBottom:'560px', padding:'20px', textAlign:'center'}}>
  Accedi o registrati a Weagle, per usufruire di sconti e dei vantaggi offerti dalle aziende partner Weagle.
  </span>
)}
   
    </div>
  )
}

export default WelfareEnterpriseComponent
