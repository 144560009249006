import { combineReducers } from "redux";
import authReducer from "./auth-reducer/auth-reducer";
import searchInput from "./search-input-reducer/search-input";
import folderReducer from "./folder-reducer/folder-reducer";
import categoryFilterReducer from "./category-filter-reducer/category-filter";

const combinedReducers = combineReducers(
  Object.assign({
    auth: authReducer,
    searchInput: searchInput,
    folder: folderReducer,
    categoryFilter: categoryFilterReducer,
  })
);

// root reducer to clear whole redux storage
const appReducer = (state, action) => {
  if (action.type === 'SIGNOUT') {
    state = undefined;
  }

  return combinedReducers(state, action);
};
export default appReducer;
