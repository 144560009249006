import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";

import "./archivio-cartelle-condivise.css";
import SharedFolder from "./shared-folder";
import { SearchContext } from "../context/search_context";

const ArchivioCartelleCondivise = (props) => {
  const { folder, auth } = useSelector((state) => state);
  const { tab } = useContext(SearchContext);
  const translations = {
    en: {
     
      'ppps1segnala': "Shared folders",
   
    },
    it: {

  
      'ppps1segnala': 'Cartelle condivise',

    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser

  const ppps1segnal = translate('ppps1segnala', lang);
  return (
    <>
      {folder?.list?.data?.folders
        ?.filter((item) =>
          auth?.data?.shared_folders?.includes(item?._id)
        )?.length >= 1? (
        <div
          className={`archivio-cartelle-condivise-archivio-cartelle-condivise 
	        ${props.rootClassName} `}>
          
          <img
            src="/playground_assets/line166761-8iwc.svg"
            alt="Line166761"
            className="archivio-cartelle-condivise-line16"
          />
          
          {/* <img
            src="/playground_assets/rectangle43626762-i1q-300h.png"
            alt="Rectangle43626762"
            className="archivio-cartelle-condivise-rectangle4362"
          /> */}

          {auth?.data?.shared_folders &&
            folder?.list?.data?.folders
              ?.filter((item) =>
                auth?.data?.shared_folders?.includes(item?._id)
              )
              ?.map((element) => {
                return (
                  <div className="tutte-le-cartelle-frame30">
                    <SharedFolder
                      id={element._id}
                      handleSharePopUp={props.handelSharePopUp}
                      tab={tab}
                      deleteFolder={props.deleteFolder}
                      handleRequest={props.handelRenameFolder}
                      data={element}
                    />
                  </div>
                );
              })}
          <span className="archivio-cartelle-condivise-text12">
            <span className="">{ppps1segnal}</span>
          </span>
        </div>
      ):''}
    </>
  );
};

ArchivioCartelleCondivise.defaultProps = {
  rootClassName: "",
};

ArchivioCartelleCondivise.propTypes = {
  rootClassName: PropTypes.string,
};

export default ArchivioCartelleCondivise;
