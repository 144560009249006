import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import { toast } from "react-toastify";
import "./loginPlatformComponent.css";

const LoginPlatformComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [resetStep, setResetStep] = useState(1); // 1: Email, 2: Code, 3: New Password
  const authContext = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (authContext.authState.isAuthenticated) {
      history.push("/dashboard");
    }
  }, [authContext.authState.isAuthenticated, history]);

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      await authContext.handlePlatformLogin(email, password);
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const handleForgotPasswordToggle = () => {
    setShowForgotPassword(!showForgotPassword);
    setResetStep(1);
    setResetEmail("");
    setResetCode("");
    setNewPassword("");
  };

  const handleSendResetCode = async (e) => {
    e.preventDefault();
    try {
      await authContext.handlePlatformSendResetCode(resetEmail);
      setResetStep(2);
    } catch (error) {
      console.error("Send reset code error:", error);
    }
  };

  const handleCodeVerification = async (e) => {
    e.preventDefault();
    try {
      await authContext.handlePlatformVerifyResetCode(resetEmail, resetCode);
      setResetStep(3);
    } catch (error) {
      console.error("Code verification error:", error);
    }
  };

  const handleSetNewPassword = async (e) => {
    e.preventDefault();
    try {
      await authContext.handlePlatformSetNewPassword(resetEmail, resetCode, newPassword);
      setShowForgotPassword(false);
      toast.success("Password reset successful. Please login with your new password.");
    } catch (error) {
      console.error("New password error:", error);
    }
  };

  return (
    <div className="login-platform-container">
      <form className="login-platform-form" onSubmit={handleSignIn}>
        <h2>Sign In</h2>
        <div className="login-platform-form-group">
          <label htmlFor="email">Email*</label>
          <input
            type="email"
            id="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="mail@example.com"
          />
        </div>
        <div className="login-platform-form-group">
          <label htmlFor="password">Password*</label>
          <input
            type="password"
            id="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Min. 8 characters"
          />
        </div>
        <div className="login-platform-form-group">
          <a href="#forgot-password" className="login-platform-forgot-password" onClick={handleForgotPasswordToggle}>
            Forgot password?
          </a>
        </div>
        <button type="submit" className="login-platform-submit-btn">Sign In</button>
      </form>
      {showForgotPassword && (
        <div className="forgot-password-popup">
          <div className="forgot-password-content">
            <span className="close-btn" onClick={handleForgotPasswordToggle}>&times;</span>
            <h3>Reset Password</h3>
            {resetStep === 1 && (
              <form onSubmit={handleSendResetCode}>
                <div className="forgot-password-form-group">
                  <label htmlFor="reset-email">Email*</label>
                  <input
                    type="email"
                    id="reset-email"
                    required
                    value={resetEmail}
                    onChange={(e) => setResetEmail(e.target.value)}
                    placeholder="mail@example.com"
                  />
                </div>
                <button type="submit" className="reset-password-btn">Send Reset Code</button>
              </form>
            )}
            {resetStep === 2 && (
              <form onSubmit={handleCodeVerification}>
                <div className="forgot-password-form-group">
                  <label htmlFor="reset-code">Code*</label>
                  <input
                    type="text"
                    id="reset-code"
                    required
                    value={resetCode}
                    onChange={(e) => setResetCode(e.target.value)}
                    placeholder="Enter the code sent to your email"
                  />
                </div>
                <button type="submit" className="reset-password-btn">Verify Code</button>
              </form>
            )}
            {resetStep === 3 && (
              <form onSubmit={handleSetNewPassword}>
                <div className="forgot-password-form-group">
                  <label htmlFor="new-password">New Password*</label>
                  <input
                    type="password"
                    id="new-password"
                    required
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Enter your new password"
                  />
                </div>
                <button type="submit" className="reset-password-btn">Set New Password</button>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginPlatformComponent;