import * as TYPES from '../types';

//portafoli dati obj Action
export const addArticleToPortafolioDati = (params, token) => {
  return {
    type: TYPES.ADD_ARTICLES_TO_PORTAFOLIO_DATI_REQUEST,
    params,
    token,
  };
};

export const getArticleFromPortafolioDati = (
  params,
  token,
  cbSuccess,
  cbFailure,
) => {
  return {
    type: TYPES.GET_ARTICLES_FROM_PORTAFOLIO_DATI_REQUEST,
    params,
    token,
    cbSuccess,
    cbFailure,
  };
};

export const deleteArticleFromPortafolioDati = (
  params,
  token,
  cbSuccess,
  cbFailure,
) => {
  return {
    type: TYPES.DELETE_ARTICLES_FROM_PORTAFOLIO_DATI_REQUEST,
    params,
    token,
    cbSuccess,
    cbFailure,
  };
};

export const sellArticleFromPortafolioDati = (
  params,
  token,
  cbSuccess,
  cbFailure,
) => {
  return {
    type: TYPES.SELL_ARTICLES_FROM_PORTAFOLIO_DATI_REQUEST,
    params,
    token,
    cbSuccess,
    cbFailure,
  };
};
