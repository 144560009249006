import React, { useEffect, useRef, useState } from "react";
import "./pop-up-save-link2.css";
import { useDispatch, useSelector } from "react-redux";
import { getFolderRequest } from "../redux/actions";
import { useOnClickOutside } from "./useClickOuteSide";
import Loader from "./loader";

const PopUpSaveLink2 = (props) => {
  
  const dispatch = useDispatch();
  const { auth, folder } = useSelector((state) => state);
  const [loader, setLoader] = useState(true);
  const ref = useRef();

  useOnClickOutside(ref, () => props.setShow(false));
  const handleGetFoldersRequest = () => {
    const cbSuccess = (response) => {
      setLoader(false);
    };
    const cbFailure = (error) => {
      setLoader(false);
      alert(error.message, [{ text: "OK" }]);
    };
    if (auth?.data?.token) {
      setLoader(true);
      dispatch(getFolderRequest(auth?.data?.token, cbSuccess, cbFailure));
    }
  };

  useEffect((item) => {
      handleGetFoldersRequest();
  }, []);

  const translations = {
    en: {
     
      'ppps1segnala': "Where do you want to save the link?",
      'ppps1segnala2': 'Save link',
      'ppps1segnala3': 'No folder found, add a folder from the archive page.',

    },
    it: {

  
      'ppps1segnala': 'Dove vuoi salvare il link?',
      'ppps1segnala2': 'Salva link',
      'ppps1segnala3': 'Nessuna cartella trovata, aggiungi una cartella dalla pagina archivio.',
    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser

  const ppps1segnal = translate('ppps1segnala', lang);
  const ppps1segnal2 = translate('ppps1segnala2', lang);
  const ppps1segnal3 = translate('ppps1segnala3', lang);
  return (
    <div className="pop-up-save-link2-container">
      <div className="pop-up-save-link2-salvataggiolink">
        <div ref={ref} className="pop-up-save-link2-frame122">
          <div className="pop-up-save-link2-group121">
            <span className="pop-up-save-link2-text">
              <span>{ppps1segnal}</span>
            </span>

            {(!folder?.list?.data?.folders ||
              folder?.list?.data?.folders?.length === 0) &&
              !loader && (
                <p className={"text-on-popup"}>
                 Nessuna cartella trovata, aggiungi una cartella dalla pagina archivio.
                </p>
              )}

            {loader && <Loader />}

            {!loader &&
              folder?.list?.data?.folders?.map((item) => {
                return (
                  <div
                    onClick={() => props.setSelectedFolder(item?._id)}
                    className={` pop-up-save-link2-frame119 ${
                      props.selectedFolder === item?._id &&
                      "pop-up-save-link2-frame119_active"
                    }`}
                  >
                    <img
                      src="/playground_assets/folderblank31203-3kne.svg"
                      alt="FolderBlank31203"
                      className="pop-up-save-link2-folder-blank3"
                    />
                    <span className="pop-up-save-link2-text2">
                      <span>{item.name}</span>
                    </span>
                  </div>
                );
              })}
          </div>
          <div
            onClick={props.folderPress}
            className="pop-up-save-link2-frame13"
          >
            <span className="pop-up-save-link2-text6">
              <span>{ppps1segnal2}</span>
            </span>
          </div>
        </div>
        <div
          onClick={() => props?.setShow(false)}
          className="pop-up-save-link2-deletedisabled2"
        >
          <div className="pop-up-save-link2-group">
            <img
              src="/playground_assets/vector4582-l9il.svg"
              alt="Vector4582"
              className="pop-up-save-link2-vector"
            />
            <img
              src="/playground_assets/vector4582-fh75.svg"
              alt="Vector4582"
              className="pop-up-save-link2-vector1"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUpSaveLink2;
