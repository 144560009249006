import React from "react";

import "./feed-detail.css";
import { useHistory, useLocation } from "react-router-dom";
import FeedDetailItem from "./feed-detail-item";

const FeedDetailComponent = (props) => {
  const location = useLocation();
  const history = useHistory();



  const translations = {
    en: {
      'coperturaindietro': '< back',

    },
    it: {

      'coperturaindietro': '< indietro',
     
    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser
  const text01Coperturaindietro = translate('coperturaindietro', lang);






  return (
    <div className="feed-detail-container">
      <span
        style={{ cursor: "pointer" }}
        onClick={() => {
          history.push("feed");
        }}
        className="feed-detail-back-button2"
      >
        <span>{text01Coperturaindietro}</span>
      </span>

      <div className="feed-detail-website-image-link">
        {location.state?.data?.icona && (
          <img
            src={location.state.data.icona}
            alt="image82510"
            className="feed-detail-website-image"
          />
        )}
        <span className="feed-detail-website-link">
          <span>{location.state?.data?.url}</span>
        </span>
      </div>

      <div className="feed-detail-feed-detail-filters"></div>

      <div className="feed-detail-feed-detail-results">
        {location.state?.data?.list.map((item, index) => {
          return <FeedDetailItem {...item} index={index} />;
        })}
      </div>
    </div>
  );
};



export default FeedDetailComponent;
