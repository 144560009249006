import React, { useEffect, useState } from "react";

import PropTypes from 'prop-types'

import './data-wallet-enterprise-header.css'
import { useRef } from "react";
import { useContext } from "react";
import { AuthContext } from "../context/authContext";
import Loader from "./loader";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
const DataWalletEnterpriseHeader = (props) => {
  const {
    firstName,
    setFirstName,
    iva,
    setIva,
    email,
    setEmail,
    loading,
    nickName,
    setNickName,
    accountType,
    setAccountType,
    address,
    setAddress,
    common,
    setCommon,
    country,
    setCountry,
    handelProfileEnterprice,
    image,
    setImage,
  } = useContext(AuthContext);
  const { auth } = useSelector((state) => state);
  const history = useHistory();
  const [img, setImg] = useState("");

  useEffect(() => {
    if (!auth?.data?.token) {
      history.push("/");
    }
  }, []);

  console.log("tyyhe-->", accountType);





  const translations = {
    en: {
      'data1': 'Delete',
      'data2': 'Delete',
      'data3': 'Discover',
      'data4': ' subscriptions to get enhanced and automatic privacy.',
      'data5': 'To increase privacy and protect your data,',
      'data6': 'every day at 23:59 we will delete browsing data',
      'data7': '',
      'data8': 'Search history',
      'data9': 'Browser',
    },
    it: {

      'data1': 'Elimina selezionati',
      'data2': 'Elimina',
      'data3': 'Scopri gli abbonamenti',
      'data4': 'per ottenere una Privacy potenziata e automatica.',
      'data5': 'I dati raccolti vengono cancellati',
      'data6': 'ogni giorno alle 23.59',
      'data7': '',
      'data8': 'Cronologia ricerche',
      'data9': 'Browser',
    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser
  const data1a = translate('data1', lang);
  const data2a = translate('data2', lang);
  const data3a = translate('data3', lang);
  const data4a = translate('data4', lang);
  const data5a = translate('data5', lang);
  const data6a = translate('data6', lang);
  const data7a = translate('data7', lang);
  const data8a = translate('data8', lang);
  const data9a = translate('data9', lang);





  return (
    <div
      className={`data-wallet-enterprise-header-container ${props.rootClassName} `}
    >
      <div className="data-wallet-enterprise-header-filtri-e-scritta-wallet-enterprise">
        <div className="data-wallet-enterprise-header-frame5017">
          <span className="data-wallet-enterprise-header-text">
            <span onClick={()=>props.handleDelete()} className="">{data1a}</span>
          </span>
          
        </div>
        <div className="data-wallet-floating"> 
          <span className="data-wallet-enterprise-header-text">
            <span onClick={()=>props.handleDelete()} className="">{data2a}</span>
          </span>
          
        </div>
        <span className="data-wallet-enterprise-header-text02">
          <span className="">Data Wallet</span>
        </span>
        {accountType === "free" ? (
       <span className="data-wallet-enterprise-header-text04">
       <span className="data-wallet-enterprise-header-text05">
         <span className="">
       {data3a}
         </span>
         <br className=""></br>
         <span className=""></span>
       </span>
       <span className="data-wallet-enterprise-header-text09">
         enterprise
       </span>
       <span className="">
       &nbsp;
       </span>
       <span className="">
      {data4a}
       </span>
     </span>
        ) : (
          <span className="data-wallet-enterprise-header-text04">
          <span className="data-wallet-enterprise-header-text05">
            <span className="">
             {data5a}
            </span>
            <br className=""></br>
            <span className=""></span>
          </span>
          <span className="data-wallet-enterprise-header-text09">
           {data6a}
          </span>
          <span className="">
          &nbsp;
          </span>
          <span className="">
         {data7a}
          </span>
        </span>
        )}
      
      <div className="data-wallet-enterprise-header-frame5019">
  <div className={`data-wallet-enterprise-header-frame52 ${props.check === 'interessi_personali' ? 'selected-tab' : ''}`}>

    <span  onClick={() => props.filter('interessi_personali')} className="">
      <span className="TextAds">{data8a}</span>
    </span>
  </div>
  <div className={`data-wallet-enterprise-header-frame53 ${props.check === 'acquisti' ? 'selected-tab' : ''}`}>

    <span  onClick={() => props.filter('acquisti')} className="">
      <span className="TextAds">{data9a}</span>
    </span>
  </div>
  <div className={`data-wallet-enterprise-header-frame54 ${props.check === 'sociale' ? 'selected-tab' : ''}`}>

    <span  onClick={() => props.filter('sociale')} className="">
      <span className="TextAds">Ads & Trackers</span>
    </span>
  </div>
</div>

        {/* <img
          alt="padlock15281"
          src="/playground_assets/padlock15281-wea-200h.png"
          className="data-wallet-enterprise-header-padlock1"
        /> */}
      </div>
    </div>
  )
}

DataWalletEnterpriseHeader.defaultProps = {
  rootClassName: '',
}

DataWalletEnterpriseHeader.propTypes = {
  rootClassName: PropTypes.string,
}

export default DataWalletEnterpriseHeader
