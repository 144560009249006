// DashbooardstatisticsGroupPicker.js
import React, { useState, useEffect, useRef } from 'react';
import './dashboardDatePicker.css';

const DashbooardstatisticsGroupPicker = ({ groups, onGroupSelected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState('All Groups');

  const popupRef = useRef();

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleGroupClick = (group) => {
    setSelectedGroup(group);
    setIsOpen(false);
    onGroupSelected(group);
  };

  return (
    <div className="dashboard-date-picker2">
      <div className="date-display">&nbsp;</div>
      
      <div className='datessourcetimerangediv'>
        <div className="toggle-button" onClick={() => setIsOpen(!isOpen)}>
          {selectedGroup} ▼
        </div>
      </div>
      <div  ref={popupRef} className={`date-range-popup2 ${isOpen ? 'open' : ''}`}>
        <div className="date-range-option" onClick={() => handleGroupClick('All Groups')}>
          All Groups
        </div>
        {groups.map((group) => (
          <div
            key={group.groupName}
            className="date-range-option"
            onClick={() => handleGroupClick(group.groupName)}
          >
            {group.groupName}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashbooardstatisticsGroupPicker;
