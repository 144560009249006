import * as TYPES from '../types';

//Folder Action
export const addFolderRequest = (params, token, cbSuccess, cbFailure) => {
  return {
    type: TYPES.ADD_FOLDER_REQUEST,
    params,
    token,
    cbSuccess,
    cbFailure,
  };
};

export const getFolderRequest = (token, cbSuccess, cbFailure) => {
  return {
    type: TYPES.GET_FOLDER_REQUEST,
    token,
    cbSuccess,
    cbFailure,
  };
};

export const deleteFolderRequest = (params, token, cbSuccess, cbFailure) => {
  return {
    type: TYPES.DELETE_FOLDER_REQUEST,
    params,
    token,
    cbSuccess,
    cbFailure,
  };
};

export const renameFolderRequest = (params, token, cbSuccess, cbFailure) => {
  return {
    type: TYPES.RENAME_FOLDER_REQUEST,
    params,
    token,
    cbSuccess,
    cbFailure,
  };
};

export const addArticleToFolderRequest = (
  params,
  token,
  cbSuccess,
  cbFailure,
) => {
  return {
    type: TYPES.ADD_ARTICLE_TO_FOLDER_REQUEST,
    params,
    token,
    cbSuccess,
    cbFailure,
  };
};

export const deleteArticleFromFolderRequest = (
  params,
  token,
  cbSuccess,
  cbFailure,
) => {
  return {
    type: TYPES.DELETE_ARTICLE_FROM_FOLDER_REQUEST,
    params,
    token,
    cbSuccess,
    cbFailure,
  };
};

export const saveSourceRequest = (params, token, cbSuccess, cbFailure) => {
  return {
    type: TYPES.SAVE_SOURCE_REQUEST,
    params,
    token,
    cbSuccess,
    cbFailure,
  };
};

export const getSourcceRequest = (token, cbSuccess, cbFailure) => {
  return {
    type: TYPES.GET_SOURCE_REQUEST,
    token,
    cbSuccess,
    cbFailure,
  };
};

export const deleteSourceRequest = (params, token, cbSuccess, cbFailure) => {
  return {
    type: TYPES.DELETE_SOURCE_REQUEST,
    params,
    token,
    cbSuccess,
    cbFailure,
  };
};

export const getSourceFeedsRequest = (token, cbSuccess, cbFailure) => {
  return {
    type: TYPES.GET_SOURCE_FEEDS_REQUEST,
    token,
    cbSuccess,
    cbFailure,
  };
};
