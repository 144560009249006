import {takeLatest, put} from 'redux-saga/effects';
import * as types from '../../actions/types';
import {Api} from '../../../utils';
import {endPoints} from '../../../utils';

export function* getSourceRequest() {
  yield takeLatest(types.GET_SOURCE_REQUEST, getSource);
}

function* getSource(params) {
  // console.log('[get folder saga]', params);
  try {
    let options = {
      headers: {
        Authorization: 'Bearer ' + params.token,
      },
    };
    let configuration = Object.assign(options);
    let response = yield Api.getAxios(endPoints.getSource, configuration);
    if (response?.status) {
      yield put({
        type: types.GET_SOURCE_SUCCESS,
        data: response.data.data.sources,
      });
      params.cbSuccess(response);
    } else {
      params.cbFailure(response);
    }
  } catch (err) {
    console.log('error from get Source request saga -- > > >  > ', err);
    params.cbFailure(err.message);
  }
}
