import React, { createContext, useEffect, useState, useContext } from "react";
import axios from "axios";
import { key, paginateArray, isDateSameOrAfter } from "../services";
import { endPoints } from "../utils";
import { useSelector, useDispatch } from "react-redux";
import { addArticleToPortafolioDati } from "../redux/actions";
import {
  //addArticleToPortafolioDati,
  saveSourceRequest,
  //deleteSourceRequest,
} from "../redux/actions";
import { AuthContext } from "./authContext";
import { bool } from "prop-types";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Api} from '../utils';
const LIMIT_PAGE = 10;

const initial = [
  {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
    filterApplied: false,
  },
];

const synonyms = [
  "acquisti", //shopping
  "Acquista", //purchase
  "affari",
  "acquisto",
  "negozio", //shop
  "vendere", //sell
  "vendita", // selling
  "shopping",
  "e-commerce",
  "shop",
  "purchasing",
  "buying",
  "buisness",
  "b2b",
  "purchase",
  "amazon",
  "ebay",
  "shopify",
  "alibaba",
  "walmart",
  "taobao",
  "etsy",
  "aliexpress",
  "flipkart",
  "myntra",
  "daraz",
  "woocommerce",
  "magento",
  "craigslist",
  "jd.com",
  "rakuten",
];
const social_synonyms = [
  "sociale", //shopping
  "facebook",
  "instagram",
  "whatsapp",
  "imo",
  "skype",
  "wechat",
  "medium",
  "qq",
  "qzone",
  "tumblr",
  "twitter",
  "viber",
  "line",
  "snapchat",
  "linkedin",
  "telegram",
  "reddit",
  "taringa",
  "slack",
  "youtube",
  "upstream",
  "twitch",
  "social media",
  "social",
];

const SearchContext = createContext({});

function SearchContextProvider({ children }) {
  const { setShowSignUp, setSelectedSignUpType } = useContext(AuthContext);
  const { Provider } = SearchContext;
  const [searchValue, setSearchValue] = useState("");
  const [searchRawResults, setSearchRawResults] = useState({});
  const [newsResults, setNewsResults] = useState([]); // apply all the filter on this array then store to paginatedNewsResults paginate
  const [webSearchResults, setWebSearchResults] = useState([]); // apply all the filter on this array then store to paginatedWebSearchResults paginate
  const [paginatedWebSearchResults, setPaginatedWebSearchResults] = useState(
    []
  );
  const [paginatedNewsResults, setPaginatedNewsResults] = useState([]);
  const [selectedEngines, setSelectedEngines] = useState(() => {
    // Initialize to an empty array or whatever default you'd like other than local storage
    return ["bing"];
  });
  
  
  const [loader, setLoader] = useState(false);
  const [tab, setTab] = useState("web");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [newsCategoryFilter, setNewsCategoryFilter] = useState("");
  const [newsLanguageFilter, setNewsLanguageFilter] = useState("");
  const [newsDateSelection, setNewsDateSelection] = useState(initial);
  const [showCategoryNewsFilter, setShowCategoryNewsFilter] = useState(false);
  const [suggestedNewsCategoryFilter, setSuggestedNewsCategoryFilter] =
    useState([]);
  const [showLanguageNewsFilter, setshowLanguageNewsFilter] = useState(false);
  const [showDateNewsFilter, setshowDateNewsFilter] = useState(false);
  const [inTitleFilter, setInTitleFilter] = useState(false);
  const [inTextFilter, setInTextFilter] = useState(false);
  const [location, setLocation] = useState("");
  const [newsSearchInSources, setNewsSearchInSources] = useState(false);
  const { auth } = useSelector((state) => state);
  const [videoSearch, setVideoSearch] = useState([]);
  const [mapsSearch, setMapsSearch] = useState([]);
  const [socialSearch, setSocialSearch] = useState([]);
  const [azindeSearch, setAzindeSearch] = useState([]);
  const [youtubePageToken, setYoutubePageToken] = useState("");
  const [youtubePageTokens, setYoutubePageTokens] = useState([]);
  const [allowSearch, setAllowSearch] = useState(true);
  const [allowNewsSearch, setAllowNewsSearch] = useState(true);
  const [mapSearchResults, setMapSearchResults] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("it"); // Define the selectedLanguage state with a default value of "en"

  const [record, setRecord] = useState({});
  const dispatch = useDispatch();
 

  const translations = {
    en: {
     
      'ppps1segnala': "No results with this engine, try selecting another one!",
      'ppps2segnala': "Error in saving the source, please try again!",
      'ppps3segnala': "Source saved successfully!",
      'ppps4segnala': "Please enter at least 3 characters!",
    },
    it: {

  
      'ppps1segnala': "Nessun risultato con questo motore, prova a selezionarne un'altro!",
      'ppps2segnala': "Errore nel salvataggio della fonte, riprova!",
      'ppps3segnala': "Fonte salvata con successo!",
      'ppps4segnala': "Inserisci più di 3 caratteri!",
    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser

  const ppps1segnal = translate('ppps1segnala', lang);
  const ppps2segnal = translate('ppps2segnala', lang);
  const ppps3segnal = translate('ppps3segnala', lang);
  const ppps4segnal = translate('ppps4segnala', lang);


  const handleAttemptedAccess = async (link) => {
    try {
      const res = await Api.postAxios(
        endPoints.attemptedAccess,
        { link },
        {
          headers: {
            Authorization: `Bearer ${auth?.data?.token}`,
          },
        }
      );
      // Handle the response if needed
    } catch (error) {
      console.error("Failed to send attempted access request:", error);
    }
  };
  


  // **********Web Search***********
  const paginateWebSearchResults = (data, pg = 1) => {
    const paginated = paginateArray(data, pg, LIMIT_PAGE);
    setPaginatedWebSearchResults(paginated);
  };

  // Add a new function to handle map search
  const handleMapSearch = async () => {
    const params = {
      key: 'AIzaSyAUnqrijWNKCls6b_Qb_iQcXnUyPfyR_7g',
      query: searchValue,
      language: selectedLanguage,
    };

    setLoader(true);
    const res = await axios.get(
      "https://afternoon-island-31692.herokuapp.com/https://maps.googleapis.com/maps/api/place/textsearch/json",
      {
        params,
      }
    );
console.log(searchValue)
    setMapSearchResults(res?.data?.results);
    setLoader(false);
  };

  const getWebSearchData = async (type, reset = false) => {
    console.log(`Fetching data for engine: ${type}`);
    setLoader(true);
  
    if (!auth?.data?.token) {
      setAllowSearch(false);
      setLoader(false);
      toast.info("Login to search with Weagle!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        // onClose: () => {
        //   setSelectedSignUpType("Persona");
        //   setShowSignUp((prev) => !prev);
        // },
        
      });
      return;
    }
  
    try {
      console.log("Request data:", {
        searchValue,
        selectedEngines,
        page,
        headers: {
          Authorization: `Bearer ${auth?.data?.token}`,
        },
      });
  
      const res = await Api.getAxios(
        endPoints.getArticlesFromWeb(searchValue, selectedEngines, page),
        {
          headers: {
            Authorization: `Bearer ${auth?.data?.token}`,
          },
        }
      );
  
      console.log(`Received data for engine: ${type}`, res?.data?.data?.results);
  
      if (res?.data?.data?.results?.length > 0) {
        const newResults = reset ? { [type]: res.data.data?.results } : {
          ...searchRawResults,
          [type]: res.data.data?.results,
        };
        setSearchRawResults(newResults);
  
        let newWebRes = [...res.data.data?.results];
        if (!reset) {
          newWebRes = [...res.data.data?.results, ...webSearchResults];
        }
        paginateWebSearchResults(newWebRes, 1);
        setWebSearchResults([...newWebRes]);
        calculateTotalPages(newWebRes);
        setPage(1);
      } else {
        console.log(`No results found for engine: ${type}`);
        toast.error("No results found", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error(`Error fetching data for engine: ${type}`, error);
      toast.error("An error occurred while fetching search results", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  
    setLoader(false);
  };
  
  
  const handleEngineSelection = (isChecked, type) => {
    if (isChecked && searchValue?.length > 0) {
      setSelectedEngines((prev) => {
        const updatedEngines = [...prev, type];
        return updatedEngines;
      });
  
      if (!(type in searchRawResults) || searchRawResults[type]?.length === 0) {
        getWebSearchData(type);
      } else {
        const newWebRes = [...searchRawResults[type], ...webSearchResults];
        paginateWebSearchResults(newWebRes, 1);
        setWebSearchResults([...newWebRes]);
        calculateTotalPages(newWebRes);
      }
    } else {
      if (selectedEngines?.length > 1) {
        setSelectedEngines((prev) => {
          const updatedEngines = prev.filter((item) => item !== type);
          return updatedEngines;
        });
  
        if (webSearchResults?.length > 0) {
          const newWebRes = webSearchResults.filter((item) => item?.type !== type);
          paginateWebSearchResults(newWebRes, 1);
          calculateTotalPages(newWebRes);
          setWebSearchResults(newWebRes);
        }
      }
    }
    setPage(1);
  };
  
  

  const handleWebSearch = () => {
    setSelectedEngines(["bing"]);
    setSearchRawResults([]);
    setWebSearchResults([]);
    setPage(1);
    getWebSearchData("bing", true);
  };


  const handleVideoSearch = async (pageToken = null) => {
    const params = {
      maxResults: 25,
      key,
      part: "snippet",
      q: searchValue,
    };

    if (pageToken) {
      params.pageToken = pageToken;
    }

    setLoader(true);
    const res = await axios.get(
      "https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=10&q=example&type=video&relevanceLanguage=it",
      {
        params,
      }
    );

    setYoutubePageTokens({
      nextPageToken: res?.data?.nextPageToken,
      prevPageToken: res?.data?.prevPageToken,
    });

    setVideoSearch(res?.data?.items);
    setLoader(false);
  };

  const onSubmitSearch = () => {
    // Assuming searchValue is the state variable holding the search input
    if (searchValue.length < 3) {
      toast.error(ppps4segnal, { 
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
      
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      return; // Exit the function early if the condition is met
    }
  
    // Continue with the existing logic if the search term is 3 or more characters
    if (tab === "web") {
      handleWebSearch();
    } else if (tab === "news") {
      handleNewsSearch();
    } else if (tab === "video") {
      handleVideoSearch();
    } else if (tab === "aziende") {
      handleAzinde();
    }
    else if (tab === "maps") {
      handleMapSearch(); // Call the new function to handle map search
    }
  };
  
  // **********Web Search***********

  // **********News Search***********
  const paginateNewsSearchResults = (data, pg = 1) => {
    const paginated = paginateArray(data, pg, LIMIT_PAGE);
    setPaginatedNewsResults(paginated);
  };

  const implementDateFilter = (news) => {
    // if filtered is applied then filter otherwise return original array
    if (newsDateSelection?.length > 0 && newsDateSelection[0].filterApplied) {
      const { startDate, endDate } = newsDateSelection[0];
      return news.filter(
        (item) =>
          isDateSameOrAfter(new Date(item.pubDate), startDate) &&
          isDateSameOrAfter(endDate, new Date(item.pubDate))
      );
    }

    return news;
  };

  // const getNewsSearchData = async (lang = "it-IT") => {
  //   if (allowNewsSearch) {
  //     if (!searchValue) {
  //       setNewsLanguageFilter("");
  //       setNewsCategoryFilter("");
  //       return;
  //     }
  //     let searchString = searchValue;
  
  //     // apply search filter
  //     if (newsCategoryFilter) {
  //       searchString = searchValue + " " + newsCategoryFilter;
  //     }
  
  //     setLoader(true);
  //     const res = await axios.post(
  //       endPoints.getSearchFeed,
  //       {
  //         keyword: searchString,
  //         locale: newsLanguageFilter || lang, // apply language
  //         countryCode: location || "IT",
  //         fromSource: newsSearchInSources,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${auth?.data?.token}`,
  //         },
  //       }
  //     );
  
  //     if (res?.data?.data?.length > 0) {
  //       const formattedResults = res.data.data?.map((item) => {
  //         const div = document.createElement("div");
  //         div.innerHTML = item?.description;
  //         const text = div.textContent || div.innerText || "";
  
  //         return {
  //           title: item.title,
  //           description: text,
  //         };
  //       });
  
  //       const res2 = await axios.post(
  //         'https://fakeapi.herokuapp.com',
  //         { arr: formattedResults }
  //       );
  
  //       const results = res2.data.result_arr;
  
  //       // add the result to the formattedResults array
  //       const formattedResultsWithResult = formattedResults.map((item, index) => ({
  //         ...item,
  //         result: results[index].result,
  //       }));
  
  //       let filteredArray = implementDateFilter(formattedResultsWithResult);
  
  //       if (inTitleFilter && !inTextFilter) {
  //         filteredArray = filteredArray.filter((item) =>
  //           item?.title?.toLowerCase()?.includes(searchString?.toLowerCase())
  //         );
  //       }
  
  //       if (!inTitleFilter && inTextFilter) {
  //         filteredArray = filteredArray.filter((item) =>
  //           item?.description
  //             ?.toLowerCase()
  //             ?.includes(searchString?.toLowerCase())
  //         );
  //       }
  
  //       paginateNewsSearchResults(filteredArray);
  //       calculateTotalPages(filteredArray);
  //       setNewsResults((prev) => [...formattedResultsWithResult]);
  //     }
  //     setLoader(false);
  //   } else {
  //     alert("Hai già utilizzato la tua ricerca demo! ");
  //   }
  
  //   if (!auth?.data?.token) {
  //     setAllowNewsSearch(false);
  //   }
  // };
  const getNewsSearchData = async (lang = "it-IT") => {
    if (allowNewsSearch) {
      if (!searchValue) {
        setNewsLanguageFilter("");
        setNewsCategoryFilter("");
        return;
      }
      let searchString = searchValue;

      // apply search filter
      if (newsCategoryFilter) {
        searchString = searchValue + " " + newsCategoryFilter;
      }

      setLoader(true);
      const res = await axios.post(
        endPoints.getSearchFeed,
        {
          keyword: searchString,
          locale: newsLanguageFilter || lang, // apply language
          countryCode: location || "IT",
          fromSource: newsSearchInSources,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.data?.token}`,
          },
        }
      );

      if (res?.data?.data?.length > 0) {
        const formattedResults = res.data.data?.map((item) => {
          const div = document.createElement("div");
          div.innerHTML = item?.description;
          const text = div.textContent || div.innerText || "";

          return {
            ...item,
            description: text,
          };
        });

        let filteredArray = implementDateFilter(formattedResults);

        if (inTitleFilter && !inTextFilter) {
          filteredArray = filteredArray.filter((item) =>
            item?.title?.toLowerCase()?.includes(searchString?.toLowerCase())
          );
        }

        if (!inTitleFilter && inTextFilter) {
          filteredArray = filteredArray.filter((item) =>
            item?.description
              ?.toLowerCase()
              ?.includes(searchString?.toLowerCase())
          );
        }

        paginateNewsSearchResults(filteredArray);
        calculateTotalPages(filteredArray);
        setNewsResults((prev) => [...formattedResults]);
      }
      setLoader(false);
    } else {
      alert("SignUp to search with Weagle!");
    }

    if (!auth?.data?.token) {
      setAllowNewsSearch(false);
    }
  };


  const handleNewsSearch = () => {
    setPage(1);
    getNewsSearchData();
  };

// search_context.js
const changePagePagination = async (page) => {
  setLoader(true); // Set loader to true before making the API call

  if (tab === "web") {
    try {
      const response = await Api.getAxios(
        endPoints.getArticlesFromWeb(searchValue, selectedEngines, page),
        {
          headers: {
            Authorization: `Bearer ${auth?.data?.token}`,
          },
        }
      );

      if (response?.status) {
        const newResults = response.data.data.results;
        setWebSearchResults(newResults);
        setPaginatedWebSearchResults(paginateArray(newResults, 1, LIMIT_PAGE));
        setTotalPages(Math.ceil(newResults.length / LIMIT_PAGE));
      } else {
        console.error("Failed to fetch web search results");
      }
    } catch (error) {
      console.error("Error fetching web search results:", error);
    }
  } else if (tab === "news") {
    const newPage = paginateArray(newsResults, page, LIMIT_PAGE);
    setPaginatedNewsResults(newPage);
  }

  setPage(page);
  setLoader(false); // Set loader back to false after receiving the results
};



  const calculateTotalPages = (data) => {
    setTotalPages(
      data?.length < LIMIT_PAGE ? 1 : Math.ceil(data?.length / LIMIT_PAGE)
    );
  };

  const resetNewsFilters = () => {
    setNewsDateSelection(initial);
    setNewsCategoryFilter("");
    setNewsLanguageFilter("");
    setNewsResults([]);
    setPaginatedNewsResults([]);
    setTotalPages(0);
    setPage(1);

    getNewsSearchData();
  };

  const handleAzinde = async () => {
    setLoader(true);
    const res = await axios.get(
      endPoints.getArticlesFromWeb(searchValue, "aziende"),
      {
        headers: {
          Authorization: `Bearer ${auth?.data?.token}`,
        },
      }
    );

    setAzindeSearch(res?.data?.data?.results);
    setLoader(false);
  };

  useEffect(() => {
    if (auth?.data?.token) {
      if (searchValue?.length > 0) {
        onSubmitSearch();
      }
    }
  }, [tab]);

  // get news if there in any filter
  useEffect(() => {
    if (
      newsLanguageFilter?.length > 0 ||
      newsCategoryFilter?.length > 0 ||
      (newsDateSelection?.length > 0 && newsDateSelection[0].filterApplied)
    ) {
      getNewsSearchData();
    }
  }, [
    newsLanguageFilter,
    newsCategoryFilter,
    JSON.stringify(newsDateSelection),
  ]);

  useEffect(() => {
    getNewsSearchData();
  }, [newsSearchInSources]);

  useEffect(() => {
    if (showDateNewsFilter) {
      setShowCategoryNewsFilter(false);
      setshowLanguageNewsFilter(false);
    }
    if (showCategoryNewsFilter) {
      setshowDateNewsFilter(false);
      setshowLanguageNewsFilter(false);
    }
    if (showLanguageNewsFilter) {
      setshowDateNewsFilter(false);
      setShowCategoryNewsFilter(false);
    }
  }, [showDateNewsFilter, showCategoryNewsFilter, showLanguageNewsFilter]);

  // handle save request

  const handelAddDataWalletRequest = () => {
    const cbSuccess = (response) => {
      console.log("Added to data wallet added successfully");
    };
    const cbFailure = (error) => {
      setLoading(false);
      alert(error.message);
    };

    const tempData = {
      ...record,
      type: "news",
    };

    if (auth?.data?.token) {
      setLoading(true);
      dispatch(
        saveSourceRequest(tempData, auth?.data?.token, cbSuccess, cbFailure)
      );
    }
  };
 
  const handleSaveSourceRequest = (text, url) => {
    const cbSuccess = (response) => {
      //setSavedSource(true);
      toast.success(ppps3segnal, { 
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
      
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    };
    const cbFailure = (error) => {
      toast.error(ppps2segnal, { 
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
      
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    };
    const data = new FormData();
    data.append("source", text);
    data.append("keyword", searchValue);
    data.append("url", url);
    if (auth?.data?.token)
      dispatch(
        saveSourceRequest(data, auth?.data?.token, cbSuccess, cbFailure)
      );
  };

  const handleAddArticleToPortafolioDati = (record) => {
    console.log("rec", record);
    let isSynonym = false;
    for (let i = 0; i < synonyms.length; i++) {
      if (
        record?.title?.toLowerCase().includes(synonyms[i].toLowerCase()) ||
        record?.description?.toLowerCase().includes(synonyms[i].toLowerCase())
      ) {
        const data = new FormData();
        data.append("type", "interessi_personali");
        data.append("title", record?.title);
        data.append("description", record?.description);
        data.append("link", record?.link);
        data.append("pubDate", record?.pubDate);
        data.append("source", JSON.stringify(record?.source));

        if (auth?.data?.token)
          dispatch(addArticleToPortafolioDati(data, auth?.data?.token));
        isSynonym = true;
        return;
      }
    }
    for (let i = 0; i < social_synonyms.length; i++) {
      if (
        record.title.toLowerCase().includes(social_synonyms[i].toLowerCase()) ||
        record.description
          .toLowerCase()
          .includes(social_synonyms[i].toLowerCase())
      ) {
        const data = new FormData();
        data.append("type", "interessi_personali");
        data.append("title", record?.title);
        data.append("description", record?.description);
        data.append("link", record?.link);
        data.append("pubDate", record?.pubDate);
        data.append("source", JSON.stringify(record?.source));
        if (auth?.data?.token)
          dispatch(addArticleToPortafolioDati(data, auth?.data?.token));
        isSynonym = true;
        return;
      }
    }
    if (!isSynonym) {
      const data = new FormData();
      data.append("type", "interessi_personali");
      data.append("title", record?.title);
      data.append("description", record?.description);
      data.append("link", record?.link);
      data.append("pubDate", record?.pubDate);
      data.append("source", JSON.stringify(record?.source));
      if (auth?.data?.token)
        dispatch(addArticleToPortafolioDati(data, auth?.data?.token));
    }
  };

  useEffect(() => {
    setAllowSearch(true);
    setAllowNewsSearch(true);
  }, [JSON.stringify(auth)]);

  return (
    <Provider
      value={{
        searchValue,
        setSearchValue,
        selectedEngines,
        handleEngineSelection,
        loader,
        webSearchResults,
        onSubmitSearch,
        tab,
        setTab,
        newsResults,
        paginatedWebSearchResults,
        changePagePagination,
        totalPages,
        page,
        paginatedNewsResults,
        newsCategoryFilter,
        setNewsCategoryFilter,
        newsLanguageFilter,
        setNewsLanguageFilter,
        newsDateSelection,
        setNewsDateSelection,
        resetNewsFilters,
        showCategoryNewsFilter,
        setShowCategoryNewsFilter,
        showLanguageNewsFilter,
        setshowLanguageNewsFilter,
        suggestedNewsCategoryFilter,
        setSuggestedNewsCategoryFilter,
        showDateNewsFilter,
        setshowDateNewsFilter,
        setInTitleFilter,
        setInTextFilter,
        location,
        setLocation,
        handleSaveSourceRequest,
        handleAttemptedAccess,
        setRecord,
        record,
        handelAddDataWalletRequest,
        handleAddArticleToPortafolioDati,
        newsSearchInSources,
        setNewsSearchInSources,
        videoSearch,
        mapsSearch,
        socialSearch,
        azindeSearch,
        setYoutubePageToken,
        youtubePageTokens,
        handleVideoSearch,
      }}
    >
      {children}
    </Provider>
  );
}

export { SearchContextProvider, SearchContext };
