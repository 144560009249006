import {fork} from 'redux-saga/effects';
import {signUpRequest} from './auth-saga/signup-saga';
import {loginRequest} from './auth-saga/login-saga';
import {azureLoginRequest} from './auth-saga/loginAzure-saga';
import {getUserDataRequest} from './auth-saga/get-user-data-saga';
import {validateTokenRequest} from './auth-saga/validate-token-saga';
import {changePassword} from './auth-saga/change-password-saga';
import {updateUserTypeRequest} from './auth-saga/update-user-type-saga';
import {addFolderRequest} from './folder-saga/add-folder-saga';
import {getFolderRequest} from './folder-saga/get-folder-saga';
import {renameFolderRequest} from './folder-saga/rename-folder-saga';
import {deleteFolderRequest} from './folder-saga/delete-folder-saga';
import {saveSourceRequest} from './folder-saga/save-source-saga';
import {getSourceRequest} from './folder-saga/get-source-saga';
import {getSourceFeedsRequest} from './folder-saga/get-source-feeds-saga';
import {deleteSourceRequest} from './folder-saga/delete-source-saga';
import {addArticleToFolderRequest} from './folder-saga/add-article-to-folder-saga';
import {deleteArticleFromFolderRequest} from './folder-saga/delete-article-from-folder-saga';
import {addArticlesToPortafolioDatiRequest} from './portafolio-dati-saga/add-articles-to-portafolio-dati-saga';
import {getArticlesFromPortafolioDatiRequest} from './portafolio-dati-saga/get-articles-from-portafolio-dati-saga';
import {deleteArticlesFromPortafolioDatiRequest} from './portafolio-dati-saga/delete-articles-from-portafolio-dati-saga';
import {profileUpdateRequest} from './profile-Update-saga/profile-update';
import {getArticlesFromWebRequest} from './search-from-web-saga/get-search-results-from-web';
import {getCouponRequest} from './coupon-saga/get-coupon-saga';
import {saveUserRequest} from './cashOut-saga/save-user-request-saga';
import {addReceiptRequest} from './receipt-saga/add-receipt-saga';
import {saveCompleteProfileRequest} from './profile-Update-saga/save-complete-profile';
import {getSurveysRequest} from './survey-saga/get-Surveys';
import {saveSurveyResponseRequest} from './survey-saga/save-survey-response-saga';
import {getSurveyResponseAndCompleteProfileRequest} from './profile-Update-saga/get-Survey-Response-and-complete-profile-saga';
import {saveSupportRequest} from './profile-Update-saga/save-support-saga';
import {sellArticlesFromPortafolioDatiRequest} from './portafolio-dati-saga/sell-articles-from-portafolio-dati-saga';
import {getNewsRequest} from './news-saga/get-News-saga';

export function* rootSaga() {
  yield fork(signUpRequest);
  yield fork(loginRequest);
  yield fork(azureLoginRequest);
  yield fork(getUserDataRequest);
  yield fork(updateUserTypeRequest);
  yield fork(addFolderRequest);
  yield fork(getFolderRequest);
  yield fork(deleteFolderRequest);
  yield fork(saveSourceRequest);
  yield fork(getSourceRequest);
  yield fork(getSourceFeedsRequest);
  yield fork(deleteSourceRequest);
  yield fork(addArticleToFolderRequest);
  yield fork(deleteArticleFromFolderRequest);
  yield fork(renameFolderRequest);
  yield fork(addArticlesToPortafolioDatiRequest);
  yield fork(getArticlesFromPortafolioDatiRequest);
  yield fork(deleteArticlesFromPortafolioDatiRequest);
  yield fork(profileUpdateRequest);
  yield fork(validateTokenRequest);
  yield fork(getArticlesFromWebRequest);
  yield fork(getCouponRequest);
  yield fork(saveUserRequest);
  yield fork(addReceiptRequest);
  yield fork(changePassword);
  yield fork(saveCompleteProfileRequest);
  yield fork(getSurveysRequest);
  yield fork(saveSurveyResponseRequest);
  yield fork(getSurveyResponseAndCompleteProfileRequest);
  yield fork(saveSupportRequest);
  yield fork(sellArticlesFromPortafolioDatiRequest);
  yield fork(getNewsRequest);
}
