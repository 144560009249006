import React, { useContext } from "react";

import "./user-sign-up.css";

import SignUpField from "./signUpField";
import { AuthContext } from "../context/authContext";
import Loader from "./loader";

const UserSignUp = (props) => {

  const translations = {
    en: {
     
      'ppps1usersign1': "First Name",
      'ppps1usersign2': "Last Name",
      'ppps1usersign3': "Create account",
      'ppps1usersign4': "I already have an account",
      'ppps1usersign5': "Set as default search engine",
   
    },
    it: {

  
      'ppps1usersign1': "Nome",
      'ppps1usersign2': "Cognome",
      'ppps1usersign3': "Crea account",
      'ppps1usersign4': "Ho già un account",
      'ppps1usersign5': "Set as default search engine",

    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser

  const ppps1usersigna = translate('ppps1usersign1', lang);
  const ppps1usersigna1 = translate('ppps1usersign2', lang);
  const ppps1usersigna2 = translate('ppps1usersign3', lang);
  const ppps1usersigna3 = translate('ppps1usersign4', lang);
  const ppps1usersigna4 = translate('ppps1usersign5', lang);




  const {
    accountType,
    setSelectedSignUpType,
    signupUser,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    password,
    setPassword,
    loading,
  } = useContext(AuthContext);

  return (
    <div className="user-sign-up-frame5143">
      {accountType === "pro" && (
        <p className={"message-signup-form"}>
          Payment Successful, please fill form to register as PRO user
        </p>
      )}

      <SignUpField
        label={ppps1usersigna}
        placeHolder={"Mario"}
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
      <SignUpField
        label={ppps1usersigna1}
        placeHolder={"Rossi"}
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />
      <SignUpField
        label={"Email"}
        placeHolder={"mario@rossi.it"}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type={'email'}
        autoComplete={'email'}
      />
      <SignUpField
        label={"Password"}
        placeHolder={"*******"}
        password={password}
        onChange={(e) => setPassword(e.target.value)}
        type={'password'}
        autoComplete={'password'}
      />

      {loading && <Loader className={"loaderFeed"} />}
      {!loading && (
        <>
          <div onClick={() => signupUser()} className="user-sign-up-frame13">
            <span className="user-sign-up-text15">
              <span>{ppps1usersigna2}</span>
            </span>
          </div>
          <div
            onClick={() => setSelectedSignUpType("login")}
            className="user-sign-up-text17"
          >
            <span>{ppps1usersigna3}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default UserSignUp;
