import React from 'react'

import PropTypes from 'prop-types'
import { NavLink } from "react-router-dom";
import './side-bar.css'
import { useContext } from "react";
import { AuthContext } from "../context/authContext"; 
import { useHistory } from "react-router-dom";
import ChangeHomepageButton from './changeHomeButton';
import SegnalaProblema from './segnalaProblema';
import { useDispatch, useSelector } from "react-redux";
import ChatIcon from '../images/ChatIcon.svg';
import ProfileSide from './profile-side';
const openInNewTab = (url) => {


  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
} 
const SideBarHome = (props) => {
  const translations = {
    en: {
      'archivio': 'Archive',
    },
    it: {

      'archivio': 'Archivio',
      
    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser
  const text01Side = translate('archivio', lang);
  const {
    firstName,
    setFirstName,
    iva,
    setIva,
    email,
    setEmail,
    loading,
    nickName,
    setNickName,
    accountType,
    setAccountType,
    address,
    setAddress,
    common,
    setCommon,
    country,
    setCountry,
    handelProfileEnterprice,
    image,
    setImage,
  } = useContext(AuthContext);
  const history = useHistory();
  const { auth, folder } = useSelector((state) => state);
  let activeStyle = {
    backgroundColor: "white",
    color: "#000",
    transition: "1s",
  }; 
  
  return (
    <div className={`side-bar-container ${props.rootClassName} `}>
     
        
   




  <div >

 
  </div>
  <div >
    <div className="segnalaemotoredivButton">
    <SegnalaProblema />  

    </div>
 
  </div>


    </div>
   
  )
}

SideBarHome.defaultProps = {
  image_src1: '/playground_assets/messages-chat-text%201.svg',
  image_src12: '/playground_assets/lock-protection-checkmark%201.svg',
  Weagle: 'Weagle',
  image_alt13: 'image',
  image_src11: '/playground_assets/heart-checkmark%201.svg',
  image_alt11: 'image',
  image_alt12: 'image',
  image_alt: 'image',
  rootClassName: '',
  image_src13: '/playground_assets/bookmarks%201.svg',
  image_alt1: 'image',
  image_src: '/playground_assets/group201.svg',
}

SideBarHome.propTypes = {
  image_src1: PropTypes.string,
  image_src12: PropTypes.string,
  Weagle: PropTypes.string,
  image_alt13: PropTypes.string,
  image_src11: PropTypes.string,
  image_alt11: PropTypes.string,
  image_alt12: PropTypes.string,
  image_alt: PropTypes.string,
  rootClassName: PropTypes.string,
  image_src13: PropTypes.string,
  image_alt1: PropTypes.string,
  image_src: PropTypes.string,
}

export default SideBarHome
