import {takeLatest, put} from 'redux-saga/effects';
import * as types from '../../actions/types';
import {Api} from '../../../utils';
import {endPoints} from '../../../utils';

export function* validateTokenRequest() {
  yield takeLatest(types.VALIDATE_TOKEN_REQUEST, validateToken);
}

function* validateToken(params) {
  try {
    let options = {
      headers: {
        Authorization: 'Bearer ' + params.token,
      },
    };
    let configuration = Object.assign(options);
    let response = yield Api.getAxios(endPoints.validateToken, configuration);
    if (response?.data.status) {
      params.cbSuccess(response);
    } else {
      params.cbFailure(response);
    }
  } catch (err) {
    console.log('error from validate token request saga -- > > >  > ', err);
    params.cbFailure(err.message);
  }
}
