import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Categories, langFilterData, dates } from "../services";
import "./component-advanced-search.css";
import { SearchContext } from "../context/search_context";
import AdvanceSearchDataFilter from "./advanceSearchDataFilter";
import { useHistory } from "react-router-dom";


const translations = {
  en: {
   
    'pppsx1': 'Advanced search',
    'pppsx2': 'By selecting all the filters, you can get a specific search.',
    'pppsx3': 'Increasing the number of filters decreases the number of results.',
    'pppsx4': 'Specific text search',
    'pppsx5': 'Search',
    'pppsx6': 'In the title',
    'pppsx7': 'In the text',
    'pppsx8': 'News',
    'pppsx9': 'Business',
    'pppsx10': 'Politics',
    'pppsx11': 'Sports',
    'pppsx12': 'Technology',
    'pppsx13': 'Science',
    'pppsx14': 'Culture',
    'pppsx15': 'Gossip',
    'pppsx16': 'Save filters',
    'pppsx17': 'Last 7 days',
    'pppsx18': 'Last 30 days',
    'pppsx19': 'Last 90 days',
    'pppsx20': 'Languages',
    'pppsx21': 'Country',
    'pppsx22': 'from',
    'pppsx23': 'to',
    'pppsx24': 'Categories',
    
  },
  it: {


    'pppsx1': 'Ricerca avanzata',
    'pppsx2': 'Selezionando tutti i filtri potrai ottenere un ricerca specifica.',
    'pppsx3': 'Aumentando il numero di filtri diminuiscono i numeri di risutlati.',
    'pppsx4': 'Ricerca testo specifico',
    'pppsx5': 'Cerca',
    'pppsx6': 'Nel titolo',
    'pppsx7': 'Nel testo',
    'pppsx8': 'Cronaca',
    'pppsx9': 'Economia',
    'pppsx10': 'Politica',
    'pppsx11': 'Sport',
    'pppsx12': 'Tecnologia',
    'pppsx13': 'Scienze',
    'pppsx14': 'Cultura',
    'pppsx15': 'Gossip',
    'pppsx16': 'Salva filtri',
    'pppsx17': 'Ultimi 7 giorni',
    'pppsx18': 'Ultimi 30 giorni',
    'pppsx19': 'Ultimi 90 giorni',
    'pppsx20': 'Lingue',
    'pppsx21': 'Paese',
    'pppsx22': 'da',
    'pppsx23': 'a',
    'pppsx24': 'Categorie',
  },
};
function translate(key, lang) {
  const italianTranslations = translations.it;

  if (lang === 'it' && italianTranslations[key]) {
    return italianTranslations[key];
  }

  return translations.en[key];
}
const lang = navigator.language.split('-')[0]; // Get the user's language from the browser

const pppsx1a = translate('pppsx1', lang);
const pppsx2a = translate('pppsx2', lang);
const pppsx3a = translate('pppsx3', lang);
const pppsx4a = translate('pppsx4', lang);
const pppsx5a = translate('pppsx5', lang);
const pppsx6a = translate('pppsx6', lang);
const pppsx7a = translate('pppsx7', lang);
const pppsx8a = translate('pppsx8', lang);
const pppsx9a = translate('pppsx9', lang);
const pppsx10a = translate('pppsx10', lang);
const pppsx11a = translate('pppsx11', lang);
const pppsx12a = translate('pppsx12', lang);
const pppsx13a = translate('pppsx13', lang);
const pppsx14a = translate('pppsx14', lang);
const pppsx15a = translate('pppsx15', lang);
const pppsx16a = translate('pppsx16', lang);
const pppsx17a = translate('pppsx17', lang);
const pppsx18a = translate('pppsx18', lang);
const pppsx19a = translate('pppsx19', lang);
const pppsx20a = translate('pppsx20', lang);
const pppsx21a = translate('pppsx21', lang);
const pppsx22a = translate('pppsx22', lang);
const pppsx23a = translate('pppsx23', lang);
const pppsx24a = translate('pppsx24', lang);
const initial = [
  {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
    filterApplied: false,
  }
];

const ComponentAdvancedSearch = (props) => {
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState(false);
  const [text, setText] = useState(false);
  const [language, setLanguage] = useState(Object.keys(langFilterData));
  const [search, setSearch] = useState("");
  const [newsDateSelection, setNewsDate] = useState(initial);
  const [newsLanguageFilter, setNewsLanguage] = useState("");
  const [showDateNewsFilter, setshowDateNewsFilter] = useState(false);
  const [locationCode, setLocationCode] = useState("");
  const [data, setData] = useState("");

  const history = useHistory();

  const {
    setNewsLanguageFilter,
    setNewsCategoryFilter,
    setNewsDateSelection,
    setInTitleFilter,
    setInTextFilter,
    setSearchValue,
    setLocation,
    searchValue
  } = useContext(SearchContext);

  const handleSearch = () => {
    if (!searchValue?.length || searchValue?.length < 4) {
      alert("Inserisci del testo nel campo di ricerca");
    }

    setSearchValue(searchValue);
    setNewsLanguageFilter(newsLanguageFilter);
    setNewsDateSelection(newsDateSelection);
    setNewsCategoryFilter(category);
    setInTitleFilter(title);
    setInTextFilter(text);
    setLocation(locationCode);

    history.push("/news-search");
  };
  const closeBtn = () => {
    setshowDateNewsFilter(false);
  };

  console.log(data);

  return (
    <div
      className={`component-advanced-search-container ${props.rootClassName} `}
    >
      <div className="component-advanced-search-frame5139">
        <div className="component-advanced-search-frame5115">
          <div className="component-advanced-search-frame5114">
            <span className="component-advanced-search-text">
              <span className="">{pppsx1a}</span>
            </span>
            <span className="component-advanced-search-text02">
              <span className="">
                <span className="">
                {pppsx2a}
                </span>
                <br className=""></br>
                <span className="">
                {pppsx3a}
                </span>
              </span>
            </span>
          </div>
          <div className="component-advanced-search-frame5113">
            <div className="component-advanced-search-frame32">
              <div className="component-advanced-search-frame33">
                <span className="component-advanced-search-text07">
                  <span className="">{pppsx4a}</span>
                </span>
              </div>
              <div className="component-advanced-search-frame31">
                <span className="component-advanced-search-text09">
                  <span className="">
                    <input
                      value={searchValue}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                        }
                      }}
                      onChange={(event) => setSearchValue(event.target.value)}
                      className={"search-input"}
                      type="text"
                      placeholder={pppsx5a}
                    />
                  </span>
                </span>
              </div>
            </div>
            <div className="component-advanced-search-frame5112">
              <div
                className="component-advanced-search-frame5110"
                onClick={() => {
                  title === true ? setTitle(false) : setTitle(true);
                }}
              >
                {title === true && (
                  <img
                    alt="Rectangle569851"
                    src="/playground_assets/approved.png"
                    className="filtro-categorie-checkbox"
                  />
                )}

                {title === false && (
                  <img
                    alt="Rectangle569851"
                    src="/playground_assets/rectangle569851-yszj-200h.png"
                    className="filtro-categorie-checkbox"
                  />
                )}

                <span className="component-advanced-search-text11">
                  <span className="">{pppsx6a}</span>
                </span>
              </div>

              <div
                className="component-advanced-search-frame5111"
                onClick={() => {
                  text === true ? setText(false) : setText(true);
                }}
              >
                {text === true && (
                  <img
                    alt="Rectangle569851"
                    src="/playground_assets/approved.png"
                    className="filtro-categorie-checkbox"
                  />
                )}

                {text === false && (
                  <img
                    alt="Rectangle569851"
                    src="/playground_assets/rectangle569851-yszj-200h.png"
                    className="filtro-categorie-checkbox"
                  />
                )}

                <span className="component-advanced-search-text13">
                  <span className="">{pppsx7a}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="component-advanced-search-frame5122">
          <span className="component-advanced-search-text15">
            <span className="">{pppsx24a}</span>
          </span>
          <div className="component-advanced-search-frame5121">
            {Categories.map((item) => (
              <div
                onClick={() => {
                  setCategory(item);
                }}
                key={`advance_search_${item}`}
                className="component-advanced-search-frame5116"
              >
                {item === category && (
                  <img
                    alt="Rectangle569851"
                    src="/playground_assets/approved.png"
                    className="filtro-categorie-checkbox"
                  />
                )}
                {item !== category && (
                  <img
                    alt="Rectangle569851"
                    src="/playground_assets/rectangle569851-yszj-200h.png"
                    className="filtro-categorie-checkbox"
                  />
                )}
                <span className="component-advanced-search-text17">
                  <span className="">{item}</span>
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="component-advanced-search-frame5132">
          <span className="component-advanced-search-text27">
            <span className="">Data</span>
          </span>
          <div className="component-advanced-search-frame5128">
            {dates.map((item) => {
              return (
                <div
                  className="component-advanced-search-frame5123"
                  onClick={() => {
                    setData(item);
                    const time = new Date();
                    time.setHours(time.getHours() - item.key);
                    setNewsDate([
                      {
                        ...initial,
                        filterApplied: true,
                        startDate: time,
                        endDate: new Date(),
                      },
                    ]);
                  }}
                >
                  {item.name !== data.name && (
                    <img
                      alt="Rectangle569851"
                      src="/playground_assets/rectangle631757-b7v-200h.png"
                      className="component-advanced-search-rectangle63"
                    />
                  )}
                  {item.name === data.name && (
                    <img
                      alt="Rectangle569851"
                      src="/playground_assets/radio-button.png"
                      className="component-advanced-search-rectangle63"
                    />
                  )}

                  <span className="component-advanced-search-text29">
                    <span className="">{item.name}</span>
                  </span>
                </div>
              );
            })}
          </div>
          <div className="component-advanced-search-frame5131">
            <div
              className="component-advanced-search-frame5129"
              onClick={() => {
                setData("");
                if (showDateNewsFilter) {
                  setshowDateNewsFilter(true);
                } else {
                  setNewsDate(initial);
                  setshowDateNewsFilter((prev) => !prev);
                }
              }}
            >
              <span className="component-advanced-search-text39">
                <span className="">da</span>
              </span>
              <div className="component-advanced-search-frame311">
                <span className="component-advanced-search-text41">
                  <span className="">
                    {moment(newsDateSelection[0].startDate).format(
                      "DD/MM/YYYY"
                    )}
                  </span>
                </span>
              </div>
            </div>
            <div
              className="component-advanced-search-frame5130"
              onClick={() => {
                  setData("");
                  if (showDateNewsFilter) {
                    setshowDateNewsFilter(true);
                  } else {
                    setNewsDate(initial);
                    setshowDateNewsFilter((prev) => !prev);
                  }
              }}
            >
              <span className="component-advanced-search-text43">a</span>
              <div className="component-advanced-search-frame331">
                <span className="component-advanced-search-text44">
                  <span className="">
                    {moment(newsDateSelection[0].endDate).format("DD/MM/YYYY")}
                  </span>
                </span>
              </div>
            </div>
            {showDateNewsFilter && (
              <AdvanceSearchDataFilter
                img={closeBtn}
                newsDateSelection={newsDateSelection}
                setNewsDateSelection={setNewsDate}
              />
            )}
          </div>
        </div>
        <div className="component-advanced-search-frame5134">
          <span className="component-advanced-search-text46">
            <span className="">{pppsx20a}</span>
          </span>
          {language.map((item) => {
            return (
              <div
                onClick={() => {
                  setNewsLanguage(
                    langFilterData[item].languages[0] + "-" + item
                  );
                }}
                key={`language_${
                  langFilterData[item].languages[0] + "-" + item
                }`}
                className={`component-advanced-search-langauge language-filter ${
                  newsLanguageFilter ===
                  langFilterData[item].languages[0] + "-" + item
                    ? "active"
                    : ""
                }`}
              >
                <span>{langFilterData[item].name}</span>
              </div>
            );
          })}
          {/* <div className="component-advanced-search-frame5133">
            <span className="component-advanced-search-text50">
              <span className="">Italia</span>
            </span>
            <span className="component-advanced-search-text52">
              <span className="">Francia</span>
            </span>
            <span className="component-advanced-search-text54">
              <span className="">Portogallo</span>
            </span>
            <span className="component-advanced-search-text56">
              <span className="">Messico</span>
            </span>
            <span className="component-advanced-search-text58">
              <span className="">Finlandia</span>
            </span>
          </div> */}
        </div>
        <div className="component-advanced-search-frame5138">
          <span className="component-advanced-search-text60">
            <span className="">{pppsx21a}</span>
          </span>
          <div className="component-advanced-search-frame5136">
            <div className="component-advanced-search-frame5135">
              {language.map((item) => {
                return (
                  <div
                    onClick={() => {
                      setLocationCode(item);
                    }}
                    key={`language_${item + "-location" + item}`}
                    className={`component-advanced-search-langauge language-filter ${
                      locationCode === item ? "active" : ""
                    }`}
                  >
                    <span>{langFilterData[item].name}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div
          onClick={handleSearch}
          className="component-advanced-search-frame13"
        >
          <span className="component-advanced-search-text90">
            <span className="">{pppsx16a}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

ComponentAdvancedSearch.defaultProps = {
  rootClassName: "",
};

ComponentAdvancedSearch.propTypes = {
  rootClassName: PropTypes.string,
};

export default ComponentAdvancedSearch;
