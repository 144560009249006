import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { LinkPreview } from "@dhaiwat10/react-link-preview";
import "./data-wallet-enterprise-results.css";
import DataWalletPagination from "./datawallet-pagination";

const DataWalletEnterpriseResults = (props) => {
  const translations = {
    en: {
     
      'data8': 'Personal interests',
      'data9': 'Shopping',
    },
    it: {

  
      'data8': 'Interessi personali',
      'data9': 'Acquisti',
    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser

  const data8a = translate('data8', lang);
  const data9a = translate('data9', lang);




    // const openLink = (link) => {
    //   window.open(link)
    // }
  // const [title, setTitle] = useState("");
  // console.log("selected type-->", props.link);
  const selectedType = {
    acquisti:"Browser",
    interessi_personali: "Cronologia ricerche",
    sociale: "Social",
  };
  return (
    <div
      className={`data-wallet-enterprise-results-container ${props.rootClassName} `}
    >
      <div className="data-wallet-enterprise-results-results-wallet-data">
        <div className="data-wallet-enterprise-results-wallet-data-container">
          {props.link.map((item) => {
            function extractDomain(url) {
              // Remove the protocol (http:// or https://) if present
              let domain = url.replace(/^(https?:\/\/)?(www\d?\.)?/, '');
            
              // Find the first slash, which marks the end of the domain
              const firstSlashIndex = domain.indexOf('/');
              if (firstSlashIndex !== -1) {
                domain = domain.slice(0, firstSlashIndex);
              }
            
              return domain;
            }
            function getFaviconUrl(domain) {
              return `https://www.google.com/s2/favicons?domain=${domain}`;
            }
            
            return (
              <div className="data-wallet-enterprise-results-results-data-wallet-cardPortafoglio"
              
              >
                {/* <div className="data-wallet-enterprise-results-frame5061"
                  onClick={()=>window.open(item?.link)}
                  >
                    <span className="data-wallet-enterprise-results-text">
                      <div className="data-wallet-enterprise-results-link" 
                      
                      >
                        {item?.link.slice(0, 60)}
                        {item?.link.length > 60 && "..."}
                      </div>
                    </span>
                    
                  </div> */}

                <div className="link">
               

                  <div className="data-wallet-enterprise-results-frame5064">
                    {props.selectedCheck.includes(item) ? (
                      <img
                        onClick={() => props.handleSelect(item)}
                        alt="Rectangle895221"
                        src="/playground_assets/approved.png"
                        className="data-wallet-enterprise-header-rectangle890"
                      />
                    ) : (
                      <img
                        onClick={() => props.handleSelect(item)}
                        alt="Rectangle895221"
                        src="/playground_assets/rectangle895221-my6q-200h.png"
                        className="data-wallet-enterprise-header-rectangle890"
                      />
                    )}
                    <div className="data-wallet-card2">
                      <div className="nuovacardwallet1">
                        
                        
                      <img src={getFaviconUrl(extractDomain(item.link))} alt={extractDomain(item.link)} className="domain-icon" />
                      <span className="data-wallet-title"
                      onClick={()=>window.open(item?.link)} 
                      >
                          
                        {item?.title?.slice(0, 60)}
                        {item?.title?.length > 60 && "..."}
                      </span>
                              {/* <span className="news-search-result-list-link-news2">
                      {item?.link ? (
    <>
      {typeof item.link === 'string' ? (
        item.link.slice(0, 40)
      ) : (
        JSON.stringify(item.link).slice(0, 40)
      )}
      {item?.link.length > 40 && "..."}
    </>
  ) : (
    "No valid link data"
  )}
                      </span> */}
                   <span className="news-search-result-list-link-news2">
  {item?.link ? (
    <>
      {typeof item.link === 'string' ? (
        <>
       
          {extractDomain(item.link)}
        </>
      ) : (
        <>
         
          {extractDomain(JSON.stringify(item.link))}
        </>
      )}
      {item?.link.length > 40 && ""}
    </>
  ) : (
    "No valid link data"
  )}
</span>

                         </div>
              
                
                      <div className="data-wallet-enterprise-results-frame5008">
                        {/* <div className="data-wallet-enterprise-results-frame5003">
                          <span className="data-wallet-enterprise-results-text02">
                            <span className="">
                              {selectedType[props.selectedType]}
                            </span>
                          </span>
                        </div> */}
                        <div className="data-wallet-enterprise-results-frame5004">
                          <span className="data-wallet-enterprise-results-text04">
                            <span className="">{item?.createdAt}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="data-wallet-enterprise-results-frame68">
                    <img
                      onClick={() => {
                        props.handleDeleteLink([item]);
                      }}
                      alt="trashdeletebin215961"
                      src="/playground_assets/trashdeletebin215961-cndo.svg"
                      className="data-wallet-enterprise-results-trashdeletebin21"
                    />
                  </div> */}
                </div>
              </div>
            );
          })}

          {props?.link?.length > 0 && (
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <DataWalletPagination
                totalPages={props.totalPages}
                changePagePagination={props.changePagePagination}
                page={props.page}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

DataWalletEnterpriseResults.defaultProps = {
  rootClassName: "",
};

DataWalletEnterpriseResults.propTypes = {
  rootClassName: PropTypes.string,
};

export default DataWalletEnterpriseResults;
