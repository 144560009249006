import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./dashboard.css";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SideBarDashboard from "../components/sideBarDashboard";
import OverviewWidgets from "./overviewWidgets";
import AccountListWidgets from "../components/accountListWidgets";
import PrivacyOptionsWidgets from "../components/privacyOptionsWidgets";
import ReportsStatisticsWidgets from "../components/reportsStatisticsWidgets";
import DashboardSettingsWidgets from "../components/dashboardSettingsWidgets";
import { AuthContext } from "../context/authContext";
import Integrations from "./integrations";

const Dashboard = (props) => {
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const [currentView, setCurrentView] = useState("overview");
  const location = useLocation();
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const initialView = location.pathname.split("/").pop();
    setCurrentView(initialView || "overview");
  }, [location.pathname]);

  const handleNavigation = (path) => {
    const view = path.split("/").pop();
    setCurrentView(view);
  };

  useEffect(() => {
    if (!authContext.authState.isAuthenticated) {
      history.push("/login");
    }
  }, [authContext.authState.isAuthenticated, history]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1315);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderView = () => {
    switch (currentView) {
      case "overview":
        return (
          <div className="overviewWidgetsView">
            <OverviewWidgets />
          </div>
        );
      case "accounts":
        return (
          <div className="accountListwWidgetsView">
            <AccountListWidgets />
          </div>
        );
      case "privacy":
        return (
          <div className="accountListwWidgetsView">
            <PrivacyOptionsWidgets />
          </div>
        );
      case "reports":
        return (
          <div className="accountListwWidgetsView">
            <ReportsStatisticsWidgets />
          </div>
        );
        case "integrations":
          return (
            <div className="integrationsView">
              <Integrations />
            </div>
          );
      case "settings":
        return (
          <div className="accountListwWidgetsView">
            <DashboardSettingsWidgets />
          </div>
        );
      default:
        return (
          <div className="overviewWidgetsView">
            <OverviewWidgets />
          </div>
        );
    }
  };

  return (
    <div className="dashboardOverview">
      <Helmet>
        <title>Dashboard - Weagle</title>
        <meta property="og:title" content="Dashboard - Weagle" />
      </Helmet>
      <div className={`sidebar ${isMobileView ? "hidden" : ""}`}>
        <SideBarDashboard
          onNavigate={handleNavigation}
          currentView={currentView}
        />
      </div>
      {isMobileView && (
        <div className="mobileIcon">
          <img src="/playground_assets/desktopOutline.svg" alt="Mobile Icon" />
        </div>
      )}
      {isMobileView && <div className="overlay1"></div>}
      {renderView()}
    </div>
  );
};

export default Dashboard;
