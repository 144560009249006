import React, { useState } from 'react';
import '../components/SharePopup.css'
import SignUpField from './signUpField'
import {validateEmail} from "../services";
import axios from "axios";
import {endPoints} from "../utils";
import {useSelector} from "react-redux";
import Loader from "./loader";
import './ChangePasswordForm.css';

import './ChangePasswordForm.css'; // Import the CSS file

const ChangePasswordForm = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [code, setCode] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(endPoints.sendVerificationCode, { email });
      console.log(response.data);
      if (response.data.status) {
        setStep(2);
        setErrorMessage('');
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(endPoints.verifyCode, { code, email });
      console.log(response.data);
      if (response.data.status) {
        setStep(3);
        setErrorMessage('');
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(endPoints.changePassword, { password, email });
      console.log(response.data);
      if (response.data.status) {
        setStep(4);
        setErrorMessage('');
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  const translations = {
    en: {
     
      'ppps1segnala': "Send Code",
      
      'ppps1segnala2': 'Code',
      'ppps1segnala3': 'Validate code',
      'ppps1segnala4': 'Change password',
    },
    it: {

  
      'ppps1segnala': 'Invia Codice',
      'ppps1segnala2': 'Codice',
      'ppps1segnala3': 'Valida codice',
      'ppps1segnala4': 'Cambia password',

    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser

  const ppps1segnal = translate('ppps1segnala', lang);
  const ppps1segnala2 = translate('ppps1segnala2', lang);
  const ppps1segnal3 = translate('ppps1segnala3', lang);
  const ppps1segnal4 = translate('ppps1segnala4', lang);

  return (
    <div className="form-container"> {/* Add a class to the container */}
     <span style={{ cursor:'pointer', fontSize:'38px', marginTop:'-50px',marginBottom:'50px'}} onClick={() => {
          history.push("/");
        }} className="menumobileLogo">weagle</span>
      {step === 1 && (
        <form className='formChange' onSubmit={handleSubmit}>
         
          <SignUpField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            validate={validateEmail}
            type="email"
          />
           {errorMessage && <div>{errorMessage}</div>}
        <button type="submit" disabled={loading} className={loading ? "loading-button" : ""}>
        {loading ? <Loader style={{ backgroundColor: 'white' }} /> : ppps1segnal}

</button>

        </form>
      )}

      {step === 2 && (
        <form  className='formChange' onSubmit={handleCodeSubmit}>
        
          <SignUpField
            label={ppps1segnala2}
            value={code}
            onChange={(e) => setCode(e.target.value)}
            type="number"
          />
            {errorMessage && <div>{errorMessage}</div>}
            <button type="submit" disabled={loading} className={loading ? "loading-button" : ""}>
            {loading ? <Loader /> : ppps1segnal3}
          </button>
        </form>
      )}

      {step === 3 && (
        <form className='formChange'  onSubmit={handleChangePassword}>
       
          <SignUpField
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
             {errorMessage && <div>{errorMessage}</div>}
             <button type="submit" disabled={loading} className={loading ? "loading-button" : ""}>
            {loading ? <Loader /> : ppps1segnal4}
          </button>
        </form>
      )}
    </div>
  );
};


export default ChangePasswordForm
