import React, { useContext, useEffect, useState } from "react";
import "./notificationOverviewComponent.css";
import { useHistory } from "react-router-dom";
import { FaTrashAlt } from 'react-icons/fa';
import { AuthContext } from "../context/authContext";

const NotificationsOverviewComponent = (props) => {
  const { cisoInfo } = useContext(AuthContext);
  let history = useHistory();

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (cisoInfo && cisoInfo.notifications) {
      const formattedNotifications = cisoInfo.notifications.map(notification => ({
        date: new Date(notification.createdAt).toLocaleDateString(),
        user: "Weagle AI",
        content: notification.message,
        type: notification.type
      })).reverse(); // Reverse the order of notifications
      setNotifications(formattedNotifications);
    }
  }, [cisoInfo]);

  const deleteNotification = (index) => {
    const updatedNotifications = notifications.filter((_, i) => i !== index);
    setNotifications(updatedNotifications);
  };

  const navigateToDetail = () => {
    history.push("/details");
  };

  const getNotificationBorderColor = (type) => {
    switch (type) {
      case "edit":
        return "#FF9900";
      case "delete":
        return "#E64436";
      case "new":
        return "#6BB480";
      default:
        return "gray";
    }
  };

  return (
    <div className="notification-overview">
      <h2 className="notification-title">What's new from the dashboard</h2>
      <div className="notifications-container">
        {notifications.map((notification, index) => (
          <div
            key={index}
            className={`notification-item`}
            style={{ borderColor: getNotificationBorderColor(notification.type) }}
          >
            <div className="notification-date">{notification.date}</div>
            <div className="notification-content">
              {notification.content.length > 100
                ? `${notification.content.slice(0, 100)}...`
                : notification.content}
            </div>

            <div className="notification-icon" onClick={navigateToDetail}>
              {/* Icon placeholder */}
            </div>
            {/* <button className="delete-button" onClick={() => deleteNotification(index)}>
              <FaTrashAlt />
            </button> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotificationsOverviewComponent;
