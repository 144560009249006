import {takeLatest, put} from 'redux-saga/effects';
import * as types from '../../actions/types';
import {Api} from '../../../utils';
import {endPoints} from '../../../utils';

export function* profileUpdateRequest() {
  yield takeLatest(types.UPDATE_PROFILE, profileUpdate);
}

function* profileUpdate(params) {
  // console.log('[update Profile saga]', params);
  try {
    let options = {
      headers: {
        Authorization: 'Bearer ' + params.token,
      },
    };
    let configuration = Object.assign(options);
    let response = yield Api.postAxios(
      endPoints.updateProfile,
      params.params,
      configuration,
    );
    if (response?.status) {
      yield put({type: types.SET_PROFILE_DATA, data: response.data});
      params.cbSuccess(response);
    } else {
      params.cbFailure(response);
    }
    // yield put({type: types.SET_PROFILE_DATA, data: response.data});
  } catch (err) {
    console.log('error from profile update request saga -- > > >  > ', err);
    params.cbFailure(err.message);
  }
}
