import React from "react";
import "./dropdown.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { DateRange } from "react-date-range";

const AdvanceSearchDateFilter = ({
  img,
  newsDateSelection,
  setNewsDateSelection,
}) => {
  // const img=()=>{
  // }
  return (
    <div className={"dropdown-content"}>
      <span>
        <img
          onClick={img}
          className="closeButton"
          src="/playground_assets/x-mark.png"
          width="15"
        />
      </span>

      <DateRange
        rangeColors={["orange"]}
        editableDateInputs={true}
        onChange={(item) =>
          setNewsDateSelection([
            {
              ...item.selection,
              filterApplied: true,
            },
          ])
        }
        moveRangeOnFirstSelection={false}
        ranges={newsDateSelection}
      />
    </div>
  );
};

export default AdvanceSearchDateFilter;
