import React, { useContext, useEffect, useState } from "react";
import { SearchContext } from "../context/search_context";
import { Helmet } from 'react-helmet'

import SideBar from '../components/side-bar'
import ProfileSide from '../components/profile-side'
import ComponentAdvancedSearch from '../components/component-advanced-search'
import './advanced-filters.css'
import Hamburgher from "../components/hamburgher-menu";
import NewmenuDesktop from "../components/newmenudesktop";
const AdvancedFilters = (props) => {
  const {
    loader,
    searchValue,
    setTab,
    setRecord,
    record,
    paginatedNewsResults,
    accountType,
    auth
  } = useContext(SearchContext);
  return (
    <div className="advanced-filters-container">
      <Helmet>
        <title>AdvancedFilters - Weagle</title>
        <meta property="og:title" content="AdvancedFilters - Weagle" />
      </Helmet>
      <div className="menumobile">
    <ProfileSide  />
  <img style={{ cursor:'pointer', marginBottom:'20px'}} onClick={() => {
          history.push("/");
        }} src="/playground_assets/logoweaglenew.svg" alt="weagleLogo" className="menumobileLogo" />

    <Hamburgher /> 
    
    </div>
      <div className="advanced-filters-container-principale">
        <div className="power-search-container1">
          <SideBar rootClassName="side-bar-root-class-name3"></SideBar>
        </div>
            <div className="menudesktop">
        <div className="newmenucompleto">
         <NewmenuDesktop accountType={accountType} auth={auth}  />
    <ProfileSide  />
 
    </div> 
    </div>
      </div>
      <div className="advanced-filters-results-div">
        <ComponentAdvancedSearch rootClassName="component-advanced-search-root-class-name"></ComponentAdvancedSearch>
      </div>
    </div>
  )
}

export default AdvancedFilters
