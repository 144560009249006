import {takeLatest, put} from 'redux-saga/effects';
import * as types from '../../actions/types';
import {Api} from '../../../utils';
import {endPoints} from '../../../utils';

export function* loginRequest() {
  yield takeLatest(types.LOGIN_REQUEST_REQUEST, login);
}

function* login(params) {
  console.log('[login saga]', params);
  try {
    let response = yield Api.postAxios(endPoints.authSignIn, params.params);
    if (response?.status) {
      yield put({type: types.SET_PROFILE_DATA, data: response.data});
      params.cbSuccess(response);

    } else {
      params.cbFailure(response);
    }
  } catch (err) {
    console.log('error from login request saga -- > > >  > ', err);
    params.cbFailure(err.message);
  }
}
