import React from "react";

import PropTypes from "prop-types";

import "./product-search-result.css";
// const openInNewTab = (url) => {
//   const newWindow = window.open(url, "_blank", "noopener,noreferrer");
//   if (newWindow) newWindow.opener = null;
// };
const ProductSearchResult = (props) => {
  return (
    <div className="product-search-result-container">
      <div
        onClick={
          () => {
            window.open(
              "https://www.amazon.it/Champions-League-Pallone-articolo-compleanno/dp/B09RWM55YL/ref=sr_1_7?__mk_it_IT=%C3%85M%C3%85%C5%BD%C3%95%C3%91&keywords=pallone+da+calcio+amazon&qid=1667774440&qu=eyJxc2MiOiIwLjk5IiwicXNhIjoiMS4wMCIsInFzcCI6IjAuMDAifQ%3D%3D&sr=8-7"
            );
          }
          // openInNewTab(
          //   "https://www.amazon.it/Champions-League-Pallone-articolo-compleanno/dp/B09RWM55YL/ref=sr_1_7?__mk_it_IT=%C3%85M%C3%85%C5%BD%C3%95%C3%91&keywords=pallone+da+calcio+amazon&qid=1667774440&qu=eyJxc2MiOiIwLjk5IiwicXNhIjoiMS4wMCIsInFzcCI6IjAuMDAifQ%3D%3D&sr=8-7"
          // )
        }
        // style={{ cursor: "pointer", marginTop: "490px" }}
        className="product-search-result-product-main-div"
      >
        <div className="product-search-result-product-result-image"></div>
        <div className="product-search-result-product-description-link-stars">
          <span className="product-search-result-product-link">
            {props.LinkWebSearch1}
          </span>
          <span className="product-search-result-product-title">
            {props.DescriptionWebSearch}
          </span>
          <div className="product-search-result-container1">
            <img
              alt={props.image_alt1}
              src={props.image_src1}
              className="product-search-result-reviews-stars"
            />
            <span className="product-search-result-number-of-reviews">
              {props.numberofreviewsproducts}
            </span>
          </div>
        </div>
        <div className="product-search-result-share-div">
          <img
            alt={props.image_alt}
            src={props.image_src}
            className="product-search-result-share-icon"
          />
          <span className="product-search-result-product-price">
            {props.PriceProductResult}
          </span>
        </div>
      </div>
    </div>
  );
};

ProductSearchResult.defaultProps = {
  numberofreviewsproducts: "71",
  image_src1: "/playground_assets/frame%205040.svg",
  image_src: "/playground_assets/share.1%203.svg",
  PriceProductResult: "€34,99",
  DescriptionWebSearch:
    "2020 Champions League Ball Pallone da calcio articolo per amanti del calcio regalo di compleanno misura 5",
  image_alt: "image",
  image_alt1: "image",
  rootClassName: "",
  LinkWebSearch1: "https://amazon.it/",
};

ProductSearchResult.propTypes = {
  numberofreviewsproducts: PropTypes.string,
  image_src1: PropTypes.string,
  image_src: PropTypes.string,
  PriceProductResult: PropTypes.string,
  DescriptionWebSearch: PropTypes.string,
  image_alt: PropTypes.string,
  image_alt1: PropTypes.string,
  rootClassName: PropTypes.string,
  LinkWebSearch1: PropTypes.string,
};

export default ProductSearchResult;
