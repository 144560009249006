import React, { createContext, useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { endPoints } from "../utils";
import { validateEmail } from "../services";
import { useDispatch, useSelector } from "react-redux";
import { signUpRequest, loginRequest, azureLoginRequest } from "../redux/actions";
import {  signout } from "../redux/actions/auth-actions";
import SignUpPopUp from "../components/sign-up-pop-up";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as types from '../redux/actions/types';
import Api from '../utils/api'; 
import { put } from '../redux/reducers/auth-reducer/auth-reducer';
const AuthContext = createContext({});
const enterprice = [
  { label: "1", inviteUser: 1, amount: 10 },
  {
    label: "10",
    inviteUser: 10,
    amount: 90,
  },
  {
    label: "50",
    inviteUser: 50,
    amount: 400,
  },
  {
    label: "200",
    inviteUser: 200,
    amount: 1500,
  },
  {
    label: "500",
    inviteUser: 500,
    amount: 3500,
  },
  {
    label: "1000",
    inviteUser: 1000,
    amount: 6000,
  },
];
import { getUserData } from "../redux/actions";

const planInvite = (invites) => {
  invites = parseInt(invites);
  if (invites === 1) {
    return 7;
  }
  if (invites <= 10) {
    return 60;
  }
  if (invites <= 50) {
    return 250;
  }
  if (invites <= 200) {
    return 800;
  }
  if (invites <= 1500) {
    return 500;
  }
  if (invites <= 1000) {
    return 2000;
  }
};

const AuthContextProvider = ({ children }) => {
  const [selectedSignUpType, setSelectedSignUpType] = useState("InvitePopup");
  // const [selectedSignUpType, setSelectedSignUpType] = useState("Persona");
  const [showSingUp, setShowSignUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(enterprice[0].amount);
  const [currency, setCurrency] = useState("EUR");
  const [accountType, setAccountType] = useState("free"); // free || pro
  const [agency, setAgency] = useState("");
  const [nickName, setNickName] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [common, setCommon] = useState("");
  const [userData, setUserData] = useState("");
  const [cisoUserData, setCisoUserData] = useState("");
  const [heading, setHeading] = useState(true);
  const [priceEnterprice, setPrice] = useState(enterprice[0]);
  const stripe = useStripe();
  const [paymentDetails, setPaymentDetails] = useState({
    name: "",
    address_line1: "",
    address_state: "",
    address_country: "",
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [password, setPassword] = useState("");
  const [iva, setIva] = useState("");
  const [inviteEmail, setInviteLink] = useState([]);
  const { auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [isValidInvite, setIsValidInvite] = useState(false);
  const [planInvitesInput, setPlanInvitesInput] = useState("");
  const [planCustom, setPlanCustom] = useState("");
  const [authState, setAuthState] = useState({
    token: null,
    isAuthenticated: false,
    loading: false,
    user: null,
    error: null, // Added to track any error states
  });

  const [cisoInfo, setCisoInfo] = useState({
    cisoName: '',
    companyName: '',
    seatsBase: 0,
    availableSeatsBase: 0,
    cisoId: '',
    groups: [],
    gptCredits: 0,
    planGptCredits: 0,
    statistics: [],
    notifications: [],
    });
  const [userInfo, setUserInfo] = useState([]);

  const [socket, setSocket] = useState(null);

  const { Provider } = AuthContext;
  const elements = useElements();

  const resetModal = () => {
    setFirstName("");
    setLastName("");
    setPassword("");
    setEmail("");
    setIva("");
    setNickName("");
    setAddress("");
    setCountry("");
    setCommon("");
    setNickName("");
  };

  const closeSignModal = () => {
    setSelectedSignUpType("Persona");
    setShowSignUp(false);
    setLoading(false);
    setAccountType("free");
  };

  const payMoney = async (e) => {
    if (!paymentDetails?.name) {
      alert("Name is required");
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      alert(error.message);
    }

    const { id } = paymentMethod;
    setLoading(true);
    const response = await fetch(endPoints.pay, {
      method: "POST",
      body: JSON.stringify({
        user_name: paymentDetails?.name,
        amount,
        currency,
        payment_method_id: id,
        email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();

    if (data?.clientSecret) {
      const res = await stripe.confirmCardPayment(data?.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: paymentMethod?.name,
          },
        },
      });

      if (res?.paymentIntent?.status === "succeeded") {
        signupEnterprise();
      }
    } else {
      if (!data?.message) {
        return Alert.alert(data.message);
      }
    }

    setLoading(false);
  };

  // const cisoFetchWebSocket = () => {
  //   const socket = new WebSocket('ws://localhost:8080');

  //   socket.onopen = () => {
  //     console.log('WebSocket connection established');
  //     socket.send(JSON.stringify({ action: 'fetchCisoUserDetails' }));
  //   };

  //   socket.onmessage = (event) => {
  //     const data = JSON.parse(event.data);

  //     if (data.status && data.data) {
  //       setCisoInfo((prevState) => ({
  //         ...prevState,
  //         cisoName: data.data.ciso.cisoName,
  //         companyName: data.data.ciso.companyName,
  //         seatsBase: data.data.ciso.seatsBase,
  //         availableSeatsBase: data.data.ciso.availableSeatsBase,
  //         cisoId: data.data.ciso._id,
  //         groups: Array.isArray(data.data.ciso.groups) ? data.data.ciso.groups : [],
  //         gptCredits: data.data.ciso.gptCredits,
  //         statistics: data.data.statistics,
  //         notifications: data.data.ciso.notifications,
  //       }));

  //       setUserInfo(data.data.users);
  //     } else {
  //       console.error('Error fetching CISO user details:', data.message);
  //     }

  //     console.log('CISO User Details:', data);
  //   };

  //   socket.onerror = (error) => {
  //     console.error('WebSocket error:', error);
  //   };

  //   socket.onclose = () => {
  //     console.log('WebSocket connection closed');
  //   };

  //   return () => {
  //     socket.close();
  //   };
  // };
  const cisoFetchWebSocket = async () => {
    try {
      const response = await fetch(endPoints.fetchCiso, {
        method: 'POST',
        credentials: 'include',
      });
  
      const data = await response.json();
  
      if (data.status && data.data) {
        setCisoInfo((prevState) => ({
          ...prevState,
          cisoName: data.data.ciso.cisoName,
          companyName: data.data.ciso.companyName,
          seatsBase: data.data.ciso.seatsBase,
          availableSeatsBase: data.data.ciso.availableSeatsBase,
          cisoId: data.data.ciso._id,
          groups: Array.isArray(data.data.ciso.groups) ? data.data.ciso.groups : [],
          gptCredits: data.data.ciso.gptCredits,
          planGptCredits: data.data.ciso.planGptCredits,
          statistics: data.data.statistics,
          notifications: data.data.ciso.notifications,
        }));
  
        setUserInfo(data.data.users);
      } else {
        console.error('Error fetching CISO user details:', data.message);
      }
  
      console.log('CISO User Details:', data);
    } catch (error) {
      console.error('Error fetching CISO user details:', error);
    }
  };
  
  useEffect(() => {
    const fetchData = cisoFetchWebSocket();

    return () => {
      fetchData();
    };
  }, []);
  const handlePlatformSendResetCode = async (email) => {
    setAuthState(prevState => ({ ...prevState, loading: true, error: null }));
    try {
      const response = await axios.post(endPoints.platformSendResetCode, { email }, { withCredentials: true });
  
      const data = response.data;
      if (data.status) {
        toast.success('Reset code sent to your email!', { position: "bottom-right" });
      } else {
        throw new Error(data.message || 'Failed to send reset code');
      }
    } catch (error) {
      setAuthState(prevState => ({
        ...prevState,
        loading: false,
        error: error.message || 'An unknown error occurred'
      }));
      toast.error(error.message || 'An unknown error occurred', { position: "bottom-right" });
    } finally {
      setAuthState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };
  
  const handlePlatformVerifyResetCode = async (email, code) => {
    setAuthState(prevState => ({ ...prevState, loading: true, error: null }));
    try {
      const response = await axios.post(endPoints.platformVerifyResetCode, { email, code }, { withCredentials: true });
  
      const data = response.data;
      if (data.status) {
        toast.success('Code verified!', { position: "bottom-right" });
      } else {
        throw new Error(data.message || 'Failed to verify code');
      }
    } catch (error) {
      setAuthState(prevState => ({
        ...prevState,
        loading: false,
        error: error.message || 'An unknown error occurred'
      }));
      toast.error(error.message || 'An unknown error occurred', { position: "bottom-right" });
      throw error;
    } finally {
      setAuthState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };
  
  const handlePlatformSetNewPassword = async (email, code, newPassword) => {
    setAuthState(prevState => ({ ...prevState, loading: true, error: null }));
    try {
      const response = await axios.post(endPoints.platformSetNewPassword, { email, code, newPassword }, { withCredentials: true });
  
      const data = response.data;
      if (data.status) {
        toast.success('Password updated!', { position: "bottom-right" });
      } else {
        throw new Error(data.message || 'Failed to update password');
      }
    } catch (error) {
      setAuthState(prevState => ({
        ...prevState,
        loading: false,
        error: error.message || 'An unknown error occurred'
      }));
      toast.error(error.message || 'An unknown error occurred', { position: "bottom-right" });
      throw error;
    } finally {
      setAuthState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };



  const handlePlatformLogin = async (email, password) => {
    setAuthState(prevState => ({ ...prevState, loading: true, error: null }));
    try {
      const response = await axios.post(endPoints.platformLogin, { email, password }, { withCredentials: true });

      const data = response.data;
      if (data.status) {
        console.log('Received Token:', data.token); // Log the received token
        document.cookie = `token=${data.token}; path=/;`;

        setAuthState(prevState => ({
          ...prevState,
          isAuthenticated: true,
          user: data.data,
          loading: false,
        }));
        cisoFetchWebSocket();

        try {
          await validateSession();
          toast.success('Login successful!', { position: "bottom-right" });
        } catch (error) {
          console.error('Session validation error:', error);
          toast.error('Session validation failed, you cannot use Chrome incognito mode', {
            position: 'bottom-right',
          });
          setAuthState(prevState => ({
            ...prevState,
            isAuthenticated: false,
            user: null,
            loading: false,
            error: 'Session validation failed',
          }));
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } else {
        throw new Error(data.message || 'Failed to login');
      }

    } catch (error) {
      setAuthState(prevState => ({
        ...prevState,
        loading: false,
        isAuthenticated: false,
        user: null,
        error: error.message || 'An unknown error occurred'
      }));
      toast.error(error.message || 'An unknown error occurred', { position: "bottom-right" });
    }
};

  const handlePlatformLogout = async () => {
    setAuthState(prevState => ({ ...prevState, loading: true, error: null }));
    try {
      const response = await axios.post(endPoints.platformLogout, {}, { withCredentials: true });
  
      const data = response.data;
      if (data.status) {
        // Clear the token from the cookie
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  
        // Update the authentication state
        setAuthState(prevState => ({
          ...prevState,
          isAuthenticated: false,
          user: null,
          loading: false,
        }));
        toast.success('Logout successful!', { position: "bottom-right" });
      } else {
        throw new Error(data.message || 'Failed to logout');
      }
    } catch (error) {
      setAuthState(prevState => ({
        ...prevState,
        loading: false,
        error: error.message || 'An unknown error occurred'
      }));
      toast.error(error.message || 'An unknown error occurred', { position: "bottom-right" });
    }
  };
  const createGroupsAndAccounts = async (importedGroups) => {
    try {
      const cisoUserId = cisoInfo.cisoId;
  
      // Prepare the data to be sent to the API
      const requestData = importedGroups.map((group) => ({
        groupName: group.groupName,
        users: group.users.map((user) => ({
          email: user.email,
          childAccountType: user.childAccountType,
        })),
      }));
  
      const response = await fetch(endPoints.createGroupsAndAccounts, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cisoUserId, groups: requestData }),
      });
    
      // Log the entire response object
      console.log("Response:", response);
    
      if (!response.ok) {
        // Handle specific error status codes
        if (response.status === 400) {
          const errorData = await response.json();
          console.error("Bad Request:", errorData);
          toast.error(`Bad Request: ${errorData.message}`);
        } else if (response.status === 500) {
          console.error("Internal Server Error");
          toast.error("Internal Server Error");
        } else {
          throw new Error(`Error creating groups and accounts: ${response.statusText}`);
        }
      } else {
        // Parse the response JSON
        const responseData = await response.json();
    
        // Log the response from the server
        console.log("Server response:", responseData);
        cisoFetchWebSocket();
    
        // Check if the response indicates success
        if (responseData.success) {
          toast.success('Synced successfully');
        } else {
          
          toast.success('Synced successfully');
        }
      }
    } catch (error) {
      console.error("Error creating groups and accounts:", error);
      toast.error(`Error creating groups and accounts: ${error.message}`);
    }
  };
  const createAccounts = async (accountData) => {
    try {
      const cisoUserId = cisoInfo.cisoId;
  
      // Prepare the data to be sent to the API
      const requestData = {
        groupName: "Basic",
        users: [
          {
            email: accountData.email,
            password: accountData.password,
            childAccountType: "Basic",
          },
        ],
      };
  
      const response = await fetch(endPoints.createAccounts, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cisoUserId, groups: [requestData] }),
      });
  
      // Log the entire response object
      console.log("Response:", response);
  
      if (!response.ok) {
        // Handle specific error status codes
        const errorData = await response.json();
  
        if (response.status === 400) {
          console.error("Bad Request:", errorData);
          toast.error(`Available seats are not enough or email already exist`);
        } else if (response.status === 500) {
          console.error("Internal Server Error:", errorData);
          toast.error(`Internal Server Error: ${errorData.error}`);
        } else {
          console.error("Error creating account:", errorData);
          toast.error(`Error creating account: ${errorData.error}`);
        }
      } else {
        // Parse the response JSON
        const responseData = await response.json();
  
        // Log the response from the server
        console.log("Server response:", responseData);
        cisoFetchWebSocket();
  
        // Check if the response contains an error property
        if (responseData.error) {
          toast.error(responseData.error);
        } else {
          toast.success(responseData.message);
        }
      }
    } catch (error) {
      console.error("Error creating account:", error);
      toast.error(`Error creating account: ${error.message}`);
    }
  };
  const changeChildMailPassword = async (selectedUserId, newEmail, newPassword) => {
    try {
      const cisoUserId = cisoInfo.cisoId;
  
      // Prepare the data to be sent to the API
      const requestData = {
        cisoUserId: cisoUserId,
        childUserId: selectedUserId,
        newEmail: newEmail,
        newPassword: newPassword,
      };
  
      const response = await fetch(endPoints.changeChildMailPassword, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      // Log the entire response object
      console.log("Response:", response);
  
      if (!response.ok) {
        // Handle specific error status codes
        const errorData = await response.json();
  
        if (response.status === 400) {
          console.error("Bad Request:", errorData);
          toast.error(`Bad Request: ${errorData.error}`);
        } else if (response.status === 404) {
          console.error("Child User Not Found:", errorData);
          toast.error(`Child User Not Found: ${errorData.error}`);
        } else if (response.status === 500) {
          console.error("Internal Server Error:", errorData);
          toast.error(`Internal Server Error: ${errorData.error}`);
        } else {
          console.error("Error updating child account:", errorData);
          toast.error(`Error updating child account: ${errorData.error}`);
        }
      } else {
        // Parse the response JSON
        const responseData = await response.json();
  
        // Log the response from the server
        console.log("Server response:", responseData);
        cisoFetchWebSocket();
  
        // Check if the response contains an error property
        if (responseData.error) {
          toast.error(responseData.error);
        } else {
          toast.success(responseData.message);
        }
      }
    } catch (error) {
      console.error("Error updating child account:", error);
      toast.error(`Error updating child account: ${error.message}`);
    }
  };
  const changeGroup = async (selectedItems, selectedGroup) => {
    try {
      const cisoUserId = cisoInfo.cisoId;
  
      // Prepare the data to be sent to the API
      const requestData = {
        cisoUserId,
        selectedItems, // The selected accounts to change the group for
        selectedGroup, // The new group to assign to the selected accounts
      };
  
      const response = await fetch(endPoints.changeGroup, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      console.log("Response:", response);
  
      if (!response.ok) {
        // Handle specific error status codes
        if (response.status === 400) {
          const errorData = await response.json();
          console.error("Bad Request:", errorData);
          toast.error(` ${errorData.message}`);
        } else if (response.status === 404) {
          console.error("CISO user or group not found");
          toast.error("CISO user or group not found");
        } else if (response.status === 500) {
          console.error("Internal Server Error");
          toast.error("An error occurred while changing the group. Please try again.");
        } else {
          throw new Error(`Error changing group: ${response.statusText}`);
        }
      } else {
        // Parse the response JSON
        const responseData = await response.json();
  
        console.log("Server response:", responseData);
        cisoFetchWebSocket(); // Optionally refresh data if needed
  
        // Check if the response indicates success
        if (responseData.success) {
          toast.success('Group changed successfully');
        } else {
          toast.success('Group changed successfully');
        }
      }
    } catch (error) {
      console.error("Error changing group:", error);
      toast.error(`Error changing group: ${error.message}`);
    }
  };



  const handlePrivacyOptions = async (groupName, feature, value) => {
    try {
      const cisoUserId = cisoInfo.cisoId;
  
      // Find the selected group
      const selectedGroup = cisoInfo.groups.find((group) => group.groupName === groupName);
  
      if (!selectedGroup) {
        console.error("Group not found with name:", groupName);
        toast.error("Group not found");
        return;
      }
  
      // Create a copy of the selected group's features
      const updatedFeatures = { ...selectedGroup.features };
  
      // Update the specific feature
      if (feature === 'blacklist' && Array.isArray(value)) {
        // If feature is 'blacklist' and value is an array, replace the entire array
        updatedFeatures[feature] = [...value];
      } else if (feature === 'accessTime' && typeof value === 'object') {
        // Handle the case when accessTime is an object containing accessTime and accessTimeHour
        updatedFeatures.accessTime = value.accessTime;
        updatedFeatures.accessTimeHour = value.accessTimeHour;
      } else if (typeof value === 'object' && !Array.isArray(value)) {
        // Merge object for other object-type features
        updatedFeatures[feature] = { ...updatedFeatures[feature], ...value };
      } else {
        // Directly set the value for scalar values
        updatedFeatures[feature] = value;
      }
  
      // Prepare the data to be sent to the API
      const requestData = {
        cisoUserId,
        groupName,
        features: updatedFeatures,
      };
  
      const response = await fetch(endPoints.privacyManagement, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      console.log("Response:", response);
  
      if (!response.ok) {
        // Handle specific error status codes
        if (response.status === 400) {
          const errorData = await response.json();
          console.error("Bad Request:", errorData);
          toast.error(` ${errorData.message}`);
        } else if (response.status === 404) {
          console.error("CISO user or group not found");
          toast.error("CISO user or group not found");
        } else if (response.status === 500) {
          console.error("Internal Server Error");
          toast.error("An error occurred while updating privacy settings. Please try again.");
        } else {
          throw new Error(`Error updating privacy settings: ${response.statusText}`);
        }
      } else {
        // Parse the response JSON
        const responseData = await response.json();
  
        console.log("Server response:", responseData);
  
        // Check if the response indicates success
        if (responseData.success) {
          // Update the cisoInfo state with the updated privacy settings
          const updatedGroups = cisoInfo.groups.map((group) => {
            if (group.groupName === groupName) {
              return {
                ...group,
                features: updatedFeatures,
              };
            }
            return group;
          });
  
          setCisoInfo({
            ...cisoInfo,
            groups: updatedGroups,
          });
  
          toast.success("Privacy settings updated successfully");
          cisoFetchWebSocket();
        } else {
          toast.success("Privacy settings updated successfully");
          cisoFetchWebSocket();
        }
      }
    } catch (error) {
      console.error("Error updating privacy settings:", error);
      toast.error(`Error updating privacy settings: ${error.message}`);
    }
  };
  
  const deleteGroups = async (groupNames) => {
    try {
        const cisoUserId = cisoInfo.cisoId;

        // Prepare the data to be sent to the API
        const requestData = {
            cisoUserId,
            groupNames, // The API expects an array of group names to delete
        };

        const response = await fetch(endPoints.deleteGroups, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
        });

        console.log("Response:", response);

        if (!response.ok) {
            // Handle specific error status codes
            if (response.status === 400) {
                const errorData = await response.json();
                console.error("Bad Request:", errorData);
                toast.error(` ${errorData.message}`);
            } else if (response.status === 404) {
                console.error("CISO user not found");
                toast.error("CISO user not found");
            } else if (response.status === 500) {
                console.error("Internal Server Error");
                toast.error("An error occurred while deleting groups. Please try again.");
            } else {
                throw new Error(`Error deleting groups: ${response.statusText}`);
            }
        } else {
            // Parse the response JSON
            const responseData = await response.json();

            console.log("Server response:", responseData);
            cisoFetchWebSocket(); // Optionally refresh data if needed

            // Check if the response indicates success
            if (responseData.success) {
                toast.success('Groups deleted successfully');
            } else {
              toast.success('Groups deleted successfully');
            }
        }
    } catch (error) {
        console.error("Error deleting groups:", error);
        toast.error(`Error deleting groups: ${error.message}`);
    }
};
const deleteAccounts = async (selectedAccounts) => {
  try {
      const cisoUserId = cisoInfo.cisoId;

      // Prepare the data to be sent to the API
      const requestData = {
          cisoUserId,
          accountIds: selectedAccounts.map((account) => account._id), // Extract the _id of each account
      };

      const response = await fetch(endPoints.deleteAccounts, {
          method: "POST", // Change this to use the POST method
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify({
              operation: "delete", // Optionally, specify the operation if your API needs it
              ...requestData,
          }),
      });

      console.log("Response:", response);

      if (!response.ok) {
          // Handle specific error status codes
          if (response.status === 400) {
              const errorData = await response.json();
              console.error("Bad Request:", errorData);
              toast.error(`Bad Request: ${errorData.message}`);
          } else if (response.status === 500) {
              console.error("Internal Server Error");
              toast.error("Internal Server Error");
          } else {
              throw new Error(`Error deleting accounts: ${response.statusText}`);
          }
      } else {
          // Parse the response JSON
          const responseData = await response.json();

          console.log("Server response:", responseData);
          cisoFetchWebSocket(); // Optionally refresh data if needed

          // Check if the response indicates success
          if (responseData.success) {
              toast.success('Accounts deleted successfully');
          } else {
            toast.success('Accounts deleted successfully');
          }
      }
  } catch (error) {
      console.error("Error deleting accounts:", error);
      toast.error(`Error deleting accounts: ${error.message}`);
  }
};
const createGroup = async (groupName) => {
  try {
    const cisoUserId = cisoInfo.cisoId;

    // Prepare the data to be sent to the API
    const requestData = {
      cisoUserId,
      groupName,
    };

    const response = await fetch(endPoints.createGroup, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    console.log("Response:", response);

    if (!response.ok) {
      // Handle specific error status codes
      if (response.status === 400) {
        const errorData = await response.json();
        console.error("Bad Request:", errorData);
        toast.error(`Bad Request: ${errorData.message}`);
      } else if (response.status === 404) {
        console.error("CISO user not found");
        toast.error("CISO user not found");
      } else if (response.status === 409) {
        console.error("Group already exists");
        toast.error("Group already exists");
      } else if (response.status === 500) {
        console.error("Internal Server Error");
        toast.error("An error occurred while creating the group. Please try again.");
      } else {
        throw new Error(`Error creating group: ${response.statusText}`);
      }
    } else {
      // Parse the response JSON
      const responseData = await response.json();

      console.log("Server response:", responseData);
      cisoFetchWebSocket(); // Optionally refresh data if needed

      if (responseData.success) {
        toast.success(responseData.message);
      } else {
        toast.success(responseData.message);
      }
    }
  } catch (error) {
    console.error("Error creating group:", error);
    toast.error(`Error creating group: ${error.message}`);
  }
};

const validateSession = async () => {
  try {
    // Make the fetch request, allowing credentials to be included automatically by the browser
    const response = await fetch(endPoints.sessionValidation, {
      method: 'GET',
      credentials: 'include', // This ensures cookies, including httpOnly ones, are automatically sent
    });

    const data = await response.json();
    if (response.ok) {
      setAuthState(prevState => ({
        ...prevState,
        isAuthenticated: true,
        user: data.user,
        loading: false,
      }));
    } else {
      throw new Error('Session validation failed');
    }
  } catch (error) {
    console.error('Session validation error:', error);
    throw error; // Rethrow the error to be caught in the handlePlatformLogin function
  }
};
  
  useEffect(() => {
    validateSession();
  }, []);
  



  const handleLoginAzure = (token, email, cbSuccess, cbFailure) => {
    const data = {
      token,
      email,
    };
  
    const success = (response) => {
      // Login successful
      console.log(response.message);
      cbSuccess(response);
      closeSignModal();
      toast.success('Login successful!', { position: "bottom-right" });
    };
  
    const failure = (error) => {
      // Login failed
      console.error(error.message);
      toast.error(error.message || 'An unknown error occurred', { position: "bottom-right" });
    };
  
    dispatch(azureLoginRequest(data, success, failure));
  };


  const handleLoginRequest = () => { 
    if (!validateEmail(email)) {
      alert("Email non valida");
      return false;
    } else if (password.length < 6) {
      alert("La password deve contenere almeno 6 caratteri");
      return false;
    }

    setLoading(true);
    const cbSuccess = (response) => {
      // alert('login successfull')
      resetModal();
      closeSignModal();
      setLoading(false);
    };
    const cbFailure = (error) => {
      setLoading(false);
      toast.error(error.message || 'An unknown error occurred', { position: "bottom-right" });
    };

    const data = new FormData();
    data.append("email", email);
    data.append("password", password);
    dispatch(loginRequest(data, cbSuccess, cbFailure));
  };

  const validateSignupInputs = () => {
    if (firstName.length < 3) {
      alert("il nome non può essere vuoto!");
      return false;
    } else if (lastName.length < 3) {
      alert("il cognome non può essere vuoto");
      return false;
    } else if (!validateEmail(email)) {
      alert("Email non valida");
      return false;
    } else if (password.length < 6) {
      alert("La password deve contenere almeno 6 caratteri");
      return false;
    }
    return true;
  };

  const validateEnterPriceInputs = () => {
    if (firstName.length < 3) {
      alert("il Azienda non può essere vuoto!");
      return false;
    } else if (iva.length < 3) {
      alert("il P.Iva non può essere vuoto!");
      return false;
    } else if (!validateEmail(email)) {
      alert("Email non valida");
      return false;
    } else if (password.length < 6) {
      alert("La password deve contenere almeno 6 caratteri");
      return false;
    }
    return true;
  };

  const handelEnterpriceSignUp = () => {
    if (validateEnterPriceInputs()) {
      setAccountType("enterprise");
      setSelectedSignUpType("plans");
    }
  };

  const signupInvitedEnterprise = () => {
    if (password?.length > 6) {
      setLoading(true);
      const cbSuccess = (response) => {
        alert("Registrazione avvenuta con successo, adesso potrai sfruttare i vantaggi di Weagle enterprise");
        window.location.assign("https://weagle.enterprises/");
      };
      const cbFailure = (error) => {
        setLoading(false);
        // Check if error.message exists, else provide a default message
        const errorMessage = error.message || 'Si è verificato un errore durante la registrazione. Riprova.';
        alert(errorMessage);
      };

      const data = new FormData();
      data.append("email", email);
      data.append("password", password);
      data.append("type", "invitedEnterprise");
      dispatch(signUpRequest(data, cbSuccess, cbFailure));
    } else {
      alert("Please enter password greater then 6 chars");
    }
  };

  const signupEnterprise = () => {
    setLoading(true);
    const cbSuccess = (response) => {
      //resetModal();
      setSelectedSignUpType("InvitePopup"); 

      // closeSignModal();
      alert("Pagamento avvenuto con successo, invita i tuoi dipendenti!");
    };
    const cbFailure = (error) => {
      setLoading(false);
      alert(error.message);
    };

    const data = new FormData();
    data.append("first_name", firstName);
    data.append("email", email);
    data.append("password", password);
    data.append("type", accountType);
    data.append("allowedInvites", parseInt(planInvitesInput));
    if (iva?.length > 0) {
      data.append("iva", iva);
    }
    dispatch(signUpRequest(data, cbSuccess, cbFailure));
  };

  const signupUser = () => {
    if (validateSignupInputs()) {
      setLoading(true);
      const cbSuccess = (response) => {
        //resetModal();
        setAccountType(data?.userType || []);
        closeSignModal();
      };
      const cbFailure = (error) => {
        setLoading(false);
        alert(error.message);
      };

      const data = new FormData();
      data.append("first_name", firstName.trim());
      data.append("last_name", lastName.trim());
      data.append("email", email);
      data.append("password", password);
      data.append("type", accountType);
      if (priceEnterprice?.inviteUser) {
        data.append("allowedInvites", parseInt(priceEnterprice.inviteUser));
      } else if (planInvitesInput) {
        data.append("allowedInvites", parseInt(planInvitesInput));
      }
      dispatch(signUpRequest(data, cbSuccess, cbFailure));
    }
  };

  const handleInviteLinkRequest = async (setLoader) => {
    if (auth?.data?.token) {
      setLoader(true);
      const data = new FormData();
      // data.append("first_name", inviteEmail);
      const res = await axios.post(
        endPoints.inviteUsers,
        { invitedUsers: inviteEmail },
        {
          headers: {
            Authorization: `Bearer ${auth?.data?.token}`,
          },
        }
      );

      console.log("--------->>>", res?.data?.data?.invitedUsers);

      if (res?.data?.data?.invitedUsers) {
        //alert('herdde')
        setInviteLink([...res?.data?.data?.invitedUsers]);
      }

      //resetModal();
      closeSignModal();
      alert(res?.data?.message);
      window.location.reload();
      setLoader(false);
    }
  };

  const handleInvitedUserRequest = async (email, id, setLoader) => {
    if (!validateEmail(email) || !id) {
      alert("Invite link is not valid");
      return;
    }
    setLoader(true);
    const data = new FormData();
    data.append("first_name", "what is this behaviour");
    const res = await axios.post(endPoints.verifyInvite, { email, id });
    if (!res?.data?.status) {
      alert("Invite link is not valid");
      setPassword("");
      setIsValidInvite(false);
      window.location.assign("https://weagle.enterprises/");
    } else {
      setIsValidInvite(true);
    }
    setLoader(false);
  };

  const profileEnterPriceInputs = () => {
    if (!validateEmail(email)) {
      alert("Email non valida");
      return false;
    }
    return true;
  };

  const handelProfileEnterprice = async () => {
    console.log("------------->", auth?.data?.token);
    if (profileEnterPriceInputs() && auth?.data?.token) {
      setLoading(true);
      const res = await axios.post(
        endPoints.updateUser,
        {
          first_name: firstName,
          iva: iva,
          email: email,
          nickname: nickName,
          invitedUsers: inviteEmail,
          image: image,
          type: accountType,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.data?.token}`,
          },
        }
      );

      handleGetUserDataRequest();

      alert(res?.data?.message);
      setLoading(false);
    }
  };

  const handleGetUserDataRequest = () => {
    const cbSuccess = (response) => {
      const { data } = response;
      console.log("--------------->>", data);
      setFirstName(data?.first_name);
      setIva(data?.iva);
      setEmail(data?.email);
      setNickName(data?.nickname || "");
      setInviteLink(data?.invitedUsers || []);
      setAccountType(data?.userType || []);
      setPlanInvitesInput(data?.allowedInvites);
      setImage(data?.image);
    };
    const cbFailure = (error) => {
      // setLoader(false);
      // alert("postogiusto");
      window.location.reload()
      dispatch(signout());
      history.push("/");
      
    };
    dispatch(getUserData(auth?.data?.token, cbSuccess, cbFailure));
  };

  const handleSetPlan = () => {
    if (!planInvitesInput) {
      alert("Please enter number of invites ");
      return;
    }
    const amountToCharge = planInvite(planInvitesInput);
    setAmount(amountToCharge);
    setSelectedSignUpType("stripeForm");
  };

  useEffect(() => {
    if (auth?.data?.token) {
      handleGetUserDataRequest();
    }
  }, [JSON.stringify(auth?.data?.token)]);

  return (
    <Provider
      value={{
        selectedSignUpType,
        authState,
        setSelectedSignUpType,
        showSingUp,
        setShowSignUp,
        validateSession,
        paymentDetails,
        setPaymentDetails,
        accountType,
        setAccountType,
        loading,
        signupUser,
        payMoney,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        userInfo,
        setEmail,
        createGroupsAndAccounts,
        password,
        changeChildMailPassword,
        createAccounts,
        setPassword,
        handleLoginAzure,
        handleLoginRequest,
        iva,
        changeGroup,
        handlePrivacyOptions,
        setIva,
        deleteAccounts,
        handelEnterpriceSignUp,
        inviteEmail,
        setInviteLink,
        handleInviteLinkRequest,
        handleInvitedUserRequest,
        signupInvitedEnterprise,
        isValidInvite,
        agency,
        handlePlatformLogin,
        setAgency,
        nickName,
        setNickName,
        handlePlatformSendResetCode,
        address,
        setAddress,
        common,
        setCommon,
        country,
        setCountry,
        handelProfileEnterprice,
        setAmount,
        deleteGroups,
        handlePlatformLogout,
        setPlanInvitesInput,
        planInvitesInput,
        setPlanCustom,
        cisoInfo,
        planCustom,
        handleSetPlan,
        amount,
        userData,
        image,
        handlePlatformVerifyResetCode,
        handlePlatformSetNewPassword ,
        setImage,
        enterprice,
        priceEnterprice,
        setPrice,
        createGroup,
      }}
    >
      {children}
      {showSingUp && <SignUpPopUp setHeading={setHeading} heading={heading} />}
    </Provider>
  );
};

export { AuthContextProvider, AuthContext };
