import React from "react";
import "./loadersearch.css";

const LoaderSearch = (props) => {
  return (
    <div className="loader-containerSearch">
      <div className="loaderSearch"></div>
   
    </div>
  );
};

export default LoaderSearch;