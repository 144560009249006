import React from "react";
import "./accountListDashboardSettings.css"; // Make sure this path is correct

const dummyData = [
  {
    id: 1,
    name: 'Andrea Marchica',
    image: 'https://i.ibb.co/QX0XxG4/user.png', // Replace with actual image path
    role: 'Basic Account',
    group: 'Marketing',
    lastAccess: '09.22.2023',
    dateOfCreation: '09.09.2023'
  },
  {
    id: 1,
    name: 'Andrea Marchica',
    image: 'https://i.ibb.co/QX0XxG4/user.png', // Replace with actual image path
    role: 'Basic Account',
    group: 'Marketing',
    lastAccess: '09.22.2023',
    dateOfCreation: '09.09.2023'
  },
  {
    id: 1,
    name: 'Andrea Marchica',
    image: 'https://i.ibb.co/QX0XxG4/user.png', // Replace with actual image path
    role: 'Basic Account',
    group: 'Marketing',
    lastAccess: '09.22.2023',
    dateOfCreation: '09.09.2023'
  },
  {
    id: 1,
    name: 'Andrea Marchica',
    image: 'https://i.ibb.co/QX0XxG4/user.png', // Replace with actual image path
    role: 'Basic Account',
    group: 'Marketing',
    lastAccess: '09.22.2023',
    dateOfCreation: '09.09.2023'
  },
  {
    id: 1,
    name: 'Andrea Marchica',
    image: 'https://i.ibb.co/QX0XxG4/user.png', // Replace with actual image path
    role: 'Basic Account',
    group: 'Marketing',
    lastAccess: '09.22.2023',
    dateOfCreation: '09.09.2023'
  },
  {
    id: 1,
    name: 'Andrea Marchica',
    image: 'https://i.ibb.co/QX0XxG4/user.png', // Replace with actual image path
    role: 'Basic Account',
    group: 'Marketing',
    lastAccess: '09.22.2023',
    dateOfCreation: '09.09.2023'
  },
  {
    id: 1,
    name: 'Andrea Marchica',
    image: 'https://i.ibb.co/QX0XxG4/user.png', // Replace with actual image path
    role: 'Basic Account',
    group: 'Marketing',
    lastAccess: '09.22.2023',
    dateOfCreation: '09.09.2023'
  },
  {
    id: 1,
    name: 'Andrea Marchica',
    image: 'https://i.ibb.co/QX0XxG4/user.png', // Replace with actual image path
    role: 'Basic Account',
    group: 'Marketing',
    lastAccess: '09.22.2023',
    dateOfCreation: '09.09.2023'
  },
  {
    id: 1,
    name: 'Andrea Marchica',
    image: 'https://i.ibb.co/QX0XxG4/user.png', // Replace with actual image path
    role: 'Basic Account',
    group: 'Marketing',
    lastAccess: '09.22.2023',
    dateOfCreation: '09.09.2023'
  },
  {
    id: 1,
    name: 'Andrea Marchica',
    image: 'https://i.ibb.co/QX0XxG4/user.png', // Replace with actual image path
    role: 'Basic Account',
    group: 'Marketing',
    lastAccess: '09.22.2023',
    dateOfCreation: '09.09.2023'
  },
  {
    id: 1,
    name: 'Andrea Marchica',
    image: 'https://i.ibb.co/QX0XxG4/user.png', // Replace with actual image path
    role: 'Basic Account',
    group: 'Marketing',
    lastAccess: '09.22.2023',
    dateOfCreation: '09.09.2023'
  },
  {
    id: 1,
    name: 'Andrea Marchica',
    image: 'https://i.ibb.co/QX0XxG4/user.png', // Replace with actual image path
    role: 'Basic Account',
    group: 'Marketing',
    lastAccess: '09.22.2023',
    dateOfCreation: '09.09.2023'
  },
  {
    id: 1,
    name: 'Andrea Marchica',
    image: 'https://i.ibb.co/QX0XxG4/user.png', // Replace with actual image path
    role: 'Basic Account',
    group: 'Marketing',
    lastAccess: '09.22.2023',
    dateOfCreation: '09.09.2023'
  },
  {
    id: 1,
    name: 'Andrea Marchica',
    image: 'https://i.ibb.co/QX0XxG4/user.png', // Replace with actual image path
    role: 'Basic Account',
    group: 'Marketing',
    lastAccess: '09.22.2023',
    dateOfCreation: '09.09.2023'
  },
  {
    id: 1,
    name: 'Andrea Marchica',
    image: 'https://i.ibb.co/QX0XxG4/user.png', // Replace with actual image path
    role: 'Basic Account',
    group: 'Marketing',
    lastAccess: '09.22.2023',
    dateOfCreation: '09.09.2023'
  },
  {
    id: 1,
    name: 'Andrea Marchica',
    image: 'https://i.ibb.co/QX0XxG4/user.png', // Replace with actual image path
    role: 'Basic Account',
    group: 'Marketing',
    lastAccess: '09.22.2023',
    dateOfCreation: '09.09.2023'
  },
  {
    id: 1,
    name: 'Andrea Marchica',
    image: 'https://i.ibb.co/QX0XxG4/user.png', // Replace with actual image path
    role: 'Basic Account',
    group: 'Marketing',
    lastAccess: '09.22.2023',
    dateOfCreation: '09.09.2023'
  },
  {
    id: 1,
    name: 'Andrea Marchica',
    image: 'https://i.ibb.co/QX0XxG4/user.png', // Replace with actual image path
    role: 'Basic Account',
    group: 'Marketing',
    lastAccess: '09.22.2023',
    dateOfCreation: '09.09.2023'
  },
  {
    id: 1,
    name: 'Andrea Marchica',
    image: 'https://i.ibb.co/QX0XxG4/user.png', // Replace with actual image path
    role: 'Basic Account',
    group: 'Marketing',
    lastAccess: '09.22.2023',
    dateOfCreation: '09.09.2023'
  },
  // Add more dummy data objects similar to the one above
];

const AccountListDashboardSettings = () => {
  return (
    <div className="account-list-widgetSettings">
  
      <table className="account-tableSettings">
        <thead>
          <tr>
            <th  className="noiconthAccountListSettings"><input style={{marginRight:"27px"}} type="checkbox" id="select-all" /></th>
            <th>Account ID</th>

    
            <th>Role</th>
      
            <th>Creation</th>
          </tr>
        </thead>
        <tbody>
          {dummyData.map(account => (
            <tr key={account.id}>
              <td><input type="checkbox" /></td>
              <td>{account.name}</td>
           
              <td>{account.role}</td>
         
     
              <td>{account.dateOfCreation}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AccountListDashboardSettings;
