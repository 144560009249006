import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import "./component-head-archivio.css";
import { addFolderRequest, getFolderRequest } from "../redux/actions";
import Loader from "./loader";
import floatingiconimg from '../images/foldericonfloat.png'

const ComponentHeadArchivio = (props) => {

  const location = useLocation();
  const isArchivioDettaglio = location.pathname === "/archivio-dettaglio";
  const isArchivio = location.pathname === "/archivio";
  const goBack = () => {
    window.history.back();
  };

    const translations = {
      en: {
        'Archiviopage': 'Archive',
        'Archiviopage1': 'Collection of all the links',
        'Archiviopage2': 'that you have saved.',
        'creab': '+ Folder',
        'cercaca': 'Search in folders',
        'backbutton': 'Back',
      },
      it: {
  
        'Archiviopage': 'Archivio',
        'Archiviopage1': 'Raccolta di tutti i link',
        'Archiviopage2': 'che hai salvato.',
        'creab': 'Crea cartella',
        'cercaca': 'Cerca nelle cartelle',
        'backbutton': 'Indietro',
      },
    };
    function translate(key, lang) {
      const italianTranslations = translations.it;
    
      if (lang === 'it' && italianTranslations[key]) {
        return italianTranslations[key];
      }
    
      return translations.en[key];
    }
    const lang = navigator.language.split('-')[0]; // Get the user's language from the browser
    const Archiviopaget = translate('Archiviopage', lang);
    const Archiviopaget1 = translate('Archiviopage1', lang);
    const Archiviopage2 = translate('Archiviopage2', lang);
    const Archiviopage3 = translate('creab', lang);
    const Cercaarchi = translate('cercaca', lang);
    const backbuttontra = translate('backbutton', lang)
  const BottoneCrea = isArchivioDettaglio 
    ? "component-head-archivio-frame13-archivio-dettaglio" 
    : "component-head-archivio-frame13";
    const BottoneCreaFloating = isArchivioDettaglio 
    ? "bottoneFloatArchivio-archivio-dettaglio" 
    : "bottoneFloatArchivio";

  const className = isArchivioDettaglio 
    ? "component-head-archivio-group92-archivio-dettaglio" 
    : "component-head-archivio-group92";
    const searchBarArchivioBackground = isArchivio 
    ? "component-head-archivio-rectangle69Hide" 
    : "component-head-archivio-rectangle69";
    const searchBarArchivioText = isArchivio 
    ? "component-head-archivio-text07Hide" 
    : "component-head-archivio-text07";
    const archivioDetailtext = isArchivioDettaglio 
    ? "component-head-archivio-text" 
    : "component-head-archivio-textArchivio";

    const archivioDetailtextSub = isArchivioDettaglio 
    ? "component-head-archivio-text02" 
    : "component-head-archivio-text02Archivio";
    const indietrotextarchiv = isArchivio 
    ? "" 
    : backbuttontra;
;
  return (
    <div className={`component-head-archivio-container ${props.rootClassName}`}>
      <div className={className}>
        <div className="component-head-archivio-group91">
          <span className={archivioDetailtext}>
            <span style={{fontFamily:"Encode Sans Expanded", fontWeight:"500"}} className="">{Archiviopaget}</span>
          </span>
          <span style={{fontFamily:"Encode Sans Expanded", fontWeight:"400"}} className={archivioDetailtextSub}>
            <span className="">
              <span className="">{Archiviopaget1}</span>
              <br className=""></br>
              <span className="">{Archiviopage2}</span>
              
            </span>
          </span>
          <img
            src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d715cf8a-99be-4b4d-8894-0bcff53b9223/c160b92a-5f5e-4c51-ba08-c4098d37d360?org_if_sml=1478"
            alt="Rectangle692331"
            className={searchBarArchivioBackground}
          />
          {/* <div className="component-head-archivio-gridlayout101">
            <div className="component-head-archivio-group">
              <img
                src="/playground_assets/vector2331-tih.svg"
                alt="Vector2331"
                className="component-head-archivio-vector"
              />
              <img
                src="/playground_assets/vector2331-t02.svg"
                alt="Vector2331"
                className="component-head-archivio-vector1"
              />
              <img
                src="/playground_assets/vector2331-djqh.svg"
                alt="Vector2331"
                className="component-head-archivio-vector2"
              />
              <img
                src="/playground_assets/vector2331-3f5h.svg"
                alt="Vector2331"
                className="component-head-archivio-vector3"
              />
            </div>
          </div> */}
          <img
            src="/playground_assets/line162331-oae3.svg"
            alt="Line162331"
            className="component-head-archivio-line16"
          />
          <span className={searchBarArchivioText}>
            <input
              value={props.search}
              onChange={props.onChange}
              className={"search-input"}
              type="text"
              placeholder={Cercaarchi}
            />
          </span>
          <span   onClick={goBack} className="component-head-archivio-line16Indietro indietroButtonArchivio">{indietrotextarchiv}</span>
          <p className="component-head-archivio-line16 classedettagliotitolo">{props.folder?.name || ""}</p> 
          {/* <span className="component-head-archivio-text09">
            <span className="">Alfabetico:</span>
          </span>
          <div className="component-head-archivio-component3">
            <span className="component-head-archivio-text11">
              <span style={{ marginLeft: "12px" }} className="">
                {" "}
                Qualsiasi
              </span>
            </span>
          </div> */}
          <div className={BottoneCrea}>
            {props.loader ? (
              <Loader />
            ) : (
              <span className="component-head-archivio-text13">
                <span className="" onClick={props.addfolder}>
                  {" "}
                  {Archiviopage3}{" "}
                </span>
              </span>
            )}
          </div>
          <div className={BottoneCreaFloating}>
            {props.loader ? (
              <Loader />
            ) : (
              <span className="component-head-archivio-text13">
                <span className="" onClick={props.addfolder}>
                  {" "}
                  <img src={floatingiconimg} />
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ComponentHeadArchivio.defaultProps = {
  rootClassName: "",
};

ComponentHeadArchivio.propTypes = {
  rootClassName: PropTypes.string,
};

export default ComponentHeadArchivio;
