import {takeLatest} from 'redux-saga/effects';
import * as types from '../../actions/types';
import {Api} from '../../../utils';
import {endPoints} from '../../../utils';

export function* changePassword() {
  yield takeLatest(types.SEND_VERIFICATION_CODE_REQUEST, sendCode);
  yield takeLatest(types.VERIFY_CODE_REQUEST, verifyCode);
  yield takeLatest(types.CHANGE_PASSWORD_REQUEST, recoverPassword);
}

function* sendCode(params) {
  console.log('[sendCode saga]', params);
  try {
    let response = yield Api.postAxios(
      endPoints.sendVerificationCode,
      params.params,
    );
    if (response?.status) {
      params.cbSuccess(response);
    } else {
      params.cbFailure(response);
    }
  } catch (err) {
    console.log('error from sendCode request saga -- > > >  > ', err);
    params.cbFailure(err.message);
  }
}

function* verifyCode(params) {
  console.log('[verifyCode saga]', params);
  try {
    let response = yield Api.postAxios(endPoints.verifyCode, params.params);
    if (response?.status) {
      params.cbSuccess(response);
    } else {
      params.cbFailure(response);
    }
  } catch (err) {
    console.log('error from verifyCode request saga -- > > >  > ', err);
    params.cbFailure(err.message);
  }
}

function* recoverPassword(params) {
  console.log('[recoverPassword saga]', params);
  try {
    let response = yield Api.postAxios(endPoints.changePassword, params.params);
    if (response?.status) {
      params.cbSuccess(response);
    } else {
      params.cbFailure(response);
    }
  } catch (err) {
    console.log('error from recoverPassword request saga -- > > >  > ', err);
    params.cbFailure(err.message);
  }
}
