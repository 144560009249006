import {takeLatest, put} from 'redux-saga/effects';
import * as types from '../../actions/types';
import {Api} from '../../../utils';
import {endPoints} from '../../../utils';

export function* saveCompleteProfileRequest() {
  yield takeLatest(types.SAVE_COMPLETE_PROFILE, saveCompleteProfile);
}

function* saveCompleteProfile(params) {
  // console.log('[update Profile saga]', params);
  try {
    let options = {
      headers: {
        Authorization: 'Bearer ' + params.token,
      },
    };
    let configuration = Object.assign(options);
    let response = yield Api.postAxios(
      endPoints.saveCompleteProfile,
      params.params,
      configuration,
    );
    if (response?.status) {
      params.cbSuccess(response);
    } else {
      params.cbFailure(response);
    }
  } catch (err) {
    console.log('error from save complete profile saga -- > > >  > ', err);
    params.cbFailure(err.message);
  }
}
