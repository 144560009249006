import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { useEffect, useState } from "react";
import "./style.css";
import PowerSearch from "./views/power-search";
import NewsSearch from "./views/news-search";
import SearchConProva from "./views/search-con-prova";
import Feed from "./views/feed";
import Home from "./views/home";
import { SearchContextProvider } from "./context/search_context";
import AdvancedFilters from "./views/advanced-filters";
import videoSearch from "./views/video-search";
import FeedDetail from "./views/feedDetail";
import Archivio from "./views/archivio";
import ArchivioDetail from "./views/archivioDetail";
import AziendeSearch from "./views/aziende-search";
import SocialSearch from "./views/social-search";
import ProdottiSearch from "./views/prodotti-search";
import DataWallet from "./views/data-wallet";
import WelfareAziendale from "./views/welfareAziendale";
import PopUpSaveLink1 from "./components/pop-up-save-link1";
import PopUpSaveLink2 from "./components/pop-up-save-link2";
import WebFont from 'webfontloader';
import { Provider as StoreProvider } from "react-redux";
import store from "./redux/storeConfig";
import SignUpPopUpC1 from "./components/sign-up-pop-up-c1";
import SignUpPopUp from "./components/sign-up-pop-up";
import { AuthContextProvider } from "./context/authContext";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import EnterpriseSignUp from "./components/enterprise-sign-up";
import InvitedUserPop from "./components/invited-user-signup";
import ProfileEnterprise from "./components/profile-enterprise";
import ProfileEnterPriceMain from "./components/profile-enterprise-main";
import SharePopup from "./components/SharePopup";
import ShareFolderInvite from "./components/shareFolderInvite";
import AziendeDetails from "./components/aziende-details";
import AziendeListCard from "./components/aziende-list-card";
import PrivacyPolicy from "./views/privacyPolicy";
import mapSearch from "./views/mapSearch";
import weagleGpt from "./views/weaglegpt";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from "./views/dashboard";
import OverviewWidgets from "./views/overviewWidgets";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { FaCookieBite } from 'react-icons/fa';
import AccountListWidgets from "./components/accountListWidgets";
import PrivacyOptionsWidgetRoleGroupSelection from "./components/privacyOptionsWidgetRoleGroupSelection";
import NewGroupPopUp from "./components/newGroupPopUp";
import LoginPlatformComponent from "./components/loginPlatformComponent";

const msalConfig = {
  auth: {
    clientId: '0c94634d-a6f9-492f-8f9a-e5fa631475a9',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: 'https://weagle.enterprises',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

const pca = new PublicClientApplication(msalConfig);
const stripe = loadStripe(
  "pk_live_51KH7qnJGK9TgyrlP16I9cinZvfaROnHRsiCtAk5AamhDPu8Gvl1qRZQRBmPzsaKuZ7bN0aHxiIPk85ncOjaxwr9q00gZvXusun"
);
const App = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    CookieConsent.run({

      // root: 'body',
      // autoShow: true,
      // disablePageInteraction: true,
      // hideFromBots: true,
      // mode: 'opt-in',
      // revision: 0,
  
      cookie: {
          name: 'cc_cookie',
          // domain: location.hostname,
          // path: '/',
          // sameSite: "Lax",
          // expiresAfterDays: 365,
      },
  
      // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
      guiOptions: {
          consentModal: {
              layout: 'cloud inline',
              position: 'bottom center',
              equalWeightButtons: true,
              flipButtons: false
          },
          preferencesModal: {
              layout: 'box',
              equalWeightButtons: true,
              flipButtons: false
          }
      },
  
      onFirstConsent: ({cookie}) => {
          console.log('onFirstConsent fired',cookie);
      },
  
      onConsent: ({cookie}) => {
          console.log('onConsent fired!', cookie)
      },
  
      onChange: ({changedCategories, changedServices}) => {
          console.log('onChange fired!', changedCategories, changedServices);
      },
  
      onModalReady: ({modalName}) => {
          console.log('ready:', modalName);
      },
  
      onModalShow: ({modalName}) => {
          console.log('visible:', modalName);
      },
  
      onModalHide: ({modalName}) => {
          console.log('hidden:', modalName);
      },
  
      categories: {
          necessary: {
              enabled: true,  // this category is enabled by default
              readOnly: true  // this category cannot be disabled
          },
          analytics: {
              autoClear: {
                  cookies: [
                      {
                          name: /^_ga/,   // regex: match all cookies starting with '_ga'
                      },
                      {
                          name: '_gid',   // string: exact cookie name
                      }
                  ]
              },
  
              // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
              services: {
                  ga: {
                      label: 'Google Analytics',
                      onAccept: () => {},
                      onReject: () => {}
                  },
                  youtube: {
                      label: 'Youtube Embed',
                      onAccept: () => {},
                      onReject: () => {}
                  },
              }
          },
          ads: {}
      },
  
      language: {
          default: 'en',
          translations: {
              en: {
                  consentModal: {
                      title: 'Informativa',
                      description: 'Noi e terze parti selezionate raccogliamo informazioni personali come specificato nella privacy policy e utilizziamo cookie o tecnologie simili per finalità tecniche e, con il tuo consenso, anche per le finalità di funzionalità, esperienza, misurazione e marketing (con annunci personalizzati) come specificato nella cookie policy.',
                      acceptAllBtn: 'Accetta tutto',
                      acceptNecessaryBtn: 'Rifiuta tutto',
                      showPreferencesBtn: 'Scopri di più',
                      // closeIconLabel: 'Reject all and close modal',
                      footer: `
                          <a href="#path-to-impressum.html" target="_blank">Terms & Conditions</a>
                          <a href="#path-to-privacy-policy.html" target="_blank">Privacy Policy</a>
                      `,
                  },
                  preferencesModal: {
                      title: 'Preferenze cookies',
                      acceptAllBtn: 'Accetta tutto',
                      acceptNecessaryBtn: 'Rifiuta tutto',
                      savePreferencesBtn: 'Accetta selezione',
                      closeIconLabel: 'Chiudi',
                      serviceCounterLabel: 'Servizio|Servizi',
                      sections: [
                          {
                              title: 'Le tue scelte',
                              description: `Questo pannello ti permette di esprimere alcune preferenze relative al trattamento delle tue informazioni personali.
                              Puoi rivedere e modificare le tue scelte in qualsiasi momento accedendo al presente pannello tramite l’apposito link.
                              Per rifiutare il tuo consenso alle attività di trattamento descritte di seguito o, dove applicabile, fare opt-out, disattiva i singoli comandi o utilizza il pulsante “Rifiuta tutto” e conferma di voler salvare le scelte effettuate.`,
                          },
                          {
                              title: 'Necessari',
                              description: 'Questi strumenti di tracciamento sono strettamente necessari per garantire il funzionamento e la fornitura del servizio che ci hai richiesto e, pertanto, non richiedono il tuo consenso.',
  
                              //this field will generate a toggle linked to the 'necessary' category
                              linkedCategory: 'necessary'
                          },
                          {
                              title: 'Performance e Analitici',
                              description: 'Questi cookie raccolgono informazioni su come utilizzi il nostro sito web. Tutti i dati sono anonimizzati e non possono essere utilizzati per identificarti.',
                              linkedCategory: 'analytics',
                              cookieTable: {
                                  caption: 'Cookie table',
                                  headers: {
                                      name: 'Cookie',
                                      domain: 'Domain',
                                      desc: 'Description'
                                  },
                                  body: [
                                      {
                                          name: '_ga',
                                          domain: location.hostname,
                                          desc: 'Description 1',
                                      },
                                      {
                                          name: '_gid',
                                          domain: location.hostname,
                                          desc: 'Description 2',
                                      }
                                  ]
                              }
                          },
                          {
                              title: 'Targeting',
                              description: "Questi cookie sono utilizzati per rendere i messaggi pubblicitari più pertinenti a te e ai tuoi interessi. L'intento è quello di mostrare annunci che siano rilevanti e coinvolgenti per l'utente singolo e quindi più preziosi per gli editori e gli inserzionisti terzi.",
                              linkedCategory: 'ads',
                          },
                          {
                              title: 'Informazioni aggiuntive',
                              description: 'Per qualsiasi domanda in relazione alla mia politica sui cookie e alle tue scelte, ti preghiamo di <a href="#contact-page">contattarci</a>.'
                          }
                      ]
                  }
              }
          }
      }
  });
      });

  // useEffect(() => {
  //   const handleResize = () => setWidth(window.innerWidth);
  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (width < 1370) {
  //     setShowMessage(true);
  //   } else {
  //     setShowMessage(false);
  //   }
  // }, [width]);

  return (
    
    <div>
      
      <button
      type="button"
      data-cc="show-preferencesModal"
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000,
        background: 'transparent',
        border: 'none',
        cursor: 'pointer'
      }}
      aria-label="Manage cookie preferences"
    >
      <FaCookieBite color="#063565" size="24px" />
    </button>


     
      {showMessage && <div className="MessageResolution">
        
        
        <h1 className="Resolution">Resolution is less than 1370px</h1>
      
        <h1 className="Resolution2">Please Resize you browser window to continue your navigation with Weagle 2.0.</h1>
        <span style={{ cursor:'pointer', fontSize:'60px', marginTop:'120px'}} className="side-bar-logo">weagle</span>
      </div>}
      

      
    {!showMessage && ( 
      <>
            <ToastContainer/>
        <Elements stripe={stripe}>
      <StoreProvider store={store}>
        
        <AuthContextProvider>
          <SearchContextProvider>
            <Router>
              <div>
              
              <Route
                  component={AziendeListCard}
                  exact
                  path="/aziende-search"
                />
                  <Route
                  component={NewGroupPopUp}
                  exact
                  path="/widgetslist"
                />
              <Route
                  component={AziendeDetails}
                  exact
                  path="/aziende-detail"
                />
              <Route
                  component={SharePopup}
                  exact
                  path="/share-popup"
                />


              <Route
                  component={InvitedUserPop}
                  exact
                  path="/invite"
                />
                <Route
                  component={ShareFolderInvite}
                  exact
                  path="/shareFolder"
                />
                <Route
                  component={ProfileEnterPriceMain}
                  exact
                  path="/profile-enterprise"
                />
              <Route
                  component={EnterpriseSignUp}
                  exact
                  path="/enterprice-sign-up"
                />
                <Route
                  component={SignUpPopUpC1}
                  exact
                  path="/sign-up-popUpC1"
                />
                <Route component={SignUpPopUp} exact path="/sign-up-popUp" />
                <Route component={PopUpSaveLink1} exact path="/popupsavelink" />
                <Route
                  component={PopUpSaveLink2}
                  exact
                  path="/popupsavelink2"
                />
        <Route component={PowerSearch} exact path="/power-search" />
<Route component={PowerSearch} exact path="/power-search/:searchterm" />



                <Route component={NewsSearch} exact path="/news-search" />
                <Route component={SearchConProva} exact path="/" />
                <Route component={Feed} exact path="/feed" />
                <Route component={Home} exact path="/home" />
                <Route component={LoginPlatformComponent} exact path="/login" />
                <Route component={Dashboard} exact path="/dashboard" />
                <Route
                  component={AdvancedFilters}
                  exact
                  path="/advanced-filters"
                />
                <Route component={videoSearch} exact path="/video-search" />
                <Route component={FeedDetail} exact path="/feed-detail" />
                <Route component={Archivio} exact path="/archivio" />
                <Route
                  component={ArchivioDetail}
                  exact
                  path="/archivio-dettaglio"
                />
                {/* <Route component={AziendeSearch} exact path="/aziende-search" /> */}
                <Route component={SocialSearch} exact path="/social-search" />
                <Route
                  component={ProdottiSearch}
                  exact
                  path="/prodotti-search"
                />
                  <Route
                  component={mapSearch}
                  exact
                  path="/maps"
                />
                <Route component={DataWallet} exact path="/data-wallet" />
                <Route component={WelfareAziendale} exact path="/welfare" />
                <Route component={PrivacyPolicy} exact path="/privacy" />
                <Route component={weagleGpt} exact path="/chat" />
              </div>
            </Router>
          </SearchContextProvider>
        </AuthContextProvider>
      </StoreProvider>
    </Elements>
      </>
    ) }
    </div>
    
  );
};

ReactDOM.render( <MsalProvider instance={pca}><App /> </MsalProvider>, document.getElementById("app"));

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./proxy-service-worker.js")
    .then((registration) => {
      console.log("Service worker registered:", registration);
    })
    .catch((error) => {
      console.error("Service worker registration failed:", error);
    });
}