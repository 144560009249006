import {takeLatest, put} from 'redux-saga/effects';
import * as types from '../../actions/types';
import {Api} from '../../../utils';
import {endPoints} from '../../../utils';

export function* getNewsRequest() {
  yield takeLatest(types.GET_NEWS, getNewsSaga);
  yield takeLatest(types.GET_SEARCH_FEED, getSearchNews);
}

function* getNewsSaga(params) {
  try {
    let options = {
      headers: {
        Authorization: 'Bearer ' + params.token,
      },
    };
    let configuration = Object.assign(options);
    let response = yield Api.getAxios(endPoints.getNews, configuration);
    if (response?.status) {
      yield put({type: types.SET_NEWS_STATUS, data: response.data});
      params.cbSuccess(response.data);
    } else {
      params.cbFailure(response);
    }
  } catch (err) {
    console.log('error from get news request saga -- > > >  > ', err);
    params.cbFailure(err.message);
  }
}

function* getSearchNews(params) {
  try {
    let options = {
      headers: {
        Authorization: 'Bearer ' + params.token,
      },
    };
    let configuration = Object.assign(options);
    let response = yield Api.postAxios(
      endPoints.getSearchFeed,
      {keyword: params?.keyword, locale: params?.locale},
      configuration,
    );
    if (response?.status) {
      params.cbSuccess({items: response?.data});
    } else {
      params.cbFailure(response);
    }
  } catch (err) {
    params.cbFailure(err.message);
  }
}
