// UsageStatisticsComponent.js
import React, { useContext, useState } from 'react';
import { Doughnut, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import './usageStatisticsComponent.css';
import DashboardDatePicker, { formatDate } from './dashboardDatePicker';
import { AuthContext } from "../context/authContext";
import DashbooardstatisticsGroupPicker from './dashbooardstatisticsGroupPicker';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const UsageStatisticsComponent = () => {
  const { cisoInfo } = useContext(AuthContext);
  const today = new Date();
  const [selectedDateRange1, setSelectedDateRange1] = useState({
    start: today,
    end: today,
    label: formatDate(today)
  });
  const [selectedDateRange2, setSelectedDateRange2] = useState({
    start: today,
    end: today,
    label: formatDate(today)
  });
  const [selectedGroup, setSelectedGroup] = useState('All Groups');

  const handleDateRangeSelected1 = (dateRange) => {
    setSelectedDateRange1(dateRange);
  };

  const handleDateRangeSelected2 = (dateRange) => {
    setSelectedDateRange2(dateRange);
  };

  const handleGroupSelected = (group) => {
    setSelectedGroup(group);
  };

  // Get the daily search statistics within the selected date range and group
  const dailySearchStats = cisoInfo.statistics.reduce((acc, group) => {
    if (selectedGroup === 'All Groups' || group.groupName === selectedGroup) {
      const { dailySearches } = group;
      Object.entries(dailySearches || {}).forEach(([date, count]) => {
        const searchDate = new Date(date);
        if (searchDate >= new Date(selectedDateRange1.start) && searchDate <= new Date(selectedDateRange1.end)) {
          acc.push({ date, count });
        }
      });
    }
    return acc;
  }, []);

  const totalSearches = dailySearchStats.reduce((total, { count }) => total + count, 0);

  // Prepare the data for the line chart
  const lineData = {
    labels: dailySearchStats.map(({ date }) => date),
    datasets: [
      {
        label: 'Number of Searches Conducted',
        data: dailySearchStats.map(({ count }) => count),
        fill: false,
        backgroundColor: 'rgb(54, 162, 235)',
        borderColor: 'rgba(54, 162, 235, 0.2)',
      },
    ],
  };

  // Calculate the active users and inactive users percentage
  const calculateActiveUsersPercentage = () => {
    const dailyLogins = cisoInfo.statistics.reduce((acc, group) => {
      if (selectedGroup === 'All Groups' || group.groupName === selectedGroup) {
        const { dailyLogins } = group;
        Object.entries(dailyLogins || {}).forEach(([date, userLogins]) => {
          const loginDate = new Date(date);
          if (loginDate >= new Date(selectedDateRange2.start) && loginDate <= new Date(selectedDateRange2.end)) {
            acc[date] = acc[date] || new Set();
            Object.keys(userLogins).forEach((userId) => {
              acc[date].add(userId);
            });
          }
        });
      }
      return acc;
    }, {});

    const uniqueUsers = new Set();
    Object.values(dailyLogins).forEach((userSet) => {
      userSet.forEach((userId) => {
        uniqueUsers.add(userId);
      });
    });

    const activeUsers = uniqueUsers.size;
    const totalUsers = cisoInfo.seatsBase - cisoInfo.availableSeatsBase;
    const activeUsersPercentage = ((activeUsers / totalUsers) * 100).toFixed(2);
    const inactiveUsersPercentage = (100 - activeUsersPercentage).toFixed(2);

    return { activeUsersPercentage, inactiveUsersPercentage };
  };

  const { activeUsersPercentage, inactiveUsersPercentage } = calculateActiveUsersPercentage();

  // Prepare the data for the doughnut chart
  const doughnutData = {
    labels: [`Active Users (${activeUsersPercentage}%)`, `Inactive Users (${inactiveUsersPercentage}%)`],
    datasets: [
      {
        label: 'User Activity',
        data: [activeUsersPercentage, inactiveUsersPercentage],
        backgroundColor: [
          'rgb(54, 162, 235)', // Active Users
          'rgb(255, 99, 132)', // Inactive Users
        ],
        hoverOffset: 4
      }
    ]
  };

  // Options for the line chart
  const lineOptions = {
    scales: {
      y: {
        beginAtZero: true,
        type: 'linear',
      },
    },
    elements: {
      line: {
        tension: 0.4
      }
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      }
    },
  };

  // Options for the doughnut chart
  const doughnutOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.parsed || 0;
            return `${label}: ${value}%`;
          },
        },
      },
    },
  };

  // Algorithm to calculate the frequency of usage based on daily logins
  const calculateFrequencyOfUsage = () => {
    const dailyLogins = cisoInfo.statistics.reduce((acc, group) => {
      if (selectedGroup === 'All Groups' || group.groupName === selectedGroup) {
        const { dailyLogins } = group;
        Object.entries(dailyLogins || {}).forEach(([date, userLogins]) => {
          const loginDate = new Date(date);
          if (loginDate >= new Date(selectedDateRange2.start) && loginDate <= new Date(selectedDateRange2.end)) {
            acc[date] = acc[date] || {};
            Object.entries(userLogins).forEach(([userId, count]) => {
              acc[date][userId] = (acc[date][userId] || 0) + count;
            });
          }
        });
      }
      return acc;
    }, {});

    const frequencyData = [];

    // Iterate over each date in the dailyLogins object
    for (const date in dailyLogins) {
      const userLogins = dailyLogins[date];
      let totalLogins = 0;

      // Calculate the total number of logins for the current date
      for (const userId in userLogins) {
        totalLogins += userLogins[userId];
      }

      frequencyData.push({ date, totalLogins });
    }

    // Sort the frequency data in descending order based on the total logins
    frequencyData.sort((a, b) => b.totalLogins - a.totalLogins);

    // Return the top 5 dates with the highest number of logins
    return frequencyData.slice(0, 5);
  };

  const frequencyOfUsage = calculateFrequencyOfUsage();

  // Prepare the data for the frequency of usage line chart
  const frequencyLineData = {
    labels: frequencyOfUsage.map(({ date }) => date),
    datasets: [
      {
        label: 'Frequency of Usage',
        data: frequencyOfUsage.map(({ totalLogins }) => totalLogins),
        fill: false,
        backgroundColor: 'rgb(75, 192, 192)',
        borderColor: 'rgba(75, 192, 192, 0.2)',
      },
    ],
  };

  // Calculate the number of users based on the difference between availableSeatsBase and seatsBase
  const numberOfUsers = cisoInfo.seatsBase - cisoInfo.availableSeatsBase;

  return (
    <div className="statisticsusagecontainer">
      <div className="statisticsusageTitleDateDiv">
        <span className='statisticsusageTitle'>Statistics of usage</span>
        <div className="statisticsusagedropdownsdiv">
        <DashbooardstatisticsGroupPicker
            groups={cisoInfo.groups}
            onGroupSelected={handleGroupSelected}
          />
          <DashboardDatePicker onDateRangeSelected={handleDateRangeSelected1} />
 
        </div>
      </div>

          <span className='statisticsusageGraphDetailtext'>Number of searches conducted: {totalSearches}</span>
          <div className="numbersearchconducteddiv">
            <Line data={lineData} options={lineOptions} />
          </div>
       
      <div className="statisticsusageTitleDateDiv2">
        <DashboardDatePicker onDateRangeSelected={handleDateRangeSelected2} />
      </div>
      <div className='doubledivtitledivt'>
        <span className='statisticsusageGraphDetailtext'>Total Accounts: {numberOfUsers}</span>
        <span className='statisticsusageGraphDetailtext'>Frequency of usage</span>
      </div>
      <div className="doublegraphdiv">
        <div style={{marginTop:'-10px'}} className="doublegraphdivc1">
          <Doughnut data={doughnutData} options={doughnutOptions} />
        </div>
        <div className="doublegraphdivc3">
          <Line data={frequencyLineData} options={lineOptions} />
        </div>
      </div>
    </div>
  );
};

export default UsageStatisticsComponent;
