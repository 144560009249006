import React, { useState } from 'react';
import './segnalaProblema.css';
import ContattiIntegrato from './contatti-integrato';
const SegnalaProblema = () => {
const [showPopup, setShowPopup] = useState(false);
const translations = {
    en: {
     
      'ppps1segnala': "Report a problem",
   
    },
    it: {

  
      'ppps1segnala': 'Segnala un problema',

    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser

  const ppps1segnal = translate('ppps1segnala', lang);

const togglePopup = () => {
setShowPopup(!showPopup);
};
const closePopup = (event) => {
    if (event.target.className === 'popup-wrapper') {
    setShowPopup(false);
    }
    };
return (
<div>
<div className='buttonSegnala' onClick={togglePopup}>{ppps1segnal}</div>
{showPopup && (
<div  onClick={closePopup} className="popup-wrapper">
<div className="popup">
<div className='closepop' onClick={togglePopup}>X</div>
<ContattiIntegrato />

</div>
</div>
)}
</div>
);
};

export default SegnalaProblema;




