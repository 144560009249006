import React, { useContext, useState } from "react";
import { langFilterData } from "../services";
import "./language-filter.css";
import { SearchContext } from "../context/search_context";

const LanguageFilter = (props) => {

  const translations = {
    en: {
     
      'pppn1la': "Search a Country",
  
    },
    it: {

  
     
      'pppn1la': "Country...",
    
    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser

  const pppn1l = translate('pppn1la', lang);





  const [input, setInput] = useState("");
  const [searched, setSearched] = useState(Object.keys(langFilterData));
  const {
    newsLanguageFilter,
    setNewsLanguageFilter,
    setshowLanguageNewsFilter,
  } = useContext(SearchContext);
  return (
    <div className={`language-filter-dropdown-search ${props.rootClassName} `}>
      <input
        value={input}
        onChange={(e) => {
          const searched = Object.keys(langFilterData).filter((item) =>
            langFilterData[item].name
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          );
          setSearched([...searched]);
          if (e.target.value?.length === 0) {
            setSearched(Object.keys(langFilterData));
          }
          setInput(e.target.value);
        }}
        type="text"
        className={"filtro-langauge-cerca-key-word"}
        placeholder={pppn1l}
      />
      {searched.map((item) => {
        return (
          <div
            onClick={() => {
              setNewsLanguageFilter(
                langFilterData[item].languages[0] + "-" + item
              );
              setshowLanguageNewsFilter((prev) => !prev);
            }}
            key={`language_${langFilterData[item].languages[0] + "-" + item}`}
            className={` language-filter ${
              newsLanguageFilter ===
              langFilterData[item].languages[0] + "-" + item
                ? "active"
                : ""
            }`}
          >
            <span>{langFilterData[item].name}</span>
          </div>
        );
      })}
    </div>
  );
};

export default LanguageFilter;
