import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./news-search-result-list.css";
import Loader from "./loader";
import cheerio from "cheerio";
import PopUpSaveLink1 from "./pop-up-save-link1";
import useLinkPreview from "../context/useLinkPreview";
import { SearchContext } from "../context/search_context";
import {formatDate} from "../services";

const NewsSearchResultList = (props) => {
 
  const { index, link, setShowFolderModal, setData, title } = props;
 
  const [showPicker, setShowPicker] = useState(false);
  const protafolioObj={
    title: props?.title,
    description: props?.description,
    link: props?.link,
    pubDate: props?.pubDate,
    source: props?.source,


  }
  // const { auth } = useSelector((state) => state);
  // const dispatch = useDispatch();
 

  const ImageAlgorithmNews = (offset, props) => {
    const [imageSource, setImageSource] = useState("");
    const [loader, setLoader] = useState(false);
    const [controller, setController] = useState(new AbortController());
  
    useEffect(() => {
      setImageSource("");
      setLoader(true);
  
      const timer = setTimeout(() => {
        getPreview();
      }, offset * 1000);
  
      return () => {
        clearTimeout(timer);
        setController(new AbortController());
      };
    }, [title]);
  
    const getPreview = (retryCount = 0, searchQuery = title) => {
      const truncatedTitle = title.split(" ").slice(0, 7).join(" ");
      const initialQuery = searchQuery === title ? truncatedTitle : searchQuery;
  
      setLoader(true);
  
      const bingImagesUrl = `https://www.bing.com/images/search?q=${encodeURIComponent(initialQuery)}`;
  
      fetch(`https://afternoon-island-31692.herokuapp.com/${bingImagesUrl}`)
        .then((imagesResponse) => {
          if (imagesResponse.status === 404 || imagesResponse.redirected) {
            if (retryCount < 10) {
              const words = searchQuery.split(" ");
              const newSearchQuery = words.slice(0, -1).join(" ");
              getPreview(retryCount + 1, newSearchQuery);
            } else {
              throw new Error("Image not found");
            }
          } else {
            return imagesResponse.text();
          }
        })
        .then((imagesHtml) => {
          const $ = cheerio.load(imagesHtml);
          const images = $('img.mimg').filter(function () {
            const width = parseInt($(this).attr('width'));
            const height = parseInt($(this).attr('height'));
            if (width && height) {
              return width/height >= 1.5;
            }
          });
          if (images.length > 0) {
            const imageSource = images.eq(0).attr('src');
            setImageSource(imageSource);
            setLoader(false);
          } else {
            throw new Error("Image not found");
          }
        })
        .catch((err) => {
          console.log(err);
          if (retryCount < 10) {
            const words = searchQuery.split(" ");
            const newSearchQuery = words.slice(0, -1).join(" ");
            getPreview(retryCount + 1, newSearchQuery);
          } else {
            setLoader(false);
            console.error("Failed to find an image after multiple retries");
          }
        });
    };
  
    return { imageSource, loader };
  };
  
  
  const { imageSource, loader } = ImageAlgorithmNews(props.source?.url, index);

  const {handleAddArticleToPortafolioDati}=useContext(SearchContext)
  const openLink = () => {
    handleAddArticleToPortafolioDati(protafolioObj);
    window.open(link);

  }; 
  return (
    <div
      style={{ cursor: "pointer" }}
      className="news-search-result-list-container"
    >
      <div className="news-search-result-list-news-maindiv" onClick={openLink}>
        <div  className="news-search-result-list-image-news-result">
          {loader && (
            <div  style={{marginLeft:'20px',marginTop:'-80px'}} className={"news-search-result-loader-container"}>
              <Loader />
            </div>
          )}

          {!loader && (
            <img
              src={imageSource}
              className={"news-search-result-list-image-result"}
              alt=""
            />
          )}
          {!loader && (
            <img
              alt={props?.image_alt1}
              src={props?.image_src1}
              className="news-search-result-list-image"
            />
          )}
        </div>

        <div className="news-search-result-list-title-link-description-news">
          <span className="news-search-result-list-link-news">
            {props?.source.text?.slice(0, 40)}
            {props?.source.text?.length > 40 && "..."}
          </span>
          <span className="news-search-result-list-title-news">
            {props?.title?.slice(0, 60)}
            {props?.title?.length > 60 && "..."}
          </span>
          <span className="news-search-result-list-description-news">
            {props.description?.slice(0, 120)}
            {props.description?.length > 120 && "..."}
          </span>
          <span className="news-search-result-list-description-news">
            {formatDate(props?.pubDate)}
          </span>
        </div>

        <div
          onClick={(event) => {
            event.stopPropagation();
            setData({
              title: props?.title,
              description: props?.description,
              link: props?.link,
              pubDate: props?.pubDate,
              source: props?.source,
            });
            setShowPicker((prev) => !prev);
          }}
          className="news-search-result-list-share-div"
        >
          <img
            alt={props?.image_alt}
            src={props?.image_src}
            className="news-search-result-list-share-icon"
          />

          {showPicker && (
            <PopUpSaveLink1
              source={props?.source.text}
              url={props?.source?.url}
              setShowFolderModal={setShowFolderModal}
              setShow={setShowPicker}
              link={props.link}
            />
          )}
        </div>
      </div>
    </div>
  );
};

NewsSearchResultList.defaultProps = {
  image_alt: "image",
  link: "https://www.asromalive.it/",
  image_src1: "/playground_assets/group%2024.svg",
  description: "-",
  image_alt1: "image",
  title: "-",
  image_src: "/playground_assets/dots%201.svg",
};

NewsSearchResultList.propTypes = {
  image_alt: PropTypes.string,
  link: PropTypes.string,
  image_src1: PropTypes.string,
  description: PropTypes.string,
  image_alt1: PropTypes.string,
  title: PropTypes.string,
  image_src: PropTypes.string,
};

export default NewsSearchResultList;
