import React  from "react";
import "./panigation.css";

const DataWalletPagination = (props) => {
  const { totalPages, changePagePagination, page } = props;
  return (
    <div className="pagination">
      <a
        href={"#"}
        className={page <= 1 ? "disabled" : ""}
        onClick={() => {
          if (page > 1) {
            changePagePagination(page - 1);
          }
        }}
      >
        &lt;
      </a>
      {Array.from({ length: totalPages }, (_, i) => i + 1).map((item) => (
        <a
          key={`pagination_${item}`}
          href="#"
          onClick={() => {
            if (item !== page) changePagePagination(item);
          }}
          className={`${page === item && "active"}`}
        >
          {item}
        </a>
      ))}
      <a
        href="#"
        className={page >= totalPages ? "disabled" : ""}
        onClick={() => {
          if (page < totalPages) {
            changePagePagination(page + 1);
          }
        }}
      >
        &gt;
      </a>
    </div>
  );
};

export default DataWalletPagination;
