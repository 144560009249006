import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import SideBar from "../components/side-bar";
import ProfileSide from "../components/profile-side";
import Hamburgher from "../components/hamburgher-menu";
import { useHistory } from "react-router-dom";
import MiddlewareChat from "../components/middlewareChat";
import { AuthContext } from "../context/authContext";
import { useContext } from "react";
import { useLocation } from 'react-router-dom';
import NewmenuDesktop from "../components/newmenudesktop";
import { SearchContext } from "../context/search_context";
import "./weagleGpt.css";
const weagleGpt = (props) => {
  
  const {
    firstName,
    setFirstName,
    iva,
    setIva,
    email,
    setEmail,
    loading,
    nickName,
    setNickName,
    accountType,
    setAccountType,
    address,
    setAddress,
    common,
    setCommon,
    country,
    setCountry,
    handelProfileEnterprice,
    image,
    setImage,
  } = useContext(AuthContext);
  const {  auth } =
  useContext(SearchContext);
  let history = useHistory();
  const { pathname } = useLocation(); 

  // useEffect per cambiare il colore di sfondo della pagina
  // useEffect(() => {
  //   setTimeout(() => {
  //     if(pathname === '/chat') {
  //       document.body.style.background = "#F9F9F9";
  //       let elements = document.getElementsByClassName('side-bar-inactive');
  //       for(let i=0; i<elements.length; i++) {
  //         elements[i].style.color = "#000";
  //       }
  //       let elements2 = document.getElementsByClassName('active-background');
  //       for(let i=0; i<elements2.length; i++) {
  //         elements2[i].style.color = "#000";
  //       }
  //       let elements3 = document.getElementsByClassName('profile-side-name-surname');
  //       for(let i=0; i<elements3.length; i++) {
  //         elements3[i].style.color = "#000";
  //       }
  //       let elements4 = document.getElementsByClassName('profile-side-username');
  //       for(let i=0; i<elements4.length; i++) {
  //         elements4[i].style.color = "#000";
  //       }
  //     } 
  //   }, 0);
  //   return () => {
  //     document.body.style.background = 'white'; 
  //     let elements = document.getElementsByClassName('side-bar-inactive');
  //     for(let i=0; i<elements.length; i++) {
  //       elements[i].style.color = "#000"; 
  //     }
  //     let elements2 = document.getElementsByClassName('active-background');
  //     for(let i=0; i<elements2.length; i++) {
  //       elements2[i].style.color = '#000'; 
  //     }
  //     let elements3 = document.getElementsByClassName('profile-side-name-surname');
  //     for(let i=0; i<elements3.length; i++) {
  //       elements3[i].style.color = "#000";
  //     }
  //     let elements4 = document.getElementsByClassName('profile-side-username');
  //     for(let i=0; i<elements4.length; i++) {
  //       elements4[i].style.color = "#000";
  //     }
  //   };
  // }, [pathname]);

  return (
    <div  className="power-search-containerDataWallet">
      <Helmet>
        <title>Confidential Chat</title>
        <meta property="og:title" content="PowerSearch - Weagle" />
      </Helmet>
      <div className="power-search-container-principale">
        <div className="power-search-container1">
          <SideBar rootClassName="side-bar-root-class-name2"></SideBar>
        </div>
     
        <div className="menudesktop">
        <div className="newmenucompleto">
         <NewmenuDesktop accountType={accountType} auth={auth}  />
    <ProfileSide  />
 
    </div> 
    </div>
    <div className="menumobile">
    <ProfileSide  />
  <img style={{ cursor:'pointer', marginBottom:'20px'}} onClick={() => {
          history.push("/");
        }} src="/playground_assets/logoweaglenew.svg" alt="weagleLogo" className="menumobileLogo" />

    <Hamburgher /> 
    
    </div>
      </div>


      <div style={{}} className="power-search-results-divChat">
  {accountType === "free" ? (
    <div className="spinner"></div>
  ) : (
    <MiddlewareChat />
  )}
</div>
    
    </div>
  );
};

export default weagleGpt;
