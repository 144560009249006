// search-pagination.js
import React, { useContext } from "react";
import "./panigation.css";
import { SearchContext } from "../context/search_context";

const SearchPagination = () => {
  const { changePagePagination, page } = useContext(SearchContext);
  const totalPages = 10;

  return (
    <div className="pagination">
      <a
        href="#"
        className={page <= 1 ? "disabled" : ""}
        onClick={() => {
          if (page > 1) {
            changePagePagination(page - 1);
          }
        }}
      >
        &lt;
      </a>
      {Array.from({ length: totalPages }, (_, i) => i + 1).map((item) => (
        <a
          key={`pagination_${item}`}
          href="#"
          onClick={() => {
            if (item !== page) changePagePagination(item);
          }}
          className={`${page === item && "active"}`}
        >
          {item}
        </a>
      ))}
      <a
        href="#"
        className={page >= totalPages ? "disabled" : ""}
        onClick={() => {
          if (page < totalPages) {
            changePagePagination(page + 1);
          }
        }}
      >
        &gt;
      </a>
    </div>
  );
};

export default SearchPagination;
