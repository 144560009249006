import {takeLatest, put} from 'redux-saga/effects';
import * as types from '../../actions/types';
import {Api} from '../../../utils';
import {endPoints} from '../../../utils';

export function* getArticlesFromPortafolioDatiRequest() {
  yield takeLatest(
    types.GET_ARTICLES_FROM_PORTAFOLIO_DATI_REQUEST,
    getArticlesFromPortafolioDati,
  );
}

function* getArticlesFromPortafolioDati(params) {
  // console.log('[get article to PortafolioDati saga]', params);
  try {
    let options = {
      headers: {
        Authorization: 'Bearer ' + params.token,
      },
    };
    let configuration = Object.assign(options);
    let response = yield Api.postAxios(
      endPoints.getArticlesFromPortafolioDati,
      params.params,
      configuration,
    );
    if (response?.status) {
      params.cbSuccess(response);
    } else {
      params.cbFailure(response);
    }
  } catch (err) {
    console.log(
      'error from get articles from Portafolio Dati request saga -- > > >  > ',
      err,
    );
    params.cbFailure(err.message);
  }
}
