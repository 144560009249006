import * as TYPES from "../../actions/types";

let initialOtherUsers = []
const strOtherUsersId = localStorage.getItem('userIds')
if (strOtherUsersId) {
  const otherUsersId = strOtherUsersId.split(',')
  otherUsersId.forEach(item => {
    const userData = localStorage.getItem(item)
    if (userData) {
      initialOtherUsers.push(JSON.parse(userData))
    }
  })
}

let initialUser = {}
const strCurrentId = localStorage.getItem('currentId')
if (strCurrentId) {
  initialUser = initialOtherUsers.find(item => item._id.toString() === strCurrentId)
}
const login = () => {
  localStorage.removeItem(usersData._id, JSON.stringify(usersData))

  
}






const initialState = {
  otherUsers: initialOtherUsers,
  loading: false,
  isKeepLogin: null,
  data: initialUser,
  error: null,
  isSuccess: false,
  isFailure: false,
  playVideo: false,
  isNews: false,
  newsTitles: [],
};
const authReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case TYPES.SET_PROFILE_DATA:

      const usersData = {
        ...state.data,
        ...actions.data,
      }

      /* if user is logined successfully, save its token to local storage. */
      localStorage.setItem('currentId', usersData._id.toString())
      localStorage.setItem(usersData._id, JSON.stringify(usersData))

      const strUserIds = localStorage.getItem('userIds')
      if (strUserIds) {
        const userIds = strUserIds.split(',')
        const currentUserId = userIds.find(item => item.toString() === usersData._id.toString())
        if (!currentUserId)
          userIds.push(usersData._id.toString())

        localStorage.setItem('userIds', userIds.toString())
      }
      else {
        localStorage.setItem('userIds', usersData._id)
      }

      let newOtherUsers = state.otherUsers;
      const prevOtherUsers = state.otherUsers?.some(item => item?.email === actions?.data.email);
      if (prevOtherUsers) {
        newOtherUsers = state.otherUsers?.map(item => {
          if (item?.email === actions?.data?.email) {
            return usersData
          } else {
            return item;
          }
        });
      } else {
        newOtherUsers.push(usersData);
      }

      return {
        ...state,
        data: usersData,
        otherUsers: [...newOtherUsers]
      };


    case TYPES.SWITCH_USER:
      const user = state.otherUsers?.find(item => item?._id === actions?.id);

      localStorage.setItem('currentId', user._id.toString())
      return {
        ...state,
        data: user,
      };
    case TYPES.AD_TIMEOUT:
      return {
        ...state,
        playVideo: !state.playVideo,
      };

    case TYPES.SET_NEWS_STATUS:
      if (actions.data.data.news.length > 0) {
        let tempStatus = false;
        let tempNews = JSON.parse(JSON.stringify(state.newsTitles));
        actions.data.data.news.forEach((item) => {
          if (state.newsTitles.indexOf(item.title) === -1) {
            tempStatus = true;
            tempNews.push(item.title);
          }
        });
        return {
          ...state,
          isNews: tempStatus === true ? true : state.isNews,
          newsTitles: tempNews,
        };
      } else {
        return {
          ...state,
          isNews: false,
          newsTitles: [],
        };
      }
    default:
      return state;
  }
};
export default authReducer;
