import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import cheerio from "cheerio";
import {formatDate} from "../services";
import Loader from "./loader";
const FeedDetailItem = (props) => {
  
  const [description, setDescription] = useState("");
  const { index, link, setShowFolderModal, setData, title } = props;
  const ImageAlgorithmNews = (offset, props) => {
    const [imageSource, setImageSource] = useState("");
    const [loader, setLoader] = useState(false);
    const [controller, setController] = useState(new AbortController());
  
    useEffect(() => {
      setImageSource("");
      setLoader(true);
  
      const timer = setTimeout(() => {
        getPreview();
      }, offset * 1000);
  
      return () => {
        clearTimeout(timer);
        setController(new AbortController());
      };
    }, [title]);
  
    const getPreview = (retryCount = 0, searchQuery = title) => {
      const truncatedTitle = title.split(" ").slice(0, 7).join(" ");
      const initialQuery = searchQuery === title ? truncatedTitle : searchQuery;
  
      setLoader(true);
  
      const bingImagesUrl = `https://www.bing.com/images/search?q=${encodeURIComponent(initialQuery)}`;
  
      fetch(`https://afternoon-island-31692.herokuapp.com/${bingImagesUrl}`)
        .then((imagesResponse) => {
          if (imagesResponse.status === 404 || imagesResponse.redirected) {
            if (retryCount < 10) {
              const words = searchQuery.split(" ");
              const newSearchQuery = words.slice(0, -1).join(" ");
              getPreview(retryCount + 1, newSearchQuery);
            } else {
              throw new Error("Image not found");
            }
          } else {
            return imagesResponse.text();
          }
        })
        .then((imagesHtml) => {
          const $ = cheerio.load(imagesHtml);
          const images = $('img.mimg').filter(function () {
            const width = parseInt($(this).attr('width'));
            const height = parseInt($(this).attr('height'));
            if (width && height) {
              return width/height >= 1.5;
            }
          });
          if (images.length > 0) {
            const imageSource = images.eq(0).attr('src');
            setImageSource(imageSource);
            setLoader(false);
          } else {
            throw new Error("Image not found");
          }
        })
        .catch((err) => {
          console.log(err);
          if (retryCount < 10) {
            const words = searchQuery.split(" ");
            const newSearchQuery = words.slice(0, -1).join(" ");
            getPreview(retryCount + 1, newSearchQuery);
          } else {
            setLoader(false);
            console.error("Failed to find an image after multiple retries");
          }
        });
    };
  
    return { imageSource, loader };
  };
  
  const { imageSource, loader } = ImageAlgorithmNews(props.source?.url, index);


  const openLink = () => {
    window.open(props?.link);
  };
  useEffect(() => {
    const div = document.createElement("div");
    div.innerHTML = props?.description;
    const text = div.textContent || div.innerText || "";
    setDescription(text);
  }, []);

  return (
    <div className="feed-detail-feed-detail-card">
      <div className="feed-detail-feed-detail-card-image">
      {loader &&   <div  style={{marginTop:'-200px'}} className={"news-search-result-loader-container feedloader"}>
              <Loader />
            </div>}
        {!loader && (imageSource ) && (
        <img
          src={imageSource}
          alt=""
          className="feed-detail-feed-image-src"
        />
        )}
        <div style={{borderRadius:'7px'}} className="feed-detail-fake-news-text">
          <span className="feed-detail-fake-news-text1">
            <span >Anti Fake News</span>
          </span>
          <div className="feed-detail-shield-protected41">
            <div className="feed-detail-group">
              <img
                src="/playground_assets/vector5961-gt1q.svg"
                alt="Vector5961"
                className="feed-detail-vector"
              />
              <img
                src="/playground_assets/vector5961-15n.svg"
                alt="Vector5961"
                className="feed-detail-vector01"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="feed-detail-link-title-descrdetail-feed"
      onClick={openLink}
      >
        <span className="feed-detail-text07">
          <span className="feed-detail-text08">
            {props.source.text.length > 25
              ? `${props.source.text.substring(0, 50)}...`
              : props.source.text}
          </span>
        </span>
        <span className="feed-detail-text10">
          <span>
            {props.title.length > 80
              ? `${props.title.substring(0, 80)}...`
              : props.title}
          </span>
        </span>
        <span className="feed-detail-text12">
          <span>{description}</span>
        </span>
        <span style={{marginTop:'7px'}} className="feed-detail-text12">
         {formatDate(props.pubDate)}
        </span>
      </div>
      <div className="feed-detail-share13">
        <div className="feed-detail-group1">
          {/* <img
            src="/playground_assets/vector5961-umvf.svg"
            alt="Vector5961"
            className="feed-detail-vector02"
          />
          <img
            src="/playground_assets/vector5961-rfuc.svg"
            alt="Vector5961"
            className="feed-detail-vector03"
          />
          <img
            src="/playground_assets/vector5961-nld.svg"
            alt="Vector5961"
            className="feed-detail-vector04"
          />
          <img
            src="/playground_assets/vector5961-ntpp.svg"
            alt="Vector5961"
            className="feed-detail-vector05"
          />
          <img
            src="/playground_assets/vector5961-cepc.svg"
            alt="Vector5961"
            className="feed-detail-vector06"
          /> */}
        </div>
      </div>

    </div>
  );
};

export default FeedDetailItem;
