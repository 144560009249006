// PrivacyOptionsWidgetRoleGroupSelection.js
import React, { useContext } from "react";
import "./privacyOptionsWidgetRoleGroupSelection.css";
import { AuthContext } from "../context/authContext";

const PrivacyOptionsWidgetRoleGroupSelection = ({ onGroupSelect, selectedGroupName }) => {
  const { cisoInfo } = useContext(AuthContext);
  const { groups } = cisoInfo;

  const selectGroup = (groupName) => {
    onGroupSelect(groupName);
  };

  const handleRowClick = (e, groupName) => {
    e.preventDefault();
    selectGroup(groupName);
  };

  const renderStatusPoint = (groupName, feature) => {
    const group = groups.find((g) => g.groupName === groupName);
    const value = group.features[feature];
    return value ? <span className="status-point green"></span> : <span className="status-point red"></span>;
  };

  return (
    <div className="privacy-options-table-container">
      <table className="privacy-options-table">
        <thead>
          <tr>
            <th>Role and Group Name <span className="sort-icon">▲</span></th>
            <th>Content Access</th>
            <th>Access Time</th>
            <th>AI Tokens</th>
            <th>Login Block</th>
            <th>Ad block & Tracker</th>
          </tr>
        </thead>
        <tbody>
          {groups.map((group) => (
            <tr
              key={group.groupName}
              onClick={(e) => handleRowClick(e, group.groupName)}
              role="button"
              style={{ cursor: "pointer" }}
            >
            <td className={selectedGroupName === group.groupName ? "selected-row" : ""}>
  {group.groupName.length > 15 ? `${group.groupName.slice(0, 15)}...` : group.groupName}
</td>

              <td>{group.features.contentAccess.securityLevel}</td>
              <td>{renderStatusPoint(group.groupName, "accessTime")}</td>
              <td>{group.features.aiSettings.aiCredits.toFixed(0)}</td>
              <td>{renderStatusPoint(group.groupName, "loginBlock")}</td>
              <td>{renderStatusPoint(group.groupName, "adBlock")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PrivacyOptionsWidgetRoleGroupSelection;
