import PropTypes from "prop-types";
import "./tutte-le-cartelle.css";
import { useHistory } from "react-router-dom";
import PopUpFolder1 from "./pop-up-folder1";
import React, { useEffect, useRef, useState } from "react";


import moment from "moment";
const TutteLeCartelle = (props) => {
  const history = useHistory();
  const { data } = props;
  const [value, setValue] = useState(props.data.name);
  const [disabled, setDisabled] = useState(true);
  const [show, setShow] = useState(false);
  const inputRef = useRef();

  const handlePopUp = () => {
    setShow((prev) => !prev);
  };
  const reName = () => {};

  useEffect(() => {
    if (data?.addNew === true) {
      setDisabled(false);
    }
  }, [JSON.stringify(data)]);
  const translations = {
    en: {
     
      'ppps1segnala': "Name",
      'ppps1segnal2': "Rename",
   
    },
    it: {

  
      'ppps1segnala': 'Nome',
      'ppps1segnala2': 'Rinomina',

    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser

  const ppps1segnal = translate('ppps1segnala', lang);
  const ppps1segnal2 = translate('ppps1segnala2', lang);
  const placeholderValue = props?.data?.addNew ? ppps1segnal : ppps1segnal2;

  return (
    <div style={{ cursor: "pointer" }} className="tutte-le-cartelle-frame30">
      <div className="tutte-le-cartelle-group87">
        <img
          src="/playground_assets/rectangle66762-vc3g.svg"
          alt="Rectangle66762"
          className="tutte-le-cartelle-rectangle6"
        />
        <div className="tutte-le-cartelle-group86">
          <img
            src="/playground_assets/dots16762-5si.svg"
            alt="dots16762"
            className="tutte-le-cartelle-dots1"
            onClick={handlePopUp}
          />
          {show ? (
            <PopUpFolder1
              deleteFolder={() => {
                setShow(false);
                props.deleteFolder(props?.data?._id);
              }}
              rename={reName}
              disable={(val) => {
                setDisabled(val);
                if (val) {
                  inputRef.current.focus();
                }
              }}
              show={setShow}
              handleSharePopUp={() => props.handleSharePopUp(props?.data?._id)}
            />) : ("")}

          <div className="tutte-le-cartelle-frame5140" onClick={() => {
                  if (disabled) {
                    history.push({
                      pathname: "/archivio-dettaglio",
                      state: { data: props.data },
                    });
                  }
                }} >
            <img
              src="/playground_assets/folderblank16762-qrj6.svg"
              alt="FolderBlank16762"
              className="tutte-le-cartelle-folder-blank1"
            />
            <div className="tutte-le-cartelle-text02">
              <div
                className=""
              >
                <input
                  ref={inputRef}
                  autoFocus={true}
                  className={disabled ? "inputDisabled" : "rename"}
                  disabled={disabled}
                  type="text"
                  placeholder={placeholderValue}
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      if (props?.data?.addNew) {
                        props.handleRequest(value, () => {
                          setDisabled(true);
                        });
                      } else {
                        props.handleRequest(value, props?.data?._id, () => {
                          setDisabled(true);
                        });
                      }
                    }
                  }}
                ></input>
                {disabled ? (
                  ""
                ) : (
                  <img
                    width={20}
                    onClick={() => {
                      setDisabled(true);
                    }}
                    className="close1"
                    src="/playground_assets/cancel.png"
                  />
                )}
              </div>
            </div>
            <span className="tutte-le-cartelle-text04">
              <span className="" >{props?.data?.createdAt}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

TutteLeCartelle.defaultProps = {
  rootClassName: "",
};

TutteLeCartelle.propTypes = {
  rootClassName: PropTypes.string,
};

export default TutteLeCartelle;
