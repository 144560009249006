import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SideBar from "../components/side-bar";
import SearchBar1 from "../components/search-bar1";
import ProfileSide from "../components/profile-side";
import TabPowerSearch from "../components/tab-power-search";
import NewsFilter from "../components/news-filter";
import Loader from "../components/loader";
import "./power-search.css";
import { SearchContext } from "../context/search_context";
import Hamburgher from "../components/hamburgher-menu";
import DateFilterVideo from "../components/date-filter-video";
import { useHistory } from "react-router-dom";
import MapComponent from "../components/mapComponent";
 const MapSearch = () => {
  let history = useHistory();
  const {  loader, setTab,  selectedLanguage, searchValue, setSearchValue } = useContext(SearchContext); // Import the selectedLanguage state
   const [location, setLocation] = useState(null);
   
   useEffect(() => {
     setTab("maps"); // Update the active tab to "maps"
console.log(searchValue)
  }, [setTab, selectedLanguage]);
  useEffect(() => {
    setLocation(searchValue);
  }, [searchValue]);
   return (
    <div className="power-search-container">
      <Helmet>
        <title>Maps - Weagle</title>
        <meta property="og:title" content="PowerSearch - Weagle" />
      </Helmet>
      <div className="menumobile">
        <ProfileSide  />
        <span style={{ cursor:'pointer'}} onClick={() => {
              history.push("/");
            }} className="menumobileLogo">weagle</span>
        <Hamburgher /> 
      </div>
      <div className="power-search-container-principale">
        <div className="power-search-container1">
          <SideBar rootClassName="side-bar-root-class-name2"/>
        </div>
        <div className="power-search-container2">
          <SearchBar1    onEnterPress={(searchValue) => {
    setSearchValue(searchValue);
    setEnterKeyPressed(true);
  }} rootClassName="search-bar1-root-class-name" />
        </div>
        <div className="menudesktop">
        <div className="nomenurightprofile">
          <ProfileSide  />
          </div>
        </div>
      </div>
      <div className="power-search-tabs-filters-divNews">
        <div className="map-search-tabs-filters-div">      
          <TabPowerSearch
            type={"maps"}
            rootClassName="tab-power-search-root-class-name"
          />
        </div>
        {/* <DateFilterVideo /> */}
      </div>
      {loader ? <Loader/> : 
        <div style={{marginBottom:'200px'}}>
          {!loader && location && <MapComponent searchValue={location} />}
        </div>
      }
    </div>
  );
};
 export default MapSearch;