import React, { useContext, useState } from "react";

import "./invite-pop-up.css";
import SignUpField from "./signUpField";
import { AuthContext } from "../context/authContext";
import { validateEmail } from "../services";
import Loader from "./loader";
import axios from "axios";
import { useSelector } from 'react-redux';
const InvitePopUp = (props) => {

  const { inviteEmail, setInviteLink, handleInviteLinkRequest } =
    useContext(AuthContext);
  const [value, setValue] = useState("");
  const [loader, setLoader] = useState(false);
  return (
    <div className={props.className}>
      <div className="invite-pop-up-frame5197">
        <div className="invite-pop-up-frame5191">
          <span className="invite-pop-up-text">
            <span>{props.title}</span>
          </span>
          <span className="invite-pop-up-text02">
            <span>
              {props.subtitle}
            </span>
          </span>
          <SignUpField
            label={""}
            placeHolder={"Inserisci email...."}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <div  className="invite-pop-up-frame5195">
            <button
              onClick={() => {
                if (value?.length && validateEmail(value)) {
                  setInviteLink((prev) => [
                    ...new Set([...prev, value.toLowerCase()]),
                  ]);
                  setValue("");
                } else {
                  alert("please enter a valid email");
                }
              }}
              className="invite-pop-up-frame13button" 
            >
              <span className="invite-pop-up-text14">
                <span>Aggiungi</span>
              </span>
            </button>
          </div>
        </div>

        <div className="invite-pop-up-frame5196">
          <div className="invite-pop-up-frame5194">
            <div className="invite-pop-up-frame33">
              <span className="invite-pop-up-text04">
                <span>Email dipendenti</span>
              </span>
            </div>
            <div className="invite-pop-up-frame5193">
              <div className="invite-pop-up-frame31input">
                <div className="invite-pop-up-frame5192">
                {Array.isArray(inviteEmail) ? inviteEmail.map((item) => {
    return (  
      <div className="invite-pop-up-frame5032">
        <span className="invite-pop-up-text06">
          <span>{item}</span>
       <img
  onClick={async () => {
    const newInviteEmails = inviteEmail.filter((el) => el !== item);
    setInviteLink(newInviteEmails);

    // update user status to free
    try {
      const res = await axios.post('https://as-weagle-test-weu-001.azurewebsites.net/updatetofree', { email: item });
      console.log(res.data.message);

      // remove the invited user from the user's record in the database
      const removeRes = await axios.post('https://as-weagle-test-weu-001.azurewebsites.net/removeinviteduser', {  email: item });
      console.log(removeRes.data);

    } catch (e) {
      console.log(e.message);
    }
  }}
  className="closeButtonInvite"
  src="/playground_assets/cancel.png"
  width="15"
/>


        </span>
      </div>
    );
  }) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        {loader && <Loader className={"loaderFeed"} />}
        {!loader && (
          <div style={{marginBottom:'20px'}} className="invite-pop-up-frame5195">
            <button
              onClick={() => handleInviteLinkRequest(setLoader)}
              className="invite-pop-up-frame13button"
            >
              <span className="invite-pop-up-text14">
                <span>Invita dipendenti</span>
              </span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default InvitePopUp;
