import React, { useRef,useState } from "react";

import "./pop-up-folder1.css";
import { useOnClickOutside } from "./useClickOuteSide";

const PopUpFolder1 = (props) => {
  const modalRef = useRef();

  useOnClickOutside(modalRef, () => {
    props.disable(true);
    props.show(false);
  });


  const translations = {
    en: {
      'Rinominacartella': 'Rename folder',
      'Cancellacartella': 'Delete folder',
      'Condividicartella': 'Share folder',
    },
    it: {
      
      'Rinominacartella': 'Rinomina cartella',
      'Cancellacartella': 'Elimina cartella',
      'Condividicartella': 'Condividi cartella',
    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser
  const Rinominacartellaa = translate('Rinominacartella', lang);
  const Cancellacartellaa = translate('Cancellacartella', lang);
  const Condividicartellaa = translate('Condividicartella', lang);




  return (
    <div className="pop-up-folder1-container">
      <div className="pop-up-folder1-frame108">
        <div ref={modalRef} className="pop-up-folder1-filtro-data">
          <div className="pop-up-folder1-frame115">
            <div
              className="pop-up-folder1-group114"
              onClick={() => {
                props.disable(false);
                props.show(false);
              }}
            >
              <div className="pop-up-folder1-frame1151">
                <img
                  src="/playground_assets/peneditwrite13248-w9b.svg"
                  alt="PenEditWrite13248"
                  className="pop-up-folder1-pen-edit-write1"
                />
                <span className="pop-up-folder1-text">
                  <span>{Rinominacartellaa}</span>
                </span>
              </div>
            </div>
            <div
              onClick={() => {
                props.deleteFolder();
              }}
              className="pop-up-folder1-frame111"
            >
              <img
                src="/playground_assets/trashdeletebin213248-es9h.svg"
                alt="trashdeletebin213248"
                className="pop-up-folder1-trashdeletebin21"
              />
              <span className="pop-up-folder1-text02">
                <span>{Cancellacartellaa}</span>
              </span>
            </div>
            {/* <div className="pop-up-folder1-frame112">
              <img
                src="/playground_assets/foldermove13248-dync.svg"
                alt="foldermove13248"
                className="pop-up-folder1-foldermove1"
              />
              <span className="pop-up-folder1-text04">
                <span>Sposta cartella in</span>
              </span>
            </div> */}
            <div className="pop-up-folder1-frame113"
            onClick={()=>{
              // props.setSharePopUp(true)
              props.show(false);
              props.handleSharePopUp()
            }}
            >

              <div className="pop-up-folder1-share11">
                <div className="pop-up-folder1-group">
                  <img
                    src="/playground_assets/vector3248-j7e.svg"
                    alt="Vector3248"
                    className="pop-up-folder1-vector"
                  />
                  <img
                    src="/playground_assets/vector3248-deuc.svg"
                    alt="Vector3248"
                    className="pop-up-folder1-vector1"
                  />
                  <img
                    src="/playground_assets/vector3249-5dk7.svg"
                    alt="Vector3249"
                    className="pop-up-folder1-vector2"
                  />
                  <img
                    src="/playground_assets/vector3249-8moh.svg"
                    alt="Vector3249"
                    className="pop-up-folder1-vector3"
                  />
                  <img
                    src="/playground_assets/vector3249-9n3a.svg"
                    alt="Vector3249"
                    className="pop-up-folder1-vector4"
                  />
                </div>
              </div>
              <span className="pop-up-folder1-text06">
                <span>{Condividicartellaa}</span>
              </span>
            </div>
            {/* <div className="pop-up-folder1-frame109">
              <img
                src="/playground_assets/groupuser123249-nmdk.svg"
                alt="GroupUser123249"
                className="pop-up-folder1-group-user12"
              />
              <span className="pop-up-folder1-text08">
                <span>Accessi cartella</span>
              </span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUpFolder1;
