import React, { useEffect, useState } from "react";
import cheerio from "cheerio";
import PropTypes from "prop-types";
const UseLinkPreview = (link, offset) => {
   

  const [imageSource, setImageSource] = useState("");
  const [loader, setLoader] = useState(false);
  const [icona, setIcona] = useState("");
  const [controller, setController] = useState(new AbortController());
console.log(link)
  useEffect(() => {
    setImageSource("");
    setIcona("");
    setLoader(true);
   
    const timer = setTimeout(() => {
      getPreview();
    }, offset * 1000);
    return () => {
      clearTimeout(timer);
      setController(new AbortController());
     
    };
  }, [link]);

  const getPreview = () => {
    const searchQuery = typeof link === 'string' ? link.split("/").slice(-1)[0].replace("-", " ") : '';
    const googleImagesUrl = `https://www.google.com/search?q=${searchQuery}&tbm=isch`;
    const googleFaviconUrl = `https://www.google.com/s2/favicons?sz=64&domain=${searchQuery}`;

    Promise.all([
      fetch(`https://afternoon-island-31692.herokuapp.com/${googleImagesUrl}`),
      fetch(`https://afternoon-island-31692.herokuapp.com/${googleFaviconUrl}`)
    
    ])
      .then(([imagesResponse, faviconResponse]) => {
        return Promise.all([imagesResponse.text(), faviconResponse.blob()]);
      })
      .then(([imagesHtml, faviconBlob]) => {
        const $ = cheerio.load(imagesHtml);
        const imageSource = $('img[data-src]').eq(0).attr('data-src');
        if (imageSource) {
          setImageSource(imageSource);
        }
        const faviconUrl = URL.createObjectURL(faviconBlob);
        setIcona(faviconUrl);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return { imageSource: imageSource , icona, loader };
};

export default UseLinkPreview;
