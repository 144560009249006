import {takeLatest, put} from 'redux-saga/effects';
import * as types from '../../actions/types';
import {Api} from '../../../utils';
import {endPoints} from '../../../utils';

export function* getCouponRequest() {
  yield takeLatest(types.GET_COUPONS, getCoupon);
}

function* getCoupon(params) {
  try {
    let options = {
      headers: {
        Authorization: 'Bearer ' + params.token,
      },
    };
    let configuration = Object.assign(options);
    let response = yield Api.getAxios(endPoints.getCoupons, configuration);
    if (response?.status) {
      let coupons = [];
      response.data?.data?.company.map((item) => {
        item.coupons.map((couponsItem) => {
          coupons.push({
            ...couponsItem,
            description: item.description,
            name: item.name,
            image: item.image,
            site_url: item.site_url,
          });
        });
      });
      params.cbSuccess(coupons);
    } else {
      params.cbFailure(response);
    }
  } catch (err) {
    console.log('error from get Coupons request saga -- > > >  > ', err);
    params.cbFailure(err.message);
  }
}
