import {takeLatest, put} from 'redux-saga/effects';
import * as types from '../../actions/types';
import {Api} from '../../../utils';
import {endPoints} from '../../../utils';

export function* getFolderRequest() {
  yield takeLatest(types.GET_FOLDER_REQUEST, getFolder);
}

function* getFolder(params) {
  // console.log('[get folder saga]', params);
  try {
    let options = {
      headers: {
        Authorization: 'Bearer ' + params.token,
      },
    };
    let configuration = Object.assign(options);
    let response = yield Api.getAxios(endPoints.getFolder, configuration);
    if (response?.status) {
      yield put({type: types.GET_FOLDER_SUCCESS, data: response.data});
      params.cbSuccess(response);
    } else {
      params.cbFailure(response);
    }
  } catch (err) {
    console.log('error from get folder request saga -- > > >  > ', err);
    params.cbFailure(err.message);
  }
}
