import React, {useContext, useEffect, useState} from "react";

import "./invite-pop-up.css";
import SignUpField from "./signUpField";
import {AuthContext} from "../context/authContext";
import Loader from "./loader";


const InvitedUserPop = (props) => {
  

  const {handleInvitedUserRequest, signupInvitedEnterprise, setPassword, password, isValidInvite, setEmail, email} = useContext(AuthContext)
  const [loader, setLoader] = useState(false);
// Clear localStorage before the component mounts
if (localStorage.getItem('userIds')) {
  setTimeout(() => {
    localStorage.clear();
    window.location.reload();
  }, 200); // 2000 milliseconds = 2 seconds
}

  useEffect(()=>{
    const params = new URLSearchParams(props.location.search);
    const email = params.get('email');
    const id = params.get('id');
    setEmail(email)
    handleInvitedUserRequest(email, id, setLoader)
  },[])
  
  return (
    <div className="invited-pop-up-container">
      <div className="invite-pop-up-frame5197">
        <div className="invite-pop-up-frame5191">
          <span className="invite-pop-up-text">
            <span>Benvenuto su Weagle</span>
          </span>
          <span className="invite-pop-up-text02">
            <span>
               Sei stato invitato da un account Enterprise, inserisci i tuoi dati per diventare membro di Weagle!
            </span>
          </span>
          <SignUpField
            disabled={true}
            label={""}
            placeHolder={"Inserisci email..."}
            value={email}
            onChange={(e) => {}} 
          />
          <SignUpField
            label={""}
            placeHolder={"Password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
        </div>

        {loader && <Loader className={"loaderFeed"} />}
        {
          !loader && <div className="invite-pop-up-frame5195">
            <button onClick={()=> {
              if(isValidInvite){
                signupInvitedEnterprise(setLoader)
              }
            }} className="invite-pop-up-frame13button">
              <span className="invite-pop-up-text14">
                <span>Registrati</span>
              </span>
            </button>
          </div>
        }

        </div>
      </div>
  );
};

export default InvitedUserPop;
