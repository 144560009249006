import React from 'react'

import PropTypes from 'prop-types'

import './welfare-header-aziendale.css'

const WelfareHeaderAziendale = (props) => {


  const translations = {
    en: {
     
      'coupon1': "Section where all the benefits of Weagle's partner companies are included.!",
      'coupon2': 'Shopping',
    },
    it: {

  
      'coupon1': '  Sezione dove sono racchiusi tutti i vantaggi delle azienda partner di Weagle.',
      'coupon2': 'Shopping',
    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser

  const coupon1a = translate('coupon1', lang);
  const coupon2a = translate('coupon2', lang);






  return (
    <div
      className={`welfare-header-aziendale-container ${props.rootClassName} `}
    >
      <div className="welfare-header-aziendale-header-welfare-and-filters">
        <div className="welfare-header-aziendale-group99">
          <span className="welfare-header-aziendale-text">
            <span className="">Welfare &amp; Benefit</span>
          </span>
          <span className="welfare-header-aziendale-text2">
            <span className="">
            {coupon1a}
            </span>
          </span>
          {/* <div className="welfare-header-aziendale-frame1">
            <span className="welfare-header-aziendale-text4">
              <span className="">Tipologia</span>
            </span>
            <img
              src="/playground_assets/vector24931-mmiv.svg"
              alt="Vector24931"
              className="welfare-header-aziendale-vector2"
            />
          </div> */}
          {/* <div className="welfare-header-aziendale-frame4">
            <span className="welfare-header-aziendale-text6">
              <span className="">Categoria</span>
            </span>
            <img
              src="/playground_assets/vector24931-lmr.svg"
              alt="Vector24931"
              className="welfare-header-aziendale-vector21"
            />
          </div> */}
          <img
            src="/playground_assets/giftbox16153-87q-200h.png"
            alt="giftbox16153"
            className="welfare-header-aziendale-giftbox1"
          />
        </div>
      </div>
    </div>
  )
}

WelfareHeaderAziendale.defaultProps = {
  rootClassName: '',
}

WelfareHeaderAziendale.propTypes = {
  rootClassName: PropTypes.string,
}

export default WelfareHeaderAziendale
