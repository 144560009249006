import React, { useState, useEffect, useContext } from "react";
 
import { Helmet } from "react-helmet";
import { AuthContext } from "../context/authContext";
import SideBar from "../components/side-bar";
import SearchBar1 from "../components/search-bar1";
import ProfileSide from "../components/profile-side";
import FeedPreview from "../components/feed-preview";
import { getSourceFeedsRequest } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import "./feed.css";
import moment from "moment/moment";
import Loader from "../components/loader";
import SearchBar2 from "../components/search-bar2";
import { useHistory } from "react-router-dom";
import Hamburgher from "../components/hamburgher-menu";
import NewmenuDesktop from "../components/newmenudesktop";
const Feed = (props) => {
  const dispatch = useDispatch();
  const { auth, folder } = useSelector((state) => state);
  const [news, setNews] = useState({});
  const [loader, setLoader] = useState(true);
  const {
    firstName,
    setFirstName,
    iva,
    setIva,
    email,
    setEmail,
    loading,
    nickName,
    setNickName,
    accountType,
    setAccountType,
    address,
    setAddress,
    common,
    setCommon,
    country,
    setCountry,
    handelProfileEnterprice,
    image,
    setImage,
  } = useContext(AuthContext);

  const history = useHistory();
  useEffect(() => {
    handleGetSourceFeedsRequest();

  }, []);

  const handleGetSourceFeedsRequest = () => {
    const cbSuccess = (response) => {
      Object.keys(response.data.data.results).map((item, index) => {
        response.data.data.results[item].list.sort((a, b) =>
          moment(a.pubDate) < moment(b.pubDate) ? 1 : -1
        );
      });
      setNews(response.data.data.results);
      setLoader(false);
    };
    const cbFailure = (error) => {
      setLoader(false);
    };
    if (auth?.data?.token) {
      setLoader(true);
      dispatch(getSourceFeedsRequest(auth?.data?.token, cbSuccess, cbFailure));
    }
  };




  
  const translations = {
    en: {
      'search-con-prova-text01': 'Discover news selected for you from your favourite sources.',
      'loggedoutfeed': "Sign in or register to start customizing your digital newspaper.",
      'search-con-prova-text08': 'Professional searches made simpler and more efficient thanks to the innovative navigation tools of Power Search.',
      'search-con-prova-text09': 'Delete, archive and discover how to monetize the data generated by your browsing.',
      'search-con-prova-text10': 'Protect your browsing data inside your Data Wallet.',
    },
    it: {

      'search-con-prova-text01': "Scopri le notizie selezionate per te dalla tue fonti preferite.",
      'loggedoutfeed': "Accedi o registrati per iniziare a personalizzare il tuo giornale digitale.",
      'search-con-prova-text08': 'Ricerche professionali più semplici e performanti grazie agli innovativi tools di navigazione del Power Search',
      'search-con-prova-text09': 'Elimina, archivia e scopri come monetizzare i dati generati dalla tua navigazione',
      'search-con-prova-text10': "Proteggi i tuoi dati di navigazione all'interno del tuo Data Wallet."
    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser
  const text01feed = translate('search-con-prova-text01', lang);
  const text02feed = translate('loggedoutfeed', lang);
  const text08 = translate('search-con-prova-text08', lang);
  const text09 = translate('search-con-prova-text09', lang);
  const text10 = translate('search-con-prova-text10', lang);

  return (
    <div className="feed-container">
      <Helmet>
        <title>Feed - Weagle</title>
        <meta property="og:title" content="Feed - Weagle" />
      </Helmet>
      <div className="feed-container-principale">
        <div className="power-search-container1">
          <SideBar rootClassName="side-bar-root-class-name1"></SideBar>
        </div>
        {/* <SearchBar1 rootClassName="power-search-container2"/> */}
        
        <div className="mio-feed">
          <div style={{marginBottom:'20px'}}>
          {/* <SearchBar2 /> */}
          </div>
       
          <h1 style={{fontFamily:"Encode Sans Expanded", fontWeight:"500"}} >B2B Digital Journal</h1>
          <p  style={{fontFamily:"Encode Sans Expanded", fontWeight:"400"}}>{text01feed}</p>
        </div>
            <div  className="menudesktop">
        <div className="newmenucompleto">
         <NewmenuDesktop accountType={accountType} auth={auth}  />
    <ProfileSide  />
 
    </div> 
    </div>
    <div className="menumobile">
    <ProfileSide  />
  <img style={{ cursor:'pointer', marginBottom:'20px'}} onClick={() => {
          history.push("/");
        }} src="/playground_assets/logoweaglenew.svg" alt="weagleLogo" className="menumobileLogo" />

    <Hamburgher /> 
    
    </div>
      </div>

      {auth?.data?.token  ? (
<div className="digitaljournalmaindiv">
{Object.keys(news).length === 0 ? (
        <Loader />
      ) : (
        <div className="feed-container2">
          { }

          {
            loader ? (
              <Loader />

            ) : (
              Object.keys(news).map((item, index) => {
                //console.log('itemss',news[item])
                return (
                  <FeedPreview
                    feedData={news[item]}
                    index={index}
                    rootClassName="feed-preview-root-class-name"
                  />
                );
              })
            
            )}
        </div>
      )}
</div>
) : (
  <span style={{marginTop:'200px',marginBottom:'330px', padding:'20px', textAlign:'center'}}>{text02feed}</span>
)}

      <div className="feed-footer">
        <div className="feed-container4">
          <div className="feed-container5">
            <img
              alt="image"
              src="/playground_assets/shield-user-protection%201.svg"
              className="feed-image"
            />
            <span className="feed-text1">
          {text10}
            </span>
          </div>

          <div className="feed-container6">
            <img
              alt="image"
              src="/playground_assets/earth%2C%20home%2C%20world%201.svg"
              className="feed-image1"
              style={{ marginTop: '66px' }}
            />
            <span className="feed-text2">
          {text08}
            </span>
          </div>

          <div className="feed-container7">
            <img
              alt="image"
              src="/playground_assets/password-edit-protection%201.svg"
              className="feed-image2"
              style={{ marginTop: '72px' }}
            />
            <span className="feed-text3">
            {text09}

              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feed;
