import React, { useContext, useEffect, useState } from "react";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import './installedExtensionWidget.css';
import { AuthContext } from "../context/authContext";
// Register the required chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const InstalledExtensionWidget = () => {
  const percentageChange = '+0%';
  const ExtensionInstalled = 0;
  const maxUsers = 1000;
  const authContext = useContext(AuthContext);
  // Data for the chart
  const data = {
    labels: Array(7).fill(''), // Assuming there are 7 bars like in the image
    datasets: [
      {
        data: [65, 59, 80, 81, 56, 55, 40], // Dummy data
        backgroundColor: '#61F3F3',
        borderColor: '#61F3F3',
        borderWidth: 1,
        borderRadius: 5,
        barThickness: 10, // Adjust bar thickness
      },
    ],
  };

  // Options for the chart
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
        suggestedMax: 0, // Assuming 1000 is the max value for y-axis
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false, // Assuming you want no tooltips as in the picture
      },
    },
    events: [], // Disable all events
  };
  const seatsDifference = authContext.cisoInfo.seatsBase - authContext.cisoInfo.availableSeatsBase;

  return (
    <div className="active-user-widget">
      <div className="widget-header">Total Extension Installed</div>
    
      <div className="widget-chart">
      <div className="widget-percentage">{percentageChange}</div>
        <Bar data={data} options={options} />
      </div>
      <div className="widget-users">
        <span className="widget-current-users">{ExtensionInstalled}</span> <span className="widget-max-users">di {seatsDifference}</span>
      </div>
    </div>
  );
};

export default InstalledExtensionWidget;
