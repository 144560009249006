// PrivacyAccessTimeLimitationComponent.js
import React, { useState, useEffect } from 'react';
import './PrivacyAccessTimeLimitationComponent.css';

const PrivacyAccessTimeLimitationComponent = ({ value, onChange, disabled }) => {
  const [fromTime, setFromTime] = useState(value?.accessTimeHour?.from || '09:00');
  const [toTime, setToTime] = useState(value?.accessTimeHour?.to || '17:30');

  useEffect(() => {
    setFromTime(value?.accessTimeHour?.from || '09:00');
    setToTime(value?.accessTimeHour?.to || '17:30');
  }, [value]);

  const handleToggleChange = () => {
    const newValue = !value.accessTime;
    onChange(newValue, newValue ? { from: fromTime, to: toTime } : undefined);
  };
  
  const handleFromTimeChange = (e) => {
    const newFromTime = e.target.value;
    setFromTime(newFromTime);
    onChange(value.accessTime, { from: newFromTime, to: toTime });
  };
  
  const handleToTimeChange = (e) => {
    const newToTime = e.target.value;
    setToTime(newToTime);
    onChange(value.accessTime, { from: fromTime, to: newToTime });
  };

  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        options.push(
          <option key={time} value={time}>
            {time}
          </option>
        );
      }
    }
    return options;
  };

  return (
    <div className="toggleComponent-popup">
      <div className="toggleComponent-header">
        <h2>Access Time Limitation</h2>
      </div>
      <div className="toggleComponent-setting">
        <span className="toggleComponent-text">Set a time range during which access <br/> to the platform is allowed:</span>
        <div className="toggleComponent-toggle">
          <label className="switch">
            <input
              type="checkbox"
              checked={value.accessTime}
              onChange={handleToggleChange}
              disabled={disabled}
            />
            <span className="slider"></span>
          </label>
          <span className="toggleComponent-status">
            {value.accessTime ? 'Enabled' : 'Disabled'}
          </span>
        </div>
        {value.accessTime && (
          <div className="TimeLimitationChangeDiv">
            <div className="TimeLimitationChangeDivc1">
              <span className="toggleComponent-text">From</span>
              <select id="from-time" value={fromTime} onChange={handleFromTimeChange} disabled={disabled}>
                {generateTimeOptions()}
              </select>
            </div>
            <div className="TimeLimitationChangeDivc2">
              <span className="toggleComponent-text">To</span>
              <select id="to-time" value={toTime} onChange={handleToTimeChange} disabled={disabled}>
                {generateTimeOptions()}
              </select>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PrivacyAccessTimeLimitationComponent;
