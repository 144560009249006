// ContentAccess.jsx
import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import './contentAccess.css';

const ContentAccess = ({ value, onChange, disabled, threats, onThreatsChange, whitelist, onWhitelistChange }) => {
  const [isAdvancedPopupOpen, setIsAdvancedPopupOpen] = useState(false);
  const [isWhitelistPopupOpen, setIsWhitelistPopupOpen] = useState(false);
  const [newLink, setNewLink] = useState('');

  const handleAdvancedSettingsClick = () => {
    setIsAdvancedPopupOpen(true);
  };

  const handleAdvancedPopupClose = () => {
    setIsAdvancedPopupOpen(false);
  };

  const handleThreatChange = (threat, newValue) => {
    onThreatsChange({ ...threats, [threat]: newValue });
  };

  const handleManageWhitelistClick = () => {
    setIsWhitelistPopupOpen(true);
  };

  const handleWhitelistPopupClose = () => {
    setIsWhitelistPopupOpen(false);
  };

  const handleLinkChange = (e) => {
    setNewLink(e.target.value);

  };
  const handleLinkKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAddLink();
    }
  };
  

  const handleAddLink = () => {
    if (newLink.trim() !== '') {
      onWhitelistChange([...whitelist, newLink.trim()]);
      setNewLink('');
    }
  };

  const handleRemoveLink = (index) => {
    const updatedWhitelist = whitelist.filter((_, i) => i !== index);
    onWhitelistChange(updatedWhitelist);
  };

  return (
    <div className="content-access">
      <div className="content-access-header">
        <h2>Content Access</h2>
      </div>
      <div className="content-access-body">
        <p>Set the minimum acceptable security level for access to web content:</p>
        <div className="content-access-security-level">
          <label htmlFor="security-level">Security Level</label>
          <select
            id="security-level"
            value={value}
            onChange={(e) => onChange(parseInt(e.target.value))}
            disabled={disabled}
          >
            {Array.from({ length: 9 }, (_, i) => (i + 1) * 10).map((level) => (
              <option key={level} value={level}>
                {level}
              </option>
            ))}
          </select>
        </div>
        <p>Create or manage a whitelist of websites that are always accessible:</p>
        <div className="content-access-buttons">
          <button onClick={handleAdvancedSettingsClick}>Advanced Settings</button>
          <button onClick={handleManageWhitelistClick}>Manage Whitelist</button>
        </div>
      </div>
      {isAdvancedPopupOpen && (
        <div className="content-access-popup">
          <div className="content-access-popup-content">
            <div className="content-access-popup-header">
            <div className="content-access-popup-headerTitolo">
              <h3>Threats Risk level</h3>
              <h4>Set the accepted risk level for each type of threat identified by the Weagle analysis tool.</h4>
              </div>
              <button className="content-access-popup-close" onClick={handleAdvancedPopupClose}>
               x
              </button>
            </div>
            <div className="content-access-popup-settings">
  {[
    { key: 'obfuscatedjs', label: 'Obfuscated Javascript' },
    { key: 'pixels', label: 'Tracking Pixels' },
    { key: 'https', label: 'SSL Certificate' },
    { key: 'iframe', label: 'Verify <iframe> tag' },
  ].map(({ key, label }) => (
    <div key={key} className="content-access-popup-setting">
      <label className='contentaccesslabel'>{label}:</label>
      <div className="content-access-popup-dots">
        {Array.from({ length: 5 }, (_, i) => (
          <span
            key={i}
            className={`content-access-popup-dot ${
              threats[key] >= i + 1 ? 'active' : ''
            }`}
            onClick={() => handleThreatChange(key, i + 1)}
          />
        ))}
      </div>
    </div>
  ))}
</div>

          </div>
        </div>
      )}
      {isWhitelistPopupOpen && (
        <div className="content-access-popup">
          <div className="content-access-popup-content">
            <div className="content-access-popup-header">
            <div className="content-access-popuptitle">
              <h3>Manage Whitelist</h3>
              <h4>Add url and menage white list for the contents access limits.</h4>
              </div>
              <button className="content-access-popup-close" onClick={handleWhitelistPopupClose}>
               x
              </button>
            </div>
            <div className="content-access-popup-whitelist">
            <input
  type="text"
  value={newLink}
  onChange={handleLinkChange}
  onKeyDown={handleLinkKeyPress}
  placeholder="Add web site to white list"
/>
              <button onClick={handleAddLink}>Add Site</button>
            </div>
            <div className="content-access-popup-whitelist-list-container">
            <ul className="content-access-popup-whitelist-list">
  {whitelist.map((link, index) => (
    <li key={index}>
      {link.length > 30 ? `${link.slice(0, 30)}...` : link}
      <button style={{maxWidth:'10px !important'}} className="content-access-popup-remove" onClick={() => handleRemoveLink(index)}>
        x
      </button>
    </li>
  ))}
</ul>

            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContentAccess;
