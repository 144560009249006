import React, { useContext, useState, useEffect } from "react";
import Loader from "./loader";
import PropTypes from "prop-types";
import cheerio from "cheerio";

const ArchivioDetailFilterItem = (props) => {
  const { index, link, setShowFolderModal, setData, title } = props;


  const ImageAlgorithmNews = (offset, props) => {
    const [imageSource, setImageSource] = useState("");
    const [loader, setLoader] = useState(false);
    const [controller, setController] = useState(new AbortController());
  
    useEffect(() => {
      setImageSource("");
      setLoader(true);
  
      const timer = setTimeout(() => {
        getPreview();
      }, offset * 1000);
  
      return () => {
        clearTimeout(timer);
        setController(new AbortController());
      };
    }, [title]);
  
    const getPreview = (retryCount = 0, searchQuery = title) => {
      const truncatedTitle = title.split(" ").slice(0, 7).join(" ");
      const initialQuery = searchQuery === title ? truncatedTitle : searchQuery;
  
      setLoader(true);
  
      const bingImagesUrl = `https://www.bing.com/images/search?q=${encodeURIComponent(initialQuery)}`;
  
      fetch(`https://afternoon-island-31692.herokuapp.com/${bingImagesUrl}`)
        .then((imagesResponse) => {
          if (imagesResponse.status === 404 || imagesResponse.redirected) {
            if (retryCount < 10) {
              const words = searchQuery.split(" ");
              const newSearchQuery = words.slice(0, -1).join(" ");
              getPreview(retryCount + 1, newSearchQuery);
            } else {
              throw new Error("Image not found");
            }
          } else {
            return imagesResponse.text();
          }
        })
        .then((imagesHtml) => {
          const $ = cheerio.load(imagesHtml);
          const images = $('img.mimg').filter(function () {
            const width = parseInt($(this).attr('width'));
            const height = parseInt($(this).attr('height'));
            if (width && height) {
              return width/height >= 1.5;
            }
          });
          if (images.length > 0) {
            const imageSource = images.eq(0).attr('src');
            setImageSource(imageSource);
            setLoader(false);
          } else {
            throw new Error("Image not found");
          }
        })
        .catch((err) => {
          console.log(err);
          if (retryCount < 10) {
            const words = searchQuery.split(" ");
            const newSearchQuery = words.slice(0, -1).join(" ");
            getPreview(retryCount + 1, newSearchQuery);
          } else {
            setLoader(false);
            console.error("Failed to find an image after multiple retries");
          }
        });
    };
  
    return { imageSource, loader };
  };

  
  const { imageSource, loader } = ImageAlgorithmNews(props.source?.url, index);

  const openLink = () => {
    window.open(props.link);
  };

  return (
    <div style={{cursor:'pointer'}} className="archivio-detail-filter-and-results-frame68" 
    onClick={openLink}
    >
      <div className="archivio-detail-filter-and-results-group66">
        {loader && (
          <div
            className={"news-search-result-loader-container"}
            style={{ marginTop:'-200px' }}
          >
            <Loader />
          </div>
        )}

        {!loader && (
          <img
            src={imageSource }
            alt="Rectangle215961"
            className="archivio-detail-filter-and-results-rectangle21"
          />
        )}

        <div className="archivio-detail-filter-and-results-group24">
          <span className="archivio-detail-filter-and-results-text12">
            <span className="">Anti Fake News</span>
          </span>
          <div className="archivio-detail-filter-and-results-shield-protected41">
            <div className="archivio-detail-filter-and-results-group1">
              <img
                src="/playground_assets/vector5961-8psu.svg"
                alt="Vector5961"
                className="archivio-detail-filter-and-results-vector05"
              />
              <img
                src="/playground_assets/vector5961-nl9.svg"
                alt="Vector5961"
                className="archivio-detail-filter-and-results-vector06"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="archivio-detail-filter-and-results-frame5041">
        <span className="archivio-detail-filter-and-results-text14">
          <span className="">
            {props?.source.text?.slice(0, 40)}
            {props?.source.text?.length > 40 && "..."}
          </span>
        </span>
        <span className="archivio-detail-filter-and-results-text16">
          <span className="">
            {props?.title?.slice(0, 60)}
            {props?.title?.length > 60 && "..."}
          </span>
        </span>
        <span className="archivio-detail-filter-and-results-text18">
          <span className="">
            {props.description?.slice(0, 120)}
            {props.description?.length > 120 && "..."}
          </span>
        </span>
      </div>
      <div className="archivio-detail-filter-and-results-frame5057">
        <div className="archivio-detail-filter-and-results-group67">
          <img
            src="/playground_assets/doneallfill0wght400grad0opsz4875961-ixof.svg"
            alt="doneallFILL0wght400GRAD0opsz4875961"
            className="archivio-detail-filter-and-results-doneall-f-i-l-l0wght400g-r-a-d0opsz487"
          />
          <img
            src="/playground_assets/doneallfill0wght400grad0opsz4885961-f99n.svg"
            alt="doneallFILL0wght400GRAD0opsz4885961"
            className="archivio-detail-filter-and-results-doneall-f-i-l-l0wght400g-r-a-d0opsz488"
          />
        </div>
        {/* <div className="archivio-detail-filter-and-results-share131">
          <div className="archivio-detail-filter-and-results-group2">
            <img
              src="/playground_assets/vector5961-spda.svg"
              alt="Vector5961"
              className="archivio-detail-filter-and-results-vector07"
            />
            <img
              src="/playground_assets/vector5961-lvmm.svg"
              alt="Vector5961"
              className="archivio-detail-filter-and-results-vector08"
            />
            <img
              src="/playground_assets/vector5961-t78f.svg"
              alt="Vector5961"
              className="archivio-detail-filter-and-results-vector09"
            />
            <img
              src="/playground_assets/vector5961-lk7.svg"
              alt="Vector5961"
              className="archivio-detail-filter-and-results-vector10"
            />
            <img
              src="/playground_assets/vector5961-s5ni.svg"
              alt="Vector5961"
              className="archivio-detail-filter-and-results-vector11"
            />

            <img
              src="/playground_assets/vector5961-s5ni.svg"
              alt="Vector5961"
              className="archivio-detail-filter-and-results-vector11"
            />
          </div>
        </div> */}
        <div
          onClick={(event) => {
            event.stopPropagation();
            props.handleDeleteArticleFromFolderRequest(props);
          }}
        > 
          <img
            src="/playground_assets/cancel.png"
            alt="Vector5961"
            className="archivio-detail-filter-and-results-cancel"
          />
        </div>
      </div>
    </div>
  );
};

export default ArchivioDetailFilterItem;
