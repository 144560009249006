import React, {useContext, useRef, useState} from "react";
import { useSelector } from "react-redux";
import "./login-pop-up.css";
import { AuthContext } from "../context/authContext";
import SignUpField from "./signUpField";
import Loader from "./loader";
import ChangePasswordForm from "./changepasswordform";
import { useMsal, useIsAuthenticated } from "@azure/msal-react"; // Import MSAL hooks
import { InteractionType, AuthenticationResult  } from "@azure/msal-browser"; // Import interaction type
import AzureAuthButtonUser from "./azureAuthButtonUser";

const LoginPopUp = (props) => {
  const {
    setSelectedSignUpType,
    email,
    setEmail,
    password,
    setPassword,
    handleLoginRequest,
    loading,
    setShowSignUp
  } = useContext(AuthContext);

  const [showExistingContent, setShowExistingContent] = useState(true);
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);

  const handleCancelClick = () => {
    setShowSignUp(false);
  };

  const handlePasswordDimenticataClick = () => {
    setShowExistingContent(false);
    setShowChangePasswordForm(true);
  };

  const handleGoBackClick = () => {
    setShowExistingContent(true);
    setShowChangePasswordForm(false);
  };

  const { auth } = useSelector((state) => state);

  const translations = {
    en: {
     
      'pppxlog1': "Sign in and protect your online",
      'pppxlog2': 'privacy',
      'pppxlog3': 'Forgot password?',
      'pppxlog4': 'Login',
      'pppxlog5': "You don't have an account, register now!",
      'pppxlog6': "Back",
    },
    it: {

  
      'pppxlog1': 'Accedi e proteggi la tua privacy',
      'pppxlog2': 'online',
      'pppxlog3': 'Password dimenticata?',
      'pppxlog4': 'Accedi',
      'pppxlog5': 'Non hai un account, registrati!',
      'pppxlog6': "Indietro",
    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser

  const pppx1login = translate('pppxlog1', lang);
  const pppx2login = translate('pppxlog2', lang);
  const pppx3login = translate('pppxlog3', lang);
  const pppx4login = translate('pppxlog4', lang);
  const pppx5login = translate('pppxlog5', lang);
  const pppx6login = translate('pppxlog6', lang);

// MSAL integration
const { instance } = useMsal();
const isAuthenticated = useIsAuthenticated();






  return (
    <div className="login-pop-up-container">
      {showExistingContent && (
        <>
          <span className="indietroButton" onClick={handleCancelClick}>
            Indietro
          </span>
          <div className="login-pop-up-frame5147">
            <div className="login-pop-up-frame5145">
              <span className="login-pop-up-text">
                <span>
                  <span>{pppx1login}</span>
                  <br></br>
                  <span>
                  {pppx2login}
                    <span dangerouslySetInnerHTML={{ __html: " " }} />
                  </span>
                </span>
              </span>
            </div>
            <SignUpField
              label={"Email"}
              placeHolder={"mario@lessi.com"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type={"email"}
              autoComplete={"email"}
            />
    <SignUpField
    label={"Password"}
    placeHolder={"******"}
    value={password}
    onChange={(e) => setPassword(e.target.value)}
    type={"password"}
    autoComplete={"password"}
    onKeyDown={(e) => {
        if (e.key === 'Enter') {
            handleLoginRequest();
        }
    }}
/>
            <span
              style={{ marginBottom: "20px", cursor: "pointer" }}
              className="login-pop-up-text15"
              onClick={handlePasswordDimenticataClick}
            >
              <span>{pppx3login}</span>
            </span>
            {loading && <Loader className={"loaderFeed"} />}
            
            {!loading && (
              <>
              
              {/* Login Button start */}
                <div
                  onClick={() => handleLoginRequest()}
                  className="login-pop-up-frame13"
                >
                  <span className="login-pop-up-text13">
                    <span>{pppx4login}</span>
                  </span>
                </div>
                {/* Login Button End */}
              
        <AzureAuthButtonUser />
                <div
                  onClick={() => setSelectedSignUpType("Persona")}
                  className="login-pop-up-frame5146"
                >
                  {/* {!auth?.data?.token && (
                    <div className="loginpasswordforgot">
                      <span className="login-pop-up-text15">
                        <span>{pppx5login}</span>
                      </span>
                    </div>
                  )} */}
                </div>
              </>
            )}
          </div>
        </>
      )}

      {showChangePasswordForm && (
        <>
          <span className="indietrobutton2" onClick={handleGoBackClick}>
           {pppx6login}
          </span>
          
          <ChangePasswordForm onClose={handleGoBackClick} />
        </>
      )}
    </div>
  );
};
export default LoginPopUp;
