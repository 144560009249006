import React from 'react';
import './manageAccountsPopup.css'; // Make sure the CSS file is linked correctly

const ManageAccountsPopup = ({
  selectedItems,
  handleModifyAccount,
  handleAddToGroup,
  handleChangeGroup,
  handleChangeRole,
  handleDeleteAccount
}) => {
  const isModifyAccountDisabled = selectedItems.length !== 1 || selectedItems[0].Sso;

  return (
    <div className="manage-accounts-popup">
      <div
        onClick={isModifyAccountDisabled ? undefined : handleModifyAccount}
        className={`popupAccountManagementdiv ${isModifyAccountDisabled ? 'disabled' : ''}`}
      >
        <img className="popupAccountManagementicon" src="/playground_assets/modifyaccount.svg" alt="modifyaccount"  />
        <button className={`popup-button modify ${isModifyAccountDisabled ? 'disabled' : ''}`}>Modify Account</button>
      </div>
      <div onClick={handleChangeGroup} className='popupAccountManagementdiv'>
        <img className="popupAccountManagementicon" src="/playground_assets/changegroup.svg" alt="modifyaccount"  />
        <button className="popup-button change-group" >Change Group</button>
      </div>
      <div onClick={handleDeleteAccount} className='popupAccountManagementdiv'>
        <img className="popupAccountManagementicon" src="/playground_assets/deleteaccount.svg" alt="modifyaccount"  />
        <button style={{color:"#F25227 !important"}} className="popup-button delete-account" >Delete Account</button>
      </div>
    </div>
  );
};

export default ManageAccountsPopup;