import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import ChatComponent from "./chatComponent";
import mdbStyles from '!!raw-loader!mdb-react-ui-kit/dist/css/mdb.min.css'; 

const MiddlewareChat = (props) => {
  const shadowHostRef = useRef(null);

  useEffect(() => {
    if (shadowHostRef.current) {
      const shadowRoot = shadowHostRef.current.attachShadow({ mode: "open" });

      const styleElement = document.createElement("style");
      styleElement.textContent = mdbStyles;
      shadowRoot.appendChild(styleElement);

      const chatContainer = document.createElement("div");
      chatContainer.style.width = "100%";
      shadowRoot.appendChild(chatContainer);

      ReactDOM.render(<ChatComponent />, chatContainer);
    }
  }, []);

  return <div style={{width:'100%'}} ref={shadowHostRef}></div>;
};

export default MiddlewareChat;
