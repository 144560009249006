import * as TYPES from '../../actions/types';

const initialState = {
  Contabilità: 0,
  'Pubblicità e marketing': 0,
  'Banche e finanza': 0,
  'Attività commerciale': 0,
  Sicurezza: 0,
  Scienza: 0,
  'Ambiente e cambiamento climatico': 0,
  'Programmazione informatica': 0,
  Cronaca: 0,
  Economia: 0,
  Politica: 0,
  Sport: 0,
  Tecnologia: 0,
  Scienze: 0,
  Cultura: 0,
  Gossip: 0,
};
const categoryFilterReducer = (state = initialState, actions) => {
  switch (actions.type) {
    default:
      return state;
  }
};
export default categoryFilterReducer;
