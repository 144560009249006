import React, {useState, useRef, useContext} from 'react'

import '../components/SharePopup.css'
import SignUpField from './signUpField'
import { useOnClickOutside } from "./useClickOuteSide";
import {validateEmail} from "../services";
import axios from "axios";
import {endPoints} from "../utils";
import {useSelector} from "react-redux";
import Loader from "./loader";

const SharePopup = (props) => {
  const modalRef = useRef();
  const { auth } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  useOnClickOutside(modalRef, () => {
    props.setShowSharePopup(false);
  });
  const [value,setValue]=useState('');

  const handleRequest = async () => {
    if(!value || !validateEmail(value)){
      alert('Please enter valid email');
      return;
    }
    setLoading(true);

    console.log(value, props?.folderId)
    const res = await axios.post(
      endPoints.saveFolderInvite,
      { folder_id: props?.folderId, email: value },
      {
        headers: {
          Authorization: `Bearer ${auth?.data?.token}`,
        },
      }
    );
    setLoading(false);
    alert(res?.data?.message);

    if(res?.data?.status ){
      setValue('');
      props.setShowSharePopup(false);
    }

    console.log(res)

  }

  return (
    <div className="share-popup-container">
      <div ref={modalRef} className="share-popup-condividi">
        <div className="share-popup-frame5219">
          <div className="share-popup-frame5216">
            <span className="share-popup-text">
              <span>Con chi vuoi condividere la cartella?</span>
            </span>
            <div className="share-popup-frame5215">
              <SignUpField
              label={"Inserisci le email"}
              placeHolder={"mario@gmail.com"}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              />

              {loading && <Loader className={"loaderFeed"} />}
              {
                !loading && <div onClick={handleRequest} className="share-popup-frame13">
                <span className="share-popup-text06">
                  <span>Invita</span>
                </span>
                </div>
              }

            </div>
          </div>
          {/* <div className="share-popup-frame5218">
            <span className="share-popup-text08">
              <span>Utilizza link condivisibile</span>
            </span>
            <div className="share-popup-frame5217">
              <span className="share-popup-text10">
                <span>https://weagle.link.share.123</span>
              </span>
            </div>
          </div> */}
        </div>
        <div className="share-popup-frame5220">
          {/* <div className="share-popup-document-content-copy1">
            <div className="share-popup-group">
              <img
                src="/playground_assets/vector2466-u0l4.svg"
                alt="Vector2466"
                className="share-popup-vector2"
              />
              <img
                src="/playground_assets/vector2466-zte.svg"
                alt="Vector2466"
                className="share-popup-vector3"
              />
              <img
                src="/playground_assets/vector2466-p85x.svg"
                alt="Vector2466"
                className="share-popup-vector4"
              />
              <img
                src="/playground_assets/vector2466-35r.svg"
                alt="Vector2466"
                className="share-popup-vector5"
              />
            </div>
          </div> */}
          {/* <span className="share-popup-text12">
            <span>Copia link</span>
          </span> */}
        </div>
      </div>
    </div>
  )
}

export default SharePopup
