import React, { useContext, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import './vulnerabilityAnalysisComponent.css';
import DashboardDatePicker from './dashboardDatePicker';
import { AuthContext } from "../context/authContext";
import DashbooardstatisticsGroupPicker from './dashbooardstatisticsGroupPicker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const VulnerabilityAnalysisComponent = () => {
  const { cisoInfo } = useContext(AuthContext);
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: new Date(),
    end: new Date(),
    label: ''
  });
  const [selectedGroup, setSelectedGroup] = useState('All Groups');

  const handleDateRangeSelected = (dateRange) => {
    setSelectedDateRange(dateRange);
  };

  const handleGroupSelected = (group) => {
    setSelectedGroup(group);
  };

  // Get the daily attempted access data within the selected date range and group
  const dailyAttemptedAccessData = cisoInfo.statistics.reduce((acc, group) => {
    if (selectedGroup === 'All Groups' || group.groupName === selectedGroup) {
      const { dailyAttemptedAccess } = group;
      Object.entries(dailyAttemptedAccess || {}).forEach(([date, count]) => {
        const accessDate = new Date(date);
        if (accessDate >= new Date(selectedDateRange.start) && accessDate <= new Date(selectedDateRange.end)) {
          acc.push({ date, count });
        }
      });
    }
    return acc;
  }, []);

  // Prepare the data for the bar chart
  const barData = {
    labels: dailyAttemptedAccessData.map(({ date }) => date),
    datasets: [
      {
        label: 'Access Attempted',
        data: dailyAttemptedAccessData.map(({ count }) => count),
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  // Options for the bar chart
  const barOptions = {
    scales: {
      y: {
        beginAtZero: true,
        precision: 0,
      },
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="vulnerabilityanalysiscontainer">
      <div className="vulnerabilityanalysisTitleDateDiv">
        <span className='vulnerabilityanalysisTitle'>Vulnerability analysis of behaviors</span>
        <DashbooardstatisticsGroupPicker
          groups={cisoInfo.groups}
          onGroupSelected={handleGroupSelected}
        />
        <DashboardDatePicker onDateRangeSelected={handleDateRangeSelected} />
    
      </div>
      <span className='vulnerabilityanalysisGraphDetailtext'>Number of Access Attempted</span>
      <div className="vulnerabilitychartdiv">
        <Bar data={barData} options={barOptions} />
      </div>
    </div>
  );
};

export default VulnerabilityAnalysisComponent;
