import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { useRef } from "react";
import { useContext } from "react";
import { AuthContext } from "../context/authContext";
import "./profile-enterprise.css";
import SignUpField from "./signUpField";
import Loader from "./loader";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import InvitePopUp from "./invite-pop-up";
import { getImg64, allowedFileSize, fileErrorMessage } from "./services";
import CambioPiano from "./cambioPiano";
const ProfileEnterprise = (props) => {
  const {
    firstName,
    setFirstName,
    iva,
    setIva,
    email,
    amount,
    setEmail,
    loading,
    nickName,
    enterprice,
    setNickName,
    accountType,
    setAccountType,
    address,
    setAddress,
    common,
    setCommon,
    country,
    setCountry,
    handelProfileEnterprice,
    image,
    setImage,
    priceEnterprice,
  } = useContext(AuthContext);
  
  const { auth } = useSelector((state) => state);
  const history = useHistory();
  const [img, setImg] = useState("");

  useEffect(() => {
    if (!auth?.data?.token) {
      history.push("/");
    }
  }, []);
  console.log('this is amount:' + amount);

  console.log("tyyhe-->", accountType);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });

  const handlePopupOpen = (event) => {
    setPopupPosition({ x: event.clientX, y: event.clientY });
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };
  return (
    <div className="profile-enterprise-container">
      <div className="profile-enterprise-frame5210">
        <div className="profile-enterprise-frame5204">
          <div className="profile-enterprise-frame5203">
            <span className="profile-enterprise-text">
              <span>
                Profilo
                <label htmlFor="img-status" className="uploadBackgroundImpresa">
                  {image ? (
                    <img
                      src={image}
                      className="uploadBackgroundImpresa"
                      width="100"
                      height="100"
                      alt="img"
                    />
                  ) : (
                    // <p className="uploadpictext">
                    //   <span className="addImgBtn">

                    //   </span> carica logo massimo 500
                    //   Kb
                    // </p>
                    <img
                      src= "https://i.ibb.co/QX0XxG4/user.png" 
                      className="uploadBackgroundImpresa"
                      width="100"
                      height="100" 
                      alt="img"
                    />
                  )}
                </label>
                <input
                  onChange={(event) => {
                    if (event.target?.files[0]) {
                      if (event.target?.files[0]?.size > allowedFileSize) {
                        alert(fileErrorMessage);
                        return;
                      }
                      getImg64(
                        URL.createObjectURL(event.target.files[0]),
                        setImage
                      );
                    }
                  }}
                  style={{ display: "none" }}
                  id="img-status"
                  className="uploadImage"
                  type="file"
                />
              </span>
            </span>
          </div>
          <div className="profile-enterprise-frame5092">
            

{accountType === "free" ? (
    <SignUpField
    label={"Nome"}
    placeHolder={"Weagle s.r.l."}
    value={firstName}
    onChange={(e) => setFirstName(e.target.value)}
  />
        ) : (
          <SignUpField
          label={"Azienda"}
          placeHolder={"Weagle s.r.l."}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        )}
            {accountType === "enterprise" ? (
              <SignUpField
                label={"P.Iva"}
                placeHolder={"000000000000000000"}
                value={iva}
                onChange={(e) => setIva(e.target.value)}
              />
            ) : (
              ""
            )}

        

{accountType === "free" ? (
       <SignUpField
       label={"Email"}
       placeHolder={"mario@weagle.it"}
       value={email}
       onChange={(e) => setEmail(e.target.value)}
     />
        ) : (
          <SignUpField
          label={"Email aziendale"}
          placeHolder={"mario@weagle.it"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        )}
            
            {/* <SignUpField
              label={"Nickname"}
              placeHolder={"@andreabeneventi12"}
              value={nickName}
              onChange={(e) => setNickName(e.target.value)}
            /> */}

            <SignUpField
              disabled={true}
              label={"Tipologia account"}
              placeHolder={"Enterprise - aziendale"}
              value={accountType}
              onChange={(e) => setAccountType(e.target.value)}
            />
          </div>
        </div>
        {/* <div className="profile-enterprise-frame5206">
          <div className="profile-enterprise-frame5205">
            <span className="profile-enterprise-text22">
              
              
              {accountType === "free" ? (
      <span>Learn about premium subscriptions to get enhanced and automatic Privacy.</span>
        ) : (
          <span>Il tuo abbonamento</span>
        )}
            </span>
            <div className="profile-enterprise-frame5095">
              <div className="profile-enterprise-frame5160">
                <div className="profile-enterprise-frame5159">
                  <div className="profile-enterprise-frame5158">
                    <span className="profile-enterprise-text24">
                      <span>Enterprise</span>
                    </span>
                    <div className="profile-enterprise-frame53">
                      <span className="profile-enterprise-text26">
                        <span>€ {amount}</span>
                      </span>
                      <span className="profile-enterprise-text28">
                        <span>/Mese</span>
                      </span>
                    </div>
                  </div>
                  <div className="profile-enterprise-frame5157">
                    <div className="profile-enterprise-frame5156">
                      <div className="profile-enterprise-frame5149">
                        <img
                          alt="checkmarkcircle155541"
                          src="/playground_assets/checkmarkcircle155541-lab.svg"
                          className="profile-enterprise-checkmarkcircle15"
                        />
                        <div className="profile-enterprise-frame32">
                          <div className="profile-enterprise-frame3305">
                            <span className="profile-enterprise-text30">
                              <span>Numero dispositivi</span>
                            </span>
                          </div>
                          <div className="profile-enterprise-frame4">
                            <div className="profile-enterprise-frame371">
                              <span className="profile-enterprise-text32"> 
                                <span>fino a {priceEnterprice.label} </span>  
                              </span>
                              <img
                                alt="Vector27032"
                                src="/playground_assets/vector27032-tbm.svg"
                                className="profile-enterprise-vector2"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="profile-enterprise-frame5150">
                        <img
                          alt="checkmarkcircle115541"
                          src="/playground_assets/checkmarkcircle115541-zjp3.svg"
                          className="profile-enterprise-checkmarkcircle11"
                        />
                        <span className="profile-enterprise-text34">
                          <span>Massima privacy aziendale</span>
                        </span>
                      </div>
                      <div className="profile-enterprise-frame5151">
                        <img
                          alt="checkmarkcircle125541"
                          src="/playground_assets/checkmarkcircle125541-yhf.svg"
                          className="profile-enterprise-checkmarkcircle12"
                        />
                        <span className="profile-enterprise-text36">
                          <span>Garanzia di non tracciamento dati</span>
                        </span>
                      </div>
                      <div className="profile-enterprise-frame5152">
                        <img
                          alt="checkmarkcircle135541"
                          src="/playground_assets/checkmarkcircle135541-cnmu.svg"
                          className="profile-enterprise-checkmarkcircle13"
                        />
                        <span className="profile-enterprise-text38">
                          <span>Navigazione senza pubblicità</span>
                        </span>
                      </div>
                      <div className="profile-enterprise-frame5153">
                        <img
                          alt="checkmarkcircle145541"
                          src="/playground_assets/checkmarkcircle145541-6ydb.svg"
                          className="profile-enterprise-checkmarkcircle14"
                        />
                        <span className="profile-enterprise-text40">
                          <span>Crea fino a 25 cartelle per utente</span>
                        </span>
                      </div>
                      <div className="profile-enterprise-frame5154">
                        <img
                          alt="checkmarkcircle165542"
                          src="/playground_assets/checkmarkcircle165542-87wk.svg"
                          className="profile-enterprise-checkmarkcircle16"
                        />
                        <span className="profile-enterprise-text42">
                          <span>Salvare massimo 50 fonti nel feed</span>
                        </span>
                      </div>
                      <div className="profile-enterprise-frame5155">
                        <img
                          alt="checkmarkcircle175542"
                          src="/playground_assets/checkmarkcircle175542-xp5.svg"
                          className="profile-enterprise-checkmarkcircle17"
                        />
                        <span className="profile-enterprise-text44">
                          <span>Supporto tecnico h24</span>
                        </span>
                      </div>
                    </div>
                    <div className="profile-enterprise-frame91" >
        <span className="profile-enterprise-text46">
          <span>Seleziona piano</span>
        </span>
        <img
          alt="Vector25542"
          src="/playground_assets/vector25542-l7y9.svg"
          className="profile-enterprise-vector21"
        />
      </div>
      {isPopupOpen && ReactDOM.createPortal(
        <div className="popup" style={{ top: popupPosition.y, left: popupPosition.x, width:'1000px', marginLeft:'200px', justifyContent:'center' }}>
          <CambioPiano />
         
        </div>,
        document.body
      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="profile-enterprise-frame5207">
          <div className="profile-enterprise-frame5097">
            <span className="profile-enterprise-text48">
              <span>Dati fatturazione</span>
            </span>
          </div>
          <div className="profile-enterprise-frame70">
            <SignUpField
              label={"Indirizzo"}
              placeHolder={"Via Roma 16, Torino, 32165, Italia"}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <SignUpField
              label={"Comune"}
              placeHolder={"Torino"}
              value={common}
              onChange={(e) => setCommon(e.target.value)}
            />
            <SignUpField
              label={"Nazione"}
              placeHolder={"Italia"}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </div>
        </div> */}
        {loading && <Loader className={"loaderFeed"} />}
        {!loading && (
          <div
            onClick={() => handelProfileEnterprice()}
            className="profile-enterprice-frame"
          >
            <span className="profile-enterprice-text">
              <span>Salva</span>
            </span>
          </div>
        )}
        {accountType === "enterprise" ? (
          <div className="profile-enterprise-frame5209">
            <InvitePopUp className={""} title={""} subtitle={""} />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ProfileEnterprise;
