
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from 'react-helmet'

import SideBar from '../components/side-bar'
import SearchBar1 from '../components/search-bar1'
import ProfileSide from '../components/profile-side'
import FeedPreview from '../components/feed-preview'

import { SearchContext } from "../context/search_context";
import './feed.css'
import FeedDetailComponent from '../components/feed-detail'
import { useHistory } from "react-router-dom";
import Hamburgher from '../components/hamburgher-menu'
import NewmenuDesktop from "../components/newmenudesktop";
const FeedDetail = (props) => {
  const {
    loader,
    searchValue,
    setTab,
    setRecord,
    record,
    paginatedNewsResults,
    accountType,
    auth
  } = useContext(SearchContext);
  let history = useHistory();
  const data = history.location.state?.data
  return (
    <div className="feed-container">
      <Helmet>
        <title>Feed Detail - Weagle</title>
        <meta property="og:title" content="Feed - Weagle" />
      </Helmet>
      <div className="feed-container-principale">
        <div className="power-search-container1">
          <SideBar rootClassName="side-bar-root-class-name1"></SideBar>
        </div>
            <div className="menudesktop">
        <div className="newmenucompleto">
         <NewmenuDesktop accountType={accountType} auth={auth}  />
    <ProfileSide  />
 
    </div> 
    </div>
    <div className="menumobile">
    <ProfileSide  />
  <img style={{ cursor:'pointer', marginBottom:'20px'}} onClick={() => {
          history.push("/");
        }} src="/playground_assets/logoweaglenew.svg" alt="weagleLogo" className="menumobileLogo" />

    <Hamburgher /> 
    
    </div>
      </div> 
      <div className='feedDetail2'>
      <div  className="feed-container2"> 
      <FeedDetailComponent feedDetail={data} />
       
      
      </div>
      </div>
     
    </div>
  )
}

export default FeedDetail
