import React, { useContext, useState } from "react";

import PropTypes from "prop-types";

import "./filtro-categorie.css";
import { SearchContext } from "../context/search_context";
const translations = {
  en: {
   
    'pppb1': "Search keyword",
    'pppb2': 'Business',
    'pppb3': 'Science & tech',
    'pppb4': 'Sport',
    'pppb5': 'Entertainment',
    'pppb6': 'Health',
  },
  it: {


    'pppb1': "Cerca keyword specifica",
    'pppb2': 'Affari',
    'pppb3': 'Scienza e tecnologia',
    'pppb4': 'Sport',
    'pppb5': 'Intrattenimento',
    'pppb6': 'Salute',
  },
};
function translate(key, lang) {
  const italianTranslations = translations.it;

  if (lang === 'it' && italianTranslations[key]) {
    return italianTranslations[key];
  }

  return translations.en[key];
}
const lang = navigator.language.split('-')[0]; // Get the user's language from the browser

const ppps1b = translate('pppb1', lang);
const ppps2b = translate('pppb2', lang);
const ppps3b = translate('pppb3', lang);
const ppps4b = translate('pppb4', lang);
const ppps5b = translate('pppb5', lang);
const ppps6b = translate('pppb6', lang);

const preDefined = [
  ppps2b,
  ppps3b,
  ppps4b,
  ppps5b,
  ppps6b,
];
const FiltroCategorie = (props) => {
  const [input, setInput] = useState("");
  const {
    newsCategoryFilter,
    setNewsCategoryFilter,
    setShowCategoryNewsFilter,
    suggestedNewsCategoryFilter,
    setSuggestedNewsCategoryFilter,
    searchValue,
  } = useContext(SearchContext);







  return (
    <div
      className={`filtro-categorie-filtro-categorie ${props.rootClassName} `}
    >
      {preDefined.map((item) => (
        <div
          onClick={() => {
            setShowCategoryNewsFilter((prev) => !prev);
            setNewsCategoryFilter(item);
          }}
          className="filtro-categorie"
        >
          {item === newsCategoryFilter && (
            <img
              alt="Rectangle569851"
              src="/playground_assets/check.png"
              className="filtro-categorie-checkbox"
            />
          )}
          {item !== newsCategoryFilter && (
            <img
              alt="Rectangle569851"
              src="/playground_assets/rectangle569851-yszj-200h.png"
              className="filtro-categorie-checkbox"
            />
          )}
          <span className="">{item}</span>
        </div>
      ))}

      {suggestedNewsCategoryFilter?.length > 0 && (
        <div>
          <span className="filtro-categorie-categoria-suggerita">
            <span className="">Categoria suggerita:</span>
          </span>
          <div style={{ margin: 10 }} />
          {suggestedNewsCategoryFilter.map((item) => (
            <div
              onClick={() => {
                setShowCategoryNewsFilter((prev) => !prev);
                setNewsCategoryFilter(item);
              }}
              className="filtro-categorie"
            >
              {item === newsCategoryFilter && (
                <img
                  alt="Rectangle569851"
                  src="/playground_assets/approved.png"
                  className="filtro-categorie-checkbox"
                />
              )}
              {item !== newsCategoryFilter && (
                <img
                  alt="Rectangle569851"
                  src="/playground_assets/rectangle569851-yszj-200h.png"
                  className="filtro-categorie-checkbox"
                />
              )}

              <span className="">{item}</span>
            </div>
          ))}
        </div>
      )}

      <input
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            setNewsCategoryFilter(e.target.value);
            setShowCategoryNewsFilter((prev) => !prev);
            setInput("");
            if (searchValue) {
              setSuggestedNewsCategoryFilter((prev) => [
                ...prev,
                e.target.value,
              ]);
            }
          }
        }}
        type="text"
        className={"filtro-categorie-cerca-key-word"}
        placeholder={ppps1b}
      />
    </div>
  );
};

FiltroCategorie.defaultProps = {
  rootClassName: "",
};

FiltroCategorie.propTypes = {
  rootClassName: PropTypes.string,
};

export default FiltroCategorie;
