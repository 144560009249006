import React from "react";

const DropDownEnterprice = (props) => {
  return (
    <div className="plan-sign-up-enterprise">
      <div className="mainEnterprice">
        {props?.enterprice?.map((item) => {
          return (
            <div
              onClick={() => {
                props?.handelSelect(item);
              }}
            >
              <span className="listDropDown">{item?.label}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DropDownEnterprice;
