import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SideBar from "../components/side-bar";
import SearchBar1 from "../components/search-bar1";
import ProfileSide from "../components/profile-side";
import TabPowerSearch from "../components/tab-power-search";
import NewsFilter from "../components/news-filter";
import Loader from "../components/loader";
import "./power-search.css";
import { SearchContext } from "../context/search_context";
import NewsSearchResultList from "../components/news-search-result-list";
import SearchPagination from "../components/search-pagination";
import PopUpSaveLink2 from "../components/pop-up-save-link2";
import { useDispatch, useSelector } from "react-redux";
import { addArticleToFolderRequest } from "../redux/actions";
import Hamburgher from "../components/hamburgher-menu";
import FiltriNewsMobile from '../../src/images/filtrimobile.png'
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import NewmenuDesktop from "../components/newmenudesktop";
import 'react-toastify/dist/ReactToastify.css';
import LoaderSearch from "../components/loadersearch";
import LoaderSearchMobile from "../components/loaderSearchMobile";
const NewsSearch = (props) => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const [showFolderModal, setShowFolderModal] = useState();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedFolder, setSelectedFolder] = useState("");
  const {
    loader,
    searchValue,
    setTab,
    setRecord,
    record,
    paginatedNewsResults,
    accountType
  } = useContext(SearchContext);
  useEffect(() => {
    setTab("news");
  });
  //console.log('Search Value key',searchValue)
  
  const history = useHistory();
  const handleAddArticleToFolderRequest = () => {
    const cbSuccess = (response) => {
      setSelectedFolder(null);
      setShowFolderModal(false);
      // alert("Article added to folder successfully");
      toast.success("Contenuto aggiunto con successo alla cartella", { 
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
      
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    };
    const cbFailure = (error) => {
      setLoading(false);
      alert(error.message);
    };

    const tempData = {
      ...record,
      folder_id: selectedFolder,
      type: 'news'
    };

    if (auth?.data?.token) {
      setLoading(true);
      dispatch(
        addArticleToFolderRequest(
          tempData,
          auth?.data?.token,
          cbSuccess,
          cbFailure
        )
      ); 
    }
  };
  return (
    <div   style={{overflow:"hidden"}} className="power-search-container">
      <Helmet>
        <title>News - Weagle</title>
        <meta property="og:title" content="PowerSearch - Weagle" />
      </Helmet>
      <div class="overlaymenu">
    
    </div>
      <div className="menumobile">
    <ProfileSide  />
  <img style={{ cursor:'pointer', marginBottom:'20px'}} onClick={() => {
          history.push("/");
        }} src="/playground_assets/logoweaglenew.svg" alt="weagleLogo" className="menumobileLogo" />

    <Hamburgher /> 
    
    </div>
      <div className="power-search-container-principale">
        <div className="power-search-container1">
          <SideBar rootClassName="side-bar-root-class-name2" />
        </div>
        
        <div className="power-search-container2">
          <SearchBar1 rootClassName="search-bar1-root-class-name" />
        
        </div>
            <div className="menudesktop">
        <div className="newmenucompleto">
         <NewmenuDesktop accountType={accountType} auth={auth}  />
    <ProfileSide  />
 
    </div> 
    </div>
      </div>
      <div className="power-search-tabs-filters-divNews">
      
      <div className="power-search-tabs-filters-div">
 
        <div className="filtriavanzmobile">
        <TabPowerSearch
          type={"news"}
          rootClassName="tab-power-search-root-class-name"
        />
   
    <div>
    <img     className="filtridesktopnew"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push("/advanced-filters");
          }}  src='/playground_assets/newfilternew.svg' />
    {/* <img     className="filtrimobilebutton"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push("/advanced-filters");
          }} src={FiltriNewsMobile} /> */}
    </div>
          
   
     
    
           </div>
        <NewsFilter rootClassName="search-engine-selection-root-class-name" />
  
      </div>
      
      </div>
   

      <div
        className="power-search-results-div"
        style={{ marginTop: loader ? 0 : -89 }}
      >
              {loader && <LoaderSearch />}
              {loader && <LoaderSearchMobile/>}
              {/* {loader && <Loader/>} */}
        {paginatedNewsResults?.map((item, index) => (
          <NewsSearchResultList
            setData={setRecord}
            showFolderModal={showFolderModal}
            setShowFolderModal={setShowFolderModal}
            index={index}
            {...item}
          />
        ))}
        {paginatedNewsResults?.length === 0 && searchValue?.length === 0 && (
          <div className={"no_data_found_text"}>
            Try searching keywords i.e 'Latest News'
          </div>
        )}
      </div>
      <div className="footersearch">
      <SearchPagination />
      </div>
      {showFolderModal && (
        <PopUpSaveLink2
          selectedFolder={selectedFolder}
          setSelectedFolder={setSelectedFolder}
          folderPress={handleAddArticleToFolderRequest}
          setShow={setShowFolderModal}
        />
      )}
    </div>
  );
};

export default NewsSearch;
