import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./SideBarDashboard.css";
import { AuthContext } from "../context/authContext";

const SideBarDashboard = ({ onNavigate, currentView }) => {
  let history = useHistory();
  let location = useLocation();
  const { cisoInfo, handlePlatformLogout } = useContext(AuthContext);

  const navigate = (path) => {
    onNavigate(path);
  };

  const isActive = (path) => {
    const view = path.split("/").pop();
    return currentView === view ? "active" : "";
  };

  return (
    <div className="sidebar-dashboard">
      <img
        src="/playground_assets/logoweaglenew.svg"
        alt="weagleLogo"
        className="logoWeagleSideBarDashboard"
      />
      <hr className="sidebar-separator" />
      <div className="sidebar-header-dashboard">
        <img
          src="https://i.ibb.co/QX0XxG4/user.png"
          alt="User"
          className="sidebar-avatar-dashboard"
        />
        <div className="sidebarDashboardHeaderNameCompany">
          <h2 className="sidebar-username-dashboard">
            {cisoInfo.cisoName.length > 18
              ? `${cisoInfo.cisoName.slice(0, 18)}...`
              : cisoInfo.cisoName}
          </h2>
          <p className="sidebar-company-dashboard">
            {cisoInfo.companyName.length > 18
              ? `${cisoInfo.companyName.slice(0, 18)}...`
              : cisoInfo.companyName}
          </p>
        </div>
      </div>
      <nav className="sidebar-nav-dashboard">
        <button
          onClick={() => navigate("/dashboard")}
          className={`sidebar-nav-item-dashboard ${isActive("/dashboard")}`}
        >
          <img
            src="/playground_assets/homedashboard.svg"
            alt="Overview"
            className="sidebar-icon"
          />{" "}
          Overview
        </button>
        <hr className="sidebar-separator" />
        <button
          onClick={() => navigate("/accounts")}
          className={`sidebar-nav-item-dashboard ${isActive("/accounts")}`}
        >
          <img
            src="/playground_assets/profiledashboard.svg"
            alt="Accounts"
            className="sidebar-icon"
          />{" "}
          Accounts
        </button>
        <hr className="sidebar-separator" />
        <button
          onClick={() => navigate("/privacy")}
          className={`sidebar-nav-item-dashboard ${isActive("/privacy")}`}
        >
          <img
            src="/playground_assets/shielddashboard.svg"
            alt="Privacy"
            className="sidebar-icon"
          />{" "}
          Privacy Options
        </button>
        <hr className="sidebar-separator" />
        <button
          onClick={() => navigate("/reports")}
          className={`sidebar-nav-item-dashboard ${isActive("/reports")}`}
        >
          <img
            src="/playground_assets/reportdashboard.svg"
            alt="Reports"
            className="sidebar-icon"
          />{" "}
          Reports & Statistics
        </button>
        <hr className="sidebar-separator" />
        <button
          onClick={() => navigate("/integrations")}
          className={`sidebar-nav-item-dashboard ${isActive("/integrations")}`}
        >
          <img
            src="/playground_assets/integrationdashboard.svg"
            alt="Integrations"
            className="sidebar-icon"
          />{" "}
          Integrations
        </button>
        <hr className="sidebar-separator" />
        <button
          onClick={() => navigate("/settings")}
          className={`sidebar-nav-item-dashboard ${isActive("/settings")}`}
        >
          <img
            src="/playground_assets/settingsdashboard.svg"
            alt="Settings"
            className="sidebar-icon"
          />{" "}
          Help & Settings
        </button>
      </nav>
      <button className="LogoutDashboardButton" onClick={handlePlatformLogout}>
        Logout
      </button>
      <hr className="sidebar-separatorBottom" />
    </div>
  );
};

export default SideBarDashboard;