import moment from "moment";
import 'moment/locale/it'; 
// export const baseURL = "https://weagle-backend.onrender.com";
export const baseURL = "https://weagle-backend.onrender.com";
export const key = 'AIzaSyAwVoMYsbkrAmqjRQYPuvPwDcqvsjVKBXY'

export const paginateArray = (list, page, limit) => {
  if (page <= 0 && list.length < limit) {
    return list;
  }
  if (page <= 0 && list.length > limit) {
    return list.slice(0, limit);
  }
  if (list.length < limit) {
    return list;
  }

  if (list.length >= page * limit) {
    return list.slice(page * limit - limit, page * limit);
  }

  return list.slice(page * limit - limit, list.length);
};
export const dates = [
  {
    name: "Ultime 24 ore",
    key: 24,
  },
  {
    name: "Ultimi 7 giorni",
    key: 168,
  },
  { name: "Ultimi 30 giorni", key: 720 },
  { name: "Ultimi 90 giorni", key: 2160 },
];
export const Categories = [
  "Cronaca",
  "Economia",
  "Politica",
  "Sport",
  "Tecnologia",
  "Scienze",
  "Cultura",
  "Gossip",
];

export const langFilterData = {
  US: {
    name: "English",
    languages: ["en"],
  },
  IT: {
    name: "Italian",
    languages: ["it"],
  },
  be: {
    name: "France",
    languages: ["fr"],
  },
  GR: {
    name: "Greek",
    languages: ["el"],
  },
  AR: {
    name: "Spanish",
    languages: ["es"],
  },
  SE: {
    name: "Swedish",
    languages: ["sv"],
  },
  BE: {
    name: "Dutch",
    languages: ["nl"],
  },
  PT: {
    name: "Purtuguese",
    languages: ["pt"],
  },
};

export const isDateSameOrAfter = (date1, date2) => {
  return moment(
    date1.getFullYear() +
      "-" +
      ("0" + (date1.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date1.getDate()).slice(-2)
  ).isSameOrAfter(
    date2.getFullYear() +
      "-" +
      ("0" + (date2.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date2.getDate()).slice(-2)
  );
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(\s)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};


export const formatDate = (pubDate) => {
  if(moment.duration(moment(new Date()).diff(moment(pubDate)))?.asHours() > 24) {
    return moment(pubDate).format('DD/MM/YYYY'); // restituisce la data formattata come "giorno/mese/anno"
  }
  moment.locale('it'); // Imposta la lingua italiana
  return moment(pubDate).fromNow();
};