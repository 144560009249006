import React, { useContext, useEffect, useState } from "react";
import "./overviewWidgets.css";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import NotificationsOverviewComponent from "../components/notificationOverviewComponent";
import ExtensionBlockNotification from "../components/extensionBlockNotification";
import ActiveUserWidget from "../components/activeUserWidget";
import InstalledExtensionWidget from "../components/installedExtensionWidget";
import UnprotectedAccountsWidget from "../components/unprotectedAccountsWidget";
import AddAccountWidget from "../components/addAccountWidget";
import VulnerabilityManagementWidget from "../components/vulnerabilityManagementWidget";
import { useForm } from "@formcarry/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OverviewWidgets = (props) => {
  const authContext = useContext(AuthContext);
  const accountsLeft = 100;
  const vipsLeft = 23;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { state, submit } = useForm({ id: "YpEFQx7LrG" });

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await submit(event);
    closePopup();
    toast.success("Request for new plan sended!");
  };
  return (
    <div className="overviewWidgetsContainer">
        <div className="WelcomeOverviewDiv">
            <div className="WelcomeOverviewDivc1">
            <span className="overviewWidgetWelcome">WELCOME</span>
        <span className="overviewWidgetName">  {authContext.cisoInfo.cisoName.length > 30
    ? `${authContext.cisoInfo.cisoName.slice(0, 30)}...`
    : authContext.cisoInfo.cisoName}</span>
            </div>
            <div className="WelcomeOverviewDivc2">
            <div className="WelcomeOverviewDivc2c1">
            <div className="WelcomeOverviewDivcSeats">
            <div className="WelcomeOverviewDivc2c11">
            <span className="overviewWidgetAccountsInfo2">{authContext.cisoInfo.availableSeatsBase}/</span>
            </div>
            <div className="WelcomeOverviewDivc2c12">
            <span className="overviewWidgetAccountsInfo">{authContext.cisoInfo.seatsBase}</span>
            </div>
            </div>
            <span className="overviewWidgetAccountsInfoSpan2">accounts left</span>
            <div className="overviewWidgetAccountsInfospanDiv">
            <span className="overviewWidgetAccountsInfoSpan">&nbsp;</span>
           
            </div>
            
</div>

<div className="overviewWidgetWelcomeDivide">

</div>

{/* <div className="WelcomeOverviewDivc2c2">
<span className="overviewWidgetAccountsInfo">{vipsLeft}</span>
            <div className="overviewWidgetAccountsInfospanDiv">
            <span className="overviewWidgetAccountsInfoSpan">VIP</span>
            <span className="overviewWidgetAccountsInfoSpan2">accounts left</span>
            </div>
</div> */}
</div>
       
        </div>

          <div className="overviewWidgetsContainerc1">
          <NotificationsOverviewComponent />
<ExtensionBlockNotification />
          </div>
          <div className="overviewWidgetsContainerc2">
          <div className="overviewWidgetsContainerc2c">
          <div className="overviewWidgetsContainerc2c1">
          <ActiveUserWidget />
          <UnprotectedAccountsWidget />
   </div>
   <div className="overviewWidgetsContainerc2c2">
   <InstalledExtensionWidget />
   <AddAccountWidget openPopup={openPopup} />
   </div>

   </div>
   <VulnerabilityManagementWidget /> 
          </div>
          {isPopupOpen && (
        <div className="addAccountPopup">
          <div className="addAccountPopupContent">
            <h2 className="addAccountPopupTitle1">Add Account</h2>
            <h4 className="addAccountPopupTitle2">If your company needs to activate new Weagle accounts, fill out the request form below, and you will receive the commercial offer for activation within 24 hours.</h4>
            <form className="addAccountForm" onSubmit={handleSubmit}>
              <div className="addAccountFormGroup">
               
                <input
                  type="text"
                  placeholder="Company Name"

                  name="company_name"
                  className="addAccountFormInput"
                  required
                />
              </div>
              <div className="addAccountFormGroup">
              
                <input
                  type="text"
                  id="reference_person"
                  name="reference_person"
                  className="addAccountFormInput"
                  placeholder= "Reference Person"

                />
              </div>
              <div className="addAccountFormGroup2">
                <label htmlFor="base_account" className="addAccountFormLabel">
                  Base Account to Activate
                </label>
                <input
                  type="text"
                  id="base_account"
                  name="base_account"
                  className="addAccountFormInput"
                  required
                />
              </div>
              <div className="addAccountFormGroup2">
                <label htmlFor="vip_account" className="addAccountFormLabel">
                  VIP Account to Activate
                </label>
                <input
                  type="text"
                  id="vip_account"
                  name="vip_account"
                  className="addAccountFormInput"
                  required
                />
              </div>
              <div className="addAccountFormActions">
                <button
                  type="button"
                  className="addAccountCancelButton"
                  onClick={closePopup}
                >
                  Cancel
                </button>
                <button type="submit" className="addAccountSubmitButton">
                  Send request
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

</div>
 
  );
};

export default OverviewWidgets;
