import React, { useState } from "react";
import "./user-sign-up.css";

const SignUpField = (props) => {
  const onFocus = () => {
    setClassName("user-sign-up-frame311");
  };
  const onBlur = () => {
    setClassName("user-sign-up-frame31");
  };

  const [className, setClassName] = useState("user-sign-up-frame31");
  return (
    <div
      {...(props.containerStyle ? { style: props.containerStyle } : {})}
      className="user-sign-up-frame32"
    >
      <div className="user-sign-up-frame5142">
        <div className="user-sign-up-frame33">
          <span className="user-sign-up-text">
            <span>{props.label}</span>
          </span>
        </div>
        <div
          {...(props.style ? { style: props.style } : {})}
          className={`${className}`}
        >
   <span className="user-sign-up-text02">
                <input
                    disabled={props?.disabled}
                    value={props?.value}
                    onChange={props?.onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    className="handelInput"
                    placeholder={props.placeHolder}
                    type={props.type}
                    autoComplete={props.autoComplete}
                    onKeyDown={props.onKeyDown} // Make sure to use props.onKeyDown here
                />
            </span>
        </div>
      </div>
    </div>
  );
};

export default SignUpField;
