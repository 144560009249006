import React, { useContext, useEffect, useState } from "react";
import "./integrations.css";

import "react-toastify/dist/ReactToastify.css";

const Integrations = (props) => {

  return (
    <div className="integrationsContainer">
     <div>
          <img src="/playground_assets/underConstruction.svg" alt="under Construction" />
        </div>
</div>
 
  );
};

export default Integrations;
