import React,{useContext} from "react";
import { Helmet } from "react-helmet";
import SideBar from "../components/side-bar";
import ProfileSide from "../components/profile-side";
import "./power-search.css";
import ArchivioComponentDetail from "../components/archivio-component-detail";
import {useHistory, useLocation } from "react-router-dom";
import { SearchContext } from "../context/search_context";
import Hamburgher from "../components/hamburgher-menu";
import NewmenuDesktop from "../components/newmenudesktop";
const ArchivioDetail = (props) => {
  const location = useLocation();
  const { tab,    accountType,
    auth } =
  useContext(SearchContext);
  console.log("following are detail data ", location?.state?.data?.articles);
  const history = useHistory();
  return (
    <div className="power-search-containerArchivioDettaglio">
      <Helmet>
        <title>Archivio - Weagle</title>
        <meta property="og:title" content="PowerSearch - Weagle" />
      </Helmet>
      <div className="menumobile">
    <ProfileSide  />
  <img style={{ cursor:'pointer', marginBottom:'20px'}} onClick={() => {
          history.push("/");
        }} src="/playground_assets/logoweaglenew.svg" alt="weagleLogo" className="menumobileLogo" />

    <Hamburgher /> 
    
    </div>
      <div className="power-search-container-principale">
        <div className="power-search-container1">
          <SideBar rootClassName="side-bar-root-class-name2"/>
        </div>
       
        <div className="menudesktop">
        <div className="newmenucompleto">
         <NewmenuDesktop accountType={accountType} auth={auth}  />
    <ProfileSide  />
 
    </div> 
    </div>
      </div>
      <div className="power-search-results-div">
        <ArchivioComponentDetail
          folder={location?.state?.data}
          list={location?.state?.data?.articles}
        />
      </div>
      {/* <Pagination /> */}
    </div>
  );
};

export default ArchivioDetail;
