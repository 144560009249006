import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import SideBar from "../components/side-bar";
import SearchBar1 from "../components/search-bar1";
import ProfileSide from "../components/profile-side";
import TabPowerSearch from "../components/tab-power-search";
import "./power-search.css";
import { SearchContext } from "../context/search_context";
import DateFilterVideo from "../components/date-filter-video";

import ProductSearchResult from "../components/product-search-result";
const data = [{ here: "" }, { here: "" }, { here: "" }];
const ProdottiSearch = (props) => {
  const { loader, searchValue, setTab, paginatedNewsResults } =
    useContext(SearchContext);
  //console.log('search values links',searchValue)
  useEffect(() => {
    setTab("prodotti");
  });

  return (
    <div className="power-search-container">
      <Helmet>
        <title>Social - Weagle</title>
        <meta property="og:title" content="PowerSearch - Weagle" />
      </Helmet>
      <div className="power-search-container-principale">
        <div className="power-search-container1">
          <SideBar rootClassName="side-bar-root-class-name2"/>
        </div>
        <div className="power-search-container2">
          <SearchBar1 rootClassName="search-bar1-root-class-name" />
        </div>
        <ProfileSide rootClassName="profile-side-root-class-name1"/>
      </div>
      <div className="product-search-tabs-filters-div">
        <TabPowerSearch
          type={"prodotti"}
          rootClassName="tab-power-search-root-class-name"
        />

        <DateFilterVideo/>
      </div>

      <div
        className="power-search-results-div"
        // style={{ marginTop: loader ? 0 : -89 }}
      >
         {data.map((item) => {
          return ( <ProductSearchResult />);
        })}
        
      </div>
      {/* <Pagination /> */}
    </div>
  );
};

export default ProdottiSearch;
