import React, { useState, useContext } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LogarithmicScale,
  ArcElement
} from 'chart.js';
import './risktreatmentComponent.css';
import DashboardDatePicker from './dashboardDatePicker';
import { AuthContext } from "../context/authContext";
import DashbooardstatisticsGroupPicker from './dashbooardstatisticsGroupPicker';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LogarithmicScale
);

const RisktreatmentComponent = () => {
  const { cisoInfo } = useContext(AuthContext);
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: new Date(),
    end: new Date(),
    label: ''
  });
  const [selectedGroup, setSelectedGroup] = useState('All Groups');

  const handleDateRangeSelected = (dateRange) => {
    setSelectedDateRange(dateRange);
  };

  const handleGroupSelected = (group) => {
    setSelectedGroup(group);
  };

  const getRiskFrequencyData = () => {
    const riskLevels = ['veryHigh', 'high', 'medium', 'low', 'free'];
    const riskFrequencyData = cisoInfo.statistics.reduce((acc, group) => {
      if (selectedGroup === 'All Groups' || group.groupName === selectedGroup) {
        const { dailyRiskFrequency } = group;
        if (dailyRiskFrequency) {
          Object.entries(dailyRiskFrequency).forEach(([date, riskData]) => {
            const riskDate = new Date(date);
            if (riskDate >= new Date(selectedDateRange.start) && riskDate <= new Date(selectedDateRange.end)) {
              riskLevels.forEach((riskLevel) => {
                acc[riskLevel] = (acc[riskLevel] || 0) + (riskData[riskLevel] || 0);
              });
            }
          });
        }
      }
      return acc;
    }, {});

    return riskLevels.map((riskLevel) => riskFrequencyData[riskLevel] || 0);
  };

  const doughnutChartData = {
    labels: ['Very High', 'High', 'Medium', 'Low', 'Free'],
    datasets: [
      {
        label: 'Risk',
        data: getRiskFrequencyData(),
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF'
        ],
        hoverOffset: 4
      }
    ]
  };

  const doughnutChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: false,
        text: 'Risk',
      },
    },
  };

  return (
    <div className="risktreatmentcontainer">
      <div className="risktreatmentTitleDateDiv">
        <span className='risktreatmentTitle'>Risk Treatment</span>
        <DashbooardstatisticsGroupPicker
          groups={cisoInfo.groups}
          onGroupSelected={handleGroupSelected}
        />
        <DashboardDatePicker onDateRangeSelected={handleDateRangeSelected} />
     
      </div>

      <div className='doubledivtitledivtRisk'>
        <span className='risktreatmentGraphDetailtext'>Risk frequency per level</span>
        <span className='risktreatmentGraphDetailtext'>Percentage of accesses per risk level</span>
      </div>

      <div className="doublegraphdivRisk">
        <div className="doublegraphdivc1Risk">
          <Doughnut data={doughnutChartData} options={doughnutChartOptions} />
        </div>
        <div className="doublegraphdivc2Risk">
          <Doughnut data={doughnutChartData} options={doughnutChartOptions} />
        </div>
      </div>

    </div>
  );
};

export default RisktreatmentComponent;
