import React from 'react'

import PropTypes from 'prop-types'
import { useForm } from '@formcarry/react';
import './cambio-piano.css'
 
const CambioPiano = (props) => {
  
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser
   // Call the "useForm" hook in your function component
   const {state, submit} = useForm({
    id: 'YpEFQx7LrG'
  });
  if (state.submitted) {
    return <div style={{fontFamily:"Encode Sans Expanded", margin:'100px', fontSize: '20px'}}>Grazie per aver segnalato il problema, i nostri sviluppatori sono già al lavoro!</div>;
  }
  return (
    <div id="contattaci" className="contatti-integrato-contatti-integrato-cambioPiano">
      <div className="contatti-integrato-free-demo-div">
        <span className="contatti-integrato-text">{props.text}</span>
        <span className="contatti-integrato-text01">{props.text1}</span>
      </div>
     
      <div className="contatti-integrato-contact-form">
      <form onSubmit={submit}>
        <div className="contatti-integrato-contact-form-c2">
          <div className="contatti-integrato-form-frame3">
            <div className="contatti-integrato-frame33">
              <span className="contatti-integrato-text02">{props.text3}</span>
            </div>
            
            <input
              id="email" type="email" name="email"
              placeholder={props.Frame31input_placeholder}
              className="contatti-integrato-frame31input"
            />
          </div>
          {/* <div className="contatti-integrato-form-frame2">
            <div className="contatti-integrato-frame331">
              <span className="contatti-integrato-text03">{props.text4}</span>
            </div>
            <input
            id="numero" type="number" name="numero"
             
              placeholder={props.Frame31input_placeholder1}
              className="contatti-integrato-frame31input1"
            />
          </div> */}
          <div className="contatti-integrato-form-frame1">
            <div className="contatti-integrato-frame332">
              <span className="contatti-integrato-text04">{props.text5}</span>
            </div>
            <input
            id="azienda"  name="azienda"
              type="text"
              placeholder={props.Frame31input_placeholder2}
              className="contatti-integrato-frame31input2"
            />
          </div>
          <div className="contatti-integrato-form-frame4">
            <div className="contatti-integrato-frame333">
              <span className="contatti-integrato-text05">{props.text6}</span>
            </div>
            <input
             id="messaggio"  name="messaggio"
              type="text"
              placeholder={props.Frame31input_placeholder3}
              className="contatti-integrato-frame31input3"
            />
          </div>
          <button  style={{cursor:'pointer'}} type="submit" className="contatti-integrato-buttonbutton">
            <span className="contatti-integrato-text06">{props.text2}</span>
          </button>
          
        </div>
        </form>
        <div className="contatti-integrato-container">
          <div className="contatti-integrato-contact-form-c1">
            <div className="contatti-integrato-free-demo-div1">
              <span className="contatti-integrato-text07">{props.text7}</span>
              <span className="contatti-integrato-text08">{props.text8}</span>
            </div>
            <div className="contatti-integrato-contact-info-div">
              <div className="contatti-integrato-contact-info-div-c1">
                <span className="contatti-integrato-text09">
                  <span>weagle</span>
                </span>
                <span className="contatti-integrato-text11">
                  <span>
                    <span>Weagle srl</span>
                    <br></br>
                    <span>Via Camillo Benso C Di Cavour</span>
                    <br></br>
                    <span>7420094 Corsico (MI)</span>
                    <br></br>
                    <span>P.IVA: 11492050965</span>
                  </span>
                </span>
              </div>
              <div className="contatti-integrato-contact-info-div-c2">
                <div className="contatti-integrato-richieste-amm-div">
                  <span className="contatti-integrato-text20">
                    <span>Per richieste amministrative contattare</span>
                  </span>
                  <div className="contatti-integrato-group1000001662">
                    <span className="contatti-integrato-text22">
                      <span>
                        info@weagle.it &gt;
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                    </span>
                    <img
                      alt={props.Line2_alt}
                      src={props.Line2_src}
                      className="contatti-integrato-line2"
                    />
                  </div>
                </div>
                <div className="contatti-integrato-tech">
                  <span className="contatti-integrato-text24">
                    <span>Per supporto dal reparto tech contattare</span>
                  </span>
                  <div className="contatti-integrato-group10000016621">
                    <span className="contatti-integrato-text26">
                      <span>
                        digital.weagle@gmail.com &gt;
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                    </span>
                    <img
                      alt={props.Line2_alt1}
                      src={props.Line2_src1}
                      className="contatti-integrato-line21"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CambioPiano.defaultProps = {
  text: 'Segnala un problema al nostro team',
  text1:
    'Segnala un problema tecnico o dai un consiglio sullo sviluppo di una nuova funzionalità.',
  Frame31input_placeholder: 'mario@weagle.it',
  Frame31input_placeholder1: '+39 349 1234567',
  Frame31input_placeholder2: 'Weagle s.r.l.',
  Frame31input_placeholder3: 'Messaggio',
  text2: 'Invia richiesta',
  text3: 'Mail aziendale',
  text4: 'Telefono',
  text5: 'Azienda',
  text6: 'Descrizione del problema riscontrato',
  text7: 'Segnala un problema al nostro team',
  text8:
    'Segnala un problema tecnico o dai un consiglio sullo sviluppo di una nuova funzionalità.',
  Line2_src: '/playground_assets/line23711-v1e8.svg',
  Line2_alt: 'Line23711',
  Line2_src1: '/playground_assets/line23711-vl2v.svg',
  Line2_alt1: 'Line23711',
}

CambioPiano.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
  Frame31input_placeholder: PropTypes.string,
  Frame31input_placeholder1: PropTypes.string,
  Frame31input_placeholder2: PropTypes.string,
  Frame31input_placeholder3: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  text4: PropTypes.string,
  text5: PropTypes.string,
  text6: PropTypes.string,
  text7: PropTypes.string,
  text8: PropTypes.string,
  Line2_src: PropTypes.string,
  Line2_alt: PropTypes.string,
  Line2_src1: PropTypes.string,
  Line2_alt1: PropTypes.string,
}

export default CambioPiano
