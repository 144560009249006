import {takeLatest, put} from 'redux-saga/effects';
import * as types from '../../actions/types';
import {Api} from '../../../utils';
import {endPoints} from '../../../utils';

export function* addFolderRequest() {
  yield takeLatest(types.ADD_FOLDER_REQUEST, addFolder);
}

function* addFolder(params) {
  console.log('[add folder saga]', params);
  try {
    let options = {
      headers: {
        Authorization: 'Bearer ' + params.token,
      },
    };
    let configuration = Object.assign(options);
    let response = yield Api.postAxios(
      endPoints.addFolder,
      params.params,
      configuration,
    );
    console.log('add folder response', response);
    if (response?.status) {
      params.cbSuccess(response);
    } else {
      params.cbFailure(response);
    }
    // yield put({type: types.SET_PROFILE_DATA, data: response.data});
  } catch (err) {
    console.log('error from add folder request saga -- > > >  > ', err);
    params.cbFailure(err.message);
  }
}
