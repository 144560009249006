import React, { useContext } from 'react';
import './unprotectedAccountsWidget.css';
import { AuthContext } from "../context/authContext";

const UnprotectedAccountsWidget = () => {
  const { cisoInfo } = useContext(AuthContext);
  // const unprotectedNumber = cisoInfo.seatsBase - cisoInfo.availableSeatsBase;
  const unprotectedNumber =  cisoInfo.availableSeatsBase;
  return (
    <div className="unprotectedWidgetContainer">
      <span className="unprotectedWidgetNumber">{unprotectedNumber}</span>
      <span className="unprotectedWidgetText">accounts still unprotected</span>
    </div>
  );
};

export default UnprotectedAccountsWidget;
