import {takeLatest} from 'redux-saga/effects';
import * as types from '../../actions/types';
import {Api} from '../../../utils';
import {endPoints} from '../../../utils';

export function* addReceiptRequest() {
  yield takeLatest(types.ADD_RECEIPT_REQUEST, addReceipt);
  yield takeLatest(types.USER_API_RECEIPT_REQUEST, getReceipt);
}

function* addReceipt(params) {
  console.log('[add receipt saga]', params);
  try {
    let options = {
      headers: {
        Authorization: 'Bearer ' + params.token,
      },
    };
    let configuration = Object.assign(options);
    let response = yield Api.postAxios(
      endPoints.addReceipt,
      params.params,
      configuration,
    );
    console.log('add receipt response', response);
    if (response?.status) {
      params.cbSuccess(response);
    } else {
      params.cbFailure(response);
    }
    // yield put({type: types.SET_PROFILE_DATA, data: response.data});
  } catch (err) {
    console.log('error from add receipt request saga -- > > >  > ', err);
    params.cbFailure(err.message);
  }
}

function* getReceipt(params) {
  console.log('[get receipt saga]', params);
  try {
    let options = {
      headers: {
        Authorization: 'Bearer ' + params.token,
      },
    };
    let configuration = Object.assign(options);
    let response = yield Api.getAxios(endPoints.getUserReceipt, configuration);
    console.log('user receipt response', response);
    if (response?.status) {
      params.cbSuccess(response);
    } else {
      params.cbFailure(response);
    }
    // yield put({type: types.SET_PROFILE_DATA, data: response.data});
  } catch (err) {
    console.log('error from user receipt request saga -- > > >  > ', err);
    params.cbFailure(err.message);
  }
}
