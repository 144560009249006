import {takeLatest, put} from 'redux-saga/effects';
import * as types from '../../actions/types';
import {Api} from '../../../utils';
import {endPoints} from '../../../utils';

export function* updateUserTypeRequest() {
  yield takeLatest(types.UPDATE_USER_TYPE, updateUserType);
}

function* updateUserType(params) {
  console.log('[updateUserType saga]', params);
  try {
    let options = {
      headers: {
        Authorization: 'Bearer ' + params.token,
      },
    };
    let configuration = Object.assign(options);
    let response = yield Api.postAxios(
      endPoints.authUpdateUserType,
      params.params,
      configuration,
    );
    if (response?.status) {
      yield put({type: types.SET_PROFILE_DATA, data: response.data});
      params.cbSuccess(response);
    } else {
      params.cbFailure(response);
    }
  } catch (err) {
    console.log('error from login request saga -- > > >  > ', err);
    params.cbFailure(err.message);
  }
}
