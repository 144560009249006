import React, { useContext, useState, useRef, useEffect } from "react";
import './manageGroupsPopUp.css';
import { AuthContext } from "../context/authContext";
 
const ManageGroupsPopUp = ({ closePopup }) => {
  const { cisoInfo, deleteGroups, createGroup } = useContext(AuthContext);
  const { groups } = cisoInfo;

  const [selectedGroups, setSelectedGroups] = useState([]);
  const [newGroupName, setNewGroupName] = useState("");
  const [showCreateGroupInput, setShowCreateGroupInput] = useState(false);

  const createGroupInputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (createGroupInputRef.current && !createGroupInputRef.current.contains(event.target)) {
        setShowCreateGroupInput(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleGroupSelectionChange = (groupName) => {
    setSelectedGroups(prevSelectedGroups =>
      prevSelectedGroups.includes(groupName)
        ? prevSelectedGroups.filter(name => name !== groupName)
        : [...prevSelectedGroups, groupName]
    );
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedGroups(groups.map(group => group.groupName));
    } else {
      setSelectedGroups([]);
    }
  };

  const handleDeleteGroups = () => {
    deleteGroups(selectedGroups);
    setSelectedGroups([]);
  };

  const handleConfirm = () => {
    closePopup();
  };

  const handleCreateGroup = () => {
    if (newGroupName.trim() !== "") {
      createGroup(newGroupName.trim());
      setNewGroupName("");
      setShowCreateGroupInput(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleCreateGroup();
    }
  };

  const isAllSelected = groups.length > 0 && selectedGroups.length === groups.length;

  return (
    <div className="group-modal-overlay">
      <div className="group-modal">
        <div className="group-modal-header">
          <div className="group-modal-headerh1">
            <h2>Manage Groups</h2>
          </div>
          <div className="group-modal-header-actions">
            <button className="btn-manageGroupModal" onClick={() => setShowCreateGroupInput(true)}>Create</button>
            <button className="btn-manageGroupModal" onClick={handleDeleteGroups}>Delete</button>
          </div>
        </div>
        {showCreateGroupInput && (
          <div ref={createGroupInputRef} className="create-group-input">
            <input
              className="create-group-input"
              type="text"
              value={newGroupName}
              onChange={(e) => setNewGroupName(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Enter group name"
            />
            <button className="managegroup-create-btn" onClick={handleCreateGroup}>Create Group</button>
          </div>
        )}
        <div className="account-list-widgetSettingsGroupModal">
          <table className="account-tableSettingsGroupModal">
            <thead>
              <tr>
                <th><input type="checkbox" className="noiconthAccountListSettingsGroupModal" id="select-all" onChange={handleSelectAll} checked={isAllSelected} /></th>
                <th>Group Name</th>
                <th>Users</th>
              </tr>
            </thead>
            <tbody>
              {groups && groups.map((group, index) => (
                <tr key={index}>
                  <td><input type="checkbox" onChange={() => handleGroupSelectionChange(group.groupName)} checked={selectedGroups.includes(group.groupName)} /></td>
                  <td>{group.groupName}</td>
                  <td>{group.users.length}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="group-modal-footer">
          <button className="btn-confirmGroupModal" onClick={handleConfirm}>Confirm</button>
        </div>
      </div>
    </div>
  );
};

export default ManageGroupsPopUp;