// Store data from Auth
export const SET_PROFILE_DATA = "SET_PROFILE_DATA";
export const SIGNOUT = "SIGNOUT";

//Types for Sign up
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";

//Types for Login
export const LOGIN_REQUEST_REQUEST = "LOGIN_REQUEST_REQUEST";
export const AZURE_LOGIN_REQUEST = "AZURE_LOGIN_REQUEST_REQUEST";
export const VALIDATE_TOKEN_REQUEST = "VALIDATE_TOKEN_REQUEST";

//Types for Update user Type
export const UPDATE_USER_TYPE = "UPDATE_USER_TYPE";
export const GET_USER_DATA = "GET_USER_DATA";

//Types for search Api
export const SEARCH_INPUT = "SEARCH_INPUT";
export const SEARCH_API_REQUEST = "SEARCH_API_REQUEST";
export const SEARCH_API_SUCCESS = "SEARCH_API_SUCCESS";

//folder types
export const ADD_FOLDER_REQUEST = "ADD_FOLDER_REQUEST";
export const GET_FOLDER_REQUEST = "GET_FOLDER_REQUEST";
export const GET_FOLDER_SUCCESS = "GET_FOLDER_SUCCESS";
export const DELETE_FOLDER_REQUEST = "DELETE_FOLDER_REQUEST";
export const ADD_ARTICLE_TO_FOLDER_REQUEST = "ADD_ARTICLE_TO_FOLDER_REQUEST";
export const DELETE_ARTICLE_FROM_FOLDER_REQUEST =
  "DELETE_ARTICLE_FROM_FOLDER_REQUEST";
export const RENAME_FOLDER_REQUEST = "RENAME_FOLDER_REQUEST";
export const SAVE_SOURCE_REQUEST = "SAVE_SOURCE_REQUEST";
export const GET_SOURCE_REQUEST = "GET_SOURCE_REQUEST";
export const GET_SOURCE_FEEDS_REQUEST = "GET_SOURCE_FEEDS_REQUEST";
export const GET_SOURCE_SUCCESS = "GET_SOURCE_SUCCESS";
export const DELETE_SOURCE_REQUEST = "DELETE_SOURCE_REQUEST";

//portafoglio dati types
export const ADD_ARTICLES_TO_PORTAFOLIO_DATI_REQUEST =
  "ADD_ARTICLES_TO_PORTAFOLIO_DATI_REQUEST";
export const GET_ARTICLES_FROM_PORTAFOLIO_DATI_REQUEST =
  "GET_ARTICLES_FROM_PORTAFOLIO_DATI_REQUEST";
export const DELETE_ARTICLES_FROM_PORTAFOLIO_DATI_REQUEST =
  "DELETE_ARTICLES_FROM_PORTAFOLIO_DATI_REQUEST";
export const SELL_ARTICLES_FROM_PORTAFOLIO_DATI_REQUEST =
  "SELL_ARTICLES_FROM_PORTAFOLIO_DATI_REQUEST";

// Types for update profile
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const SAVE_COMPLETE_PROFILE = "SAVE_COMPLETE_PROFILE";
export const GET_SURVEY_RESPONSE_AND_COMPLETE_PROFILE =
  "GET_SURVEY_RESPONSE_AND_COMPLETE_PROFILE";
export const SAVE_SUPPORT = "SAVE_SUPPORT";

//Types for get articles from web seacrh

export const GET_ARTICLES_FROM_WEB_SEARCH = "GET_ARTICLES_FROM_WEB_SEARCH";

//Types for coupon

export const GET_COUPONS = "GET_COUPONS";

//Types for CashOuts

export const SAVE_USER_REQUEST = "SAVE_USER_REQUEST";

// Types for Receipt
export const ADD_RECEIPT_REQUEST = "ADD_RECEIPT_REQUEST";
export const USER_API_RECEIPT_REQUEST = "USER_API_RECEIPT_REQUEST";

// Types for change password flow
export const SEND_VERIFICATION_CODE_REQUEST = "SEND_VERIFICATION_CODE_REQUEST";
export const VERIFY_CODE_REQUEST = "VERIFY_CODE_REQUEST";
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";

//types for add screen
export const AD_TIMEOUT = "AD_TIMEOUT";

//types for Survey
export const GET_SURVEYS = "GET_SURVEYS";
export const SAVE_SURVEY_RESPONSE = "SAVE_SURVEY_RESPONSE";

//types for News
export const GET_NEWS = "GET_NEWS";
export const GET_SEARCH_FEED = "GET_SEARCH_FEED";
export const SET_NEWS_STATUS = "SET_NEWS_STATUS";

export const SWITCH_USER = "SWITCH_USER";
