import { takeLatest, put } from 'redux-saga/effects';
import * as types from '../../actions/types';
import { Api } from '../../../utils';
import { endPoints } from '../../../utils';

export function* getArticlesFromWebRequest() {
  yield takeLatest(types.GET_ARTICLES_FROM_WEB_SEARCH, getArticlesFromWeb);
}

function* getArticlesFromWeb(params) {
  try {
    let options = {
      headers: {
        Authorization: 'Bearer ' + params.token,
        'Accept-Language': params.locale, // Add the Accept-Language header
      },
    };
    let configuration = Object.assign(options);
    let response = yield Api.getAxios(
      endPoints.getArticlesFromWeb(params.params.q, params.params.engine, params.params.page),
      configuration,
    );
    if (response?.status) {
      params.cbSuccess(response);
    } else {
      params.cbFailure(response);
    }
  } catch (err) {
    console.log(
      'error from get articles from Portafolio Dati request saga -- > > >  > ',
      err,
    );
    params.cbFailure(err.message);
  }
}
