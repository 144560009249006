import { applyMiddleware } from "redux";
import * as TYPES from "../types";

//Login action
export const loginRequest = (params, cbSuccess, cbFailure) => {
  return {
    type: TYPES.LOGIN_REQUEST_REQUEST,
    params,
    cbSuccess,
    cbFailure,
  };
};
export const azureLoginRequest = (params, cbSuccess, cbFailure) => {
  return {
    type: TYPES.AZURE_LOGIN_REQUEST,
    params,
    cbSuccess,
    cbFailure,
  };
};

//Sign up obj Action
export const signUpRequest = (params, cbSuccess, cbFailure) => {
  return {
    type: TYPES.SIGNUP_REQUEST,
    params,
    cbSuccess,
    cbFailure,
  };
};

export const validateTokenRequest = (token, cbSuccess, cbFailure) => {
  return {
    type: TYPES.VALIDATE_TOKEN_REQUEST,
    token,
    cbSuccess,
    cbFailure,
  };
};

export const updateUserType = (params, token, cbSuccess, cbFailure) => {
  return {
    type: TYPES.UPDATE_USER_TYPE,
    params,
    token,
    cbSuccess,
    cbFailure,
  };
};

export const getUserData = (token, cbSuccess, cbFailure) => {
  return {
    type: TYPES.GET_USER_DATA,
    token,
    cbSuccess,
    cbFailure,
  };
};

// Send verification action
export const sendVerificationCode = (params, cbSuccess, cbFailure) => {
  return {
    type: TYPES.SEND_VERIFICATION_CODE_REQUEST,
    params,
    cbSuccess,
    cbFailure,
  };
};

// Send verification action
export const verifyCode = (params, cbSuccess, cbFailure) => {
  return {
    type: TYPES.VERIFY_CODE_REQUEST,
    params,
    cbSuccess,
    cbFailure,
  };
};

// Send reset password action
export const resetPassword = (params, cbSuccess, cbFailure) => {
  return {
    type: TYPES.CHANGE_PASSWORD_REQUEST,
    params,
    cbSuccess,
    cbFailure,
  };
};

// switch user
export const switchUser = (id) => {
  return {
    type: TYPES.SWITCH_USER,
    id,
  };
}

// signup
export const signout = () => {
 localStorage.removeItem('userIds'); 

  
  
}

//export const signout = () => console.log('signout function called');



// app.get('/logout',(req, res) => {
//   req.logout();
//   res.redirect('/login');
// });

