import React, { useContext } from "react";

import PropTypes from "prop-types";

import "./sign-up-pop-up-c1.css";
import { AuthContext } from "../context/authContext";

const SignUpPopUpC1 = (props) => {


  const translations = {
    en: {
     
      'pppsign1': 'SignUp to have all Weagle functionalities and infinite searches!',
      'pppsign2': 'User',
      'pppsign3': 'Business',
      'pppsign4': 'Back',
    },
    it: {

  
      'pppsign1': 'Registrati per avere tutte le funzionalità Weagle e infinite ricerche!',
      'pppsign2': 'Utente',
      'pppsign3': 'Azienda',
      'pppsign4': 'Indietro',
   
    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser

  const pppsign1a = translate('pppsign1', lang);
  const pppsign2a = translate('pppsign2', lang);
  const pppsign3a = translate('pppsign3', lang);
  const pppsign4a = translate('pppsign4', lang);


  const handleCancelClick = () => {
    setShowSignUp(false);
  };
  const { selectedSignUpType, setSelectedSignUpType,   setShowSignUp } = useContext(AuthContext);
  return (
    
    <div className={`sign-up-pop-up-c1-container ${props.rootClassName} `}>
      <span className="indietroButtonSignUp" onClick={handleCancelClick}>{pppsign4a}</span>
      <div className="sign-up-pop-up-c1-frame125">
        
        <div className="sign-up-pop-up-c1-frame5142">
          <span className="sign-up-pop-up-c1-text">
            
            <span className="">
              
              <span className="">
             {pppsign1a}
              </span>
              <br className=""></br>
              {/* <span className="">e infinite ricerche!</span> */}
            </span>
          </span>
          <div className="sign-up-pop-up-c1-group124">
            <div className="sign-up-pop-up-c1-group123">
              <div
                onClick={() => setSelectedSignUpType("Persona")}
                className={
                  selectedSignUpType === "Persona"
                    ? "sign-up-pop-up-c1-group122"
                    : ""
                }
              >
                <span className="sign-up-pop-up-c1-text5">
                  <span className=""> &nbsp;  {pppsign2a}</span>
                </span>
              </div>
              <div
                onClick={() => setSelectedSignUpType("Azienda")}
                className={
                  selectedSignUpType === "Azienda"
                    ? "sign-up-pop-up-c1-group122"
                    : ""
                }
              >
                <span className="sign-up-pop-up-c1-text7">
                  <span className="">{pppsign3a}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SignUpPopUpC1.defaultProps = {
  rootClassName: "",
};

SignUpPopUpC1.propTypes = {
  rootClassName: PropTypes.string,
};

export default SignUpPopUpC1;
