import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import "./news-filter.css";
import "./dropdown.css";
import DatepickerDropdown from "./datepickerDropdown";
import { useHistory } from "react-router-dom";
import { SearchContext } from "../context/search_context";
import FiltroCategorie from "./filtro-categorie";
import LanguageFilter from "./language-filter";
const NewsFilter = (props) => {
  const {
    resetNewsFilters,
    showCategoryNewsFilter,
    setShowCategoryNewsFilter,
    showLanguageNewsFilter,
    setshowLanguageNewsFilter,
    showDateNewsFilter,
    setshowDateNewsFilter,
    setNewsSearchInSources,
    newsSearchInSources,
    searchValue
  } = useContext(SearchContext);
  const history = useHistory();


  const translations = {
    en: {
     
      'pppn1': "Categories",
      'pppn2': 'Date',
      'pppn3': 'Lang',
      'pppn4': 'Within sources',
      'pppn5': 'Reset',
      'pppn6': 'Advanced',
    },
    it: {

  
     
      'pppn1': "Categorie",
      'pppn2': 'Data',
      'pppn3': 'Lingue',
      'pppn4': 'Ricerca da Fonti',
      'pppn5': 'Reset filtri',
      'pppn6': 'Filtri avanzati',
    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser

  const pppn1a = translate('pppn1', lang);
  const pppn2a = translate('pppn2', lang);
  const pppn3a = translate('pppn3', lang);
  const pppn4a = translate('pppn4', lang);
  const pppn5a = translate('pppn5', lang);
  const pppn6a = translate('pppn6', lang);



  return (
    <div className={`news-filter-group74 ${props.rootClassName} `}>
      <div className={"categoryFilter"}>
        <div
          onClick={() => setShowCategoryNewsFilter((prevState) => !prevState)}
          className="news-filter-frame4"
        >
          <span className="news-filter-text">
            <span>{pppn1a}</span>
          </span>
          <img
            alt="Vector29830"
            src="/playground_assets/vector29830-4ko.svg"
            className="news-filter-vector2"
          />
        </div>
        {showCategoryNewsFilter && (
          <FiltroCategorie rootClassName={"filtro-categorie-root-class-name"} />
        )}
      </div>

   
        <div className="news-filter-text02"
          style={{ cursor: "pointer" }}
          onClick={() => {
            resetNewsFilters();
          }}
        >
          {pppn5a}
        </div>
        <div className="news-filter-text02-2"
          style={{ cursor: "pointer", marginLeft: 20 }}
          onClick={() => {
            history.push("/advanced-filters");
          }}
        >
        {pppn6a}
        </div>
      

      <div>
        <div
          onClick={() => setshowDateNewsFilter((prev) => !prev)}
          className="news-filter-frame1"
        >
          <span className="news-filter-text04">
            <span>{pppn2a}</span>
          </span>
          <img
            alt="Vector21071"
            src="/playground_assets/vector21071-vclq.svg"
            className="news-filter-vector21"
          />
        </div>
        {showDateNewsFilter && <DatepickerDropdown />}
      </div>

      <div className={"languageFilter"}>
        <div
          onClick={() => setshowLanguageNewsFilter((prev) => !prev)}
          className="news-filter-frame2"
        >
          <span className="news-filter-text06">
            <span>{pppn3a}</span>
          </span>
          <img
            alt="Vector21071"
            src="/playground_assets/vector21071-odt2w.svg"
            className="news-filter-vector22"
          />
        </div>

        {showLanguageNewsFilter && (
          <LanguageFilter rootClassName={"filtro-language-root-class-name"} />
        )}
      </div>

      <span className="news-filter-text08">
        <span>{pppn4a}</span>
      </span>
      <div onClick={()=> {
        if(searchValue){
          setNewsSearchInSources(prev => !prev)
        }
      }} className="news-filter-group23">
        <img
          alt="Rectangle61I175"
          src={`/playground_assets/toggle-${newsSearchInSources ? 'on' : 'off'}.png`}
          className="news-filter-rectangle61"
        />
      </div>
    </div>
  );
};

NewsFilter.defaultProps = {
  rootClassName: "",
};

NewsFilter.propTypes = {
  rootClassName: PropTypes.string,
};

export default NewsFilter;
