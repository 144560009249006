import React, { useContext, useRef } from "react";

import "./logout-pop-up.css";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "../context/authContext";
import { useHistory } from "react-router-dom";
import { useOnClickOutside } from "./useClickOuteSide";
import { switchUser, signout } from "../redux/actions/auth-actions";
import authReducer from "../redux/reducers/auth-reducer/auth-reducer";
import LoginPng from "../images/loginacc.png";
import LogoutPng from "../images/logoutacc.png";
import PlusAcc from "../images/plususeracc.png";
import Scudopng from "../images/security.png";
import RettangoloGrigio from "../images/rettangologrigioacc.png";
const LogoutPopUp = (props) => {
  const { setSelectedSignUpType, setShowSignUp, setEmail, setPassword } =
    useContext(AuthContext);
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const history = useHistory();
  const modalRef = useRef();

  useOnClickOutside(modalRef, () => {
    props?.setShowPopup(false);
    setEmail(auth?.data?.email);
  });
 



  
  const translations = {
    en: {
      'profilo': 'Profile',
      'logincon': 'Log in with another account',
    },
    it: {

      'profilo': 'Profilo',
      'logincon': 'Log in con un altro account',
    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser
  const profilopop = translate('profilo', lang);
  const loginconpop = translate('logincon', lang);






  return (
    <div ref={modalRef} className="logout-pop-up-container">
      <div className="logout-pop-up-logout">
        {auth?.otherUsers?.map((item) => {
          return (
            <div
              onClick={() => {
                dispatch(switchUser(item?._id));
				

              }}
              className="logout-pop-up-frame5125"
            >
              <div className="logout-pop-up-frame5124">
                {auth?.data?._id === item?._id ? (
                  
                  <img
                    src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d715cf8a-99be-4b4d-8894-0bcff53b9223/66852583-6f7b-4cae-9d25-85af5b1bc110?org_if_sml=1246"
                    alt="Rectangle689671"
                    className="logout-pop-up-rectangle68 background-checkbox"
                  />
                ) : (
                  <div className="logout-pop-up-rectangle68" />
                )}

                <img
                  src={ 
                    item?.image ||
                    "https://i.ibb.co/QX0XxG4/user.png" 
                  }
                  alt="Ellipse19671"
                  className="logout-pop-up-ellipse1"
                />
                <span className="logout-pop-up-text">
                  <span>{item?.email}</span>
                </span>
              </div>
            </div>
          );
        })}
    <span className="linelogout"> </span>
        {/* <div
          onClick={() => {
            history.push("/profile-enterprise");
            props?.setShowPopup(false);
          }}
          className="logout-pop-up-frame5126"
        >
          
          <span className="logout-pop-up-text10">
          <img className="iconaccountchange"
              src={LoginPng}
                />
            <span>{profilopop} </span>
            
          </span>
        </div> */}

        {/* <div
          onClick={() => {
            setSelectedSignUpType("login");
            setPassword("");
            setEmail("");
            setShowSignUp(true);
          }}
          className="logout-pop-up-frame5126"
        >
          <span className="logout-pop-up-text10">
          <img className="iconaccountchange"
              src={PlusAcc}
                />
          
            <span>{loginconpop}</span>
          </span>
        </div> */}
        <div
          onClick={() => {
            history.push("/privacy");
            props?.setShowPopup(false);
          }}
          className="logout-pop-up-frame5126"
        >
          <span className="logout-pop-up-text10">
          <img className="iconaccountchange"
              src='/playground_assets/shieldblu.svg'
                />
            <span>Privacy Policy</span>
          </span>
        </div>
        <div
          onClick={() => {
            window.location.reload()
            dispatch(signout());
            history.push("/");
          }}
          className="logout-pop-up-frame5126 marginBottomW"
        >
          <span className="logout-pop-up-text10">
          <img className="iconaccountchange"
               src='/playground_assets/logoutblu.svg'
                />
            <span>Log out</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default LogoutPopUp;
