import React, { useState } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, useMsal } from '@azure/msal-react';
import GroupModal from './GroupModal';

const msalConfig = {
  auth: {
    clientId: '0c94634d-a6f9-492f-8f9a-e5fa631475a9',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: 'http://localhost:3000',
  }
};

const pca = new PublicClientApplication(msalConfig);

const SignInButton = () => {
  const { instance } = useMsal();
  const [groups, setGroups] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleLogin = (loginType) => {
    const loginRequest = {
      scopes: ["User.Read.All", "Group.Read.All", "Directory.Read.All"]
    };

    const fetchDirectoryData = async (accessToken) => {
      try {
        let allGroups = [];
        let nextLink = 'https://graph.microsoft.com/v1.0/groups?$expand=members&$top=100';

        while (nextLink) {
          const groupsResponse = await fetch(nextLink, {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          });

          if (!groupsResponse.ok) {
            throw new Error(`Error fetching groups: ${groupsResponse.statusText}`);
          }

          const groupsData = await groupsResponse.json();
          allGroups = [...allGroups, ...groupsData.value];

          nextLink = groupsData["@odata.nextLink"];
        }

        const groupsWithMemberCount = await Promise.all(allGroups.map(async (group) => {
          const memberEmails = [];
          let allMembers = [];

          if (group.members) {
            let nextMemberLink = `https://graph.microsoft.com/v1.0/groups/${group.id}/members?$top=100`;

            while (nextMemberLink) {
              const membersResponse = await fetch(nextMemberLink, {
                headers: {
                  'Authorization': `Bearer ${accessToken}`
                }
              });

              if (!membersResponse.ok) {
                throw new Error(`Error fetching group members: ${membersResponse.statusText}`);
              }

              const membersData = await membersResponse.json();
              allMembers = [...allMembers, ...membersData.value];

              nextMemberLink = membersData["@odata.nextLink"];
            }

            allMembers.forEach((member) => {
              if (member["@odata.type"] === "#microsoft.graph.user") {
                if (member.userPrincipalName) {
                  memberEmails.push(member.userPrincipalName);
                } else if (member.otherMails && member.otherMails.length > 0) {
                  memberEmails.push(...member.otherMails);
                }
              }
            });
          }

          return {
            ...group,
            memberCount: memberEmails.length,
            memberEmails: memberEmails,
            members: allMembers,
          };
        }));

        setGroups(groupsWithMemberCount);
        setModalOpen(true);
        console.log("Groups:", groupsWithMemberCount);
      } catch (error) {
        console.error(error);
      }
    };

    if (loginType === "popup") {
      instance.loginPopup(loginRequest)
        .then(response => {
          if (response.accessToken) {
            fetchDirectoryData(response.accessToken);
          } else {
            instance.acquireTokenSilent(loginRequest).then(accessTokenResponse => {
              fetchDirectoryData(accessTokenResponse.accessToken);
            }).catch(error => {
              console.error("Error acquiring the access token silently:", error);
            });
          }
        })
        .catch(e => {
          console.error(e);
        });
    } else {
      instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
      });
    }
  };

  return (
    <>
      <button onClick={() => handleLogin("popup")}>
        <img src="/playground_assets/syncWithAzureGroup.svg" alt="" className="addAccountWidgetIcon" />
      </button>
      <GroupModal isOpen={isModalOpen} groups={groups} onClose={() => setModalOpen(false)} />
    </>
  );
};

const AzureAuthButton = () => {
  return (
    <MsalProvider instance={pca}>
      <SignInButton />
    </MsalProvider>
  );
};

export default AzureAuthButton;