import React, { useState } from "react";
import "./searchBarAccountList.css";

const SearchBarAccountList = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    onSearch(term);
  };

  return (
    <div className="search-bar-container">
      <input
        type="text"
        className="search-inputAccountList"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearchChange}
      />
    </div>
  );
};

export default SearchBarAccountList;