import React from 'react'

import PropTypes from 'prop-types'

import './coupon-welfare.css'

const CouponWelfare3 = (props) => {
  return (
    <div className={`coupon-welfare-container ${props.rootClassName} surf3`}>
      <div className="coupon-welfare-coupon-welfare-container">
        <div className="coupon-welfare-frame5024">
          <div className="coupon-welfare-frame5022">
            <div className="coupon-welfare-frame5021">
              <div className="coupon-welfare-frame5020">
                <span className="coupon-welfare-text">
                  <span className="">Surf Shark - VPN New</span>
                </span>
                <span className="coupon-welfare-text02">
                  <span className="">84% + 2 mesi gratis</span>
                </span>
              </div>
              <span className="coupon-welfare-text04">
                <span className="">
                  <span className="">
                  Surf Shark VPN New  

Un'offerta VPN per sfruttare tutti i vantaggi

                  </span>
                  <br className=""></br>
                  {/* <span className="">266 caratteri</span> */}
                </span>
              </span>
            </div>
            <div className="coupon-welfare-frame5008">
              <div className="coupon-welfare-frame5030">
                <img
                  src="https://www.punto-informatico.it/app/uploads/2022/05/surfshark.png"
                  alt="image95251"
                  className="coupon-welfare-image9"
                />
                <div className="coupon-welfare-frame5003">
                  <span className="coupon-welfare-text09">
                    <span className="">Servizi & altro</span>
                  </span>
                </div>
                <div className="coupon-welfare-frame5004">
                  <span className="coupon-welfare-text11">
                    {/* <span className="">Scade il 26/06/22</span> */}
                  </span>
                </div>
              </div>
              <a target="_blank" href='https://get.surfshark.net/aff_c?offer_id=926&aff_id=14887'>
              <div className="coupon-welfare-frame13">
                <span className="coupon-welfare-text13">
                  <span className="">Scopri i dettagli</span>
                </span>
                <img
                  src="/playground_assets/vector24951-98.svg"
                  alt="Vector24951"
                  className="coupon-welfare-vector2"
                />
              </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CouponWelfare3.defaultProps = {
  rootClassName: '',
}

CouponWelfare3.propTypes = {
  rootClassName: PropTypes.string,
}

export default CouponWelfare3
