// privacyOptionsWidgets.js
import React, { useContext, useState, useEffect } from "react";
import "./privacyOptionsWidgets.css"; 
import { AuthContext } from "../context/authContext";
import PrivacyOptionsWidgetRoleGroupSelection from "./privacyOptionsWidgetRoleGroupSelection";
import PrivacyToggleComponentPopUp from "./PrivacyToggleComponentPopUp";
import AiSettingsPrivacy from "./aiSettingsPrivacy";
import ContentAccess from "./contentAccess";
import AiTokenManagement from "./aiTokenManagement";
import PrivacyToggleBlackListComponent from "./PrivacyToggleBlackListComponent";
import PrivacyAccessTimeLimitationComponent from "./PrivacyAccessTimeLimitationComponent";

const PrivacyOptionsWidgets = () => {
  const { cisoInfo, handlePrivacyOptions } = useContext(AuthContext);
  const { groups } = cisoInfo;

  const [selectedGroup, setSelectedGroup] = useState(null);

  const handleGroupSelect = (groupName) => {
    const group = groups.find(g => g.groupName === groupName);
    // Ensure blacklist is initialized as an array if not already defined
    const featuresWithArrayBlacklist = {
      ...group.features,
      blacklist: Array.isArray(group.features.blacklist) ? group.features.blacklist : []
    };

    setSelectedGroup({
      ...group,
      features: featuresWithArrayBlacklist
    });
  };

  const updateBlacklist = (newBlacklist) => {
    if (selectedGroup) {
      const updatedGroup = {
        ...selectedGroup,
        features: {
          ...selectedGroup.features,
          blacklist: newBlacklist,
        },
      };
      setSelectedGroup(updatedGroup);
      handlePrivacyOptions(selectedGroup.groupName, "blacklist", newBlacklist);
    }
  };

  const handleToggleChange = (feature, value) => {
    if (selectedGroup) {
      const updatedGroup = {
        ...selectedGroup,
        features: {
          ...selectedGroup.features,
          [feature]: value,
        },
      };
      setSelectedGroup(updatedGroup);
      handlePrivacyOptions(selectedGroup.groupName, feature, value);
    }
  };

  const handleAiSettingsChange = (feature, value) => {
    if (selectedGroup) {
      const updatedAiSettings = {
        ...selectedGroup.features.aiSettings,
        [feature]: value,
      };
      const updatedGroup = {
        ...selectedGroup,
        features: {
          ...selectedGroup.features,
          aiSettings: updatedAiSettings,
        },
      };
      setSelectedGroup(updatedGroup);
      handlePrivacyOptions(selectedGroup.groupName, "aiSettings", updatedAiSettings);
    }
  };
  const handleTokenAssignmentChange = (groupName, value) => {
    const prevAiCredits = selectedGroup.features.aiSettings.aiCredits;
    const updatedGroup = {
      ...selectedGroup,
      features: {
        ...selectedGroup.features,
        aiSettings: {
          ...selectedGroup.features.aiSettings,
          aiCredits: value,
        },
      },
    };
    const success = handlePrivacyOptions(groupName, 'aiSettings', updatedGroup.features.aiSettings);
    if (!success) {
      // If the update fails, revert to the previous aiCredits value
      setInputValue(prevAiCredits.toFixed(2));
    } else {
      setSelectedGroup(updatedGroup);
    }
  };

  const handleTokensAssignmentChange = (groupName, value) => {
    const updatedGroup = {
      ...selectedGroup,
      features: {
        ...selectedGroup.features,
        aiSettings: {
          ...selectedGroup.features.aiSettings,
          aiCreditsRefill: value,
        },
      },
    };
    setSelectedGroup(updatedGroup);
    handlePrivacyOptions(groupName, 'aiSettings', updatedGroup.features.aiSettings);
  };

  const handleSecurityLevelChange = (value) => {
    if (selectedGroup) {
      const updatedContentAccess = {
        ...selectedGroup.features.contentAccess,
        securityLevel: value,
      };
      const updatedGroup = {
        ...selectedGroup,
        features: {
          ...selectedGroup.features,
          contentAccess: updatedContentAccess,
        },
      };
      setSelectedGroup(updatedGroup);
      handlePrivacyOptions(selectedGroup.groupName, "contentAccess", updatedContentAccess);
    }
  };

  const handleThreatsChange = (updatedThreats) => {
    if (selectedGroup) {
      const updatedContentAccess = {
        ...selectedGroup.features.contentAccess,
        threats: updatedThreats,
      };
      const updatedGroup = {
        ...selectedGroup,
        features: {
          ...selectedGroup.features,
          contentAccess: updatedContentAccess,
        },
      };
      setSelectedGroup(updatedGroup);
      handlePrivacyOptions(selectedGroup.groupName, "contentAccess", updatedContentAccess);
    }
  };

  const handleWhitelistChange = (updatedWhitelist) => {
    if (selectedGroup) {
      const updatedContentAccess = {
        ...selectedGroup.features.contentAccess,
        whitelist: updatedWhitelist,
      };
      const updatedGroup = {
        ...selectedGroup,
        features: {
          ...selectedGroup.features,
          contentAccess: updatedContentAccess,
        },
      };
      setSelectedGroup(updatedGroup);
      handlePrivacyOptions(selectedGroup.groupName, "contentAccess", updatedContentAccess);
    }
  };

  const handleAccessTimeChange = (accessTime, accessTimeHour) => {
    if (selectedGroup) {
      const updatedGroup = {
        ...selectedGroup,
        features: {
          ...selectedGroup.features,
          accessTime,
          accessTimeHour,
        },
      };
      setSelectedGroup(updatedGroup);
      handlePrivacyOptions(selectedGroup.groupName, "accessTime", { accessTime, accessTimeHour });
    }
  };
  

  return (
    <div className="PrivacyWidgetsContainer">
      <div className="AccountListNameSearch">
        <div className="AccountListNameSearchc1">
          <span className="accountListTitle">PRIVACY OPTIONS</span>
        </div>
      </div>
      <div className="PrivacyWidgetsContainerc1">
        <PrivacyOptionsWidgetRoleGroupSelection
          onGroupSelect={handleGroupSelect}
          selectedGroupName={selectedGroup?.groupName}
        />
      </div>
      <div className="PrivacyWidgetsContainerc2">
        <ContentAccess
          value={selectedGroup?.features.contentAccess?.securityLevel || 10}
          onChange={handleSecurityLevelChange}
          disabled={!selectedGroup}
          threats={selectedGroup?.features.contentAccess?.threats || {
            pixels: 1,
            https: 1,
            iframe: 1,
            obfuscatedjs: 1,
          }}
          onThreatsChange={handleThreatsChange}
          whitelist={selectedGroup?.features.contentAccess?.whitelist || []}
          onWhitelistChange={handleWhitelistChange}
        />
             <PrivacyToggleBlackListComponent
          value={selectedGroup?.features.loginBlock || false}
          onChange={(value) => handleToggleChange("loginBlock", value)}
          disabled={!selectedGroup}
          blacklist={selectedGroup?.features.blacklist || []}
          updateBlacklist={updateBlacklist}
        />
        <PrivacyToggleComponentPopUp
          title="ADBlock Setting"
          description="Set up AD blocker during browsing:"
          enabledStatus="Ads & Trackers Blocked"
          disabledStatus="Ads & Trackers Allowed"
          value={selectedGroup?.features.adBlock || false}
          onChange={(value) => handleToggleChange("adBlock", value)}
          disabled={!selectedGroup}
          className="custom-widthadblock"
        />

      </div>
      <div className="PrivacyWidgetsContainerc3">
        <AiSettingsPrivacy
          aiSettings={selectedGroup?.features.aiSettings || {}}
          onChange={handleAiSettingsChange}
          disabled={!selectedGroup}
        />
        <div className="PrivacyWidgetsContainerc3c">
          <AiTokenManagement
            groups={groups}
            onTokenAssignmentChange={handleTokenAssignmentChange}
            cisoInfo={cisoInfo}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            onTokensAssignmentChange={handleTokensAssignmentChange}
          />
          <div className="PrivacyWidgetsContainerc3c1">
          <PrivacyAccessTimeLimitationComponent
  value={{
    accessTime: selectedGroup?.features.accessTime || false,
    accessTimeHour: selectedGroup?.features.accessTimeHour,
  }}
  onChange={(accessTime, accessTimeHour) => handleAccessTimeChange(accessTime, accessTimeHour)}
  disabled={!selectedGroup}
/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyOptionsWidgets;
