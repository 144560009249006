import React, {useContext, useRef} from "react";

import SignUpPopUpC1 from "./sign-up-pop-up-c1";
import UserSignUp from "./user-sign-up";
import StripePaymentPlan from "./stripe-payment-plan";
import "./sign-up-pop-up.css";
import { AuthContext } from "../context/authContext";
import LoginPopUp from "./login-pop-up";
import EnterpriseSignUp from "./enterprise-sign-up";
import InvitePopUp from "./invite-pop-up";
import PlanSignUpEnterprise from "./plan-sign-up-enterprise";
import {useOnClickOutside} from "./useClickOuteSide";

const SignUpPopUp = (props) => {
  const { selectedSignUpType, setShowSignUp } = useContext(AuthContext);

  const modalRef = useRef();


  useOnClickOutside(modalRef, () => {
    setShowSignUp(false)
  });



  return (
    <div ref={modalRef} className="sign-up-pop-up-container signUp">
      {selectedSignUpType === "login" && <LoginPopUp />}
      {(selectedSignUpType === "Persona" ||
        selectedSignUpType === "Azienda") && (
        <SignUpPopUpC1 rootClassName="sign-up-pop-up-c1-root-class-name" />
      )}

      {selectedSignUpType === "Persona" && <UserSignUp />}
      {selectedSignUpType === "Azienda" && <EnterpriseSignUp />}
      {selectedSignUpType === "stripeForm" && <StripePaymentPlan />}
      {selectedSignUpType === "InvitePopup" && <InvitePopUp className={"invite-pop-up-container"} 
      title={'Invita i tuoi dipendenti su Weagle'} 
      subtitle={'Una volta completata la task verrà inviata una email con un link,tramite esso potrannio registrarsi al servizio come utentienterprise.'}
      />}
      {selectedSignUpType === "plans" && <PlanSignUpEnterprise />}
    </div>
  );
};

export default SignUpPopUp;
