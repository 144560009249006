import React, { useContext, useEffect, useState } from "react";
import "./dashboardSettingsWidgets.css";
import { useHistory } from "react-router-dom";
import AccountListWidgetComponent from "./accountListWidgetComponent";
import AccountListDashboardSettings from "./accountListDashboardSettings";
import { useForm } from "@formcarry/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DashboardSettingsWidgets = (props) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { state, submit } = useForm({ id: "YpEFQx7LrG" });

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await submit(event);
    closePopup();
    toast.success("Your ticket has been submitted successfully! We will get back to you within 24 hours.");
  };

  return (
    <div className="accountListWidgetsContainer">
      <div className="AccountListNameSearchparent">
        <div className="AccountListNameSearch">
          <div className="AccountListNameSearchc1">
            <span className="accountListTitle">Help & Settings</span>
          </div>
        </div>
        <div className="AccountListSettingsFull dashboardsettings">
          <button className="addAccountButtonSettings">Add Account</button>
          <AccountListDashboardSettings />
          <span className="helpCenterText">Help Center</span>
          <button className="openTicketButtonSettings" onClick={openPopup}>
            Open a Ticket
          </button>
          {isPopupOpen && (
            <div className="dashboardSettingsPopup">
              <div className="dashboardSettingsPopupContent">
                <h2 className="dashboardSettingsPopupTitle">Open a Ticket</h2>
                <h4 className="dashboardSettingsPopupTitle">Send us a request for help. We will respond promptly within 24 hours.</h4>
                <form className="dashboardSettingsTicketForm" onSubmit={handleSubmit}>
                  <div className="dashboardSettingsFormGroup">
                    <label htmlFor="ds_name_or_email" className="dashboardSettingsFormLabel">
                      Name or Email*:
                    </label>
                    <input
                      type="text"
                      id="ds_name_or_email"
                      name="name_or_email"
                      className="dashboardSettingsFormInput"
                      required
                    />
                  </div>
                  <div className="dashboardSettingsFormGroup">
                    <label htmlFor="ds_company" className="dashboardSettingsFormLabel">
                      Company:
                    </label>
                    <input
                      type="text"
                      id="ds_company"
                      name="company"
                      className="dashboardSettingsFormInput"
                    />
                  </div>
                  <div className="dashboardSettingsFormGroup">
                    <label htmlFor="ds_topic" className="dashboardSettingsFormLabel">
                      Topic of issue:
                    </label>
                    <select id="ds_topic" name="topic" className="dashboardSettingsFormSelect">
                      <option value="">Select a topic</option>
                      <option value="technical">Technical</option>
                      <option value="billing">Billing</option>
                      <option value="general">General</option>
                    </select>
                  </div>
                  <div className="dashboardSettingsFormGroup">
                    <label htmlFor="ds_message" className="dashboardSettingsFormLabel">
                      Message:
                    </label>
                    <textarea
                      id="ds_message"
                      name="message"
                      className="dashboardSettingsFormTextarea"
                      required
                    ></textarea>
                  </div>
                  <div className="dashboardSettingsFormActions">
                    <button
                      type="button"
                      className="dashboardSettingsCancelButton"
                      onClick={closePopup}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="dashboardSettingsSubmitButton">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardSettingsWidgets;
