import React, { useState, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import './adBlockTrackingreports.css';
import DashboardDatePicker from './dashboardDatePicker';
import { AuthContext } from "../context/authContext";
import DashbooardstatisticsGroupPicker from './dashbooardstatisticsGroupPicker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const AdBlockTrackingreports = () => {
  const { cisoInfo } = useContext(AuthContext);
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: new Date(),
    end: new Date(),
    label: ''
  });
  const [selectedGroup, setSelectedGroup] = useState('All Groups');

  const handleDateRangeSelected = (dateRange) => {
    setSelectedDateRange(dateRange);
  };

  const handleGroupSelected = (group) => {
    setSelectedGroup(group);
  };

  const getAdTrackerBlockedData = () => {
    return cisoInfo.statistics.reduce((acc, group) => {
      if (selectedGroup === 'All Groups' || group.groupName === selectedGroup) {
        const { dailyAdTrackerBlocked } = group;
        if (dailyAdTrackerBlocked) {
          Object.entries(dailyAdTrackerBlocked).forEach(([date, count]) => {
            const adTrackerDate = new Date(date);
            if (adTrackerDate >= new Date(selectedDateRange.start) && adTrackerDate <= new Date(selectedDateRange.end)) {
              acc += count;
            }
          });
        }
      }
      return acc;
    }, 0);
  };

  const adTrackerBlockedCount = getAdTrackerBlockedData();

  const calculatePercentageChange = () => {
    const currentDate = new Date();
    const currentMonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const currentMonthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const previousMonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    const previousMonthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);

    const currentMonthCount = cisoInfo.statistics.reduce((acc, group) => {
      if (selectedGroup === 'All Groups' || group.groupName === selectedGroup) {
        const { dailyAdTrackerBlocked } = group;
        if (dailyAdTrackerBlocked) {
          Object.entries(dailyAdTrackerBlocked).forEach(([date, count]) => {
            const adTrackerDate = new Date(date);
            if (adTrackerDate >= currentMonthStart && adTrackerDate <= currentMonthEnd) {
              acc += count;
            }
          });
        }
      }
      return acc;
    }, 0);

    const previousMonthCount = cisoInfo.statistics.reduce((acc, group) => {
      if (selectedGroup === 'All Groups' || group.groupName === selectedGroup) {
        const { dailyAdTrackerBlocked } = group;
        if (dailyAdTrackerBlocked) {
          Object.entries(dailyAdTrackerBlocked).forEach(([date, count]) => {
            const adTrackerDate = new Date(date);
            if (adTrackerDate >= previousMonthStart && adTrackerDate <= previousMonthEnd) {
              acc += count;
            }
          });
        }
      }
      return acc;
    }, 0);

    if (previousMonthCount === 0) {
      return currentMonthCount > 0 ? 'N/A' : '0.00';
    }

    const percentageChange = ((currentMonthCount - previousMonthCount) / previousMonthCount) * 100;
    return percentageChange.toFixed(2);
  };

  const percentageChange = calculatePercentageChange();

  return (
    <div className="adblockTrackingcontainer">
      <div className="adblockTrackingTitleDateDiv">
        <span className='adblockTrackingTitle'>AdBlock & Tracking</span>
        <DashbooardstatisticsGroupPicker
          groups={cisoInfo.groups}
          onGroupSelected={handleGroupSelected}
        />
        <DashboardDatePicker onDateRangeSelected={handleDateRangeSelected} />
 
      </div>

      <div className="doublegraphdivadblockTracking">
        <div className="doublegraphdivc2adblockTracking">
          <span className='titleadblockTrackingchart'>Ads & tracker blocked</span>
          <span className='numberadblockTrackingchart'>{adTrackerBlockedCount.toLocaleString()}</span>
          <span className='percentageadblockTrackingchart'>
            {percentageChange === 'N/A' ? 'N/A' : `${percentageChange >= 0 ? '+' : ''}${percentageChange}%`} from last month
          </span>
        </div>
      </div>

    </div>
  );
};

export default AdBlockTrackingreports;
