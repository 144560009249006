
import React, { useState, useContext } from "react";

import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import SideBar from "../components/side-bar";
import "./privacy.css";
import ProfileSide from "../components/profile-side";
import Hamburgher from "../components/hamburgher-menu";
import SearchBar2 from "../components/search-bar2";
import { AuthContext } from "../context/authContext";
import { useDispatch, useSelector } from "react-redux";
const PrivacyPolicy = (props) => {

  const {
    firstName,
    setFirstName,
    iva,
    setIva,
    email,
    setEmail,
    loading,
    nickName,
    setNickName,
    accountType,
    setAccountType,
    address,
    setAddress,
    common,
    setCommon,
    country,
    setCountry,
    handelProfileEnterprice,
    image,
    setImage,
  } = useContext(AuthContext);
  const history = useHistory();
  const { auth, folder } = useSelector((state) => state);




  return (
    <div className="search-con-prova-container">
  <Helmet>
    <title>Weagle</title>
    <meta property="og:title" content="Weagle" />
  </Helmet>
  <div className="search-con-prova-container-principale">
    <div className="search-con-prova-container1">
      <SideBar rootClassName="side-bar-root-class-name" />
    </div> 
      
    <div className="menudesktop">
    <div className="nomenurightprofile">
      <ProfileSide />
    </div>
    </div>
    <div className="menumobile">
      <ProfileSide />
      <span style={{ cursor:'pointer'}} onClick={() => {
        history.push("/");
      }} className="menumobileLogo">weagle</span>
      <Hamburgher /> 
    </div>


  </div>
  <div className="privacyPolicy">
<div>
  <p><strong>INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI</strong></p>
  <p>Con la presente informativa, resa ai sensi dell’art. 13 del Regolamento (UE) 2016/679 (“Regolamento” o “GDPR”), Weagle S.r.l. desidera illustrarle le finalità per cui raccoglie e tratta i Suoi dati tramite la propria piattaforma (di seguito, la “Piattaforma”), quali categorie di dati sono oggetto di trattamento, quali sono i Suoi diritti riconosciuti dalla normativa in materia di protezione dei dati personali e come possono essere esercitati.</p>
  <ol>
    <li>
      <strong>Titolare del trattamento e DPO</strong>
      <p>Weagle S.r.l., con sede legale in Via Camillo Benso C. di Cavour, 74 – 20094, Corsico (MI), P.Iva 11492050965 è il titolare del trattamento dei Suoi dati personali (il “Titolare” o la “Società”).</p>
      <p>Il Titolare è raggiungibile via e-mail all’indirizzo <a href="mailto:info@weagle.it">info@weagle.it</a>, oppure scrivendo via posta ordinaria a Weagle S.r.l., con sede legale in Via Camillo Benso C. di Cavour, 74 – 20094, Corsico (MI).</p>
      <p>Il Titolare ha nominato un Responsabile della protezione dei dati (in inglese “Data Protection Officer”, “DPO”), contattabile via e-mail all’indirizzo <a href="mailto:dpo@e-lex.it">dpo@e-lex.it</a>.</p>
    </li>
  </ol>
</div>
<div>
  <ol start="2">
    <li>
      <strong>Categorie di dati personali trattati, finalità e base giuridica del trattamento</strong>
      <p>Le attività di trattamento svolte dal Titolare tramite la Piattaforma implicano il trattamento di dati di natura comune degli utenti, necessari per la creazione e la gestione di un account sulla Piattaforma, nonché per erogare i servizi tramite la stessa offerti.</p>
      <p>In tale contesto, i dati trattati sono il nome e il cognome dell’utente, l’indirizzo e-mail e il metodo di pagamento prescelto, nonché le informazioni la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet.</p>
      <p>La base giuridica per il trattamento di tali dati personali è l’art. 6, par. 1, lett. b) del GDPR, posto che il trattamento è necessario ad erogare i servizi resi tramite Piattaforma, su richiesta dell’interessato.</p>
      <p>Per l’erogazione di alcuni servizi, l’utente può scegliere che la Società utilizzi i dati contenuti nel proprio portafogli di dati (c.d. “Data Wallet”) per creare dei profili, relativi a ciascun utente (riguardanti, ad esempio, le ricerche online, le preferenze di consumo, etc.).</p>
      <p>Il profilo così costruito, sempre ed unicamente su richiesta dell’utente, può essere ceduto a terzi per finalità commerciali, alle condizioni pattuite con la Società.</p>
      <p>Sia la creazione del profilo, sia la cessione dei dati a terzi, presuppongono che l’utente abbia letto la presente informativa e che, inoltre, abbia espresso uno specifico consenso con riferimento a ciascuna delle due attività sopra descritte.</p>
      <p>La base giuridica del trattamento dei dati degli utenti in relazione a tali finalità, pertanto, è lo specifico consenso dell’interessato, ai sensi dell’art. 6, par. 1, lett. a) del GDPR, che sarà necessario per: (i) la raccolta dei cookie diversi dai cookie tecnici, generati durante la navigazione; (ii) la creazione del profilo dell’utente; (iii) la cessione dei dati a terzi per finalità commerciali.</p>
    </li>
  </ol>
</div>
<div>
  <ol start="3">
    <li>
      <strong>Categorie di soggetti ai quali i dati personali possono essere comunicati e finalità della comunicazione</strong>
      <p>I dati a Lei riferibili saranno trattati, all’interno della struttura del Titolare, da collaboratori e/o dipendenti della Società in qualità di persone autorizzate al trattamento dei dati.</p>
      <p>Inoltre, la Società potrà comunicare alcuni Suoi dati personali a soggetti terzi dei quali si avvale per lo svolgimento di attività connesse alla finalità di cui al precedente paragrafo. Tali soggetti tratteranno i Suoi dati personali in qualità di responsabili del trattamento.</p>
      <p>L’elenco dei responsabili del trattamento è disponibile presso il Titolare e può essere richiesto scrivendo ai recapiti indicati nel precedente paragrafo 1.</p>
      <p>I dati a Lei riferibili potranno essere ceduti a terzi soggetti per finalità commerciali, unicamente dietro Suo specifico e libero consenso, nel caso in cui decida di usufruire del servizio della cessione dei dati presenti nel Suo Data Wallet.</p>
    </li>
  </ol>
</div>

<div>
  <ol start="4">
    <li>
      <strong>Periodo di conservazione</strong>
      <p>Con riferimento alla durata del trattamento e al periodo di conservazione dei dati, si rileva che la Società tratterà i Suoi dati personali:</p>
      <ul>
        <li>con riferimento ai dati comuni utilizzati per la creazione e gestione del Suo account, nonché per erogare i servizi resi tramite la Piattaforma, per il lasso di tempo in cui sussiste il rapporto contrattuale; decorso tale periodo, i dati possono essere conservati per un ulteriore periodo di 10 anni;</li>
        <li>con riferimento ai dati utilizzati per finalità di profilazione, per 12 mesi dalla creazione del profilo, salvo che Lei richieda la cancellazione dei propri dati alla Società;</li>
        <li>nel caso in cui richieda la cessione a terzi dei Suoi dati, gli stessi saranno poi trattati per il tempo necessario a provvedere a tale cessione e, successivamente, i dati verranno conservati fin quando non sarà decorso il periodo di 12 mesi sopra individuato;</li>
        <li>con specifico riferimento alle informazioni contenute nel Data Wallet, l’utente può in qualsiasi momento richiedere alla Società di cancellare i dati in esso contenuti.</li>
      </ul>
    </li>
  </ol>
</div>

<div>
  <ol start="5">
    <li>
      <strong>Trasferimento dei dati al di fuori dell’Unione europea</strong>
      <p>Laddove dovesse ricorrere il caso, la Società potrà effettuare trasferimenti transfrontalieri dei Suoi dati unicamente mediante il ricorso ad idonei strumenti, che costituiscano garanzie adeguate ai sensi del GDPR.</p>
      <p>Lei potrà ottenere informazioni sul luogo in cui i Suoi dati sono stati eventualmente trasferiti, nonché copia di tali dati, scrivendo ai recapiti indicati al precedente paragrafo 1.</p>
    </li>
  </ol>
</div>

<div>
  <ol start="6">
    <li>
      <strong>Diritti degli interessati</strong>
      <p>La informiamo che Lei – in qualità di soggetto interessato - ha i diritti previsti dagli artt. 15 e seguenti del GDPR e, in particolare:</p>
      <ul>
        <li>il diritto di accesso ai dati personali e, in particolare, di accedere, nonché di chiedere e ottenere informazioni circa l’esistenza di dati personali a Lei riferibili nella disponibilità del Titolare;</li>
        <li>il diritto di rettifica e, in particolare, di chiedere e ottenere la modifica e/o la correzione dei Suoi dati personali se ritiene che siano inaccurati o incompleti;</li>
        <li>il diritto di richiedere, al ricorrere delle circostanze di cui al Regolamento, la cancellazione dei dati personali ovvero la limitazione del trattamento avente ad oggetto gli stessi;</li>
        <li>il diritto di richiedere, se del caso, la portabilità dei dati personali.</li>
      </ul>
      <p>Per esercitare i Suoi diritti, La preghiamo contattare il Titolare o il DPO ai recapiti indicati nel precedente paragrafo 1.</p>
      <p>La informiamo, infine, che ai sensi della disciplina vigente può proporre eventuali reclami riguardanti i trattamenti di Suoi dati personali all’Autorità garante per la protezione dei dati personali.</p>
    </li>
  </ol>
  <p>Per la gestione del flusso di dati generati sulla nostra piattaforma e sul nostro sito, utilizziamo HotJar, piattaforma approvata a livello internazionale e compilant con tutte le Policy per la privacy.</p>
</div>




</div>
</div>

  );
};

export default PrivacyPolicy;
