import { takeLatest, put } from 'redux-saga/effects';
import * as types from '../../actions/types';
import { Api } from '../../../utils';
import { endPoints } from '../../../utils';

export function* azureLoginRequest() {
  yield takeLatest(types.AZURE_LOGIN_REQUEST, handleLoginAzure);
}

function* handleLoginAzure(params) {
  console.log('[handleLoginAzure saga]', params);
  try {
    let response = yield Api.postAxios(endPoints.handleLoginAzure, params.params);
    if (response?.status) {
      yield put({ type: types.SET_PROFILE_DATA, data: response.data });
      params.cbSuccess(response);
    } else {
      params.cbFailure(response);
    }
  } catch (err) {
    console.log('error from handleLoginAzure saga -- > > >  > ', err);
    params.cbFailure(err.message);
  }
}