import React, { useContext, useState, useEffect, useRef } from "react";
import "./accountListWidgetComponent.css";
import NewAccountPopUp from "./newAccountPopUp";
import AzureAuthButton from "./azureAuthButton";
import { AuthContext } from "../context/authContext";
import ManageGroupsPopUp from "./manageGroupsPopUp";
import ManageAccountsPopup from "./manageAccountsPopup";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ChangeGroupPopUp from "./changeGroupPopUp";
import DeleteAccountPopUp from "./deleteAccountPopUp";
import ModifyAccountPopUp from "./modifyAccountPopUp";

const AccountListWidgetComponent = ({ searchTerm }) => {
  const { userInfo } = useContext(AuthContext);
  const [showNewAccountPopup, setShowNewAccountPopup] = useState(false);
  const [showManageAccountPopup, setShowManageAccountPopup] = useState(false);
  const [showChangeGroupPopup, setShowChangeGroupPopup] = useState(false);
  const [showDeleteAccountPopup, setShowDeleteAccountPopup] = useState(false);
  const [showModifyAccountPopup, setShowModifyAccountPopup] = useState(false);
  const [showBackupPopup, setShowBackupPopup] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState("excel");
  const [selectedItems, setSelectedItems] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);
  const [filteredUserInfo, setFilteredUserInfo] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "" });
  const [hoveredEmail, setHoveredEmail] = useState(null);
  const [emailOverlayPosition, setEmailOverlayPosition] = useState({ top: 0, left: 0 });
  const emailRefs = useRef({});

  useEffect(() => {
    const filtered = userInfo.filter((user) =>
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUserInfo(filtered);
  }, [userInfo, searchTerm]);

  const toggleNewAccountPopup = () => {
    setShowNewAccountPopup(!showNewAccountPopup);
  };

  const toggleManageAccountPopup = () => {
    setShowManageAccountPopup(!showManageAccountPopup);
  };

  const openBackupPopup = () => {
    setShowBackupPopup(true);
  };

  const closeBackupPopup = () => {
    setShowBackupPopup(false);
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      selectedItems.map((user) => ({
        "Account ID": user.email,
        Group: user.group,
        Package: user.package,
        "Last Login": user.lastLogin ? new Date(user.lastLogin).toLocaleString() : "N/A",
        "Date of Creation": user.createdAt ? new Date(user.createdAt).toLocaleString() : "N/A",
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Accounts");
    XLSX.writeFile(workbook, "accounts.xlsx");
    closeBackupPopup();
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    const tableData = selectedItems.map((user) => [
      user.email,
      user.group,
      user.package,
      user.lastLogin ? new Date(user.lastLogin).toLocaleString() : "N/A",
      user.createdAt ? new Date(user.createdAt).toLocaleString() : "N/A",
    ]);
    doc.autoTable({
      head: [["Account ID", "Group", "Package", "Last Login", "Date of Creation"]],
      body: tableData,
    });
    doc.save("accounts.pdf");
    closeBackupPopup();
  };

  const handleDownload = () => {
    if (selectedFormat === "excel") {
      downloadExcel();
    } else if (selectedFormat === "pdf") {
      downloadPDF();
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedItems(userInfo);
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectItem = (user) => {
    const index = selectedItems.findIndex((item) => item._id === user._id);
    if (index === -1) {
      setSelectedItems([...selectedItems, user]);
    } else {
      setSelectedItems(selectedItems.filter((item) => item._id !== user._id));
    }
  };

  const handleModifyAccount = () => {
    setShowModifyAccountPopup(!showModifyAccountPopup);
  };

  const handleAddToGroup = () => {
    setShowOverlay(false);
  };

  const handleChangeGroup = () => {
    setShowChangeGroupPopup(!showChangeGroupPopup);
  };

  const handleChangeRole = () => {
    setShowOverlay(false);
  };

  const handleDeleteAccount = () => {
    setShowDeleteAccountPopup(!showDeleteAccountPopup);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedUserInfo = [...filteredUserInfo].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });

  const handleEmailMouseMove = (email, event) => {
    const emailElement = emailRefs.current[email];
    if (emailElement && emailElement.contains(event.target)) {
      setHoveredEmail(email);
      const rect = emailElement.getBoundingClientRect();
      setEmailOverlayPosition({ top: rect.top + window.scrollY, left: rect.left });
    } else {
      setHoveredEmail(null);
    }
  };

  return (
    <>
      {showNewAccountPopup && (
        <div className="overlay">
          <NewAccountPopUp closePopup={toggleNewAccountPopup} />
        </div>
      )}
      {showManageAccountPopup && (
        <div className="overlay">
          <ManageGroupsPopUp closePopup={toggleManageAccountPopup} />
        </div>
      )}
      {showChangeGroupPopup && (
        <div className="overlay">
          <ChangeGroupPopUp
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
            closePopup={handleChangeGroup}
          />
        </div>
      )}
      {showDeleteAccountPopup && (
        <div className="overlay">
          <DeleteAccountPopUp
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
            closePopup={handleDeleteAccount}
          />
        </div>
      )}
      {showModifyAccountPopup && (
        <div className="overlay">
          <ModifyAccountPopUp
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
            closePopup={handleModifyAccount}
          />
        </div>
      )}
      {showBackupPopup && (
        <div className="overlay">
          <div className="popupBackup">
            <h2>Backup Accounts</h2>
            <div style={{ marginTop: "10px" }} className="new-account-instructions">
              Export your company account
            </div>
            <div className="excelpdfdivbackup">
              <label>
                <input
                  type="radio"
                  value="excel"
                  checked={selectedFormat === "excel"}
                  onChange={() => setSelectedFormat("excel")}
                />
                <span> </span>
                Excel
              </label>
              <label>
                <input
                  type="radio"
                  value="pdf"
                  checked={selectedFormat === "pdf"}
                  onChange={() => setSelectedFormat("pdf")}
                />
                <span> </span>
                PDF
              </label>
            </div>
            <div className="buttonsbackupaccount">
              <button className="popupBackupBtn2" onClick={closeBackupPopup}>
                Cancel
              </button>
              <button className="popupBackupBtn" onClick={handleDownload}>
                Download
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="account-list-widget">
        <div className="widget-headerAccount">
          <button onClick={toggleNewAccountPopup}>New Account</button>
          <button onClick={toggleManageAccountPopup} className="buttonAccountListWithDivide">
            Manage Groups
          </button>
          <button onClick={openBackupPopup}>Backup Accounts</button>
          <AzureAuthButton />
        </div>
        <table className="account-table">
          <thead>
            <tr>
              <th className="noiconthAccountList">
                <input
                  style={{ marginRight: "27px" }}
                  type="checkbox"
                  id="select-all"
                  checked={selectedItems.length === filteredUserInfo.length}
                  onChange={handleSelectAll}
                />
              </th>
              <th onClick={() => handleSort("email")}>Account ID</th>
              <th onClick={() => handleSort("Sso")}>Sign On</th>
              <th onClick={() => handleSort("group")}>Group</th>
              <th onClick={() => handleSort("package")}>Package</th>
              <th onClick={() => handleSort("lastLogin")}>Last Login</th>
              <th onClick={() => handleSort("createdAt")}>Date of Creation</th>
            </tr>
          </thead>
          <tbody>
            {sortedUserInfo.length === 0 ? (
              <tr>
                <td colSpan="7" className="no-users-message">
                  {userInfo.length === 0
                    ? "Add or Sync groups and Users with Azure"
                    : "No matching accounts found"}
                </td>
              </tr>
            ) : (
              sortedUserInfo.map((user) => (
                <tr key={user._id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedItems.findIndex((item) => item._id === user._id) !== -1}
                      onChange={() => handleSelectItem(user)}
                    />
                  </td>
                  <td onMouseMove={(event) => handleEmailMouseMove(user.email, event)}>
                    <span ref={(el) => (emailRefs.current[user.email] = el)}>{user.email}</span>
                  </td>
                  <td>
                    {user.Sso ? (
                      <img
                        style={{ width: "22px", objectFit: "contain" }}
                        src="/playground_assets/azuretable.svg"
                        alt="Azure"
                      />
                    ) : (
                      <img
                        style={{ width: "22px", objectFit: "contain" }}
                        src="/playground_assets/weagletable.svg"
                        alt="Weagle"
                      />
                    )}
                  </td>
                  <td>{user.group.length > 15 ? `${user.group.slice(0, 15)}...` : user.group}</td>
                  <td>{user.package}</td>
                  <td>{user.lastLogin ? new Date(user.lastLogin).toLocaleString() : "N/A"}</td>
                  <td>{user.createdAt ? new Date(user.createdAt).toLocaleString() : "N/A"}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {selectedItems.length > 0 && (
        <ManageAccountsPopup
          selectedItems={selectedItems}
          handleModifyAccount={handleModifyAccount}
          handleAddToGroup={handleAddToGroup}
          handleChangeGroup={handleChangeGroup}
          handleChangeRole={handleChangeRole}
          handleDeleteAccount={handleDeleteAccount}
        />
      )}
      {showOverlay && (
        <div className="overlay">
          <div className="overlay-content">
            <h2>Confirmation</h2>
            <p>Are you sure you want to perform this action?</p>
            <div className="overlay-buttons">
              <button onClick={handleModifyAccount}>Modify Account</button>
              <button onClick={handleAddToGroup}>Add To a Group</button>
              <button onClick={handleChangeGroup}>Change Group</button>
              <button onClick={handleChangeRole}>Change Role</button>
              <button onClick={handleDeleteAccount}>Delete Account</button>
              <button onClick={() => setShowOverlay(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      {hoveredEmail && (
        <div
          className="email-overlay"
          style={{
            top: emailOverlayPosition.top,
            left: emailOverlayPosition.left,
          }}
        >
          {hoveredEmail}
        </div>
      )}
    </>
  );
};

export default AccountListWidgetComponent;