import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import SideBar from "../components/side-bar";
import SearchBar1 from "../components/search-bar1";
import ProfileSide from "../components/profile-side";
import TabPowerSearch from "../components/tab-power-search";
import "./power-search.css";
import { SearchContext } from "../context/search_context";
import Hamburgher from "../components/hamburgher-menu";
import VideoSearchResult from "../components/video-search-result";
import { useHistory } from "react-router-dom";
import NewmenuDesktop from "../components/newmenudesktop";
import LoaderSearch from "../components/loadersearch";
import LoaderSearchMobile from "../components/loaderSearchMobile";

const VideoSearch = (props) => {
  let history = useHistory();
  const { 
    setTab, 
    videoSearch, 
    loader, 
    handleVideoSearch, 
    youtubePageTokens, 
    accountType, 
    auth 
  } = useContext(SearchContext);

  useEffect(() => {
    setTab("video");
  }, [setTab]);

  return (
    <div style={{overflow:"hidden"}} className="power-search-container">
      <Helmet>
        <title>Video - Weagle</title>
        <meta property="og:title" content="PowerSearch - Weagle" />
      </Helmet>
      <div className="overlaymenu"></div>
      <div className="menumobile">
        <ProfileSide />
        <img 
          style={{ cursor:'pointer', marginBottom:'20px'}} 
          onClick={() => { history.push("/"); }} 
          src="/playground_assets/logoweaglenew.svg" 
          alt="weagleLogo" 
          className="menumobileLogo" 
        />
        <Hamburgher />
      </div>
      <div style={{overflow:"hidden"}} className="power-search-container-principale">
        <div className="power-search-container1">
          <SideBar rootClassName="side-bar-root-class-name2"/>
        </div>
        <div className="power-search-container2">
          <SearchBar1 rootClassName="search-bar1-root-class-name" />
        </div>
        <div className="menudesktop">
          <div className="newmenucompleto">
            <NewmenuDesktop accountType={accountType} auth={auth} />
            <ProfileSide />
          </div> 
        </div>
      </div>
      <div className="power-search-tabs-filters-divNews">
        <div className="aziende-search-tabs-filters-div">
          <TabPowerSearch
            type={"video"}
            rootClassName="tab-power-search-root-class-name"
          />
        </div>
      </div>
      <div className="power-search-results-divvideo">
        {loader ? (
          <>
          
          
              <LoaderSearchMobile />
              <LoaderSearch />
      
          </>
        ) : (
          <>
            {videoSearch.map((item, index) => (
              <VideoSearchResult key={index} {...item} loader={loader} />
            ))}
            <div style={{marginBottom:'30px'}} className="video-search-btn">
              {youtubePageTokens?.prevPageToken && (
                <div onClick={() => handleVideoSearch(youtubePageTokens?.prevPageToken)} className="bottone-generico1-container">
                  <span className="bottone-generico1-testobottonegenerico">
                    <span>{"Indietro"}</span>
                  </span>
                </div>
              )}
              {youtubePageTokens?.nextPageToken && (
                <div onClick={() => handleVideoSearch(youtubePageTokens?.nextPageToken)} className="bottone-generico1-container">
                  <span className="bottone-generico1-testobottonegenerico">
                    <span>{"Avanti"}</span>
                  </span>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default VideoSearch;