import React, { useState, useEffect, useRef } from 'react';
import './dashboardDatePicker.css';

// Helper function to format the date in "1 May - 2024" format.
export const formatDate = (date) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};

// Helper function to subtract days from a date.
const subtractDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};

const DashboardDatePicker = ({ onDateRangeSelected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState(() => {
    const today = new Date();
    const startDate = subtractDays(today, 0.1);
    return {
      start: startDate,
      end: today,
      label: formatDate(startDate) + ' - ' + formatDate(today)
    };
  });

  const popupRef = useRef();

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleDateRangeSelection = (days) => {
    const endDate = new Date();
    const startDate = subtractDays(endDate, days - 1);
    const newDateRange = {
      start: startDate,
      end: endDate,
      label: formatDate(startDate) + ' - ' + formatDate(endDate)
    };
    setDateRange(newDateRange);
    setIsOpen(false);
    onDateRangeSelected(newDateRange); // Call the callback function with the new date range
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Call the callback function with the initial date range
    onDateRangeSelected(dateRange);
  }, []);

  return (
    <div className="dashboard-date-picker">
     
      <div className="date-display">{dateRange.label}</div>
      <div className='datessourcetimerangediv'>
        {/* <div className="toggle-button" onClick={() => setIsOpen(!isOpen)}>Date Source ▼</div> */}
        <div className="toggle-button" onClick={() => setIsOpen(!isOpen)}>Time Range ▼</div>
      </div>
      <div ref={popupRef} className={`date-range-popup ${isOpen ? 'open' : ''}`}>
        <div className="date-range-option" onClick={() => handleDateRangeSelection(1.1)}>Today</div>
        <div className="date-range-option" onClick={() => handleDateRangeSelection(7)}>Last 7 days</div>
        <div className="date-range-option" onClick={() => handleDateRangeSelection(30)}>Last 30 days</div>
      </div>
    </div>
  );
};

export default DashboardDatePicker;
