import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import "./newmenudesktop.css";

const translations = {
  it: {
    archivio: "Archivio",
    // Add other Italian translations here
  },
  en: {
    archivio: "Archive",
    // Add other English translations here
  }
};

function translate(key, lang) {
  const italianTranslations = translations.it;
  if (lang === 'it' && italianTranslations[key]) {
    return italianTranslations[key];
  }
  return translations.en[key];
}

const NewmenuDesktop = ({ accountType, auth }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser
  const text01Side = translate('archivio', lang);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="menu-container" ref={menuRef}>
      <div className="menu-icon" onClick={toggleMenu}>
        <img src="/playground_assets/newmenuhamburger.svg" alt="Menu" style={{ width: '35px', height: '35px' }} />
      </div>
      {isMenuVisible && (
        <div className="menu-content">
          <ul>
          <li>
                <NavLink to="/power-search" activeClassName="active-background">
                  <button className={`sidebar-nav-item-dashboardnew`}>
                    <img src="/playground_assets/securesearch.svg" alt="Accounts" style={{ width: '28px' }} /> Secure Search
                  </button>
                </NavLink>
              </li>
         
              <li>
                <NavLink to="/chat" activeClassName="active-background">
                  <button className={`sidebar-nav-item-dashboardnew`}>
                    <img src="/playground_assets/iconaaiblu.svg" alt="Accounts" style={{ width: '28px' }} /> Confidential AI
                  </button>
                </NavLink>
              </li>
         
           
              <li>
                <NavLink to="/data-wallet" activeClassName="active-background">
                  <button className={`sidebar-nav-item-dashboardnew`}>
                    <img src="/playground_assets/walletblu.svg" alt="Accounts" style={{ width: '28px' }} /> Data Wallet
                  </button>
                </NavLink>
              </li>
           
            <li>
              <NavLink to="/feed" activeClassName="active-background">
                <button className={`sidebar-nav-item-dashboardnew`}>
                  <img src="/playground_assets/journalblu.svg" alt="Accounts" style={{ width: '28px' }} /> Digital Journal
                </button>
              </NavLink>
            </li>
            <li>
              <NavLink to="/archivio" activeClassName="active-background">
                <button className={`sidebar-nav-item-dashboardnew`}>
                  <img src="/playground_assets/archivioblu.svg" alt="Accounts" style={{ width: '28px' }} /> {text01Side}
                </button>
              </NavLink>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default NewmenuDesktop;