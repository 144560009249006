import React, { useState, useContext } from 'react';
import './deleteAccountPopUp.css';
import { AuthContext } from "../context/authContext";

const DeleteAccountPopUp = ({ closePopup, selectedItems, setSelectedItems }) => {
  const { deleteAccounts } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      await deleteAccounts(selectedItems);
      console.log("Accounts deleted successfully");
      closePopup();
      setSelectedItems([]);
    } catch (error) {
      console.error("Error deleting accounts:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    if (!isLoading) {
      closePopup();
    }
  };

  return (
    <div className="new-account-modal">
      <div style={{ minWidth: '410px', width: '410px', height: '217px', minHeight: '217px' }} className="new-account-content">
        {!isLoading && <span className="close" onClick={closePopup}>&times;</span>}
        <div className="new-account-header">Delete Accounts</div>
        <div className="new-account-instructions">
          {isLoading ? "Deleting accounts..." : "Are you sure you want to delete the selected accounts?"}
        </div>
        <div className='deleteaccountbuttons'>
          <button className="new-account-create-btn2" onClick={handleCancel} disabled={isLoading}>
            Cancel
          </button>
          <button className="new-account-create-btn" onClick={handleConfirm} disabled={isLoading}>
            {isLoading ? "Deleting..." : "Confirm"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountPopUp;