import React, {useEffect, useState} from "react";

import "./invite-pop-up.css";
import Loader from "./loader";
import axios from "axios";
import {endPoints} from "../utils";
import {validateEmail} from "../services";


const ShareFolderInvite = (props) => {
  const [loader, setLoader] = useState(false);
  const [folderId, setFolderId] = useState();
  const [email, setEmail] = useState();
  useEffect(()=>{
    const params = new URLSearchParams(props.location.search);
    const email = params.get('email');
    const id = params.get('id');
    setFolderId(id);
    setEmail(email);

  },[]);

  const handleRequest = async () => {
    if(!folderId || !validateEmail(email)){
      alert('Invalid request')
    }
    setLoader(true);
    const res = await axios.post(
      endPoints.actionFolderInvite,
      { folder_id: folderId, email: email },
    );
    setLoader(false);
    alert(res?.data?.message);
  }


  return (
    <div className="invited-pop-up-container">
      <div className="invite-pop-up-frame5197">
        <div className="invite-pop-up-frame5191">
          <span className="invite-pop-up-text">
            <span>benvenuto su Weagle</span>
          </span>
          <span className="invite-pop-up-text02">
            <span>
                Sei stato invitato alla condivisione di una cartella
            </span>
          </span>
        </div>

        {loader && <Loader className={"loaderFeed"} />}
        {
          !loader && <div className="invite-pop-up-frame5195">
            <button onClick={()=> handleRequest()} className="invite-pop-up-frame13button">
              <span className="invite-pop-up-text14">
                <span>Accetta</span>
              </span>
            </button>
          </div>
        }

        </div>
      </div>
  );
};

export default ShareFolderInvite;
