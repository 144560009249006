import React, { useState } from 'react';
import './changeHomeButton.css'
const ChangeHomepageButton = () => {
  const [homepage, setHomepage] = useState('https://chrome.google.com/webstore/detail/weagle-powersearch/binkonkoddicjfkccnifkfgeacecbgde');

  const handleClick = () => {
    if (window.chrome) {
    setHomepage('https://chrome.google.com/webstore/detail/weagle-powersearch/binkonkoddicjfkccnifkfgeacecbgde');
    window.location.assign(homepage);
    } else {
    alert("This feature is only supported in Google Chrome");
    }
    };
    const translations = {
      en: {
       
        'ppps1pred': "Set as default search engine",
     
      },
      it: {
  
    
        'ppps1pred': 'Imposta come motore predefinito',
  
      },
    };
    function translate(key, lang) {
      const italianTranslations = translations.it;
    
      if (lang === 'it' && italianTranslations[key]) {
        return italianTranslations[key];
      }
    
      return translations.en[key];
    }
    const lang = navigator.language.split('-')[0]; // Get the user's language from the browser
  
    const ppps1preda = translate('ppps1pred', lang);
  return (
   
      <div onClick={handleClick} className='motorepredefdiv'>
  <span >{ppps1preda}</span>
      </div>
  
  );
};

export default ChangeHomepageButton;