import React from "react";
import { useContext } from "react";
import { AuthContext } from "../context/authContext";

import "./enterprise-sign-up.css";
import SignUpField from "./signUpField";
import Loader from "./loader";

const EnterpriseSignUp = (props) => {


  const translations = {
    en: {
     
      'ppps2usersign1': "Business name",
      'ppps2usersign2': "VAT",
      'ppps2usersign3': "Business email",
      'ppps2usersign4': "Continue",
      'ppps2usersign5': "Set as default search engine",
   
    },
    it: {

  
      'ppps2usersign1': "Azienda",
      'ppps2usersign2': "P.IVA",
      'ppps2usersign3': "Email aziendale",
      'ppps2usersign4': "Continua",
      'ppps2usersign5': "Set as default search engine",

    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser

  const ppps2usersigna = translate('ppps2usersign1', lang);
  const ppps2usersigna1 = translate('ppps2usersign2', lang);
  const ppps2usersigna2 = translate('ppps2usersign3', lang);
  const ppps2usersigna3 = translate('ppps2usersign4', lang);
  const ppps2usersigna4 = translate('ppps2usersign5', lang);




  const {
    handelProfileEnterprice,
    handelEnterpriceSignUp,
    firstName,
    setFirstName,
    iva,
    setIva,
    email,
    setEmail,
    password,
    setPassword,
    loading,
    setSelectedSignUpType,
  } = useContext(AuthContext);

  return (
    <div className="enterprise-sign-up-container">
      <div className="enterprise-sign-up-frame5144">
        <SignUpField
          label={ppps2usersigna}
          placeHolder={"Weagle s.r.l."}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />

        <SignUpField
          label={ppps2usersigna1}
          placeHolder={"000000000000000000"}
          value={iva}
          onChange={(e) => setIva(e.target.value)}
        />
        <SignUpField
          label={ppps2usersigna2}
          placeHolder={"mario@weagle.it"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type={'email'}
          autoComplete={'email'}
        />

        <SignUpField
          label={"Password"}
          placeHolder={"*********"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type={'password'}
          autoComplete={'password'}
        />

        {loading && <Loader className={"loaderFeed"} />}

        {!loading && (
          <div className="enterprise-sign-up-frame13">
            <span className="enterprise-sign-up-text16">
              <span
                onClick={() => {
                  handelEnterpriceSignUp();
                }}
              >
              {ppps2usersigna3}
              </span>
            </span>
          </div>
        )}

        <div
          onClick={() => setSelectedSignUpType("login")}
          className="enterprise-sign-up-frame5143"
        >
          <span className="enterprise-sign-up-text18">
            <span>Login</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default EnterpriseSignUp;
