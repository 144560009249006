import React from 'react'

import PropTypes from 'prop-types'
import { useForm } from '@formcarry/react';
import './contatti-integrato.css'
 
const ContattiIntegrato = (props) => {
  const translations = {
    en: {
     
      'ppps1segnala': "Report a problem to our team",
      'ppps1segnala2': "Report a technical problem or give advice on the development of a new feature.",
      'ppps1segnala3': "For administrative requests, please contact",
      'ppps1segnala4': "For support from the tech department, please contact",
      'ppps1segnala5': "Company email",
      'ppps1segnala6': "Company",
      'ppps1segnala7': "Problem description",
      'ppps1segnala8': "Send request",
      'ppps1segnala9': "Message",
      'ppps1segnala10': "Thank you for reporting the issue, our developers are already working on it!",
    },
    it: {

  
      'ppps1segnala': 'Segnala un problema al nostro team',
      'ppps1segnala2': 'Segnala un problema tecnico o dai un consiglio sullo sviluppo di una nuova funzionalità.',
      'ppps1segnala3': "Per richieste amministrative contattare",
      'ppps1segnala4': "Per supporto dal reparto tech contattare",
      'ppps1segnala5': "Mail aziendale",
      'ppps1segnala6': "Azienda",
      'ppps1segnala7': "Descrizione del problema riscontrato",
      'ppps1segnala8': "Invia richiesta",
      'ppps1segnala9': "Messaggio",
      'ppps1segnala10': "Grazie per aver segnalato il problema, i nostri sviluppatori sono già al lavoro!",
    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser

  const ppps1segnal = translate('ppps1segnala', lang);
  const ppps1segnal2 = translate('ppps1segnala2', lang);
  const ppps1segnal3 = translate('ppps1segnala3', lang);
  const ppps1segnal4 = translate('ppps1segnala4', lang);
  const ppps1segnal5 = translate('ppps1segnala5', lang);
  const ppps1segnal6 = translate('ppps1segnala6', lang);
  const ppps1segnal7 = translate('ppps1segnala7', lang);
  const ppps1segnal8 = translate('ppps1segnala8', lang);
  const ppps1segnal9 = translate('ppps1segnala9', lang);
  const ppps1segnal10 = translate('ppps1segnala10', lang);
   // Call the "useForm" hook in your function component
   const {state, submit} = useForm({
    id: 'YpEFQx7LrG'
  });
  if (state.submitted) {
    return <div style={{fontFamily:"Encode Sans Expanded", margin:'100px', fontSize: '20px'}}> {ppps1segnal10}</div>;
  }
  return (
    <div id="contattaci" className="contatti-integrato-contatti-integrato">
      <div className="contatti-integrato-free-demo-div">
        <span className="contatti-integrato-text">{props.text}</span>
        <span className="contatti-integrato-text01">{props.text1}</span>
      </div>
     
      <div className="contatti-integrato-contact-form">
      <form onSubmit={submit}>
        <div className="contatti-integrato-contact-form-c2">
          <div className="contatti-integrato-form-frame3">
            <div className="contatti-integrato-frame33">
              <span className="contatti-integrato-text02">{ppps1segnal5}</span>
            </div>
            
            <input
              id="email" type="email" name="email"
              placeholder={props.Frame31input_placeholder}
              className="contatti-integrato-frame31input"
            />
          </div>
          {/* <div className="contatti-integrato-form-frame2">
            <div className="contatti-integrato-frame331">
              <span className="contatti-integrato-text03">{props.text4}</span>
            </div>
            <input
            id="numero" type="number" name="numero"
             
              placeholder={props.Frame31input_placeholder1}
              className="contatti-integrato-frame31input1"
            />
          </div> */}
          <div className="contatti-integrato-form-frame1">
            <div className="contatti-integrato-frame332">
              <span className="contatti-integrato-text04">{ppps1segnal6}</span>
            </div>
            <input
            id="azienda"  name="azienda"
              type="text"
              placeholder={props.Frame31input_placeholder2}
              className="contatti-integrato-frame31input2"
            />
          </div>
          <div className="contatti-integrato-form-frame4">
            <div className="contatti-integrato-frame333">
              <span className="contatti-integrato-text05">{ppps1segnal7}</span>
            </div>
            <textarea
             id="messaggio"  name="messaggio"
              type="text"
              placeholder={ppps1segnal9}
              className="contatti-integrato-frame31input3"
            />
          </div>
          <button  style={{cursor:'pointer'}} type="submit" className="contatti-integrato-buttonbutton">
            <span className="contatti-integrato-text06">{ppps1segnal8}</span>
          </button>
          
        </div>
        </form>
        <div className="contatti-integrato-container">
          <div className="contatti-integrato-contact-form-c1">
            <div className="contatti-integrato-free-demo-div1">
              <span className="contatti-integrato-text07">{ppps1segnal}</span>
              <span className="contatti-integrato-text08">{ppps1segnal2}</span>
            </div>
            <div className="contatti-integrato-contact-info-div">
              <div className="contatti-integrato-contact-info-div-c1">
                <span className="contatti-integrato-text09">
                  <span>weagle</span>
                </span>
                <span className="contatti-integrato-text11">
                  <span>
                    <span>Weagle srl</span>
                    <br></br>
                    <span>Via Camillo Benso C Di Cavour</span>
                    <br></br>
                    <span>7420094 Corsico (MI)</span>
                    <br></br>
                    <span>P.IVA: 11492050965</span>
                  </span>
                </span>
              </div>
              <div className="contatti-integrato-contact-info-div-c2">
                <div className="contatti-integrato-richieste-amm-div">
                  <span className="contatti-integrato-text20">
                    <span>{ppps1segnal3}</span>
                  </span>
                  <div className="contatti-integrato-group1000001662">
                    <span className="contatti-integrato-text22">
                      <span>
                        info@weagle.it &gt;
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                    </span>
                    <img
                      alt={props.Line2_alt}
                      src={props.Line2_src}
                      className="contatti-integrato-line2"
                    />
                  </div>
                </div>
                <div className="contatti-integrato-tech">
                  <span className="contatti-integrato-text24">
                    <span>{ppps1segnal4}</span>
                  </span>
                  <div className="contatti-integrato-group10000016621">
                    <span className="contatti-integrato-text26">
                      <span>
                        digital.weagle@gmail.com &gt;
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                    </span>
                    <img
                      alt={props.Line2_alt1}
                      src={props.Line2_src1}
                      className="contatti-integrato-line21"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ContattiIntegrato.defaultProps = {
  text: 'Segnala un problema al nostro team',
  text1:
    'Segnala un problema tecnico o dai un consiglio sullo sviluppo di una nuova funzionalità.',
  Frame31input_placeholder: 'mario@weagle.it',
  Frame31input_placeholder1: '+39 349 1234567',
  Frame31input_placeholder2: 'Weagle s.r.l.',
  Frame31input_placeholder3: 'Messaggio',
  text2: 'Invia richiesta',
  text3: 'Email',
  text4: 'Telefono',
  text5: 'Azienda',
  text6: 'Descrizione del problema riscontrato',
  text7: 'Segnala un problema al nostro team',
  text8:
    'Segnala un problema tecnico o dai un consiglio sullo sviluppo di una nuova funzionalità.',
  Line2_src: '/playground_assets/line23711-v1e8.svg',
  Line2_alt: 'Line23711',
  Line2_src1: '/playground_assets/line23711-vl2v.svg',
  Line2_alt1: 'Line23711',
}

ContattiIntegrato.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
  Frame31input_placeholder: PropTypes.string,
  Frame31input_placeholder1: PropTypes.string,
  Frame31input_placeholder2: PropTypes.string,
  Frame31input_placeholder3: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  text4: PropTypes.string,
  text5: PropTypes.string,
  text6: PropTypes.string,
  text7: PropTypes.string,
  text8: PropTypes.string,
  Line2_src: PropTypes.string,
  Line2_alt: PropTypes.string,
  Line2_src1: PropTypes.string,
  Line2_alt1: PropTypes.string,
}

export default ContattiIntegrato
