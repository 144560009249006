import React, { useState, useCallback } from "react";
import "./popUpGroupManagement.css";

const NewGroupPopUp = () => {
    const [selectedGroup, setSelectedGroup] = useState(""); // State to hold the selected group
    const groups = ["Group 1", "Group 2", "Group 3"]; // Example groups. Replace with actual data source if necessary

    const onBottonenewGroupClick = useCallback(() => {
        // Sync "Dashboard - Desktop - Accounts" to the project
        // Handle the action to be taken after selecting a group and clicking "Done"
        console.log("Selected Group:", selectedGroup); // Example action
    }, [selectedGroup]);

    const handleGroupChange = (event) => {
        setSelectedGroup(event.target.value);
    };

    return (
        <div className="account-move-accounts-to-groNewGroupPopUp">
            <section className="frame-containerNewGroupPopUp">
                <div className="frame-container-childNewGroupPopUp" />
                <div className="nested-frameNewGroupPopUp">
                    <div className="move-accounts-labelNewGroupPopUp">
                        <h3 className="move-accountsNewGroupPopUp">New Group</h3>
                    </div>
                    <div className="choose-the-groupNewGroupPopUp">
                    Please enter the name of the group you intend to create.
                    </div>
                </div>
                <div >
                    <div />
                    {/* Dropdown for choosing a group */}
                    <select
    className="backgroundNewGroupPopUp"
    value={selectedGroup}
    onChange={handleGroupChange}
>
    <option value="">Select a Group</option>
    {groups.map((group, index) => (
        <option key={index} value={group}>
            {group}
        </option>
    ))}
</select>
                    <img className="iconNewGroupPopUp" alt="" src="/icon.svg" />
                </div>
                <div className="button-group-instanceNewGroupPopUp">
                    <button className="bottone-gruppoNewGroupPopUp" onClick={onBottonenewGroupClick}>
                        <div className="rectangle-shape-parentNewGroupPopUp">
                            <div className="rectangle-shapeNewGroupPopUp" />
                            <div className="createNewGroupPopUp">Create</div>
                        </div>
                    </button>
                </div>
            </section>
        </div>
    );
};

export default NewGroupPopUp;
