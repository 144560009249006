import React, { useContext } from "react";

import PropTypes from "prop-types";

import "./bottone-generico1.css";
import { AuthContext } from "../context/authContext";

const BottoneGenerico1 = (props) => {
  const { setShowSignUp, setSelectedSignUpType } = useContext(AuthContext);
  return (
    <div
      onClick={() => {
        setSelectedSignUpType("login");
        setShowSignUp((prev) => !prev);
      }}
      className={`bottone-generico1-container1 ${props.rootClassName} `}
    >
      <span className="bottone-generico1-testobottonegenerico">
        <span>Login</span>
        <br></br>
      </span>
    </div>
  );
};

BottoneGenerico1.defaultProps = {
  rootClassName: "",
};

BottoneGenerico1.propTypes = {
  rootClassName: PropTypes.string,
};

export default BottoneGenerico1;
