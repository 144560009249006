import React, { useContext, useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  StreetViewPanorama,
  HeatmapLayer,
  TrafficLayer,
} from "@react-google-maps/api";
import { SearchContext } from "../context/search_context";
import axios from "axios";
import "./mapStyle.css";

function MapComponent({ searchValue }) {
  const { loader } = useContext(SearchContext);
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState({ lat: 40.7128, lng: -74.0060 });
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [distance, setDistance] = useState(null);
  const [streetViewVisible, setStreetViewVisible] = useState(false);
  const [heatmapData, setHeatmapData] = useState([]);
  const [trafficVisible, setTrafficVisible] = useState(false);
  const [nearbyPlaces, setNearbyPlaces] = useState([]); // New state for storing nearby places
  const [enterKeyPressed, setEnterKeyPressed] = useState(false);
  useEffect(() => {
    if (enterKeyPressed && searchValue) {
      const fetchCoordinates = async () => {
        const fetchCoordinates = async () => {
          try {
            const response = await axios.get(
              `https://afternoon-island-31692.herokuapp.com/https://maps.googleapis.com/maps/api/geocode/json?address=${searchValue}&key=AIzaSyAUnqrijWNKCls6b_Qb_iQcXnUyPfyR_7g`
            );
  
            const results = response.data.results;
            if (results.length > 0) {
              const { lat, lng } = results[0].geometry.location;
              setCenter({ lat, lng });
              setSelectedPlace(results[0]);
            } else {
              console.error("No results found for the search query.");
            }
          } catch (error) {
            console.error("Error fetching coordinates:", error);
          }
        };
  
  
        // Reset enterKeyPressed after fetching data
        setEnterKeyPressed(false);
      };
  
      fetchCoordinates();
    }
  }, [searchValue, enterKeyPressed]);
  useEffect(() => {
    if (center) {
      const fetchNearbyPlaces = async () => {
        try {
          const response = await axios.get(
            `https://afternoon-island-31692.herokuapp.com/https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${center.lat},${center.lng}&radius=1500&keyword=${searchValue}&key=AIzaSyAUnqrijWNKCls6b_Qb_iQcXnUyPfyR_7g`
          );

          setNearbyPlaces(response.data.results);
        } catch (error) {
          console.error("Error fetching nearby places:", error);
        }
      };

      fetchNearbyPlaces();
    }
  }, [center, searchValue]);

  useEffect(() => {
    if (searchValue) {
      const fetchCoordinates = async () => {
        try {
          const response = await axios.get(
            `https://afternoon-island-31692.herokuapp.com/https://maps.googleapis.com/maps/api/geocode/json?address=${searchValue}&key=AIzaSyAUnqrijWNKCls6b_Qb_iQcXnUyPfyR_7g`
          );

          const results = response.data.results;
          if (results.length > 0) {
            const { lat, lng } = results[0].geometry.location;
            setCenter({ lat, lng });
            setSelectedPlace(results[0]);
          } else {
            console.error("No results found for the search query.");
          }
        } catch (error) {
          console.error("Error fetching coordinates:", error);
        }
      };

      fetchCoordinates();
    }
  }, [searchValue]);

  const handleMarkerClick = (place) => {
    setSelectedPlace(place);
  
  };

  const handleInfoWindowCloseClick = () => {
    setSelectedPlace(null);
    setStreetViewVisible(false);
  };

  const handleStreetViewCloseClick = () => {
    setStreetViewVisible(false);
  };

  const handleMapLoad = (map) => {
    setMap(map);
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLoc = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setUserLocation(userLoc);
          // Set the default position to user's location
          setCenter(userLoc);
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (map && selectedPlace && userLocation) {
      map.panTo({
        lat: selectedPlace.geometry.location.lat,
        lng: selectedPlace.geometry.location.lng,
      });
      map.setZoom(15);

      const calculateDistance = async () => {
        const radianFactor = Math.PI / 180;
        const earthRadius = 6371; // in kilometers

        const lat1 = userLocation.lat * radianFactor;
        const lng1 = userLocation.lng * radianFactor;
        const lat2 = selectedPlace.geometry.location.lat * radianFactor;
        const lng2 = selectedPlace.geometry.location.lng * radianFactor;

        const diffLat = lat2 - lat1;
        const diffLng = lng2 - lng1;

        const a =
          Math.sin(diffLat / 2) * Math.sin(diffLat / 2) +
          Math.cos(lat1) * Math.cos(lat2) * Math.sin(diffLng / 2) * Math.sin(diffLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const distance = earthRadius * c;
        setDistance(distance.toFixed(2));
      };

      calculateDistance();
    }
  }, [map, selectedPlace, userLocation]);

  const handleNavigateClick = () => {
    if (selectedPlace && userLocation) {
      const { lat, lng } = selectedPlace.geometry.location;
      const url = `https://www.google.com/maps/dir/?api=1&origin=${userLocation.lat},${userLocation.lng}&destination=${lat},${lng}`;

      window.open(url, "_blank");
    }
  };

  const toggleTraffic = () => {
    setTrafficVisible(!trafficVisible); // Toggle traffic layer visibility
  };
  const centerOnUserLocation = () => {
    if (userLocation) {
      setCenter(userLocation);
      if (map) {
        map.panTo(userLocation);
        map.setZoom(15);
      }
    }
  };
  const mapOptions = {
    disableDefaultUI: true, // Hide the default UI controls
    styles: [
      {
        featureType: "all",
        elementType: "all",
        stylers: [
          {
            hue: "#ff9900",
          },
        ],
      },
    ],
  };
  const toggleStreetView = () => {
    setStreetViewVisible(!streetViewVisible);
  };


  const translations = {
    en: {
     
      'ppps1segnala': 'Center',
 
      'ppps1segnala2': "Realtime Traffic",
       
      'ppps1segnala3': "Navigate",
       
      'ppps1segnala4': "Distance",
   
    },
    it: {

  
      'ppps1segnala': 'Centra',
 
      'ppps1segnala2': "Traffico Realtime",
       
      'ppps1segnala3': "Naviga",
       
      'ppps1segnala4': "Distanza",
    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser

  const ppps1segnal = translate('ppps1segnala', lang);


  const ppps1segnal2 = translate('ppps1segnala2', lang);
  const ppps1segnal3 = translate('ppps1segnala3', lang);
  const ppps1segnal4 = translate('ppps1segnala4', lang);




  return (
    <LoadScript googleMapsApiKey="AIzaSyAUnqrijWNKCls6b_Qb_iQcXnUyPfyR_7g">
    <div style={{ position: "relative" }}>
   
      {/* Button to toggle traffic layer */}
      <button
        style={{ position: "absolute", top: "10px", right: "10px", zIndex: 1 }}
        onClick={toggleTraffic}
      >
        {ppps1segnal2}
      </button>
      <button
          style={{ position: "absolute", top: "10px", right: "220px", zIndex: 1 }}
          onClick={centerOnUserLocation}
        >
          {ppps1segnal}
        </button>
      <GoogleMap
    mapContainerClassName="google-map-container"
        center={center}
        zoom={10}
        onLoad={handleMapLoad}
        options={mapOptions}
      >
        {selectedPlace && (
          <Marker
            position={{
              lat: selectedPlace.geometry.location.lat,
              lng: selectedPlace.geometry.location.lng,
            }}
            onClick={() => handleMarkerClick(selectedPlace)}
          />
        )}

        {/* Map through nearbyPlaces to display them as markers */}
        {nearbyPlaces.map((place) => (
          <Marker
            key={place.place_id}
            position={{
              lat: place.geometry.location.lat,
              lng: place.geometry.location.lng,
            }}
            onClick={() => handleMarkerClick(place)}
          />
        ))}

{selectedPlace && (
            <InfoWindow
              position={{
                lat: selectedPlace.geometry.location.lat,
                lng: selectedPlace.geometry.location.lng,
              }}
              onCloseClick={handleInfoWindowCloseClick}
            >
              <div style={{display:'flex', flexDirection:'column', gap:'10px'}}>
                <h4>{selectedPlace.name}</h4>
                <p>{selectedPlace.formatted_address}</p>
                {userLocation && <p>{ppps1segnal4}: {distance} km</p>}
                <button onClick={handleNavigateClick}>{ppps1segnal3}</button>
                {/* Button to toggle StreetView */}
                <button onClick={toggleStreetView}>Street View</button>
              </div>
            </InfoWindow>
          )}
          {streetViewVisible && selectedPlace && (
            <StreetViewPanorama
              position={{
                lat: selectedPlace.geometry.location.lat,
                lng: selectedPlace.geometry.location.lng,
              }}
              visible={streetViewVisible}
              onCloseClick={handleStreetViewCloseClick}
            />
          )}

    

        {heatmapData.length > 0 && (
          <HeatmapLayer data={heatmapData} options={{ radius: 20 }} />
        )}

        {trafficVisible && <TrafficLayer autoUpdate />}
      </GoogleMap>
    </div>
  </LoadScript>
  );
}

export default React.memo(MapComponent);
