import React, { useState, useEffect } from "react";
import cheerio from "cheerio";
import PropTypes from "prop-types";
import useLinkPreview from "../context/useLinkPreview";
import "./feed-preview.css";
import { useHistory } from "react-router-dom";
import Loader from "./loader";
import { deleteSourceRequest } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const translations = {
  en: {
   
    'pppsign1': 'You cannot delete a default source!',
    'pppsign2': 'User',
    'pppsign3': 'Business',
    'pppsign4': 'Back',
  },
  it: {


    'pppsign1': 'Non puoi cancellare una fonte predefinita!',
    'pppsign2': 'Utente',
    'pppsign3': 'Azienda',
    'pppsign4': 'Indietro',
 
  },
};
function translate(key, lang) {
  const italianTranslations = translations.it;

  if (lang === 'it' && italianTranslations[key]) {
    return italianTranslations[key];
  }

  return translations.en[key];
}
const lang = navigator.language.split('-')[0]; // Get the user's language from the browser

const pppsign1a = translate('pppsign1', lang);
const pppsign2a = translate('pppsign2', lang);
const pppsign3a = translate('pppsign3', lang);
const pppsign4a = translate('pppsign4', lang);
const FeedPreviewItem = (props) => {
  const { index, link, setShowFolderModal, setData, title } = props;


  const ImageAlgorithmNews = (offset, props) => {
    const [imageSource, setImageSource] = useState("");
    const [loader, setLoader] = useState(false);
    const [controller, setController] = useState(new AbortController());
  
    useEffect(() => {
      setImageSource("");
      setLoader(true);
  
      const timer = setTimeout(() => {
        getPreview();
      }, offset * 1000);
  
      return () => {
        clearTimeout(timer);
        setController(new AbortController());
      };
    }, [title]);
  
    const getPreview = (retryCount = 0, searchQuery = title) => {
      const truncatedTitle = title.split(" ").slice(0, 7).join(" ");
      const initialQuery = searchQuery === title ? truncatedTitle : searchQuery;
  
      setLoader(true);
  
      const bingImagesUrl = `https://www.bing.com/images/search?q=${encodeURIComponent(initialQuery)}`;
  
      fetch(`https://afternoon-island-31692.herokuapp.com/${bingImagesUrl}`)
        .then((imagesResponse) => {
          if (imagesResponse.status === 404 || imagesResponse.redirected) {
            if (retryCount < 10) {
              const words = searchQuery.split(" ");
              const newSearchQuery = words.slice(0, -1).join(" ");
              getPreview(retryCount + 1, newSearchQuery);
            } else {
              throw new Error("Image not found");
            }
          } else {
            return imagesResponse.text();
          }
        })
        .then((imagesHtml) => {
          const $ = cheerio.load(imagesHtml);
          const images = $('img.mimg').filter(function () {
            const width = parseInt($(this).attr('width'));
            const height = parseInt($(this).attr('height'));
            if (width && height) {
              return width/height >= 1.5;
            }
          });
          if (images.length > 0) {
            const imageSource = images.eq(0).attr('src');
            setImageSource(imageSource);
            setLoader(false);
          } else {
            throw new Error("Image not found");
          }
        })
        .catch((err) => {
          console.log(err);
          if (retryCount < 10) {
            const words = searchQuery.split(" ");
            const newSearchQuery = words.slice(0, -1).join(" ");
            getPreview(retryCount + 1, newSearchQuery);
          } else {
            setLoader(false);
            console.error("Failed to find an image after multiple retries");
          }
        });
    };
  
    return { imageSource, loader };
  };



  const { imageSource,  loader } = ImageAlgorithmNews(props.source?.url, index);

  const openNewLink = (link) => {
    window.open(props.link);
  };

  return (
    <div className="feed-preview-container04" onClick={openNewLink}>
      <div className="feed-preview-container05">
        <span className="feed-preview-text1">{props.title.slice(0, 75)}</span>
      </div>
      <div className="feed-preview-container06">
        {loader && <Loader className={"loaderFeed"} />}
        {!loader && (imageSource ) && (
          <img
            alt={props.image_alt}
            src={ imageSource}
            className="feed-preview-image2"
          />
        )}
      </div>
    </div>
  );
};


const FeedPreview = (props) => {
  const { fav, loader, icona } = useLinkPreview(props?.feedData?.url, props?.index);

  const history = useHistory();
  const dispatch = useDispatch();
  const { auth, folder } = useSelector((state) => state);
  const handleDeleteSourceRequest = (route) => {
    const cbSuccess = (response) => {
      let arr = folder.source.filter((item) => route !== item.source);
      folder.source = arr;
      window.location.reload();
    };
    const cbFailure = (error) => {
      toast.error(pppsign1a, { 
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
      
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    };
    const data = new FormData();
    data.append("source", route);
  
    if (auth?.data?.token) {
      dispatch(
        deleteSourceRequest(data, auth?.data?.token, cbSuccess, cbFailure)
      );
    }
  };
  console.log('dati del feed' , props.feedData?.list.slice(0, 3))




  const translations = {
    en: {
      'copertura': 'Complete coverage >',

    },
    it: {

      'copertura': 'Copertura completa >',
     
    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser
  const text01Copertura = translate('copertura', lang);






  return (
    <div className={`feed-preview-container ${props.rootClassName} `}>
      <div className="feed-preview-container01">
     
        <div className="feed-preview-container02">
          <div className="feed-preview-container03">
            {icona && !loader && (
              <img alt={"Image"} src={icona} className="feed-preview-image" />
            )}
            {loader && <Loader className={"loaderFeed"} />}
            <span className="feed-preview-text">
              {props.feedData?.url || ""}
            </span>
          </div>
          <div
            className="deleteFeed"
            onClick={() => {
              handleDeleteSourceRequest(props.feedData.heading);
            }}
          >
            <img
              alt={props.image_alt4}
              src={props.image_src4}
              className="feed-preview-image1"
            />
          </div>
        </div>
      </div>
      {props.feedData?.list.slice(0, 3).map((item, index) => {
        return <FeedPreviewItem key={index} {...item} index={index} icona={icona} />;
      })}

      <div className="feed-preview-container13">
        <div
          onClick={() => {
            const state = { data: { list: props.feedData?.list, fav, url: props.feedData?.url, icona } };
            history.push("feed-detail", state);
          }}
          style={{ cursor: "pointer" }}
          className="feed-preview-container14"
        >
          <span className="feed-preview-text4">{text01Copertura}</span>
        </div>
      </div>
    </div>
  );
};

FeedPreview.defaultProps = {
  rootClassName: "",
  image_alt3: "image",
  image_alt4: "image",
  image_src4: "/playground_assets/cancel.png",
  image_alt: "image",
  image_src1: "/playground_assets/image%206-200h.png",
  text4: "https://www.ilsole24ore.com/",
  image_src: "/playground_assets/rectangle%2017-200h.png",
  image_alt2: "image",
  text2:
    "Nucleare, chi è per il sì e chi per il no. Cosa dicono i piani elettorali sull’energia...",
  image_src2: "/playground_assets/rectangle%2017-200h.png",
  image_alt1: "image",
  text1: "Copertura completa >",
  image_src3: "/playground_assets/rectangle%2017-200h.png",
  text: "Nucleare, chi è per il sì e chi per il no. Cosa dicono i piani elettorali sull’energia...",
  text3:
    "Nucleare, chi è per il sì e chi per il no. Cosa dicono i piani elettorali sull’energia...",
};

FeedPreview.propTypes = {
  rootClassName: PropTypes.string,
  image_alt3: PropTypes.string,
  image_alt4: PropTypes.string,
  image_src4: PropTypes.string,
  image_alt: PropTypes.string,
  image_src1: PropTypes.string,
  text4: PropTypes.string,
  image_src: PropTypes.string,
  image_alt2: PropTypes.string,
  text2: PropTypes.string,
  image_src2: PropTypes.string,
  image_alt1: PropTypes.string,
  text1: PropTypes.string,
  image_src3: PropTypes.string,
  text: PropTypes.string,
  text3: PropTypes.string,
};

export default FeedPreview;
