import React, { useContext, useState } from "react";
import './changeGroupPopUp.css';
import { AuthContext } from "../context/authContext";

const ChangeGroupPopUp = ({ closePopup, selectedItems, setSelectedItems }) => {
  const [selectedGroup, setSelectedGroup] = useState('');
  const { cisoInfo, changeGroup } = useContext(AuthContext);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Call the changeGroup function from the AuthContext
    changeGroup(selectedItems, selectedGroup);

    closePopup();
  };

  return (
    <div className="new-account-modal">
      <div style={{ minWidth: '410px', width: '410px', height: '423px', minHeight: '423px' }} className="new-account-content">
        <span className="close" onClick={closePopup}>&times;</span>
        <div className="new-account-header">Move Accounts</div>
        <div className="new-account-instructions">
          Choose the group to which to move the selected accounts.
        </div>
        <form className="new-account-form" onSubmit={handleSubmit}>
          <div className="new-account-inputs">
            <select
              className="dropdownchangegroup-form"
              id="group"
              value={selectedGroup}
              onChange={(e) => setSelectedGroup(e.target.value)}
              required
            >
              <option className="new-account-form" value="">Select a group</option>
              {cisoInfo.groups.map((group) => (
                <option key={group.groupName} value={group.groupName}>
                  {group.groupName}
                </option>
              ))}
            </select>
            <button type="submit" className="new-account-create-btn">
              Done
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangeGroupPopUp;