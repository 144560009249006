import React, { useContext, useEffect, useState } from "react";
import "./GroupModal.css"; // Ensure the CSS file has the necessary styles
import { AuthContext } from "../context/authContext";

const GroupModal = ({ isOpen, groups, onClose }) => {
  const [selectedGroups, setSelectedGroups] = useState(new Set());
  const [isLoading, setIsLoading] = useState(false);
  const authContext = useContext(AuthContext);
 
  if (!isOpen) return null;

  const handleSelectGroup = (groupId) => {
    const newSelection = new Set(selectedGroups);
    if (newSelection.has(groupId)) {
      newSelection.delete(groupId);
    } else {
      newSelection.add(groupId);
    }
    setSelectedGroups(newSelection);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedGroups(new Set(groups.map((group) => group.id)));
    } else {
      setSelectedGroups(new Set());
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const selectedGroupsData = groups.filter((group) =>
      selectedGroups.has(group.id)
    );
    const importedGroups = selectedGroupsData.map((group) => ({
      groupName: group.displayName,
      users: group.members
        .filter((member) => member["@odata.type"] === "#microsoft.graph.user")
        .map((member) => ({
          email: member.userPrincipalName || (member.otherMails && member.otherMails[0]),
          childAccountType: "Base", // Set the appropriate child account type
        })),
    }));
    await authContext.createGroupsAndAccounts(importedGroups);
    setIsLoading(false);
    onClose();
  };

  return (
    <div className="group-modal-overlay">
      <div className="group-modal">
        <div className="group-modal-header">
          <div className="group-modal-headerh1">
            <h2>Sync Groups</h2>
            <span>
              Select the Azure groups you want to import <br></br>into Weagle
            </span>
          </div>
          <div className="LeftAccountGroupModal">
            <span className="LeftAccountGroupModalc1">
              {authContext.cisoInfo.availableSeatsBase}
            </span>
            <span className="LeftAccountGroupModalc2">accounts left</span>
          </div>
        </div>

        <div className="account-list-widgetSettingsGroupModal">
          <table className="account-tableSettingsGroupModal">
            <thead>
              <tr>
                <th>
                  <input
                    className="noiconthAccountListSettingsGroupModal"
                    type="checkbox"
                    id="select-all"
                    onChange={handleSelectAll}
                    checked={selectedGroups.size === groups.length}
                  />
                </th>
                <th>Group Name</th>
                <th>Users</th>
              </tr>
            </thead>
            <tbody>
              {groups.map((group) => (
                <tr
                  key={group.id}
                  className={selectedGroups.has(group.id) ? "selected" : ""}
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedGroups.has(group.id)}
                      onChange={() => handleSelectGroup(group.id)}
                    />
                  </td>
                  <td>{group.displayName}</td>
                  <td>{group.memberCount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="group-modal-footer">
          <button className="btn-cancelGroupModal" onClick={onClose}>
            Cancel
          </button>
          <button className="btn-confirmGroupModal" onClick={handleSubmit}>
            Confirm
          </button>
        </div>
      </div>
      {isLoading ? (
        <div className="loading-overlayImportAzure">
          <div className="loading-spinnerImportAzure"></div>
        </div>
      ) : null}
    </div>
  );
};

export default GroupModal;