import React, { useContext } from "react";
import "./dropdown.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { DateRange } from "react-date-range";
import { SearchContext } from "../context/search_context";

const DatepickerDropdown = () => {
  const { newsDateSelection, setNewsDateSelection } = useContext(SearchContext);

  return (
    <div className={"dropdown-content"}>
      <DateRange
        rangeColors={["orange"]}
        editableDateInputs={true}
        onChange={(item) =>
          setNewsDateSelection([
            {
              ...item.selection,
              filterApplied: true,
            },
          ])
        }
        moveRangeOnFirstSelection={false}
        ranges={newsDateSelection}
      />
    </div>
  );
};

export default DatepickerDropdown;
