import React, { useContext } from "react";

import "./stripe-payment-plan.css";
import SignUpField from "./signUpField";
import { CardElement } from "@stripe/react-stripe-js";
import { AuthContext } from "../context/authContext";
import Loader from "./loader";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#ea9630",
      color: "#000",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#ea9630" },
    },
    invalid: {
      iconColor: "#ff5454",
      color: "#ff5454",
    },
  },
};

const StripePaymentPlan = (props) => {
  const { paymentDetails, setPaymentDetails, payMoney, loading, amount } =
    useContext(AuthContext);
  return (
    <div className="stripe-payment-plan-container">
      <div className="stripe-payment-plan-frame5180">
        <span className="stripe-payment-plan-text">
          <span>Inserisci i dati per la fatturazione</span>
        </span>
      </div>
      <div className="stripe-payment-plan-frame5181">
        <span className="stripe-payment-plan-text02">
          <span>Disdici il tuo abbonamento quando vuoi!</span>
        </span>
      </div>

      <div className="plan-sign-up-enterprise-frame53">
        <span className="plan-sign-up-enterprise-text04">
          <span>€{amount}</span>
        </span>
      </div>
      <div className="stripe-payment-plan-frame5187">
        <div className="stripe-payment-plan-frame5186">
          <div className="stripe-payment-plan-frame5183">
            <SignUpField
              onChange={(e) =>
                setPaymentDetails((prev) => ({
                  ...prev,
                  address_line1: e.target.value,
                }))
              }
              value={paymentDetails?.address_line1}
              label={"Indirizzo"}
              placeHolder={"Via Roma 16, Torino, 32165, Italia"}
            />
            <SignUpField
              onChange={(e) =>
                setPaymentDetails((prev) => ({
                  ...prev,
                  address_state: e.target.value,
                }))
              }
              value={paymentDetails?.address_state}
              label={"Comune"}
              placeHolder={"Torino"}
            />
            <SignUpField
              onChange={(e) =>
                setPaymentDetails((prev) => ({
                  ...prev,
                  address_country: e.target.value,
                }))
              }
              value={paymentDetails?.address_country}
              label={"Nazione"}
              placeHolder={"Italia"}
            />
            <SignUpField
              onChange={(e) =>
                setPaymentDetails((prev) => ({ ...prev, name: e.target.value }))
              }
              value={paymentDetails?.name}
              label={"Nome sulla carta"}
              placeHolder={"Andrea"}
            />
            <CardElement options={CARD_OPTIONS} />
          </div>
        </div>
        {loading && <Loader className={"loaderFeed"} />}
        {!loading && (
          <div
            onClick={() => payMoney()}
            className="stripe-payment-plan-frame13"
          >
            <span className="stripe-payment-plan-text28">
              <span>Crea account</span>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default StripePaymentPlan;
