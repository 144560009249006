import React from 'react';
import './addAccountWidget.css';

const AddAccountWidget = ({ openPopup }) => {
  return (
    <div className="addAccountWidgetContainer">
      <span className="unprotectedWidgetNumber"></span>
      <button className='buttonWidgetAddAccount' onClick={openPopup}>
        <img src="/playground_assets/userAddDashboard.svg" alt="AddAccount" className="addAccountWidgetIcon" />
      </button>
      <span className="addAccountWidgetText">Add More ACCOUNTS</span>
    </div>
  );
};

export default AddAccountWidget;
