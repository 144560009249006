import React from 'react'
import ProfileEnterprise from './profile-enterprise'
import { Helmet } from "react-helmet";
import SideBar from './side-bar';
import ProfileSide from './profile-side';
import './profile-enterprise.css';
import { useHistory } from "react-router-dom";
import Hamburgher from "../components/hamburgher-menu";
const ProfileEnterPriceMain = () => {
  const history = useHistory();
  return (
    <div className="profile-enterprice-container">
    <Helmet>
        <title>Data Wallet - Weagle</title>
        <meta property="og:title" content="PowerSearch - Weagle" />
      </Helmet> 
      <div className="power-search-container-principale">
        <div className="power-search-container1">
          <SideBar rootClassName="side-bar-root-class-name2"></SideBar>
        </div>
        <div className="menudesktop">
        <div className="nomenurightprofile">
    <ProfileSide  />
    </div>
    
    </div>
    <div className="menumobile">
    <ProfileSide  />
  <img style={{ cursor:'pointer', marginBottom:'20px'}} onClick={() => {
          history.push("/");
        }} src="/playground_assets/logoweaglenew.svg" alt="weagleLogo" className="menumobileLogo" />

    <Hamburgher /> 
    
    </div>
      </div>
    <ProfileEnterprise/>
    </div>
  )
}

export default ProfileEnterPriceMain