import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import { rootSaga } from "./saga";
import rootReducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
const sagaMiddleware = createSagaMiddleware();

let store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware, createLogger()))
);

sagaMiddleware.run(rootSaga);
export default store;
