import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SideBar from "../components/side-bar";
import SearchBar1 from "../components/search-bar1";
import ProfileSide from "../components/profile-side";
import TabPowerSearch from "../components/tab-power-search";
import SearchEngineSelection from "../components/search-engine-selection";
import WebSearchResultList from "../components/web-search-result-list";
import Loader from "../components/loader";
import { useDispatch, useSelector } from "react-redux";
import "./power-search.css";
import { SearchContext } from "../context/search_context";
import SearchPagination from "../components/search-pagination";
import PopUpSaveLink2 from "../components/pop-up-save-link2";
import { addArticleToFolderRequest } from "../redux/actions";
import Hamburgher from "../components/hamburgher-menu";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, useLocation , useParams} from "react-router-dom";
import LoaderPowerSearch from "../components/loader-power-search";
import NewmenuDesktop from "../components/newmenudesktop";
import LoaderSearchMobile from "../components/loaderSearchMobile";

const PowerSearch = (props) => {
  const { searchterm } = useParams();
  const location = useLocation(); // <-- Add this to use the location object
  const history = useHistory();
  const { loader, paginatedWebSearchResults, searchValue, setSearchValue, setTab, onSubmitSearch, accountType } = useContext(SearchContext);
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const [data, setData] = useState({});
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTab("web");
  }, []); // Run only on initial render

  useEffect(() => {
    // Checking if it's a power search route, i.e. '/power-search/something'
    const isPowerSearchRoute = location.pathname.startsWith('/power-search/') && location.pathname.split('/').length === 3;
  
    if (isPowerSearchRoute) {
   
      setTab("web");
      if (searchterm) {
        const decodedSearchTerm = decodeURIComponent(searchterm);
        setSearchValue(decodedSearchTerm);
      }
      
      const timeout = setTimeout(() => {
        const searchIconElement = document.querySelector('.search-bar1-search-icon');
        
        if (searchIconElement) {
          searchIconElement.click(); // Simulate click on the element
        }
      }, 1000);
  
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [location.pathname, searchterm, setSearchValue]);
  
  

 
  const handleAddArticleToFolderRequest = () => {
    const cbSuccess = (response) => {
      setSelectedFolder(null);
      setShowFolderModal(false);
      setTab('web');
      // alert("Article added to folder successfully");
      toast.success("Contenuto aggiunto con successo alla cartella", { 
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
      
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    };
    const cbFailure = (error) => {
      setLoading(false);
      alert(error.message);
    };

    const tempData = {
      ...data,
      folder_id: selectedFolder,
      type: 'web'
    };
    if (auth?.data?.token) {
      setLoading(true);
      dispatch(
        addArticleToFolderRequest(
          tempData,
          auth?.data?.token,
          cbSuccess,
          cbFailure
        )
      );
    }
  };
  console.log(paginatedWebSearchResults);
  return (
    <div style={{overflow:"hidden"}} className="power-search-container">
      <Helmet>
        <title>Power Search - Weagle</title>
        <meta property="og:title" content="PowerSearch - Weagle" />
      </Helmet>
      <div class="overlaymenu">
    
    </div>
      <div className="menumobile">
    <ProfileSide  />
  <img style={{ cursor:'pointer', marginBottom:'20px'}} onClick={() => {
          history.push("/");
        }} src="/playground_assets/logoweaglenew.svg" alt="weagleLogo" className="menumobileLogo" />

    <Hamburgher /> 
    
    </div>
      <div className="power-search-container-principale">
        <div className="power-search-container1">
          <SideBar rootClassName="side-bar-root-class-name2"/>
          
        </div>
        
        <div className="power-search-container2">
        <SearchBar1 rootClassName="search-bar1-root-class-name" searchTerm={searchterm} />
        </div>
        
        <div className="menudesktop">
        <div className="newmenucompleto">
         <NewmenuDesktop accountType={accountType} auth={auth}  />
    <ProfileSide  />
 
    </div> 
    </div>
 
      </div>
      <div className="powersearchhorizontaltab">

     
      <div className="power-search-tabs-filters-div provastilepower">
        <TabPowerSearch
          type={"web"}
          rootClassName="tab-power-search-root-class-name"
        ></TabPowerSearch>
        <div style={{visibility:"hidden"}} className="divsearchselectionscroll">
        <SearchEngineSelection rootClassName="search-engine-selection-root-class-name"/>
        </div>
       

      </div>
      </div>
      {loader && <LoaderPowerSearch />}
      {loader && <LoaderSearchMobile/>}
      <div
        className="power-search-results-div"
        style={{ marginTop: loader ? 0 : -89 }}
      >

        {paginatedWebSearchResults?.map((item) => (
          <WebSearchResultList
            setData={setData}
            showFolderModal={showFolderModal}
            setShowFolderModal={setShowFolderModal}
            //index={index} {...item}
            {...item}
            rootClassName="web-search-result-list-root-class-name1"
          />
        ))}

        {paginatedWebSearchResults?.length === 0 &&
          searchValue?.length === 0 && (
            <div className={"no_data_found_text"}>
              Try searching keywords i.e 'Laptops'
            </div>
          )}
      </div>
      <div className="footersearch">
      <SearchPagination />
      <div style={{marginBottom:"20px"}} className="divsearchselectionscroll">
        <SearchEngineSelection rootClassName="search-engine-selection-root-class-name"/>
        </div>
      </div>
  
      {showFolderModal && (
        <PopUpSaveLink2
          selectedFolder={selectedFolder}
          setSelectedFolder={setSelectedFolder}
          folderPress={handleAddArticleToFolderRequest}
          setShow={setShowFolderModal}
          setTab={setTab}
        />
      )}
    </div>
  );
};

export default PowerSearch;
