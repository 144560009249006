import React, { useContext, useState } from "react";

import "./plan-sign-up-enterprise.css";
import { AuthContext } from "../context/authContext";
import DropDownEnterprice from "./dropDownEnterprice";

import PersonalizzazionePiano from "./personalizzazionepiano";
const PlanSignUpEnterprise = (props) => {
  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const closePopup = (event) => {
    if (event.target.className === "popup-wrapper") {
      setShowPopup(false);
    }
  };
  const {
    setSelectedSignUpType,
    setPlanInvitesInput,
    planInvitesInput,
    handleSetPlan,
    setAmount,
    enterprice,
    priceEnterprice,
    setPrice,
    amount,
    planCustom,
    setPlanCustom,
    
  } = useContext(AuthContext);
  const [show, setShow] = useState(false);

  const handleDropDown = () => {
    setShow((prev) => !prev);
  };
  const handelSelect = (obj) => {
    setPlanInvitesInput(obj.inviteUser);
    setAmount(obj.amount);
    setPrice(obj);
    setShow(false);
  };

  return (
    <div className="plan-sign-up-enterprise-container">
      <div className="plan-sign-up-enterprise-frame5176">
        <div className="plan-sign-up-enterprise-frame5148">
          <span className="plan-sign-up-enterprise-text">
            <span>Seleziona il piano che più ti si addice</span>
          </span>
        </div>
        <div className="plan-sign-up-enterprise-frame5175">
          <div className="plan-sign-up-enterprise-frame5160">
            <div className="plan-sign-up-enterprise-frame5159">
              <div className="plan-sign-up-enterprise-frame5158">
                <span className="plan-sign-up-enterprise-text02">
                  <span>enterprise</span>
                </span>
                <div className="plan-sign-up-enterprise-frame53">
                  <span className="plan-sign-up-enterprise-text04">
                    <span>€ {amount}</span>
                  </span>
                </div>
              </div>
              <div className="plan-sign-up-enterprise-frame5157">
                {show && (
                  <DropDownEnterprice
                    handelSelect={handelSelect}
                    enterprice={enterprice}
                  />
                )}
                <div className="plan-sign-up-enterprise-frame5156">
                  <div className="plan-sign-up-enterprise-frame5149">
                    <img
                      src="/playground_assets/checkmarkcircle155541-lab.svg"
                      alt="checkmarkcircle155541"
                      className="plan-sign-up-enterprise-checkmarkcircle15"
                    />
                    <div className="plan-sign-up-enterprise-frame32">
                      <div className="plan-sign-up-enterprise-frame33">
                        <span className="plan-sign-up-enterprise-text08">
                          <span>Numero dispositivi</span>
                        </span>
                      </div>

                      <div
                        className="plan-sign-up-enterprise-frame4"
                        onClick={() => {
                          handleDropDown();
                        }}
                      >
                        <div className="plan-sign-up-enterprise-frame37">
                          <span className="plan-sign-up-enterprise-text10">
                            <span>{priceEnterprice.label}</span>
                          </span>
                          <img
                            src="/playground_assets/vector27032-tbm.svg"
                            alt="Vector27032"
                            className="plan-sign-up-enterprise-vector2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="plan-sign-up-enterprise-frame5150">
                    <img
                      src="/playground_assets/checkmarkcircle115541-zjp3.svg"
                      alt="checkmarkcircle115541"
                      className="plan-sign-up-enterprise-checkmarkcircle11"
                    />
                    <span className="plan-sign-up-enterprise-text12">
                      <span>Massima privacy aziendale</span>
                    </span>
                  </div>
                  <div className="plan-sign-up-enterprise-frame5151">
                    <img
                      src="/playground_assets/checkmarkcircle125541-yhf.svg"
                      alt="checkmarkcircle125541"
                      className="plan-sign-up-enterprise-checkmarkcircle12"
                    />
                    <span className="plan-sign-up-enterprise-text14">
                      <span>Garanzia di non tracciamento dati</span>
                    </span>
                  </div>
                  <div className="plan-sign-up-enterprise-frame5152">
                    <img
                      src="/playground_assets/checkmarkcircle135541-cnmu.svg"
                      alt="checkmarkcircle135541"
                      className="plan-sign-up-enterprise-checkmarkcircle13"
                    />
                    <span className="plan-sign-up-enterprise-text16">
                      <span>Navigazione senza pubblicità</span>
                    </span>
                  </div>
                  <div className="plan-sign-up-enterprise-frame5153">
                    <img
                      src="/playground_assets/checkmarkcircle145541-6ydb.svg"
                      alt="checkmarkcircle145541"
                      className="plan-sign-up-enterprise-checkmarkcircle14"
                    />
                    <span className="plan-sign-up-enterprise-text18">
                      <span>Crea fino a 25 cartelle per utente</span>
                    </span>
                  </div>
                  <div className="plan-sign-up-enterprise-frame5154">
                    <img
                      src="/playground_assets/checkmarkcircle165542-87wk.svg"
                      alt="checkmarkcircle165542"
                      className="plan-sign-up-enterprise-checkmarkcircle16"
                    />
                    <span className="plan-sign-up-enterprise-text20">
                      <span>Salvare massimo 50 fonti nel feed</span>
                    </span>
                  </div>
                  <div className="plan-sign-up-enterprise-frame5155">
                    <img
                      src="/playground_assets/checkmarkcircle175542-xp5.svg"
                      alt="checkmarkcircle175542"
                      className="plan-sign-up-enterprise-checkmarkcircle17"
                    />
                    <span className="plan-sign-up-enterprise-text22">
                      <span>Supporto tecnico h24</span>
                    </span>
                  </div>
                </div>
                <div style={{cursor:'pointer'}}
                  onClick={() => {
                    setSelectedSignUpType("stripeForm");
                  }}
                  className="plan-sign-up-enterprise-frame91"
                >
                  <span className="plan-sign-up-enterprise-text24">
                    <span className="selezionapianob1">Seleziona piano</span>
                  </span>
                  <img
                    src="/playground_assets/vector25542-l7y9.svg"
                    alt="Vector25542"
                    className="plan-sign-up-enterprise-vector21"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="plan-sign-up-enterprise-frame5174">
            <div className="plan-sign-up-enterprise-frame5173">
              <div className="plan-sign-up-enterprise-frame5163">
                <div className="plan-sign-up-enterprise-frame5161">
                  <span className="plan-sign-up-enterprise-text26"></span>
                </div>
                <div className="plan-sign-up-enterprise-frame5162">
                  <span className="plan-sign-up-enterprise-text28">
                    <span className="testopersonalizzato">
                      Se la tua azienda ha più di 1000 dipendenti richiedi un
                      preventivo personalizzato
                    </span>
                    <span className="testopersonalizzatoMobile">
                    Piano personalizzato
                    </span>
                  </span>
                </div>
              </div>
              <div className="plan-sign-up-enterprise-frame5172">
                <div className="plan-sign-up-enterprise-frame5171">
                  <div className="plan-sign-up-enterprise-frame5164">
                    <img
                      src="/playground_assets/checkmarkcircle1155542-7pcf.svg"
                      alt="checkmarkcircle1155542"
                      className="plan-sign-up-enterprise-checkmarkcircle115"
                    />
                    <div className="plan-sign-up-enterprise-frame94">
                      <div className="plan-sign-up-enterprise-frame331">
                        <span className="plan-sign-up-enterprise-text30">
                          <span>Numero dispositivi</span>
                        </span>
                      </div>
                      <div className="plan-sign-up-enterprise-frame31">
                        <input
                          min={1}
                          placeholder={"Numero dispositivi"}
                          className="plan-sign-up-enterprise-text32"
                          type="number"
                          value={planCustom}
                          onChange={(e) => setPlanCustom(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="plan-sign-up-enterprise-frame5165">
                    <img
                      src="/playground_assets/checkmarkcircle1165542-idli.svg"
                      alt="checkmarkcircle1165542"
                      className="plan-sign-up-enterprise-checkmarkcircle116"
                    />
                    <span className="plan-sign-up-enterprise-text34">
                      <span>Massima privacy aziendale</span>
                    </span>
                  </div>
                  <div className="plan-sign-up-enterprise-frame5166">
                    <img
                      src="/playground_assets/checkmarkcircle1175542-ozxv.svg"
                      alt="checkmarkcircle1175542"
                      className="plan-sign-up-enterprise-checkmarkcircle117"
                    />
                    <span className="plan-sign-up-enterprise-text36">
                      <span>Garanzia di non tracciamento dati</span>
                    </span>
                  </div>
                  <div className="plan-sign-up-enterprise-frame5167">
                    <img
                      src="/playground_assets/checkmarkcircle1185542-0d33.svg"
                      alt="checkmarkcircle1185542"
                      className="plan-sign-up-enterprise-checkmarkcircle118"
                    />
                    <span className="plan-sign-up-enterprise-text38">
                      <span>Navigazione senza pubblicità</span>
                    </span>
                  </div>
                  <div className="plan-sign-up-enterprise-frame5168">
                    <img
                      src="/playground_assets/checkmarkcircle1195542-gdsf.svg"
                      alt="checkmarkcircle1195542"
                      className="plan-sign-up-enterprise-checkmarkcircle119"
                    />
                    <span className="plan-sign-up-enterprise-text40">
                      <span>Crea fino a 100 cartelle per utente</span>
                    </span>
                  </div>
                  <div className="plan-sign-up-enterprise-frame5169">
                    <img
                      src="/playground_assets/checkmarkcircle1205542-4yab.svg"
                      alt="checkmarkcircle1205542"
                      className="plan-sign-up-enterprise-checkmarkcircle120"
                    />
                    <span className="plan-sign-up-enterprise-text42">
                      <span>Salvare infinite fonti nel feed</span>
                    </span>
                  </div>
                  <div className="plan-sign-up-enterprise-frame5170">
                    <img
                      src="/playground_assets/checkmarkcircle1215542-8gyn.svg"
                      alt="checkmarkcircle1215542"
                      className="plan-sign-up-enterprise-checkmarkcircle121"
                    />
                    <span className="plan-sign-up-enterprise-text44">
                      <span>Supporto tecnico h24</span>
                    </span>
                  </div>
                </div>
                <div style={{cursor:'pointer'}}
          onClick={() => {  togglePopup(); }}

                  className="plan-sign-up-enterprise-frame95"
                >
                  {showPopup && (
                    <div className="popup-wrapper">
                      <div className="popup">
                        <div className="closepop" onClick={togglePopup}>
                          X
                        </div>
                        <PersonalizzazionePiano />
                      </div>
                    </div>
                  )}
                  <span className="plan-sign-up-enterprise-text46">
                    <span>Richiedi servizio</span>
                  </span>
                  <img
                    src="/playground_assets/vector25542-3l9n.svg"
                    alt="Vector25542"
                    className="plan-sign-up-enterprise-vector22"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanSignUpEnterprise;
