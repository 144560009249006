import React, { useRef } from "react";

import "./pop-up-save-link1.css";
import { useOnClickOutside } from "./useClickOuteSide";
import { useContext } from "react";
import { SearchContext } from "../context/search_context";


const PopUpSaveLink1 = (props) => {
  const { handleSaveSourceRequest, tab } = useContext(SearchContext);
  const ref = useRef();

  useOnClickOutside(ref, () => props.setShow(false));


  const translations = {
    en: {
     
      'ppps1': "Back to Weagle",
      'ppps2': 'Save article',
      'ppps3': 'Save source',
      'ppps4': 'Companies',
      'ppps5': 'Share link',
      'ppps6': 'Link copied!',
    },
    it: {

  
      'ppps1': 'Torna a Weagle',
      'ppps2': 'Salva Articolo',
      'ppps3': 'Salva fonte',
      'ppps4': 'Companies',
      'ppps5': 'Condividi link',
      'ppps6': 'Link copiato!',
    },
  };
  function translate(key, lang) {
    const italianTranslations = translations.it;
  
    if (lang === 'it' && italianTranslations[key]) {
      return italianTranslations[key];
    }
  
    return translations.en[key];
  }
  const lang = navigator.language.split('-')[0]; // Get the user's language from the browser

  const ppps1a = translate('ppps1', lang);
  const ppps2a = translate('ppps2', lang);
  const ppps3a = translate('ppps3', lang);
  const ppps5a = translate('ppps5', lang);
  const ppps6a = translate('ppps6', lang);
  return (
    <div className="pop-up-save-link1-container">
      <div className="pop-up-save-link1-filtro-data">
        <div  className="pop-up-save-link1-frame119">
          <div ref={ref} className="pop-up-save-link1-frame5141">
            <div className="pop-up-save-link1-group115">
              <img
                alt="Arrow2614582"
                src="/playground_assets/arrow2614582-8tom.svg"
                className="pop-up-save-link1-arrow261"
              />
              <span className="pop-up-save-link1-text">
                <span>{ppps1a}</span>
              </span>
            </div>
            <div
              onClick={() => props?.setShowFolderModal(true)}
              className="pop-up-save-link1-group116"
            >
              <img
                alt="bookmarkfavorite134582"
                src="/playground_assets/bookmarkfavorite134582-ddtl.svg"
                className="pop-up-save-link1-bookmarkfavorite13"
              />
              <span className="pop-up-save-link1-text2">
                <span>{ppps2a}</span>
              </span>
            </div>
            {tab === "news" && (
              <div className="pop-up-save-link1-group117">
                <img
                  alt="messageschattext14582"
                  src="/playground_assets/messageschattext14582-v70q.svg"
                  className="pop-up-save-link1-messageschattext1"
                />
                <span style={{whiteSpace:'nowrap'}} className="pop-up-save-link1-text4">
                  <span
                    onClick={() => {
                      handleSaveSourceRequest(props?.source, props?.url);
                    }}
                  >
                 {ppps3a}
                  </span>
                </span>
              </div>
            )}

<div className="pop-up-save-link1-group118"
  onClick={() => {
    navigator.clipboard.writeText(props.link)
    const popup = document.createElement('div');

    // Close Icon
    const closeIcon = document.createElement('span');
    closeIcon.innerHTML = '&times;';
    closeIcon.style.position = 'absolute';
    closeIcon.style.top = '10px';
    closeIcon.style.right = '10px';
    closeIcon.style.fontSize = '30px';
    closeIcon.style.cursor = 'pointer';
    closeIcon.addEventListener('click', () => {
      popup.remove();
    });
    
    // Popup Content
    popup.style.position = 'fixed';
    popup.style.top = '50%';
    popup.style.width = '20#%';
    popup.style.left = '50%';
    popup.style.transform = 'translate(-50%, -50%)';
    popup.style.backgroundColor = 'white';
    popup.style.borderRadius = '7px';
    popup.style.padding = '40px';
    popup.style.zIndex = '40000';
    popup.style.boxShadow = '0px 0px 5px rgba(0, 0, 0, 0.3)';
    
    // Text Container
    const textContainer = document.createElement('div');
    textContainer.style.display = 'flex';
    textContainer.style.flexDirection = 'column';
    textContainer.style.alignItems = 'center';
    textContainer.style.textAlign = 'center';
    // Text
    const text = document.createElement('p');
    text.innerHTML = ppps6a;
    text.style.marginBottom = '20px';
    text.style.flexGrow = '1';
    text.style.width = '70%';
    
    text.style.maxWidth = '400px'; // Limit width on larger screens
    
    
    
    // Add Click Event Listener to Document
    document.addEventListener('click', (event) => {
      if (!popup.contains(event.target)) {
        popup.remove();
      }
    });
    
    // Append Elements
    textContainer.appendChild(text);
    
    popup.appendChild(closeIcon);
    popup.appendChild(textContainer);
    document.body.appendChild(popup);
    
    // Media Query for Mobile Devices
    const mediaQuery = window.matchMedia('(max-width: 480px)');
    if (mediaQuery.matches) {
      popup.style.width = '90%';
      textContainer.style.alignItems = 'center';
      textContainer.style.width = '90%';
      textContainer.style.textAlign = 'center';
    }
  }}
>

              <div className="pop-up-save-link1-share11">
                <div className="pop-up-save-link1-group">
                  <img
                    alt="Vector4582"
                    src="/playground_assets/vector4582-bmmr.svg"
                    className="pop-up-save-link1-vector"
                  />
                  <img
                    alt="Vector4582"
                    src="/playground_assets/vector4582-d0i.svg"
                    className="pop-up-save-link1-vector1"
                  />
                  <img
                    alt="Vector4582"
                    src="/playground_assets/vector4582-mro8.svg"
                    className="pop-up-save-link1-vector2"
                  />
                  <img
                    alt="Vector4582"
                    src="/playground_assets/vector4582-drw.svg"
                    className="pop-up-save-link1-vector3"
                  />
                  <img
                    alt="Vector4582"
                    src="/playground_assets/vector4582-81oq.svg"
                    className="pop-up-save-link1-vector4"
                  />
                </div>
              </div>
              <span className="pop-up-save-link1-text6">
                <span>{ppps5a}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUpSaveLink1;
