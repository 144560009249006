// AiSettingsPrivacy.jsx
import React from 'react';
import './aiSettingsPrivacy.css';

const AiSettingsPrivacy = ({ aiSettings, onChange, disabled }) => {
  return (
    <div className="ai-settings-privacy">
      <div className="ai-settings-privacy-header">
        <h2>AI Settings</h2>
      </div>
      <div className="ai-settings-privacy-section">
        <p>Select the type of data you want to block in the prompt:</p>
        <div className="ai-settings-privacy-checkboxes ai-settings-privacy-section3">
          <label>
            <input
              type="checkbox"
              checked={aiSettings.emails}
              onChange={(e) => onChange('emails', e.target.checked)}
              disabled={disabled}
            />{' '}
            E-mails
          </label>
          <label>
            <input
              type="checkbox"
              checked={aiSettings.addresses}
              onChange={(e) => onChange('addresses', e.target.checked)}
              disabled={disabled}
            />{' '}
            Addresses
          </label>
          <label>
            <input
              type="checkbox"
              checked={aiSettings.properNames}
              onChange={(e) => onChange('properNames', e.target.checked)}
              disabled={disabled}
            />{' '}
            Proper names
          </label>
          <label>
            <input
              type="checkbox"
              checked={aiSettings.companyNames}
              onChange={(e) => onChange('companyNames', e.target.checked)}
              disabled={disabled}
            />{' '}
            Names of company
          </label>
          <label>
            <input
              type="checkbox"
              checked={aiSettings.phoneNumbers}
              onChange={(e) => onChange('phoneNumbers', e.target.checked)}
              disabled={disabled}
            />{' '}
            Telephone numbers
          </label>
        </div>
      </div>
      <div className="ai-settings-privacy-section">
        <p>Select the types of documents you want to upload:</p>
        <div className="ai-settings-privacy-checkboxes">
          <label>
            <input
              type="checkbox"
              checked={aiSettings.pdf}
              onChange={(e) => onChange('pdf', e.target.checked)}
              disabled={disabled}
            />{' '}
            PDF
          </label>
          <label>
            <input
              type="checkbox"
              checked={aiSettings.doc}
              onChange={(e) => onChange('doc', e.target.checked)}
              disabled={disabled}
            />{' '}
            Doc
          </label>
          <label>
            <input
              type="checkbox"
              checked={aiSettings.xls}
              onChange={(e) => onChange('xls', e.target.checked)}
              disabled={disabled}
            />{' '}
            Xls
          </label>
        </div>
      </div>
      <div className="ai-settings-privacy-section">
        <p>Select the level of document you want to allow:</p>
        <div className="ai-settings-privacy-checkboxes ai-settings-privacy-section3">
          <label>
            <input
              type="checkbox"
              checked={aiSettings.public}
              onChange={(e) => onChange('public', e.target.checked)}
              disabled={disabled}
            />{' '}
            Public
          </label>
          <label>
            <input
              type="checkbox"
              checked={aiSettings.confidential}
              onChange={(e) => onChange('confidential', e.target.checked)}
              disabled={disabled}
            />{' '}
            Confidential
          </label>
          <label>
            <input
              type="checkbox"
              checked={aiSettings.internal}
              onChange={(e) => onChange('internal', e.target.checked)}
              disabled={disabled}
            />{' '}
            Internal
          </label>
          <label>
            <input
              type="checkbox"
              checked={aiSettings.restricted}
              onChange={(e) => onChange('restricted', e.target.checked)}
              disabled={disabled}
            />{' '}
            Restricted
          </label>
          <label>
            <input
              type="checkbox"
              checked={aiSettings.private}
              onChange={(e) => onChange('private', e.target.checked)}
              disabled={disabled}
            />{' '}
            Private
          </label>
        </div>
      </div>
      <p className="ai-settings-privacy-warning">
       <b>Warning!</b> For maximum privacy, the selected options prevent the user from uploading certain types of documents or obscure some of the information contained in it.
      </p>
    </div>
  );
};

export default AiSettingsPrivacy;
