import React from 'react'

import PropTypes from 'prop-types'

import './social-search-result.css'

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}
const SocialSearchResult = (props) => {
  return (
    <div  className="social-search-result-container">
      <div onClick={() => window.open('https://it-it.facebook.com/SerieA/') 
      // openInNewTab('https://it-it.facebook.com/SerieA/')
    } className="social-search-result-social-result-main-div">
        <div className="social-search-result-social-result-link-description-title">
          <span className="social-search-result-social-link">
            {props.LinkWebSearch1}
          </span>
          <span className="social-search-result-social-title">
            {props.DescriptionWebSearch}
          </span>
          <span className="social-search-result-social-description">
            {props.DescriptionLongWeBSearch}
          </span>
        </div>
        <div className="social-search-result-share-icon-div">
          <img
            alt={props.image_alt}
            src={props.image_src}
            className="social-search-result-share-icon"
          />
          <img
            alt={props.image_alt1}
            src={props.image_src1}
            className="social-search-result-facebook-icon"
          />
        </div>
      </div>
    </div>
  )
}

SocialSearchResult.defaultProps = {
  image_src: '/playground_assets/share.1%203.svg',
  LinkWebSearch1: 'https://it-it.facebook.com › ... › Campionato sportivo',
  image_src1: '/playground_assets/image%202.svg',
  DescriptionWebSearch: 'Serie A - Wikipedia',
  image_alt: 'image',
  DescriptionLongWeBSearch:
    "10 ore fa — Il Campionato di Serie A, colloquialmente abbreviato in Serie A e ufficialmente denominato Serie A TIM dall'edizione 1998-1999 per ragioni di ...",
  image_alt1: 'image',
  rootClassName: '',
}

SocialSearchResult.propTypes = {
  image_src: PropTypes.string,
  LinkWebSearch1: PropTypes.string,
  image_src1: PropTypes.string,
  DescriptionWebSearch: PropTypes.string,
  image_alt: PropTypes.string,
  DescriptionLongWeBSearch: PropTypes.string,
  image_alt1: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default SocialSearchResult
