import React, { useState } from "react";
import "./accountListWidgets.css";
import AccountListWidgetComponent from "./accountListWidgetComponent";
import SearchBarAccountList from "./searchBarAccountList";

const AccountListWidgets = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  return (
    <div className="accountListWidgetsContainer">
      <div className="AccountListNameSearchparent">
        <div className="AccountListNameSearch">
          <div className="AccountListNameSearchc1">
            <span className="accountListTitle">ACCOUNT LIST</span>
          </div>
          <div className="AccountListNameSearchc2">
            <SearchBarAccountList onSearch={handleSearch} />
          </div>
        </div>
        <AccountListWidgetComponent searchTerm={searchTerm} />
      </div>
    </div>
  );
};

export default AccountListWidgets;