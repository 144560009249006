import React from "react";
import "./loaderSearchMobile.css";
const LoaderSearchMobile = (props) => {
  return (
    <div className="loader-containerloaderSearchMobile">
      <div className="loaderSearchMobile"></div>
   
    </div>
  );
};

export default LoaderSearchMobile;
