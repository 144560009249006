import React, { useState } from "react";

import ComponentHeadArchivio from "./component-head-archivio";
import ArchivioDetailFilterAndResults from "./archivio-detail-filter-and-results";
import "./archivio-component-detail.css";

const ArchivioComponentDetail = (props) => {
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  console.log("following are folders", props.list);
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    setSearchResults([
      ...props.list.filter(
        (item) =>
          item?.title?.toLowerCase().includes(value?.toLowerCase()) ||
          item?.description?.toLowerCase().includes(value?.toLowerCase())
      ),
    ]);
  };

  return ( 
    <div className="archivio-component-detail-container">
      <ComponentHeadArchivio
        search={search}
        onChange={handleSearch}
        rootClassName="component-head-archivio-root-class-name"
        folder={props.folder}
      />
   
      <ArchivioDetailFilterAndResults
        folder={props.folder}
        list={search?.length > 3 ? searchResults : props.list}
        rootClassName="archivio-detail-filter-and-results-root-class-name"
      />
    </div>
  );
};

export default ArchivioComponentDetail;
