import {takeLatest, put} from 'redux-saga/effects';
import * as types from '../../actions/types';
import {Api} from '../../../utils';
import {endPoints} from '../../../utils';

export function* addArticleToFolderRequest() {
  yield takeLatest(types.ADD_ARTICLE_TO_FOLDER_REQUEST, addArticleToFolder);
}

function* addArticleToFolder(params) {
  // console.log('[add article to folder saga]', params);
  try {
    let options = {
      headers: {
        Authorization: 'Bearer ' + params.token,
      },
    };
    let configuration = Object.assign(options);
    let response = yield Api.postAxios(
      endPoints.addArticleToFolder,
      params.params,
      configuration,
    );
    if (response?.status) {
      params.cbSuccess(response);
    } else {
      params.cbFailure(response);
    }
  } catch (err) {
    console.log(
      'error from add article to folder request saga -- > > >  > ',
      err,
    );
    params.cbFailure(err.message);
  }
}
