import {takeLatest, put} from 'redux-saga/effects';
import * as types from '../../actions/types';
import {Api} from '../../../utils';
import {endPoints} from '../../../utils';

export function* deleteArticleFromFolderRequest() {
  yield takeLatest(
    types.DELETE_ARTICLE_FROM_FOLDER_REQUEST,
    deleteArticleFromFolder,
  );
}

function* deleteArticleFromFolder(params) {
  // console.log('[add article to folder saga]', params);
  try {
    let options = {
      headers: {
        Authorization: 'Bearer ' + params.token,
      },
    };
    let configuration = Object.assign(options);
    let response = yield Api.postAxios(
      endPoints.deleteArticleFromFolder,
      params.params,
      configuration,
    );
    if (response?.status) {
      params.cbSuccess(response);
    } else {
      params.cbFailure(response);
    }
  } catch (err) {
    console.log(
      'error from delete article from folder request saga -- > > >  > ',
      err,
    );
    params.cbFailure(err.message);
  }
}
